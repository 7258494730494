import { DaysOfWeekChoices } from 'src/lib';
import { Weekday, WeekdayDTO } from 'src/types';

export function getDtoFromWeekdays(weekdays: Weekday[]): WeekdayDTO {
  return weekdays.reduce(
    (result, weekday) =>
      result +
      (DaysOfWeekChoices.find(dayOfWeek => dayOfWeek.name === weekday)?.id ||
        0),
    0
  );
}
