import { SyntheticEvent, useEffect, useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import { Link, matchPath, useLocation } from 'react-router-dom';

export type TabItem = {
  label: string;
  link: string;
  pattern?: string;
  disabled?: boolean;
};

export type NavigationTabsProps = {
  children: TabItem[];
};

export const NavigationTabs = ({ children: tabs }: NavigationTabsProps) => {
  const { pathname } = useLocation();
  const [tabValue, setTabValue] = useState<string | undefined>();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    tabs.find((tab) => {
      const pattern = tab.pattern || `${tab.link}/*`;
      const routeMatch = matchPath(pattern, pathname);
      if (routeMatch) {
        setTabValue(`${tab.link}`);
        return true;
      }
      return false;
    });
  }, [tabs, pathname]);

  return tabValue ? (
    <Tabs value={tabValue} variant="scrollable" onChange={handleChange}>
      {tabs.map((tab) => (
        <Tab
          key={tab.link}
          label={tab.label}
          component={Link}
          value={tab.link}
          to={tab.disabled ? '' : tab.link}
          disabled={tab.disabled}
        />
      ))}
    </Tabs>
  ) : (
    <></>
  );
};
