import { Weekday } from 'src/types/enums/Weekday.enum';
import {
  PlacementScheduleDTO,
  PlacementScheduleCreateResponseDTO
} from 'src/types/dto';
import { Student, Supervisor, TrainingArea } from 'src/types';

export type PlacementSchedule = {
  startDateTime: Date;
  endDateTime: Date;
  createdDateTime: Date;
  lastUpdatedDateTime: Date;
  recurrence: Recurrence;
  academicBookingStatus?: AcademicBookingStatus;
  trainingArea?: TrainingArea;
  supervisors: Supervisor[];
  students: Student[];
} & Omit<
  PlacementScheduleDTO,
  | 'startDateTime'
  | 'endDateTime'
  | 'createdDateTime'
  | 'lastUpdatedDateTime'
  | 'recurrence'
  | 'academicBookingStatus'
>;

export type Recurrence = {
  id: number;
  frequencyType: string;
  activeDate: Date;
  expiryDate: Date;
  arrivalDate?: Date;
  departureDate?: Date;
  enabled: boolean;
  weekdays: Weekday[];
  future: boolean;
  series: boolean;
  interval: number;
  maxOccurrences: number;
};

export type PlacementScheduleCreateResponse =
  PlacementScheduleCreateResponseDTO;

export enum AcademicBookingStatus {
  APPROVED,
  AWAITING_APPROVAL,
  REJECTED,
  CANCELLED
}
