import { RoomBookingStatus } from 'src/types';
import { PillStyle } from 'src/common';

export function getPillStyleFromBookingStatus(status: RoomBookingStatus) {
  switch (status) {
    case RoomBookingStatus.CANCELLED:
      return PillStyle.GREY;
    case RoomBookingStatus.APPROVED:
      return PillStyle.GREEN;
    case RoomBookingStatus.AWAITING_APPROVAL:
      return PillStyle.AMBER;
    case RoomBookingStatus.REJECTED:
      return PillStyle.RED;
  }
}
