import { useCallback } from 'react';
import { useAxiosInstance } from 'src/lib';
import {
  ThemeSchedule,
  BulkThemeScheduleDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  ThemeScheduleDTO,
  BulkThemeSchedule,
  Theme,
  BulkThemeScheduleDeleteDTO
} from 'src/types';

export interface IThemeScheduleService {
  getThemeSchedules: GetAPI<ThemeSchedule>;
  getThemeSchedule: (
    themeScheduleId: number,
    abortController?: AbortController
  ) => Promise<ThemeSchedule>;
  toggleThemeScheduleStatus: (
    isActiveStatus: number,
    active: boolean,
    abortController?: AbortController
  ) => Promise<void>;
  updateThemeSchedule: (
    themeSchedule: ThemeSchedule,
    abortController?: AbortController
  ) => Promise<void>;
  deleteThemeSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  scheduleBulkThemes: (
    data: BulkThemeSchedule,
    abortController?: AbortController
  ) => Promise<void>;
  deleteBulkThemeSchedule: (
    themeScheduleIds?: number[],
    moduleScheduleId?: number,
    abortController?: AbortController
  ) => Promise<BulkThemeScheduleDeleteDTO>;
}

const ThemeScheduleStatusUrlParam = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const useThemeScheduleService = (): IThemeScheduleService => {
  const themeScheduleApi = useAxiosInstance('/v2/themeSchedules');

  const getThemeSchedules = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<ThemeSchedule>> => {
      return themeScheduleApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<ThemeSchedule>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ThemeSchedule>;
        });
    },
    [themeScheduleApi]
  );

  const getThemeSchedule = useCallback(
    (
      themeScheduleId: number,
      abortController?: AbortController
    ): Promise<ThemeSchedule> => {
      return themeScheduleApi
        .get(`${themeScheduleId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => response.data as ThemeSchedule);
    },
    [themeScheduleApi]
  );

  const toggleThemeScheduleStatus = useCallback(
    (
      themeScheduleId: number,
      isActiveStatus: boolean,
      abortController?: AbortController
    ): Promise<void> =>
      themeScheduleApi.put(
        `toggleStatus/${themeScheduleId}/${
          isActiveStatus
            ? ThemeScheduleStatusUrlParam.ACTIVE
            : ThemeScheduleStatusUrlParam.INACTIVE
        }`,
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      ),
    [themeScheduleApi]
  );

  const updateThemeSchedule = useCallback(
    (
      themeSchedule: ThemeSchedule,
      abortController?: AbortController
    ): Promise<void> =>
      themeScheduleApi.put(
        `${themeSchedule.id}`,
        getDTOfromThemeSchedule(themeSchedule),
        {
          withCredentials: true,

          signal: abortController?.signal
        }
      ),

    [themeScheduleApi]
  );

  const deleteThemeSchedule = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      themeScheduleApi.delete(`${id}`, {
        withCredentials: true,

        signal: abortController?.signal
      }),

    [themeScheduleApi]
  );

  const deleteBulkThemeSchedule = useCallback(
    (
      themeScheduleIds?: number[],
      moduleScheduleId?: number,
      abortController?: AbortController
    ): Promise<BulkThemeScheduleDeleteDTO> => {
      return themeScheduleApi
        .delete('', {
          data: {
            ids: themeScheduleIds,
            moduleScheduleId: moduleScheduleId
          },
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => {
          return response.data as BulkThemeScheduleDeleteDTO;
        });
    },
    [themeScheduleApi]
  );

  const scheduleBulkThemes = (
    bulkThemeSchedule: BulkThemeSchedule,
    abortController?: AbortController
  ): Promise<void> => {
    return themeScheduleApi.post(
      '',
      getDTOfromBulkThemeSchedule(bulkThemeSchedule),
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );
  };

  return {
    getThemeSchedules,
    getThemeSchedule,
    toggleThemeScheduleStatus,
    updateThemeSchedule,
    deleteThemeSchedule,
    deleteBulkThemeSchedule,
    scheduleBulkThemes
  };

  function getDTOfromThemeSchedule(
    themeSchedule: ThemeSchedule
  ): ThemeScheduleDTO {
    return { ...themeSchedule };
  }

  function getDTOfromBulkThemeSchedule(
    bulkThemeSchedule: BulkThemeSchedule
  ): BulkThemeScheduleDTO {
    return {
      active: bulkThemeSchedule.active,
      deactivateRelatedThemes: bulkThemeSchedule.deactivateRelatedThemes,
      moduleId: bulkThemeSchedule.moduleId
        ? bulkThemeSchedule.moduleId
        : undefined,
      themeIds: bulkThemeSchedule.themes
        ? bulkThemeSchedule.themes.map((theme: Theme) => theme.id)
        : [],
      calendarYear: bulkThemeSchedule.calendarYear
    };
  }
};
