import { Menu, MenuItem } from '@mui/material';
import React, { FC } from 'react';

export type ListMenuProps = {
  isListMenuOpen: boolean;
  onAllMarkedAsRead: () => void;
  onAllMarkedAsCompleted: () => void;
  onListMenuClosed: () => void;
  anchorElement: HTMLElement | null;
};

export const ListMenu: FC<ListMenuProps> = ({
  isListMenuOpen,
  onAllMarkedAsRead,
  onAllMarkedAsCompleted,
  onListMenuClosed,
  anchorElement
}: ListMenuProps) => {
  return (
    <Menu
      anchorEl={anchorElement}
      open={isListMenuOpen}
      onClose={onListMenuClosed}
    >
      <MenuItem onClick={onAllMarkedAsRead}>Mark All As Read</MenuItem>
      <MenuItem onClick={onAllMarkedAsCompleted}>
        Mark All As Completed
      </MenuItem>
    </Menu>
  );
};
