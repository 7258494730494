import './fonts/Roboto-Medium.ttf';
import React from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import {} from './modules';
import ApplicationModule from './modules/application';
import { EnvProvider } from './common';

function App() {
  if (process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING) {
    const browserHistory = createBrowserHistory();

    const reactPlugin = new ReactPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        connectionString:
          process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        }
      }
    });

    appInsights.loadAppInsights();
  }

  return (
    <EnvProvider>
      <ApplicationModule />
    </EnvProvider>
  );
}

export default App;
