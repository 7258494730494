import { Clear } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { ChangeEvent } from 'react';
import { ShowIf } from '../show-if/ShowIf';

/**
 * @deprecated use {@link TextFieldV2} instead
 */

export const TextInputField = (props: TextFieldProps) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        endAdornment: (
          props.InputProps?.endAdornment ??
          <ShowIf condition={ !props.InputProps?.readOnly } >
            <InputAdornment position="end">
              <InputAdornment position="end">
                <ShowIf condition={!(props.value === '' || !props.value)}>
                  <IconButton
                    disabled={props.value === '' || !props.value}
                    onClick={() => {
                      const event = {
                        target: {
                          value: ''
                        }
                      };
                      props &&
                      props.onChange &&
                      props.onChange(event as ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </ShowIf>
              </InputAdornment>
            </InputAdornment>
          </ShowIf>
        )
      }}
    />
  );
};
