import { Env } from 'src/types';

export const validateEnv = (
  envConfig: Partial<Env>,
  defaultConfig?: Partial<Env>
): Env => {
  /*  envConfig.auctionsApiUrl ||= defaultConfig?.auctionsApiUrl;
  if (!envConfig.auctionsApiUrl) {
    throw new Error('Auctions API Url not defined in ENV config');
  }*/

  return envConfig as Env;
};
