import React, { useCallback, useEffect } from 'react';
import { Skeleton, Typography } from '@mui/material';
import {
  BookingGroupType,
  DateRange,
  DateRangeType,
  Event,
  GetAPI,
  ListViewType,
  RoomBooking,
  RoomBookingStatus,
  RoomBookingType
} from 'src/types';
import {
  ICacheStore,
  LoadingIndicator,
  TableFooter,
  usePaginatedControl
} from 'src/common';
import { BookingsGroupBy, RoomBookingParams } from 'src/services';
import { useBookingsGrouped, useBookings } from '../hooks';
import { DATE_TIME_FORMAT, getDisplayDateStringFromDate } from 'src/lib';
import { BookingLoader } from './BookingsLoader';
import { BookingsApproverTable } from './BookingsApproverTable';
import { BookingsGrouped } from './BookingsGroupLayout';
import { BookingsTable } from './BookingsTable';
import { BookingsCalendar } from './BookingsCalendar';
import { Filters } from './filters-form';
import { useBookingsContext } from './BookingsContextProvider';

export type BookingsListProps = {
  listViewType: ListViewType;
  bookingType: RoomBookingType;
  bookingGroupType: BookingGroupType;
  isApproverViewEnabled: boolean;
  getRoomBookings: GetAPI<RoomBooking, RoomBookingParams>;
  onViewBooking: (bookingId: number) => void;
  getBookingUrl: (booking: RoomBooking) => string | undefined;
  onViewBookingConflicts: (booking: RoomBooking) => void;
  dateRange: DateRange;
  dateRangeType: DateRangeType;
  events: Event[];
  filters: Filters;
  isEventsLoading: boolean;
  cacheStore: ICacheStore;
};

const CACHE_STORE_BOOKINGS_GROUPS = 'bookings-groups';
const CACHE_STORE_BOOKINGS_LIST = 'bookings-list';

export function BookingsList(props: BookingsListProps) {
  const { bookingsTypeCount, setBookingsTypeCount } = useBookingsContext();

  const {
    isApproverViewEnabled,
    onViewBooking,
    onViewBookingConflicts,
    listViewType,
    bookingGroupType,
    dateRange,
    dateRangeType,
    events,
    isEventsLoading,
    getBookingUrl,
    filters,
    bookingType,
    cacheStore,
    getRoomBookings
  } = props;

  const paginationForBookingGroups = usePaginatedControl({
    cacheStore,
    cacheStoreKeySuffix: CACHE_STORE_BOOKINGS_GROUPS
  });

  const {
    goToPage: goToPageBookingGroups,
    setTotalPages: setTotalPagesBookingGroups,
    setTotalRows: setTotalRowsBookingGroups
  } = paginationForBookingGroups;

  const handleOnPaginatedDetailsChangedGroupedBookings = useCallback(
    (totalPages: number, totalElements: number) => {
      setTotalPagesBookingGroups(totalPages);
      setTotalRowsBookingGroups(totalElements);
    },
    [setTotalPagesBookingGroups, setTotalRowsBookingGroups]
  );

  const paginationForBookingsList = usePaginatedControl({
    cacheStore,
    cacheStoreKeySuffix: CACHE_STORE_BOOKINGS_LIST
  });

  const {
    goToPage: goToPageBookingsList,
    setTotalPages: setTotalPagesBookingsList,
    setTotalRows: setTotalRowsBookingsList
  } = paginationForBookingsList;

  const handleOnPaginatedDetailsChangedListBookings = useCallback(
    (totalPages: number, totalElements: number) => {
      setTotalPagesBookingsList(totalPages);
      setTotalRowsBookingsList(totalElements);
    },
    [setTotalPagesBookingsList, setTotalRowsBookingsList]
  );

  const { bookingGroups, isBookingGroupsLoading } = useBookingsGrouped({
    bookingType: bookingType,
    groupBy:
      bookingGroupType === BookingGroupType.DAY
        ? BookingsGroupBy.DAY
        : BookingsGroupBy.FACILITY,
    onPaginatedDetailsChanged: handleOnPaginatedDetailsChangedGroupedBookings,
    pageNumber: paginationForBookingGroups.currentPage,
    pageSize: paginationForBookingGroups.rowsPerPage,
    isApproverViewEnabled: isApproverViewEnabled,
    dateRange: dateRange,
    filters: filters
  });

  const bookings = useBookings({
    dateRange: dateRange,
    filters: filters,
    bookingType: bookingType,
    pageNumber: paginationForBookingsList.currentPage,
    pageSize: paginationForBookingsList.rowsPerPage,
    onPaginatedDetailsChanged: handleOnPaginatedDetailsChangedListBookings,
    getRoomBookings: getRoomBookings,
    isApproverViewEnabled: true,
    isPopulateConflicts: true
  });

  useEffect(() => {
    goToPageBookingsList(0);
    goToPageBookingGroups(0);
  }, [
    dateRange,
    dateRangeType,
    filters,
    bookingGroupType,
    listViewType,
    goToPageBookingsList,
    goToPageBookingGroups
  ]);

  useEffect(() => {
    if (
      !bookings.isBookingsLoading &&
      isApproverViewEnabled &&
      listViewType === ListViewType.TABLE
    ) {
      const bookingsCountCurrent = bookingsTypeCount.get(bookingType);
      const bookingsCountNew = bookings.bookings.filter(
        (booking) => booking.status === RoomBookingStatus.AWAITING_APPROVAL
      ).length;

      if (bookingsCountCurrent !== bookingsCountNew) {
        setBookingsTypeCount(bookingType, bookingsCountNew);
      }
    }
  }, [
    bookingsTypeCount,
    bookings,
    setBookingsTypeCount,
    bookingType,
    isApproverViewEnabled,
    listViewType
  ]);

  if (
    isBookingGroupsLoading ||
    (listViewType === ListViewType.CALENDAR && isEventsLoading)
  ) {
    return <Skeleton variant="rectangular" height={200} />;
  }

  if (bookingGroups.length === 0) {
    return (
      <Typography variant="headingSixForm">No Bookings to display</Typography>
    );
  }

  if (isApproverViewEnabled && listViewType === ListViewType.TABLE) {
    if (bookings.isBookingsLoading) {
      return <Skeleton variant="rectangular" height={200} />;
    }

    return (
      <>
        <BookingsApproverTable
          bookings={bookings.bookings}
          onViewBooking={onViewBooking}
          onViewBookingConflicts={onViewBookingConflicts}
        />
        <TableFooter
          page={paginationForBookingsList.currentPage}
          pageSize={paginationForBookingsList.rowsPerPage}
          rowCount={paginationForBookingsList.totalRows}
          handlePageSizeChange={paginationForBookingsList.setRowsPerPage}
          handlePageChange={paginationForBookingsList.goToPage}
        />
      </>
    );
  }

  return (
    <>
      <BookingsGrouped
        key={bookingGroupType}
        groups={bookingGroups.map((bookingGroup) => ({
          title:
            bookingGroup.facilityName ??
            (bookingGroup.date
              ? getDisplayDateStringFromDate(
                  bookingGroup.date,
                  DATE_TIME_FORMAT.EXCEL_DATE_FORMAT
                )
              : ''),
          count: bookingGroup.childRecordCount,
          render: () => (
            <BookingLoader
              dateRange={dateRange}
              filters={filters}
              getRoomBookings={getRoomBookings}
              facilityId={bookingGroup.facilityId}
              date={bookingGroup.date}
              bookingType={bookingType}
            >
              {(isBookingsLoading, bookings) => {
                if (isBookingsLoading || !bookings) {
                  return <LoadingIndicator />;
                }

                if (
                  bookingGroupType === BookingGroupType.DAY &&
                  bookingGroup.date === undefined
                ) {
                  throw new Error(
                    'bookingGroup date expected with grouping by day'
                  );
                }

                return listViewType === ListViewType.TABLE ? (
                  <BookingsTable
                    bookings={bookings}
                    onViewBooking={({ id }) => onViewBooking(id)}
                  />
                ) : (
                  <BookingsCalendar
                    bookings={bookings}
                    dateRange={
                      bookingGroupType === BookingGroupType.DAY &&
                      bookingGroup.date
                        ? {
                            fromDate: bookingGroup.date,
                            toDate: bookingGroup.date
                          } || dateRange
                        : dateRange
                    }
                    dateRangeType={
                      bookingGroupType === BookingGroupType.DAY
                        ? DateRangeType.DAY
                        : dateRangeType
                    }
                    onViewBooking={({ id }) => onViewBooking(id)}
                    getBookingUrl={getBookingUrl}
                    events={events}
                  />
                );
              }}
            </BookingLoader>
          )
        }))}
      />
      <TableFooter
        page={paginationForBookingGroups.currentPage}
        pageSize={paginationForBookingGroups.rowsPerPage}
        rowCount={paginationForBookingGroups.totalRows}
        handlePageSizeChange={paginationForBookingGroups.setRowsPerPage}
        handlePageChange={paginationForBookingGroups.goToPage}
      />
    </>
  );
}
