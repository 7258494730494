import { Gender, User } from 'src/types';

const genderDisplay = {
  Female: 'F',
  Male: 'M',
  None: 'N'
};
export function getDisplayStringForGender(gender?: Gender) {
  if (gender === undefined) {
    return '';
  }
  switch (gender) {
    case Gender.FEMALE:
      return genderDisplay.Female;
    case Gender.MALE:
      return genderDisplay.Male;
    case Gender.NONE:
      return genderDisplay.None;
    default:
      return '';
  }
}

export function getUserFullInitials(user: User | undefined) {
  if (user === undefined) {
    return '';
  }
  const firstLetterOfSurname = user.surname.at(0) ?? '';
  return `${user.initials}${firstLetterOfSurname}`;
}

export function getUserFormalGreeting(user: User | undefined) {
  if (user === undefined) {
    return '';
  }
  return `${user.title} ${user.surname}`;
}
