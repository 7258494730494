import { useCallback } from 'react';
import {
  AcademicYearOriginal,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IAcademicYearsService {
  getAcademicYears: GetAPI<AcademicYearOriginal>;
  getAcademicYear: (
    id: number,
    abortController?: AbortController
  ) => Promise<AcademicYearOriginal>;
}

export const useAcademicYearsService = (): IAcademicYearsService => {
  const academicYearsApi = useAxiosInstance('/v2/academicYear');

  const getAcademicYears = useCallback(
    (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<AcademicYearOriginal>> => {
      return academicYearsApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<AcademicYearOriginal>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<AcademicYearOriginal>;
        });
    },
    [academicYearsApi]
  );

  const getAcademicYear = useCallback(
    (
      id: number,
      abortController?: AbortController
    ): Promise<AcademicYearOriginal> => {
      return academicYearsApi
        .get(`${id}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => response.data as AcademicYearOriginal);
    },
    [academicYearsApi]
  );

  return { getAcademicYears, getAcademicYear };
};
