import { PaginatedResponse } from 'src/types';

export const getPaginatedResponseFromArray = <T>(
  items: T[]
): PaginatedResponse<T> => {
  return {
    items: items,
    hasNextPage: false,
    hasPrevPage: false,
    pageNumber: 0,
    totalCount: items.length,
    totalPages: 1
  };
};
