import {
  Link,
  Breadcrumbs as MaterialBreadcrumbs,
  Skeleton
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { To } from 'react-router';
import { useBreadcrumbs } from '../breadcrumbs-context-provider/BreadcrumbsContextProvider';

export const BreadcrumbsContainer = () => {
  const navigate = useNavigate();
  const { breadcrumbs } = useBreadcrumbs();

  return (
    <MaterialBreadcrumbs maxItems={5}>
      {breadcrumbs.map((breadcrumb, index) => {
        const isLast = index === breadcrumbs.length - 1;
        const { text, link, id } = breadcrumb;

        if (!text && id) {
          return (
            <Skeleton animation="wave" variant="text" key={text}>
              ...
            </Skeleton>
          );
        }

        return isLast || !link ? (
          <span key={text}>{text}</span>
        ) : (
          <Link key={text} onClick={() => navigate(link as To)}>
            {text}
          </Link>
        );
      })}
    </MaterialBreadcrumbs>
  );
};
