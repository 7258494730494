import { FormikProps } from 'formik';
import { AuthorizedUser } from 'src/common';
import {
  EditSeries,
  Facility,
  FormType,
  GetAPI,
  PlacementSchedule,
  PlacementScheduleStudentDetails,
  RoomBooking,
  RoomBookingRecurrence,
  RoomBookingStatus,
  RoomBookingType,
  Staff,
  Student,
  Tag,
  TrainingArea
} from 'src/types';
import { RoomBookingDetails } from '../../../services';

export type BaseForm = {
  getClassListStudents: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<Student[]>;
  getStudents: GetAPI<Student>;
};

export type ViewForm = BaseForm & {
  formType: FormType.VIEW;
  initialValues: RoomBookingDetails;
  bookingType: RoomBookingType;
  placementSchedule?: PlacementSchedule;
  getTags: GetAPI<Tag>;
  getFacilities?: GetAPI<Facility>;
  facilityType: string;
  getTrainingAreas: GetAPI<TrainingArea>;
  getDrivers?: GetAPI<Staff>;
  onDeleteButtonClick?: () => void;
  onCloseButtonClick?: () => void;
  onApproveButtonClick?: () => void;
  authorizedUser: AuthorizedUser;
  isApprover?: boolean;
  isUserAbleToApproveBooking: boolean;
  onConflictsPillClick: () => void;
};

export type UpdateForm = BaseForm & {
  formType: FormType.UPDATE;
  initialValues: RoomBookingDetails;
  bookingType: RoomBookingType;
  placementSchedule?: PlacementSchedule;
  getTags: GetAPI<Tag>;
  getFacilities?: GetAPI<Facility>;
  facilityType: string;
  getTrainingAreas: GetAPI<TrainingArea>;
  getDrivers?: GetAPI<Staff>;
  onDeleteButtonClick?: () => void;
  onCloseButtonClick?: () => void;
  onApproveButtonClick?: () => void;
  onSaveBooking: (booking: RoomBooking, updateSeries: boolean) => Promise<void>;
  authorizedUser: AuthorizedUser;
  isApprover?: boolean;
  isUserAbleToApproveBooking: boolean;
  onConflictsPillClick: () => void;
};

export type CreateForm = BaseForm & {
  formType: FormType.CREATE;
  initialValues?: RoomBookingDetails;
  studentIds?: number[];
  bookingType: RoomBookingType;
  placementSchedule?: PlacementSchedule;
  getTags: GetAPI<Tag>;
  getFacilities?: GetAPI<Facility>;
  facilityType: string;
  getTrainingAreas: GetAPI<TrainingArea>;
  getDrivers?: GetAPI<Staff>;
  onCreateBooking: (booking: RoomBooking) => Promise<void>;
  onCloseButtonClick?: () => void;
  authorizedUser: AuthorizedUser;
  isApprover?: boolean;
};

export type FormProps = ViewForm | CreateForm | UpdateForm;

export type FormValues = {
  id?: number;
  title: string;
  status: RoomBookingStatus;
  placementScheduleId: number;
  moduleScheduleId?: number;
  bookingType: RoomBookingType;
  recurrence: RoomBookingRecurrence;
  startDateTime: Date;
  endDateTime: Date;
  equipment?: Tag[];
  facility?: Facility;
  facilityType?: string;
  capacity: number;
  trainingArea?: TrainingArea;
  trainingAreas?: TrainingArea[];
  notes?: string;
  staff?: Staff;
  driverStaff?: Staff;
  bookingKmTravelled?: number;
  individualStudents: PlacementScheduleStudentDetails[];
  bookedByUser?: string;
  lastUpdatedByUser?: string;
  approvedByUser?: string;
  isUpdateSeriesModalOpen: boolean;
  editSeries?: EditSeries;
};

export enum PlacementPlusVersion {
  LEGACY,
  REBUILD
}

export type StudentFormLegacyProps = {
  placementPlusVersion: PlacementPlusVersion.LEGACY;
  formikHelpers: FormikProps<FormValues>;
  type: FormType;
  getModuleScheduleStudents: GetAPI<PlacementScheduleStudentDetails>;
  isModuleStudentsLoading: boolean;
  isApprover?: boolean;
};

export type StudentFormUpgradeProps = {
  placementPlusVersion: PlacementPlusVersion.REBUILD;
  formikHelpers: FormikProps<FormValues>;
  type: FormType;
  moduleScheduleStudents: PlacementScheduleStudentDetails[];
  isModuleStudentsLoading: boolean;
  isApprover?: boolean;
};

export type StudentFormProps = StudentFormUpgradeProps | StudentFormLegacyProps;
