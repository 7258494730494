import { Switch } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { RefreshStatus, useTableContext } from './TableContextProvider';

interface TableSwitchProps {
  value: boolean;
  disabled: boolean;
  onChange: (value: boolean, update: (rowCount?: number) => void) => void;
}

export const TableSwitch = ({
  value,
  disabled,
  onChange,
}: TableSwitchProps) => {

  const { setRefreshStatus, handlePageChange } = useTableContext();

  const update = (rowCount?: number) => {
    if(rowCount && rowCount === 1){
      handlePageChange(0);
    }
    setRefreshStatus(RefreshStatus.REFRESHING);
  };

  return (
    <Switch
      checked={value}
      onClick={(event) => event.stopPropagation()}
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.checked, update);
      }}
      size="small"
      disabled={disabled}
    />
  );
};
