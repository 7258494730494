import { useCallback } from 'react';
import {
  BulkModuleScheduleDTO,
  GetAPI,
  ModuleSchedule,
  ModuleScheduleDTO,
  ModuleScheduleStatus,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';

export interface IModuleSchedulesService {
  getModuleSchedules: GetAPI<ModuleSchedule>;
  getMyModuleSchedules: GetAPI<ModuleSchedule>;
  editModuleScheduleStatus: (
    id: number,
    active: boolean,
    abortController?: AbortController
  ) => Promise<void>;

  getMyModuleSchedule: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<ModuleSchedule>;

  getModuleSchedule: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<ModuleSchedule>;

  getStudentModuleSchedules: (
    request: PaginatedRequest,
    studentId: number,
    calendarYearId?: number
  ) => Promise<PaginatedResponse<ModuleSchedule>>;

  getDistinctActiveYears: (
    abortController?: AbortController
  ) => Promise<number[]>;
  updateModuleSchedule: (
    id: number,
    moduleSchedule: ModuleSchedule,
    abortController?: AbortController
  ) => Promise<void>;
  deleteModuleSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  approveModuleSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  unapproveModuleSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  scheduleBulkModules: (
    data: BulkModuleScheduleDTO,
    abortController?: AbortController
  ) => Promise<void>;
}

const ModuleScheduleStatusUrlParam = {
  ACTIVE: 'Active',
  INACTIVE: 'Inactive'
};

export const useModuleSchedulesService = (): IModuleSchedulesService => {
  const moduleSchedulesApi = useAxiosInstance();

  const getMyModuleSchedules = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<ModuleSchedule>> => {
      return moduleSchedulesApi
        .get('/v2/moduleSchedules/myModuleSchedules', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<ModuleScheduleDTO>;
          return {
            items: content.map((contentItem) =>
              getModuleScheduleModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ModuleSchedule>;
        });
    },
    [moduleSchedulesApi]
  );

  const getMyModuleSchedule = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<ModuleSchedule> => {
      return moduleSchedulesApi
        .get('/v2/moduleSchedules/myModuleSchedules', {
          params: {
            pageNumber: 0,
            pageSize: 1,
            ids: [moduleScheduleId],
            abortController: abortController
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const { content } =
            response.data as PaginatedResponseDTO<ModuleScheduleDTO>;
          return getModuleScheduleModel(content[0]);
        });
    },
    [moduleSchedulesApi]
  );

  const getModuleSchedule = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<ModuleSchedule> => {
      return moduleSchedulesApi
        .get(`/v2/moduleSchedules/${moduleScheduleId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) =>
          getModuleScheduleModel(response.data as ModuleScheduleDTO)
        );
    },
    [moduleSchedulesApi]
  );

  const getModuleSchedules = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<ModuleSchedule>> => {
      return moduleSchedulesApi
        .get('/v2/moduleSchedules', {
          params: {
            ...request
          },
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<ModuleScheduleDTO>;
          return {
            items: content.map((contentItem) =>
              getModuleScheduleModel(contentItem)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ModuleSchedule>;
        });
    },
    [moduleSchedulesApi]
  );

  const editModuleScheduleStatus = useCallback(
    (
      id: number,
      active: boolean,
      abortController?: AbortController
    ): Promise<void> =>
      moduleSchedulesApi.put(
        `/v2/moduleSchedules/toggleStatus/${id}/${
          active
            ? ModuleScheduleStatusUrlParam.ACTIVE
            : ModuleScheduleStatusUrlParam.INACTIVE
        }`,
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      ),
    [moduleSchedulesApi]
  );

  const getStudentModuleSchedules = useCallback(
    (
      request: PaginatedRequest,
      studentId: number,
      yearId?: number,
      abortController?: AbortController
    ): Promise<PaginatedResponse<ModuleSchedule>> => {
      return moduleSchedulesApi
        .get(`/v2/moduleSchedules/student/${studentId}`, {
          params: {
            filters: request.filters,
            calendarYearId: yearId
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const moduleSchedulesDto = response.data as ModuleScheduleDTO[];

          return {
            items: moduleSchedulesDto.map(getModuleScheduleModel),
            hasNextPage: false,
            hasPrevPage: false,
            pageNumber: 0,
            totalCount: response.data.length,
            totalPages: 1
          } as PaginatedResponse<ModuleSchedule>;
        });
    },
    [moduleSchedulesApi]
  );

  const getDistinctActiveYears = useCallback(
    (abortController?: AbortController): Promise<number[]> => {
      return moduleSchedulesApi
        .get('/v2/moduleSchedules/distinctActiveYears', {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => response.data as number[]);
    },
    [moduleSchedulesApi]
  );

  const updateModuleSchedule = useCallback(
    (
      id: number,
      moduleSchedule: ModuleSchedule,
      abortController?: AbortController
    ): Promise<void> => {
      return moduleSchedulesApi.put(
        `/v2/moduleSchedules/${id}`,
        getDTOfromModuleSchedule(moduleSchedule),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      );
    },
    [moduleSchedulesApi]
  );

  const approveModuleSchedule = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      moduleSchedulesApi.post(
        '/v2/moduleSchedules/approve',
        {},
        {
          params: {
            moduleScheduleId: String(id)
          },
          withCredentials: true,
          signal: abortController?.signal
        }
      ),
    [moduleSchedulesApi]
  );

  const unapproveModuleSchedule = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      moduleSchedulesApi.post(
        '/v2/moduleSchedules/resetApprovalStatus',
        {},
        {
          params: {
            moduleScheduleId: String(id)
          },
          withCredentials: true,
          signal: abortController?.signal
        }
      ),
    [moduleSchedulesApi]
  );

  const deleteModuleSchedule = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      moduleSchedulesApi.delete(`/v2/moduleSchedules/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [moduleSchedulesApi]
  );

  const scheduleBulkModules = (
    data: BulkModuleScheduleDTO,
    abortController?: AbortController
  ): Promise<void> =>
    moduleSchedulesApi.post(
      '/v2/moduleSchedules/bulk',

      { ...data },

      { withCredentials: true, signal: abortController?.signal }
    );

  return {
    getModuleSchedules,
    getModuleSchedule,
    getMyModuleSchedules,
    getMyModuleSchedule,
    getStudentModuleSchedules,
    getDistinctActiveYears,
    updateModuleSchedule,
    deleteModuleSchedule,
    editModuleScheduleStatus,
    approveModuleSchedule,
    unapproveModuleSchedule,
    scheduleBulkModules
  };
};

function reconcileStatusAndActive(
  moduleSchedule: ModuleSchedule
): ModuleSchedule {
  if (moduleSchedule.status === ModuleScheduleStatus.ACTIVE) {
    moduleSchedule.active = true;
  } else if (moduleSchedule.status === ModuleScheduleStatus.INACTIVE) {
    moduleSchedule.active = false;
  }
  return moduleSchedule;
}

function getDTOfromModuleSchedule(
  moduleSchedule: ModuleSchedule
): ModuleScheduleDTO {
  moduleSchedule = reconcileStatusAndActive(moduleSchedule);
  const { dateVerified, startDate, endDate, approvalDateTime, status } =
    moduleSchedule;
  return {
    ...moduleSchedule,
    dateVerified: dateVerified
      ? getDtoDateStringFromDate(dateVerified)
      : undefined,
    startDate: startDate ? getDtoDateStringFromDate(startDate) : undefined,
    endDate: endDate ? getDtoDateStringFromDate(endDate) : undefined,
    approvalDateTime: approvalDateTime
      ? getDtoDateStringFromDate(approvalDateTime)
      : undefined,
    status: getDtoStatusFromModuleSchedule(status)
  };
}

function getModuleScheduleModel(dto: ModuleScheduleDTO): ModuleSchedule {
  const { dateVerified, startDate, endDate, approvalDateTime, active } = dto;
  return {
    ...dto,
    dateVerified: dateVerified
      ? getDateFromDtoDateString(dateVerified)
      : undefined,
    startDate: startDate ? getDateFromDtoDateString(startDate) : undefined,
    endDate: endDate ? getDateFromDtoDateString(endDate) : undefined,
    approvalDateTime: approvalDateTime
      ? getDateFromDtoDateString(approvalDateTime)
      : undefined,
    status: getModuleScheduleStatusFromDto(active),
    tags: [] // TODO: update backend to return tag objects instead of Ids
  };
}

export function getModuleScheduleStatusFromDto(
  status: boolean
): ModuleScheduleStatus {
  return status ? ModuleScheduleStatus.ACTIVE : ModuleScheduleStatus.INACTIVE;
}

export function getDtoStatusFromModuleSchedule(
  status: ModuleScheduleStatus
): boolean {
  return status === ModuleScheduleStatus.ACTIVE;
}
