import { createContext, FC, useContext } from 'react';
import { ErrorHandler } from 'src/types';

interface IAppContext {
  handleError: ErrorHandler;
}

export const AppContext = createContext<IAppContext>({
  handleError: (error) => {
    throw new Error(
      'App Context requires function handleError to be overridden'
    );
  }
});

export type AppContextProps = {
  handleError: ErrorHandler;
};

export const AppContextProvider: FC<AppContextProps> = ({
  handleError,
  children
}) => {
  return (
    <AppContext.Provider value={{ handleError }}>
      {children}
    </AppContext.Provider>
  );
};

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error(
      'App Context cannot be used outside of App Context Provider'
    );
  }

  return context;
};
