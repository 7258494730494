const getFormattedSortingValue = (sortingValue: string | number) => {
  switch (typeof sortingValue) {
    case 'number': {
      return sortingValue;
    }
    case 'string': {
      return sortingValue.toUpperCase();
    }
  }
};

const NEGATIVE_ONE_MEANS_FIRST_ELEMENT_LESS_THAN_SECOND_ELEMENT = -1;
const POSITIVE_ONE_MEANS_FIRST_ELEMENT_GREATER_THAN_SECOND_ELEMENT = 1;
const ZERO_MEANS_FIRST_ELEMENT_EQUAL_TO_SECOND_ELEMENT = 0;

/**
 * Used to build a comparator that can be passed to `sort` method of `Array`
 * instances. Currently, supports sorting on `number` or `string`  arrays, and
 * object arrays, on a `string` or `number` field on the objects.
 *
 * @param getElementSortingValue a function that is applied to each array item to
 * determine the value that the array is sorted on; for objects, this would be
 * the value of one of the object fields. For `number` and `string` arrays, this
 * function would just return the original element.
 *
 * @param order 'asc' for ascending order or 'desc` for descending order.
 *
 * @returns a comparator
 */
export const getComparator =
  <T>(
    getElementSortingValue: ((element: T) => string) | ((element: T) => number),
    order: 'asc' | 'desc'
  ) =>
  (firstElement: T, secondElement: T) => {
    const firstElementSortingValue = getFormattedSortingValue(
      getElementSortingValue(firstElement)
    );
    const secondElementSortingValue = getFormattedSortingValue(
      getElementSortingValue(secondElement)
    );

    if (firstElementSortingValue === secondElementSortingValue) {
      return ZERO_MEANS_FIRST_ELEMENT_EQUAL_TO_SECOND_ELEMENT;
    }

    if (firstElementSortingValue < secondElementSortingValue) {
      return order === 'asc'
        ? NEGATIVE_ONE_MEANS_FIRST_ELEMENT_LESS_THAN_SECOND_ELEMENT
        : POSITIVE_ONE_MEANS_FIRST_ELEMENT_GREATER_THAN_SECOND_ELEMENT;
    } else {
      return order === 'asc'
        ? POSITIVE_ONE_MEANS_FIRST_ELEMENT_GREATER_THAN_SECOND_ELEMENT
        : NEGATIVE_ONE_MEANS_FIRST_ELEMENT_LESS_THAN_SECOND_ELEMENT;
    }
  };

/**
 * Used to build one comparator that uses multiple comparators under the hood.
 * Useful to construct a comparator that sorts on multiple attributes/fields
 * e.g. first in ascending order on one field, then in descending order of
 * another field.
 *
 * @remarks
 * For a pair of elements, the function starts with the first comparator in the
 * {@link Array} and evaluates them; if the two elements are found to be equal,
 * it proceeds to evaluate the pair using the next comparator in the {@link Array}; if
 * it has reached the end of the {@link Array}, then it returns the result of the
 * current comparator
 * If the pair are not equal, then the result of the current comparator is returned
 * (e.g. {@link NEGATIVE_ONE_MEANS_FIRST_ELEMENT_LESS_THAN_SECOND_ELEMENT} or
 * {@link POSITIVE_ONE_MEANS_FIRST_ELEMENT_GREATER_THAN_SECOND_ELEMENT}).
 *
 * @param comparators an {@link Array} of comparators.
 *
 * @returns a comparator
 */
export const getCompoundComparator =
  <T>(comparators: ((firstElement: T, secondElement: T) => number)[]) =>
  (firstElement: T, secondElement: T) => {
    for (const comparator of comparators) {
      const result = comparator(firstElement, secondElement);
      if (result !== ZERO_MEANS_FIRST_ELEMENT_EQUAL_TO_SECOND_ELEMENT) {
        return result;
      }
    }
    return ZERO_MEANS_FIRST_ELEMENT_EQUAL_TO_SECOND_ELEMENT;
  };
