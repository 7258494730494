import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useAreaCapacityService } from 'src/services';
import {
  DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
  getAllFromPaginatedApiV2,
  getDateRangeFromDateAndDateRangeType,
  getDtoDateStringFromDate
} from 'src/lib';
import { AreaCapacity, DateRange, DateRangeType } from 'src/types';
import { useAppContext } from 'src/common';
import { BookedBy, Filters } from '../components';

export type AreaCapacities = {
  areaCapacities: AreaCapacity[];
  isAreaCapacitiesLoading: boolean;
};

export type UseAreaCapacitiesProps = {
  dateRange: DateRange;
  filters: Filters;
  isLoadingEnabled: boolean;
  facilityId?: number;
  date?: Date;
  debounceDelayMilliseconds?: number;
};

export function useAreaCapacities(
  props: UseAreaCapacitiesProps
): AreaCapacities {
  const {
    dateRange,
    filters,
    isLoadingEnabled,
    debounceDelayMilliseconds,
    facilityId,
    date
  } = props;

  const { handleError } = useAppContext();
  const { getAreaCapacities } = useAreaCapacityService();
  const [areaCapacities, setAreaCapacities] = useState<AreaCapacity[]>([]);
  const [isAreaCapacitiesLoading, setIsAreaCapacitiesLoading] =
    useState<boolean>(false);

  const fetchAreaCapacitiesDebounced = useMemo(
    () =>
      debounce(
        (
          filters: Filters,
          dateRange: DateRange,
          abortController: AbortController,
          date?: Date,
          facilityId?: number
        ) => {
          const dateRangeGroupBy = date
            ? getDateRangeFromDateAndDateRangeType(date, DateRangeType.DAY)
            : undefined;

          const requestFilters: Record<string, string> = {
            startDateTime: getDtoDateStringFromDate(
              dateRangeGroupBy?.fromDate ?? dateRange.fromDate
            ),
            endDateTime: getDtoDateStringFromDate(
              dateRangeGroupBy?.toDate ?? dateRange.toDate
            ),
            facilityId:
              filters.facility?.id.toString() ?? facilityId?.toString() ?? '',
            simpleDataRequired: true.toString()
          };

          if (filters.bookedBy !== BookedBy.EVERYONE_ALL) {
            requestFilters.moduleScheduleId =
              filters.moduleSchedule?.id?.toString() || '';
            requestFilters.themeScheduleId =
              filters.themeSchedule?.id?.toString() || '';
          }

          getAllFromPaginatedApiV2({
            getApi: getAreaCapacities,
            filters: requestFilters,
            abortController: abortController
          })
            .then((areaCapacitiesNew) => {
              setAreaCapacities(areaCapacitiesNew);
            })
            .catch(handleError)
            .finally(() => {
              setIsAreaCapacitiesLoading(false);
            });
        },
        debounceDelayMilliseconds ?? DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
        { trailing: true, leading: false }
      ),
    [debounceDelayMilliseconds, getAreaCapacities, handleError]
  );

  useEffect(() => {
    const abortController = new AbortController();
    if (isLoadingEnabled) {
      setIsAreaCapacitiesLoading(true);
      fetchAreaCapacitiesDebounced(
        filters,
        dateRange,
        abortController,
        date,
        facilityId
      );
    }
    return () => {
      abortController.abort();
    };
  }, [
    dateRange,
    fetchAreaCapacitiesDebounced,
    filters,
    isLoadingEnabled,
    facilityId,
    date
  ]);

  return { areaCapacities, isAreaCapacitiesLoading };
}
