import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { DateRangeType } from 'src/types';

export type DateRangeTypePickerProps = {
  /** the current date range type */
  dateRangeType: DateRangeType;
  /** called when the date range type changes */
  onDateRangeTypeChange: (dateRangeType: DateRangeType) => void;
  /** <code>true</code> if the year button should be shown. Defaults to <code>true</code> */
  showYearButton?: boolean;
  /** <code>true</code> if the month button should be shown. Defaults to <code>true</code> */
  showMonthButton?: boolean;
  /** <code>true</code> if the week button should be shown. Defaults to <code>true</code> */
  showWeekButton?: boolean;
};

export const DateRangeTypePicker = ({
  dateRangeType,
  onDateRangeTypeChange,
  showYearButton = true,
  showMonthButton = true,
  showWeekButton = true
}: DateRangeTypePickerProps) => {
  return (
    <ToggleButtonGroup
      value={dateRangeType}
      exclusive
      onChange={(_, value) => {
        if (value !== null) {
          onDateRangeTypeChange(value as DateRangeType);
        }
      }}
    >
      {showYearButton && (
        <ToggleButton color="primary" size="small" value={DateRangeType.YEAR}>
          Year
        </ToggleButton>
      )}

      {showMonthButton && (
        <ToggleButton color="primary" size="small" value={DateRangeType.MONTH}>
          Month
        </ToggleButton>
      )}

      {showWeekButton && (
        <ToggleButton color="primary" size="small" value={DateRangeType.WEEK}>
          Week
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
};
