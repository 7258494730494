import {
  Activity,
  ActivityType,
  Facility,
  GetAPI,
  ModuleSchedule,
  RoomBookingStatus,
  RoomBookingType,
  Staff,
  Student,
  StudentGroup,
  ThemeSchedule,
  TrainingArea
} from 'src/types';
import { ProgrammeSchedule } from 'src/types/models/ProgrammeSchedule.type';

export type AcademicBookingFiltersForm = {
  roomBookingType: RoomBookingType.ACADEMIC;
  getProgrammeSchedules: GetAPI<ProgrammeSchedule>;
  getModuleSchedules: GetAPI<ModuleSchedule>;

  getThemeSchedules: GetAPI<ThemeSchedule>;

  getActivities: GetAPI<Activity>;

  getActivityTypes: GetAPI<ActivityType>;

  getStudentGroups: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<StudentGroup[]>;

  getStudents: GetAPI<Student>;

  getStaff: GetAPI<Staff>;
  getFacilities: GetAPI<Facility>;
  getTrainingAreas: GetAPI<TrainingArea>;
  initialFilters?: Filters;

  onFiltersChanged: (filters: Filters) => void;
  isApproverViewEnabled?: boolean;
};

export type TransportBookingFiltersForm = {
  roomBookingType: RoomBookingType.TRANSPORT;
  getStudents: GetAPI<Student>;
  getStaff: GetAPI<Staff>;
  getFacilities: GetAPI<Facility>;
  getTrainingAreas: GetAPI<TrainingArea>;
  initialFilters?: Filters;
  onFiltersChanged: (filters: Filters) => void;
  isApproverViewEnabled?: boolean;
};

export type AccommodationBookingFiltersForm = {
  roomBookingType: RoomBookingType.ACCOMMODATION;
  getStudents: GetAPI<Student>;
  getStaff: GetAPI<Staff>;
  getFacilities: GetAPI<Facility>;
  getTrainingAreas: GetAPI<TrainingArea>;
  initialFilters?: Filters;
  onFiltersChanged: (filters: Filters) => void;
  isApproverViewEnabled?: boolean;
};

export type AdhocBookingFiltersForm = {
  roomBookingType: RoomBookingType.ADHOC;
  getStudents: GetAPI<Student>;
  getStaff: GetAPI<Staff>;
  getFacilities: GetAPI<Facility>;
  getTrainingAreas: GetAPI<TrainingArea>;
  initialFilters?: Filters;
  onFiltersChanged: (filters: Filters) => void;
  isApproverViewEnabled?: boolean;
};

export type FormProps =
  | AcademicBookingFiltersForm
  | TransportBookingFiltersForm
  | AccommodationBookingFiltersForm
  | AdhocBookingFiltersForm;

export type FormValues = {
  searchText?: string;
  programmeSchedule?: ProgrammeSchedule;
  moduleSchedule?: ModuleSchedule;
  themeSchedule?: ThemeSchedule;
  isNoThemeSchedulesLinkedToModuleSchedule?: boolean;
  isNoModuleSchedulesLinkedToProgrammeSchedule?: boolean;
  isNoProgrammeScheduleAvailable?: boolean;
  activityType?: ActivityType;
  activity?: Activity;
  studentGroup?: StudentGroup;
  student?: Student;
  status?: RoomBookingStatus;
  bookedBy?: BookedBy;
  approver?: Staff;
  facility?: Facility;
  trainingArea?: TrainingArea;
};

/** updating {@link Filters}, may require making corresponding changes to {@link useFilterTotals} **/
export type Filters = FormValues;

export type ActivityTypeBasic = Pick<ActivityType, 'id' | 'type'>;

export enum BookedBy {
  ME,
  EVERYONE,
  EVERYONE_ALL
}
