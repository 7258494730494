import React, { useState } from 'react';
import {
  Chip,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import {
  EntityType,
  getFileSizesStringFromBytes,
  getWarningTextForNoViewPermissions
} from 'src/lib';
import { FileDetails, PermissionId } from 'src/types';
import {
  FileInput,
  useAppContext,
  useAuthorizedUser,
  useResponseHandler
} from 'src/common';
import {
  ListViewTableActions,
  useButtonDisabledDebounce
} from 'src/common-v2';

export type FileTableProps = {
  files: FileDetails[];
  onDownloadFile: (fileIndex: number) => void;
  onPreviewFile: (fileIndex: number) => void;
  onDeleteFile?: (fileIndex: number) => void;
  onEditFileTags?: (fileIndex: number) => void;
  onFileUpload?: (fileData: Blob, fileName: string) => Promise<void>;
};

export const FileTable = ({
  files,
  onDownloadFile,
  onPreviewFile,
  onDeleteFile,
  onEditFileTags,
  onFileUpload
}: FileTableProps) => {
  const authorizedUser = useAuthorizedUser();
  const userHasViewTagsPermission = authorizedUser.isAllowedTo(
    PermissionId.ViewTags
  );

  const { handleServiceCall } = useResponseHandler();
  const [isDownloadButtonDisabled, setIsDownloadButtonDisabled] =
    useState(false);
  const { buttonDisabledDebounce } = useButtonDisabledDebounce();
  const { handleError } = useAppContext();

  const downloadHandler = (fileIndex: number): void => {
    buttonDisabledDebounce(setIsDownloadButtonDisabled);
    handleServiceCall({
      serviceCall: () => {
        return Promise.resolve(onDownloadFile(fileIndex)).catch(handleError);
      },
      successProps: {
        title: 'Starting download...',
        description: 'Download starting...'
      },
      failureProps: {
        title: 'File download failed'
      }
    });
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              {onEditFileTags && <TableCell align="left">Tags</TableCell>}
              <TableCell align="left">Date Created</TableCell>
              <TableCell align="left">Created By</TableCell>
              <TableCell align="left">Size</TableCell>
              <TableCell align="left">Actions</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {files.map((file, index) => (
              <TableRow key={`${file.id}${index}`}>
                <TableCell align="left">{file.name}</TableCell>
                {onEditFileTags && (
                  <TableCell align="left">
                    <Grid container spacing={1}>
                      {file.tags &&
                        file.tags.map((tag) => (
                          <Grid item key={`tag-${tag.tag}${tag.id}`}>
                            <Chip label={<Typography>{tag.tag}</Typography>} />
                          </Grid>
                        ))}
                    </Grid>
                  </TableCell>
                )}

                <TableCell align="left">
                  {file.dateCreated.toLocaleString()}
                </TableCell>
                <TableCell align="left">{file.createdBy}</TableCell>

                <TableCell align="left">
                  {getFileSizesStringFromBytes(file.size)}
                </TableCell>

                <TableCell align="left">
                  <ListViewTableActions
                    options={[
                      {
                        label: 'Download',
                        key: `download-button-${index}`,
                        onClick: () => {
                          downloadHandler(index);
                        },
                        isDisabled: isDownloadButtonDisabled
                      },
                      {
                        label: 'Preview',
                        key: `preview-button-${index}`,
                        onClick: () => {
                          onPreviewFile(index);
                        },
                        isDisabled: !file.mimeType.includes('image')
                      },

                      {
                        label: 'Edit Tags',
                        key: `edit-tags-button-${index}`,
                        onClick: () => {
                          onEditFileTags && onEditFileTags(index);
                        },
                        isDisabled: !userHasViewTagsPermission,
                        tooltip: userHasViewTagsPermission
                          ? ''
                          : getWarningTextForNoViewPermissions(EntityType.TAG),
                        isHidden: !onEditFileTags
                      },

                      {
                        label: 'Delete',
                        key: `delete-button-${index}`,
                        onClick: () => {
                          onDeleteFile && onDeleteFile(index);
                        },
                        isHidden: !onDeleteFile
                      }
                    ]}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {onFileUpload && <FileInput onFileUpload={onFileUpload} />}
    </>
  );
};
