import { useCallback } from 'react';
import {
  GetAPI,
  Notification,
  NotificationDTO,
  NotificationState,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface INotificationsService {
  getNotifications: GetAPI<Notification>;

  markNotificationAsRead: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;

  markNotificationAsComplete: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;

  markAllNotificationsAsRead: (
    ids: number[],
    abortController?: AbortController
  ) => Promise<void>;

  markAllNotificationsAsComplete: (
    ids: number[],
    abortController?: AbortController
  ) => Promise<void>;
}

export const useNotificationsService = (): INotificationsService => {
  const notificationsApi = useAxiosInstance('/v2/notifications');

  const getNotifications = useCallback(
    async (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<Notification>> => {
      return notificationsApi
        .get('myNotifications', {
          params: request,
          withCredentials: true,
          signal: request.abortController?.signal
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<NotificationDTO>;

          return {
            items: content.map(getNotificationFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          };
        });
    },
    [notificationsApi]
  );

  const markNotificationAsRead = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      notificationsApi.put(`markViewed/${id}`, undefined, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [notificationsApi]
  );

  const markNotificationAsComplete = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      notificationsApi.put(`markCompleted/${id}`, undefined, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [notificationsApi]
  );

  const markAllNotificationsAsRead = useCallback(
    (ids: number[], abortController?: AbortController): Promise<void> =>
      notificationsApi.put('markViewed', ids, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [notificationsApi]
  );

  const markAllNotificationsAsComplete = useCallback(
    (ids: number[], abortController?: AbortController): Promise<void> =>
      notificationsApi.put('markCompleted', ids, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [notificationsApi]
  );

  return {
    getNotifications,
    markNotificationAsRead,
    markNotificationAsComplete,
    markAllNotificationsAsRead,
    markAllNotificationsAsComplete
  };
};

function getNotificationFromDto(dto: NotificationDTO): Notification {
  return {
    ...dto,
    state: getNotificationStateFromDto(dto)
  };
}

function getNotificationStateFromDto(dto: NotificationDTO): NotificationState {
  if (dto.completedAt !== undefined) {
    return NotificationState.COMPLETED;
  } else if (dto.viewed) {
    return NotificationState.READ;
  } else {
    return NotificationState.UNREAD;
  }
}
