import React, { FC } from 'react';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

export const FormElementsPanel: FC = ({ children }) => {
  return (
    <ThemableBox type={ThemableBoxType.FORM_ELEMENTS_CONTAINER}>
      {children}
    </ThemableBox>
  );
};
