import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from 'react';
import { Breadcrumb } from 'src/types';

/**
 * An async function used to get values
 * to set in {@link Breadcrumb.text}.
 *
 * The function accepts a route parameter value (e.g. 54) of a route
 * parameter ids (e.g. moduleScheduleId) and returns a human-friendly
 * substitute for the route parameter value.
 */
export type BreadcrumbTextGetter =
  | ((routeParameterIdValue: number) => Promise<string>)
  | undefined;

/**
 * An object storing {@link BreadcrumbTextGetter} values
 * keyed on the id fields {@link Breadcrumb} values.
 */
export type BreadcrumbTextGetters = {
  [breadcrumbId: string]: BreadcrumbTextGetter;
};

export interface IBreadcrumbsContext {
  /**
   * The {@link Breadcrumb}, set via {@link setBreadcrumbs}
   * that are rendered at the top of the page.
   */
  breadcrumbs: Breadcrumb[];

  /**
   * Used to set the current value of {@link breadcrumbs}
   */
  setBreadcrumbs: (breadcrumbs: Breadcrumb[]) => void;

  /**
   * Updates the text field of specific breadcrumbs
   *
   * @param breadcrumbText a {@link Record<string, string | undefined>{
   * where each key-value is breadcrumb id and a string that should be
   * used to replace the text field of the breadcrumb with the matching id.
   */
  setBreadcrumbText: (
    breadcrumbText: Record<string, string | undefined>
  ) => void;

  /**
   * Clears the text field of specific breadcrumbs, by
   * setting them to an empty string.
   */
  clearBreadcrumbText: (breadcrumbIds: string[]) => void;

  /**
   * Can be used to store a {@link BreadcrumbTextGetters}
   */
  breadcrumbTextGetters?: BreadcrumbTextGetters;

  /**
   * Can be used to set the current value of {@link BreadcrumbTextGetters}
   */
  setBreadcrumbTextGetters: (
    breadcrumbTextGetters: BreadcrumbTextGetters
  ) => void;

  /**
   * @deprecated - use {@link setBreadcrumbText} going forward instead
   *
   * @remarks - currently only used by {@link useBreadcrumbParamValues}
   */
  setParams: (params: Record<string, string | undefined>) => void;
}

export const BreadcrumbsContext = createContext<
  IBreadcrumbsContext | undefined
>(undefined);

export const BreadcrumbsContextProvider = ({
  children
}: {
  children?: ReactNode;
}) => {
  const [parameterValues, setParameterValues] = useState<
    Record<string, string | undefined>
  >({});
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([]);
  const [breadcrumbTextGetters, setBreadcrumbTextGetters] = useState<
    BreadcrumbTextGetters | undefined
  >();

  const breadcrumbsWithParams = useMemo(() => {
    const breadcrumbsNew: Breadcrumb[] = breadcrumbs.map<Breadcrumb>(
      (breadcrumb) => {
        const textNewValue: string = breadcrumb.id
          ? parameterValues[breadcrumb.id] || breadcrumb.text
          : breadcrumb.text;

        const result: Breadcrumb = {
          ...breadcrumb,
          text: textNewValue
        };
        return result;
      }
    );
    return breadcrumbsNew;
  }, [breadcrumbs, parameterValues]);

  const setBreadcrumbText = useCallback(
    (breadcrumbText: Record<string, string | undefined>) => {
      setParameterValues((parameterValuesOld) => {
        const parameterValuesNew = Object.entries(breadcrumbText).reduce(
          (result, [parameterName, parameterValue]) => {
            result[String(parameterName)] = parameterValue;

            return result;
          },
          { ...parameterValuesOld }
        );

        return parameterValuesNew;
      });
    },
    [setParameterValues]
  );

  const clearBreadcrumbText = useCallback(
    (breadcrumbIds: string[]) => {
      setParameterValues((parameterValuesOld) => {
        return breadcrumbIds.reduce(
          (result, paramId) => {
            result[String(paramId)] = undefined;

            return result;
          },
          { ...parameterValuesOld }
        );
      });
    },
    [setParameterValues]
  );

  /**
   * @deprecated - use {@link setParametersText}
   */
  const setParams = useMemo(
    () => (params: Record<string, string | undefined>) => {
      const breadcrumbsNew: Breadcrumb[] = breadcrumbs.map<Breadcrumb>(
        (breadcrumb) => {
          const textNewValue: string = breadcrumb.id
            ? params[breadcrumb.id] || breadcrumb.text
            : breadcrumb.text;

          const result: Breadcrumb = {
            ...breadcrumb,
            text: textNewValue
          };
          return result;
        }
      );

      if (
        !breadcrumbsNew.some((newBreadcrumb, index) => {
          const oldBreadcrumb = breadcrumbs.at(index);
          return !(
            oldBreadcrumb &&
            oldBreadcrumb.link === newBreadcrumb.link &&
            oldBreadcrumb.text === newBreadcrumb.text &&
            oldBreadcrumb.id === newBreadcrumb.id
          );
        })
      ) {
        return;
      }

      setBreadcrumbs(breadcrumbsNew);
    },
    [breadcrumbs]
  );

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs: breadcrumbsWithParams,
        setBreadcrumbs,
        setBreadcrumbText,
        clearBreadcrumbText,
        breadcrumbTextGetters,
        setBreadcrumbTextGetters,
        setParams
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};

export const useBreadcrumbs = () => {
  const context = useContext(BreadcrumbsContext);

  if (!context) {
    throw new Error(
      'Breadcrumbs Context cannot be used outside of Breadcrumbs Context Provider'
    );
  }

  return context;
};
