import { useCallback, useEffect, useRef } from 'react';
import { isTextValidPositiveInteger } from 'src/lib';
import {
  BreadcrumbTextGetters,
  useBreadcrumbs
} from '../breadcrumbs-context-provider/BreadcrumbsContextProvider';
import { useAppContext } from '../app-context-provider/AppContext';

/**
 * This hook accepts key-value pairs where the keys are route parameter
 * ids (e.g. programmeScheduleId), and the values are route parameter values
 * (e.g. 54). It invokes the {@link BreadcrumbTextGetters} async functions
 * from the {@link BreadcrumbsContext} to derive human-friendly text
 * equivalents of the route parameter values (e.g. MB CHB), and passes
 * these values setBreadcrumbText function of the {@link BreadcrumbsContext}
 * to update the {@link Breadcrumb.text} fields of the breadcrumbs in the
 * {@link BreadcrumbsContext}.
 *
 * @param routeParams {@link Record<string, string | undefined>}
 */
export const useBreadcrumbTextFromBreadcrumbTextGetters = (
  routeParams: Record<string, string | undefined>
): void => {
  const { handleError } = useAppContext();
  const { setBreadcrumbText, clearBreadcrumbText, breadcrumbTextGetters } =
    useBreadcrumbs();
  const propsMemo = useRef(routeParams);

  const setBreadcrumbTextFromRouteParams = useCallback(
    (paramIds: Record<string, string | undefined>) => {
      if (!breadcrumbTextGetters) {
        return;
      }

      getBreadcrumbTextFromBreadcrumbTextGetters(
        paramIds,
        breadcrumbTextGetters
      )
        .then((params) => {
          setBreadcrumbText(params);
        })
        .catch(handleError);
    },
    [breadcrumbTextGetters, setBreadcrumbText, handleError]
  );

  useEffect(() => {
    if (propsMemo.current) {
      clearBreadcrumbText(Object.keys(propsMemo.current));
      setBreadcrumbTextFromRouteParams(propsMemo.current);
    }
  }, [setBreadcrumbTextFromRouteParams, clearBreadcrumbText]);

  function getBreadcrumbTextFromBreadcrumbTextGetters(
    routeParams: Record<string, string | undefined>,
    breadcrumbTextGetters: BreadcrumbTextGetters
  ): Promise<Record<string, string | undefined>> {
    const { routeParameterIds, breadcrumbTextPromises } = Object.entries(
      routeParams
    ).reduce<{
      routeParameterIds: string[];
      breadcrumbTextPromises: Promise<string>[];
    }>(
      (result, [routeParameterId, routeParameterValue]) => {
        if (routeParameterId === '*' || !routeParameterValue) {
          return result;
        }

        if (!isTextValidPositiveInteger(routeParameterValue)) {
          throw new Error(
            `route parameter ${routeParameterId} has invalid integer value: ${routeParameterValue}`
          );
        }

        const breadcrumbTextGetter = breadcrumbTextGetters[routeParameterId];

        if (breadcrumbTextGetter) {
          result.routeParameterIds.push(routeParameterId);
          result.breadcrumbTextPromises.push(
            breadcrumbTextGetter(parseInt(routeParameterValue))
          );
        }

        return result;
      },
      {
        routeParameterIds: [],
        breadcrumbTextPromises: []
      }
    );

    return Promise.all(breadcrumbTextPromises).then((breadcrumbTextItems) => {
      return breadcrumbTextItems.reduce<Record<string, string | undefined>>(
        (result, breadcrumbText, index) => {
          const routeParameterId = routeParameterIds.at(index);

          if (routeParameterId) {
            result[String(routeParameterId)] = breadcrumbText;
          }

          return result;
        },
        {}
      );
    });
  }
};
