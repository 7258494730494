import React, {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { LoadingIndicator } from 'src/common/loading-indicator/LoadingIndicator';
import { useAuthService } from 'src/services';
import {
  IsUserAssignedPermission,
  PermissionId,
  User,
  UserRole
} from 'src/types';
import { useAppContext } from '../app-context-provider/AppContext';

type UserPermissionsContextInterface = {
  permissions: Set<PermissionId>;
  isLoggingOut: boolean;
  setIsLoggingOut: (value: boolean) => void;
  clearPermissions: () => void;
  user: User;
};

const UserContext = createContext<UserPermissionsContextInterface | undefined>(
  undefined
);
UserContext.displayName = 'User Context';

export type UserContextProviderProps = {
  getCurrentUserRoles: (abortController?: AbortController) => Promise<UserRole>;
  getCurrentUser: (abortController?: AbortController) => Promise<User>;
  onUserLoggedIn: (user: User, userPermissions: PermissionId[]) => void;
};

export const UserContextProvider: FC<
  PropsWithChildren<UserContextProviderProps>
> = ({ children, onUserLoggedIn, getCurrentUserRoles, getCurrentUser }) => {
  const { handleError } = useAppContext();
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);

  const [permissions, setPermissions] = useState<
    Set<PermissionId> | undefined
  >();
  const [user, setUser] = useState<User>();

  const clearPermissions = useCallback(() => {
    setPermissions(undefined);
  }, []);

  useEffect(() => {
    const abortController = new AbortController();

    Promise.all([
      getCurrentUser(abortController),
      getCurrentUserRoles(abortController)
    ])
      .then(([userCurrent, userRoleCurrent]) => {
        const permissionsNew = userRoleCurrent.roles.flatMap<PermissionId>(
          (role) => role.permissionsIds
        );
        const permissionsNewSet = new Set<PermissionId>(permissionsNew);
        setPermissions(permissionsNewSet);
        setUser(userCurrent);
        onUserLoggedIn(userCurrent, Array.from(permissionsNewSet.values()));
      })
      .catch(handleError);

    return () => abortController.abort();
  }, [getCurrentUserRoles, getCurrentUser, handleError, onUserLoggedIn]);

  return permissions && user ? (
    <UserContext.Provider
      value={{
        permissions,
        isLoggingOut,
        setIsLoggingOut,
        clearPermissions,
        user
      }}
    >
      {children}
    </UserContext.Provider>
  ) : (
    <LoadingIndicator text={`Logging ${isLoggingOut ? 'out' : 'in'}`} />
  );
};

export type AuthorizedUser = {
  isAllowedTo: IsUserAssignedPermission;
  isForbiddenTo: IsUserAssignedPermission;
  logout: () => Promise<void>;
  user: User;
};

const { DoSystemAdmin } = PermissionId;

export const useAuthorizedUser = (): AuthorizedUser => {
  const context = useContext(UserContext);
  const { logout } = useAuthService();

  if (!context) {
    throw new Error(
      'Permissions Context cannot be used outside of Permissions Context Provider'
    );
  }

  const { permissions, user, clearPermissions, setIsLoggingOut } = context;

  const isAllowedTo: IsUserAssignedPermission = useCallback(
    (permission: PermissionId) => {
      return permissions
        ? permissions.has(DoSystemAdmin) || permissions.has(permission)
        : false;
    },
    [permissions]
  );

  const isForbiddenTo: IsUserAssignedPermission = useCallback(
    (permission: PermissionId) => {
      return !isAllowedTo(permission);
    },
    [isAllowedTo]
  );

  // eslint-disable-next-line
  const logoutCurrentUser = useCallback(async () => {
    setIsLoggingOut(true);
    clearPermissions();
    logout();
  }, [clearPermissions, logout, setIsLoggingOut]);

  const authorizedUser: AuthorizedUser = useMemo(() => {
    return {
      isAllowedTo,
      isForbiddenTo,
      logout: logoutCurrentUser,
      user
    };
  }, [isAllowedTo, isForbiddenTo, logoutCurrentUser, user]);

  return authorizedUser;
};
