import { useCallback } from 'react';
import { GetAPI, PaginatedRequest, PaginatedResponse } from 'src/types';
import { getAllFromPaginatedApi, useAxiosInstance } from 'src/lib';
import { Supervisor } from 'src/types/models/Supervisor.type';

export interface ISupervisorsService {
  getSupervisors: GetAPI<Supervisor>;

  getSupervisorsFull: GetAPI<Supervisor>;

  getSupervisorsWithoutPagination: (
    supervisorIds: number[]
  ) => Promise<Supervisor[]>;

  getSupervisor: (
    userId: number,
    moduleScheduleId?: number,
    abortController?: AbortController
  ) => Promise<Supervisor>;

  saveSupervisor: (
    supervisor: Supervisor,
    abortController?: AbortController
  ) => Promise<void>;

  deleteSupervisor: (
    supervisorId: number,
    abortController?: AbortController
  ) => Promise<void>;

  createSupervisor: (
    supervisor: Supervisor,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useSupervisorsService = (): ISupervisorsService => {
  const supervisorsApi = useAxiosInstance('/v2/supervisors');

  const getSupervisors = useCallback(
    (
      request: PaginatedRequest,
      simpleDataRequired = true
    ): Promise<PaginatedResponse<Supervisor>> => {
      return supervisorsApi
        .get(simpleDataRequired ? '' : '?simpleDataRequired=0', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { page, _embedded } = response.data;

          return {
            items: _embedded?.supervisorDToes || [],
            hasNextPage: page.number < page.totalElements,
            hasPrevPage: page.number > 0,
            pageNumber: page.number,
            totalCount: page.totalElements,
            totalPages: page.totalPages
          } as PaginatedResponse<Supervisor>;
        });
    },
    [supervisorsApi]
  );

  const getSupervisorsFull = useCallback(
    (request: PaginatedRequest) => {
      return getSupervisors(request, false);
    },
    [getSupervisors]
  );

  const getSupervisorsWithoutPagination = useCallback(
    (supervisorIds: number[]) => {
      return getAllFromPaginatedApi({
        getApi: getSupervisors,
        ids: supervisorIds
      });
    },
    [getSupervisors]
  );

  const getSupervisor = useCallback(
    (
      supervisorId: number,
      moduleScheduleId?: number,
      abortController?: AbortController
    ): Promise<Supervisor> => {
      return supervisorsApi
        .get(
          moduleScheduleId
            ? `${supervisorId}?moduleScheduleId=${moduleScheduleId}`
            : `${supervisorId}`,
          {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          }
        )
        .then((response) => response.data as Supervisor);
    },
    [supervisorsApi]
  );

  const saveSupervisor = useCallback(
    (
      supervisor: Supervisor,
      abortController?: AbortController
    ): Promise<void> => {
      return supervisorsApi.put(
        `${supervisor.id}`,
        {
          ...supervisor
        },
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [supervisorsApi]
  );

  const deleteSupervisor = useCallback(
    (
      supervisorId: number,
      abortController?: AbortController
    ): Promise<void> => {
      return supervisorsApi.delete(`${supervisorId}`, {
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [supervisorsApi]
  );

  const createSupervisor = useCallback(
    (
      supervisor: Supervisor,
      abortController?: AbortController
    ): Promise<void> => {
      return supervisorsApi.post(
        '',
        {
          ...supervisor
        },
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [supervisorsApi]
  );

  return {
    getSupervisors,
    getSupervisorsFull,
    getSupervisor,
    saveSupervisor,
    deleteSupervisor,
    createSupervisor,
    getSupervisorsWithoutPagination
  };
};
