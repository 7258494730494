import { PropsWithChildren } from 'react';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';

export type TableLayoutProps = {
    size?: 'small' | 'large';
};

export const TableLayout = ({
    children,
    size = 'small'
}: PropsWithChildren<TableLayoutProps>) => {
    switch (size) {
        case 'small':
            return (
                <ThemableBox type={ThemableBoxType.TABLE_LAYOUT_SMALL}>
                    {children}
                </ThemableBox>
            );
        case 'large':
            return (
                <ThemableBox type={ThemableBoxType.TABLE_LAYOUT_LARGE} >
                    {children}
                </ThemableBox >
            );
    }
};
