import {
  SortDirection,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography
} from '@mui/material';
import { TableHeaderConfig } from 'src/types';

interface TableHeaderProps {
  headers: TableHeaderConfig[];
  order?: SortDirection;
  orderBy?: string;
  handleSort?: (
    property: string
  ) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  disableSort?: boolean;
  disableSortColumns?: string[];
}

export const TableHeader = ({
  order,
  orderBy,
  headers,
  handleSort,
  disableSort,
  disableSortColumns
}: TableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        {headers.map(header => (
          <TableCell
            key={header.id}
            align="left"
            sortDirection={orderBy === header.id ? order : false}
          >
            {!disableSort &&
            (!disableSortColumns || !disableSortColumns.includes(header.id)) ? (
              <TableSortLabel
                active={orderBy === header.id && !disableSort}
                direction={orderBy === header.id && order ? order : 'asc'}
                onClick={handleSort ? handleSort(header.id) : undefined}
              >
                {header.label}
              </TableSortLabel>
            ) : (
              <Typography variant="body2">{header.label}</Typography>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
