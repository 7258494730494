import { useCallback } from 'react';
import {
  AutomationRequestProgress,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  PlacementAutomationDTO,
  PlacementAutomationRequest,
  PlacementAutomationRequestDTO
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';

export interface IPlacementAutomationService {
  getPlacementAutomationRequests: GetAPI<PlacementAutomationRequest>;
  getPlacementAutomationRequest: (
    placementAutomationRequestId: number,
    abortController?: AbortController
  ) => Promise<PlacementAutomationRequest>;
  createPlacementAutomationRequest: (
    placementAutomationRequest: PlacementAutomationRequest,
    abortController?: AbortController
  ) => Promise<void>;
  deletePlacementAutomationRequest: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const usePlacementAutomationService =
  (): IPlacementAutomationService => {
    const placementAutomationApi = useAxiosInstance(
      '/v2/placementAutomationRequests'
    );

    const getPlacementAutomationRequests = useCallback(
      (
        request: PaginatedRequest
      ): Promise<PaginatedResponse<PlacementAutomationRequest>> => {
        return placementAutomationApi
          .get('', {
            params: request,
            withCredentials: true,
            signal: request.abortController
              ? request.abortController.signal
              : undefined
          })
          .then((response) => {
            const { content, first, last, number, totalElements, totalPages } =
              response.data as PaginatedResponseDTO<PlacementAutomationRequestDTO>;

            return {
              items: content.map(getPlacementAutomationRequestFromDto),
              hasNextPage: !last,
              hasPrevPage: !first,
              pageNumber: number,
              totalCount: totalElements,
              totalPages: totalPages
            } as PaginatedResponse<PlacementAutomationRequest>;
          });
      },
      [placementAutomationApi]
    );

    const getPlacementAutomationRequest = useCallback(
      (
        placementAutomationRequestId: number,
        abortController?: AbortController
      ) => {
        return placementAutomationApi
          .get(`${placementAutomationRequestId}`, {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) => {
            const placementAutomationRequestDTO =
              response.data as PlacementAutomationRequestDTO;

            return getPlacementAutomationRequestFromDto(
              placementAutomationRequestDTO
            );
          });
      },
      [placementAutomationApi]
    );

    const deletePlacementAutomationRequest = useCallback(
      (id: number, abortController?: AbortController): Promise<void> =>
        placementAutomationApi.delete(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        }),
      [placementAutomationApi]
    );

    const createPlacementAutomationRequest = (
      placementAutomationRequest: PlacementAutomationRequest,
      abortController?: AbortController
    ): Promise<void> =>
      placementAutomationApi.post(
        '',
        getDtoFromPlacementAutomationRequest(placementAutomationRequest),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      );

    return {
      getPlacementAutomationRequests,
      getPlacementAutomationRequest,
      deletePlacementAutomationRequest,
      createPlacementAutomationRequest
    };

    function getPlacementAutomationRequestFromDto(
      placementAutomationRequestDto: PlacementAutomationRequestDTO
    ): PlacementAutomationRequest {
      const { createdAt, completedAt, progress } =
        placementAutomationRequestDto;

      return {
        ...placementAutomationRequestDto,
        createdAt: getDateFromDtoDateString(createdAt),
        completedAt: completedAt
          ? getDateFromDtoDateString(completedAt)
          : undefined,
        progress: getProgressEnumFromProgressString(progress)
      };
    }

    function getDtoFromPlacementAutomationRequest(
      placementAutomationRequest: PlacementAutomationRequest
    ): PlacementAutomationRequestDTO {
      const { createdAt, progress } = placementAutomationRequest;

      return {
        ...placementAutomationRequest,
        createdAt: getDtoDateStringFromDate(createdAt),
        completedAt: undefined,
        progress: getProgressStringFromProgressEnum(progress)
      };
    }
  };

function getProgressStringFromProgressEnum(
  progress: AutomationRequestProgress
) {
  switch (progress) {
    case AutomationRequestProgress.IN_PROGRESS:
      return PlacementAutomationDTO.IN_PROGRESS;
    case AutomationRequestProgress.ERROR:
      return PlacementAutomationDTO.ERROR;
    case AutomationRequestProgress.AWAITING_START:
      return PlacementAutomationDTO.AWAITING_START;
    case AutomationRequestProgress.SUCCESS:
      return PlacementAutomationDTO.SUCCESS;
    case AutomationRequestProgress.IMPORTED:
      return PlacementAutomationDTO.IMPORTED;
  }
}

function getProgressEnumFromProgressString(
  progress: string
): AutomationRequestProgress {
  switch (progress) {
    case PlacementAutomationDTO.IN_PROGRESS:
      return AutomationRequestProgress.IN_PROGRESS;
    case PlacementAutomationDTO.ERROR:
      return AutomationRequestProgress.ERROR;
    case PlacementAutomationDTO.AWAITING_START:
      return AutomationRequestProgress.AWAITING_START;
    case PlacementAutomationDTO.SUCCESS:
      return AutomationRequestProgress.SUCCESS;
    case PlacementAutomationDTO.IMPORTED:
      return AutomationRequestProgress.IMPORTED;
    default:
      throw new Error('unsupported progress type');
  }
}
