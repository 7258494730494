import * as React from 'react';
import { useMemo, useState } from 'react';
import { Autocomplete } from '@mui/material';
import { useFormikContext } from 'formik';
import { TextFieldV2 } from 'src/common';

export type StaticAutocompleteProps<T> = {
  label: string;
  options: T[];
  getOptionLabel: (value: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  error?: boolean;
  required?: boolean;
  size?: 'small' | 'medium';
  helperText?: React.ReactNode;
  multiple?: boolean;
  comparator?: (firstOption: T, secondOption: T) => number;
  onSelectedOptionChanged?: (value: T | T[] | null) => void; // eslint-disable-next-line
  [x: string]: any;
};

export function StaticAutocomplete<T>(
  props: StaticAutocompleteProps<T>
): JSX.Element {
  const [open, setOpen] = useState(false);

  const {
    label,
    options,
    getOptionsFilters,
    isOptionEqualToValue,
    getOptionLabel,
    multiple,
    name,
    field,
    error,
    helperText,
    required,
    onSelectedOptionChanged,
    value,
    ...rest
  } = props;

  const formikContext = useFormikContext();

  const optionsSorted: T[] = useMemo(
    () => (props.comparator ? [...options].sort(props.comparator) : options),
    [options, props.comparator]
  );

  return (
    <Autocomplete
      {...rest}
      value={value ?? null}
      onChange={(e, value) => {
        onSelectedOptionChanged && onSelectedOptionChanged(value);
        if (formikContext) {
          formikContext.setFieldValue(
            field.name as string,
            value === null ? undefined : value
          );
        }
      }}
      multiple={multiple || false}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={optionsSorted || []}
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextFieldV2
          {...params}
          value={value}
          InputLabelProps={{
            ...params.InputLabelProps,
            required: required && (!multiple || props.value?.length === 0)
          }}
          error={error}
          helperText={helperText}
          label={label}
          InputProps={{
            ...params.InputProps,
            size: props.size ?? 'medium',
            endAdornment: <>{params.InputProps.endAdornment}</>
          }}
        />
      )}
    />
  );
}
