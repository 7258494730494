import { SortDirection } from '@mui/material';
import { createContext, useContext, FC, useState, useEffect } from 'react';
import { useTableQueryParams } from 'src/common/table/hooks';

interface TableContextInterface {
  disableSort?: boolean;
  disableSortColumns?: string[];
  order: SortDirection;
  orderBy: string;
  handleRequestSort: (
    property: string
  ) => (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void;
  page: number;
  handlePageChange: (page: number) => void;
  pageSize: number;
  handlePageSizeChange: (pageSize: number) => void;
  filters: Record<string, string>;
  setFilter: (filterName: string, filterValue?: string) => void;
  setFilters: (newFilters: Record<string, string | undefined>) => void;
  search?: string;
  setSearch: (search: string) => void;
  refreshStatus: RefreshStatus;
  setRefreshStatus: (refreshStatus: RefreshStatus) => void;
}

export enum RefreshStatus {
  REFRESHED,
  REFRESHING
}

interface DefaultProviderProps {
  disableSort?: boolean;
  disableSortColumns?: string[];
  defaultFilters?: Record<string, string>;
}

const TableContext = createContext<TableContextInterface | undefined>(
  undefined
);

export const TableContextProvider: FC<DefaultProviderProps> = ({
  disableSort,
  disableSortColumns,
  children,
  defaultFilters
}) => {
  const [refreshStatus, setRefreshStatus] = useState<RefreshStatus>(
    RefreshStatus.REFRESHED
  );

  const {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    handlePageChange,
    pageSize,
    handlePageSizeChange,
    filters: filtersObject,
    setFilter,
    setFilters,
    search,
    setSearch
  } = useTableQueryParams({ defaultPageSize: 10, disableSort, defaultFilters });

  const handleRequestSort =
    (property: string) =>
    (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

  const [filters, setNewFilters] = useState<Record<string, string>>(
    Object.entries(filtersObject).reduce<Record<string, string>>(
      (result, [key, value]) => {
        if (value) {
          result[String(key)] = value;
        }
        return result;
      },
      {}
    )
  );

  useEffect(() => {
    setNewFilters(
      Object.entries(filtersObject).reduce<Record<string, string>>(
        (result, [key, value]) => {
          if (value) {
            result[String(key)] = value;
          }
          return result;
        },
        {}
      )
    );
  }, [filtersObject, setNewFilters]);

  const startingValue: TableContextInterface = {
    disableSort,
    disableSortColumns,
    handleRequestSort,
    order,
    orderBy,
    page,
    handlePageChange,
    pageSize,
    handlePageSizeChange,
    filters,
    setFilter,
    setFilters,
    search,
    setSearch,
    refreshStatus,
    setRefreshStatus
  };

  return (
    <TableContext.Provider value={startingValue}>
      {children}
    </TableContext.Provider>
  );
};

export const useTableContext = () => {
  const tableContext = useContext(TableContext);

  if (!tableContext) {
    throw new Error(
      'Cannot use Table Context outside of Table Context Provider'
    );
  }

  return tableContext;
};
