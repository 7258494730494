import { FileDetails } from '../types';
import { useFilesService } from './file-service';
import { useCallback } from 'react';
import { useUsersService } from './user-service';

export interface IUploadFileService {
  uploadFile: (
    file: Blob,
    fileName: string,
    abortController?: AbortController
  ) => Promise<FileDetails>;
}
export const useUploadFileService = (): IUploadFileService => {
  const { uploadFileBlob } = useFilesService();
  const { getUser } = useUsersService();

  const uploadFile = useCallback(
    (
      file: Blob,
      fileName: string,
      abortController?: AbortController
    ): Promise<FileDetails> => {
      return uploadFileBlob(file, fileName, abortController).then(file =>
        getUser(file.userId).then(user => {
          const { id, name, size, mimeType, tagIds, dateCreated } = file;

          return {
            id,
            name,
            size,
            mimeType,
            tagIds,
            dateCreated: new Date(Date.parse(dateCreated)),
            createdByUserId: file.userId,
            createdBy: user.displayName
          } as FileDetails;
        })
      );
    },
    [uploadFileBlob, getUser]
  );

  return {
    uploadFile
  };
};
