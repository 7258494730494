import {
  TablePaginatedContainer,
  TablePaginatedProps,
  TableStaticContainer,
  TableStaticMultiSelect,
  TableStaticMultiSelectProps,
  TableStaticProps,
  TablePaginatedWithGroupByContainer,
  TablePaginatedWithGroupByProps,
  TablePaginatedCollapsibleRowProps,
  TablePaginatedCollapsibleRowContainer,
  TableStaticVirtualisedProps,
  TableStaticVirtualisedContainer
} from './containers';

export enum TableType {
  PAGINATED = 'PAGINATED',
  STATIC = 'STATIC',
  STATIC_VIRTUALISED = 'STATIC_VIRTUALISED',
  STATIC_MULTI_SELECT = 'STATIC_MULTI_SELECT',
  PAGINATED_WITH_GROUP_BY = 'PAGINATED_WITH_GROUP_BY',
  PAGINATED_COLLAPSIBLE_ROW = 'PAGINATED_COLLAPSIBLE_ROW'
}

export type TableProps =
  | TablePaginatedProps
  | TableStaticProps
  | TableStaticVirtualisedProps
  | TableStaticMultiSelectProps
  | TablePaginatedWithGroupByProps
  | TablePaginatedCollapsibleRowProps;

export const Table = (props: TableProps) => {
  switch (props.type) {
    case TableType.PAGINATED:
      return <TablePaginatedContainer {...props} />;

    case TableType.PAGINATED_WITH_GROUP_BY:
      return <TablePaginatedWithGroupByContainer {...props} />;

    case TableType.STATIC:
      return <TableStaticContainer {...props} />;

    case TableType.STATIC_VIRTUALISED:
      return <TableStaticVirtualisedContainer {...props} />;

    case TableType.STATIC_MULTI_SELECT:
      return <TableStaticMultiSelect {...props} />;

    case TableType.PAGINATED_COLLAPSIBLE_ROW:
      return <TablePaginatedCollapsibleRowContainer {...props} />;
  }
};
