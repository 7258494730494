import React, { FC } from 'react';
import { Badge, IconButton, Tooltip } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';

export type MenuButtonProps = {
  onClick: (mouseEvent: React.MouseEvent<HTMLElement>) => void;
  totalNotifications?: number;
  maxNotifications?: number;
};

export const ButtonExpandList: FC<MenuButtonProps> = ({
  onClick,
  totalNotifications,
  maxNotifications
}) => {
  return (
    <ThemableBox type={ThemableBoxType.NOTIFICATIONS_MENU_BUTTON}>
      <Tooltip title="Notifications">
        <Badge
          badgeContent={totalNotifications ?? 0}
          max={maxNotifications}
          overlap="circular"
        >
          <IconButton onClick={onClick} size="large">
            <NotificationsIcon />
          </IconButton>
        </Badge>
      </Tooltip>
    </ThemableBox>
  );
};
