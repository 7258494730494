import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate
} from 'src/lib/date-utils';

/**
 * Wraps `JSON.parse` and passes its own reviver to this function. This reviver
 * is able to revive strings created by the `stringify` function defined in this file.
 *
 * Currently includes special for the following types (which otherwise don't play nicely
 * with `JSON.parse`: Map, Date,
 *
 * To be used in conjunction with the `stringify` function defined in this file.
 *
 * @param text to parse
 * @return T
 */
export function parse<T>(text: string): T {
  return JSON.parse(text, reviver) as T;
}

/**
 * Wraps `JSON.stringify` for and passes its own replacer to this function. This replacer
 * creates strings that can be  revived by the `parse` function defined in this file.
 *
 * Currently includes special for the following types (which otherwise don't play nicely
 * with `JSON.stringify`: Map, Date,
 *
 * To be used in conjunction with the `parse` function defined in this file.
 *
 * @param value to stringify
 * @return string
 */
export function stringify<T>(value: T): string {
  return JSON.stringify(value, replacer);
}

enum DataType {
  'MAP' = 'Map',
  'DATE' = 'Date'
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function replacer(key: string, value: any) {
  if (value instanceof Map) {
    return {
      dataType: DataType.MAP,
      value: Array.from(value.entries())
    };
  }

  if (value instanceof Date) {
    return {
      dataType: DataType.DATE,
      value: getDtoDateStringFromDate(value)
    };
  }

  if (typeof value === 'object') {
    for (const key in value) {
      if (value[String(key)]) {
        value[String(key)] = replacer(key, value[String(key)]);
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function reviver(key: string, value: any) {
  if (typeof value === 'object' && value !== null) {
    if (value.dataType === DataType.MAP) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return new Map(value.value);
    }

    if (value.dataType === DataType.DATE) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      return getDateFromDtoDateString(value.value);
    }

    if (typeof value === 'object') {
      for (const key in value) {
        if (value[String(key)]) {
          value[String(key)] = reviver(key, value[String(key)]);
        }
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return value;
}
