import { isNumber } from 'lodash';

export const isRecordsEqual = <T extends string | number | symbol>(
  recordLeft?: Record<string, T>,
  recordRight?: Record<string, T>
): boolean => {
  if (!recordLeft && !recordRight) {
    return true;
  } else if (!recordLeft || !recordRight) {
    return false;
  }
  const keysLeft = Object.keys(recordLeft);
  const keysRight = Object.keys(recordRight);

  return (
    keysLeft.length === keysRight.length &&
    keysLeft.every((keyLeft) => {
      return recordLeft[`${keyLeft}`] === recordRight[`${keyLeft}`];
    })
  );
};

export const isStringTheNumberZero = (value: string | undefined): boolean => {
  return (
    isNumber(value) &&
    !isNaN(Number.parseInt(value)) &&
    Number.parseInt(value) === 0
  );
};
