import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactElement } from 'react';

export type NestedAccordianProps = {
  accordianGroups: NestedAccordianGroup[];
};

export type NestedAccordianGroup = {
  isHidden?: boolean;
  header: string;
  content?: () => ReactElement;
  subGroups?: NestedAccordianGroup[];
};

function renderAccordian(
  accordianGroup: NestedAccordianGroup,
  index: number,
  subIndex?: number
) {
  return (
    <Box
      key={`${accordianGroup.header}-${index}-${subIndex ? subIndex : ''}`}
      sx={{ paddingBottom: '5px' }}
    >
      {!accordianGroup.isHidden && (
        <Accordion TransitionProps={{ timeout: 0, unmountOnExit: true }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {accordianGroup.header}
          </AccordionSummary>
          <Box
            sx={{
              paddingTop: '5px',
              paddingLeft: '25px',
              paddingRight: '25px'
            }}
          >
            {accordianGroup.subGroups &&
              accordianGroup.subGroups.map((subGroup, subIndex) =>
                renderAccordian(subGroup, index, subIndex)
              )}
          </Box>

          {accordianGroup.content && (
            <AccordionDetails>{accordianGroup.content()}</AccordionDetails>
          )}
        </Accordion>
      )}
    </Box>
  );
}

export const NestedAccordian = ({ accordianGroups }: NestedAccordianProps) => {
  return (
    <Box>
      {accordianGroups.map((group, index) => {
        return renderAccordian(group, index);
      })}
    </Box>
  );
};
