import React from 'react';
import moment from 'moment/moment';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  Stack,
  Switch,
  Typography
} from '@mui/material';
import { Field, FormikProps } from 'formik';
import {
  AsyncAutocompleteV2,
  AuthorizedUser,
  DateInputField,
  TextFieldV2,
  TimeInputField
} from 'src/common';
import {
  DATE_TIME_FORMAT,
  EntityType,
  getFieldErrorProps,
  getWarningTextForNoViewPermissions,
  isTextValidPositiveInteger,
  usePaginatedApiWithFixedParams
} from 'src/lib';
import {
  Facility,
  FormType,
  GetAPI,
  PermissionId,
  RoomBookingStatus,
  RoomBookingType,
  Staff,
  Tag,
  TrainingArea,
  Weekday
} from 'src/types';
import { FormValues } from './form-types';
import { RecurrenceDetails } from '../RecurrenceDetails';

type FormDetailsProps = {
  formikHelpers: FormikProps<FormValues>;
  formType: FormType;
  bookingType: RoomBookingType;
  getTags: GetAPI<Tag>;
  getFacilities?: GetAPI<Facility>;
  facilityType?: string;
  getTrainingAreas: GetAPI<TrainingArea>;
  getDrivers?: GetAPI<Staff>;
  isApprover?: boolean;
  authorizedUser: AuthorizedUser;
};

const WEEK_DAYS = [
  { id: Weekday.MONDAY, label: 'Monday' },
  { id: Weekday.TUESDAY, label: 'Tuesday' },
  { id: Weekday.WEDNESDAY, label: 'Wednesday' },
  { id: Weekday.THURSDAY, label: 'Thursday' },
  { id: Weekday.FRIDAY, label: 'Friday' },
  { id: Weekday.SATURDAY, label: 'Saturday' },
  { id: Weekday.SUNDAY, label: 'Sunday' }
];

export function BookingDetailsForm(formProps: FormDetailsProps) {
  const { values, setFieldValue, setValues } = formProps.formikHelpers;

  function getBookingStatus(roomBookingStatus: RoomBookingStatus): string {
    switch (roomBookingStatus) {
      case RoomBookingStatus.APPROVED:
        return 'Approved';

      case RoomBookingStatus.AWAITING_APPROVAL:
        return 'Awaiting Approval';

      case RoomBookingStatus.REJECTED:
        return 'Rejected';

      case RoomBookingStatus.CANCELLED:
        return 'Cancelled';

      default:
        return '';
    }
  }

  function getTrainingAreaName(roomBookingType: RoomBookingType): string {
    switch (roomBookingType) {
      case RoomBookingType.TRANSPORT:
        return 'Seat Number';

      case RoomBookingType.ACCOMMODATION:
        return 'Accommodation Site';

      case RoomBookingType.ADHOC:
        return 'Facility Area';

      default:
        return '';
    }
  }

  function handleDateFocus() {
    return () => {
      if (values.editSeries === undefined) {
        setValues({ ...values, isUpdateSeriesModalOpen: true });
      }
    };
  }

  function handleCapacityOnChangeEvent(
    event: React.ChangeEvent<HTMLInputElement>
  ) {
    if (isTextValidPositiveInteger(event.target.value)) {
      const stringValue = Number(event.target.value).toString();
      if (
        values.trainingArea &&
        (values.trainingArea.capacity ?? 0) < parseInt(stringValue)
      ) {
        setFieldValue('trainingArea', undefined);
      }
      setFieldValue('capacity', stringValue.toString());
    } else {
      setFieldValue('capacity', 0);
    }
  }

  const getTrainingAreasSortedByCapacity = usePaginatedApiWithFixedParams({
    getApi: formProps.getTrainingAreas,
    order: 'asc',
    orderBy: 'capacity'
  });

  return (
    <Stack padding={2} spacing={4}>
      <Typography variant="headingFourForm">Booking Details</Typography>

      <Stack spacing={2}>
        <Field
          {...getFieldErrorProps('title', formProps.formikHelpers)}
          size="medium"
          label="Topic"
          variant="outlined"
          required={formProps.formType !== FormType.VIEW}
          as={TextFieldV2}
          InputProps={{ readOnly: formProps.formType === FormType.VIEW }}
        />
        {formProps.formType !== FormType.CREATE && (
          <Field
            {...getFieldErrorProps('status', formProps.formikHelpers)}
            size="medium"
            label="Status"
            value={getBookingStatus(values.status)}
            variant="outlined"
            as={TextFieldV2}
            InputProps={{ readOnly: true }}
          />
        )}
        {formProps.bookingType !== RoomBookingType.ADHOC && (
          <FormControlLabel
            value="start"
            control={
              <Field
                {...getFieldErrorProps(
                  'recurrence',
                  formProps.formikHelpers,
                  'enabled'
                )}
                name="recurrence.enabled"
                value={values.recurrence.enabled}
                checked={values.recurrence.enabled}
                onChange={() => {
                  setFieldValue(
                    'recurrence.enabled',
                    !values.recurrence.enabled
                  );
                }}
                disabled={
                  formProps.formType === FormType.VIEW ||
                  formProps.formType === FormType.UPDATE
                }
                component={Switch}
              />
            }
            label="Is recurring?"
            labelPlacement="start"
            style={{ display: 'flex', justifyContent: 'space-between' }}
          />
        )}
        <Stack direction="row" spacing={2}>
          <Field
            {...getFieldErrorProps('startDateTime', formProps.formikHelpers)}
            size="medium"
            name="startDateTime"
            label="Start Date"
            variant="outlined"
            InputProps={{
              readOnly: formProps.formType === FormType.VIEW
            }}
            as={DateInputField}
            type="date"
            required={formProps.formType !== FormType.VIEW}
            value={values.startDateTime}
            onFocus={handleDateFocus()}
          />

          <Field
            {...getFieldErrorProps('endDateTime', formProps.formikHelpers)}
            size="medium"
            name="endDateTime"
            label="End Date"
            variant="outlined"
            InputProps={{
              readOnly: formProps.formType === FormType.VIEW
            }}
            as={DateInputField}
            type="date"
            required={formProps.formType !== FormType.VIEW}
            value={values.endDateTime}
            onFocus={handleDateFocus()}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <Field
            {...getFieldErrorProps('startDateTime', formProps.formikHelpers)}
            size="medium"
            name="startDateTime"
            label="Start Time"
            variant="outlined"
            InputProps={{
              readOnly: formProps.formType === FormType.VIEW
            }}
            as={TimeInputField}
            type="time"
            required={formProps.formType !== FormType.VIEW}
            value={values.startDateTime}
          />

          <Field
            {...getFieldErrorProps('endDateTime', formProps.formikHelpers)}
            size="medium"
            name="endDateTime"
            label="End Time"
            variant="outlined"
            InputProps={{
              readOnly: formProps.formType === FormType.VIEW
            }}
            as={TimeInputField}
            type="time"
            required={formProps.formType !== FormType.VIEW}
            value={values.endDateTime}
          />
        </Stack>
        {formProps.bookingType !== RoomBookingType.ADHOC &&
          values.recurrence.enabled && (
            <Stack direction="row" spacing={2}>
              <FormLabel>Recurring Days</FormLabel>
              <Stack>
                {WEEK_DAYS.map((weekday) => (
                  <Field
                    type="checkbox"
                    name={weekday.id}
                    value={weekday.id}
                    key={weekday.id}
                    as={FormControlLabel}
                    control={<Checkbox />}
                    disabled={formProps.formType === FormType.VIEW}
                    checked={values.recurrence.daysOfWeek.includes(weekday.id)}
                    label={weekday.label}
                    onChange={() => {
                      const index = values.recurrence.daysOfWeek.indexOf(
                        weekday.id
                      );
                      if (index > -1) {
                        values.recurrence.daysOfWeek.splice(index, 1);
                        setFieldValue(
                          'recurrence.weekdays',
                          values.recurrence.daysOfWeek
                        );
                        setFieldValue(
                          `${weekday.id}`,
                          values.recurrence.daysOfWeek.includes(weekday.id)
                        );
                      } else {
                        values.recurrence.daysOfWeek.push(weekday.id);
                        setFieldValue(
                          'recurrence.weekdays',
                          values.recurrence.daysOfWeek
                        );
                        setFieldValue(
                          `${weekday.id}`,
                          values.recurrence.daysOfWeek.includes(weekday.id)
                        );
                      }
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          )}
        {formProps.authorizedUser.isForbiddenTo(PermissionId.ViewTags) ? (
          <Field
            label="Amenities"
            size="medium"
            variant="outlined"
            component={TextFieldV2}
            helperText={getWarningTextForNoViewPermissions(EntityType.AMENITY)}
            FormHelperTextProps={{
              variant: 'helperTextWarning'
            }}
            disabled={true}
          />
        ) : (
          formProps.bookingType === RoomBookingType.ACCOMMODATION && (
            <Field
              name="equipment"
              size="medium"
              label="Amenities"
              multiple={true}
              variant="outlined"
              getOptions={formProps.getTags}
              getOptionsFilters={{ category: 'Amenities' }}
              getOptionLabel={(option: Tag) => `${option.tag}`}
              readOnly={formProps.formType === FormType.VIEW}
              component={AsyncAutocompleteV2}
              value={values.equipment}
            />
          )
        )}
        <Field
          {...getFieldErrorProps('facility', formProps.formikHelpers)}
          name="facility"
          size="medium"
          label={
            formProps.bookingType === RoomBookingType.TRANSPORT
              ? 'Transport Mode'
              : 'Facility'
          }
          variant="outlined"
          getOptions={
            formProps.formType === FormType.VIEW
              ? () => Promise.resolve([])
              : formProps.getFacilities
          }
          getOptionsFilters={
            formProps.facilityType === 'Adhoc'
              ? {
                  facilityType: 'Academic',
                  status: 'Viewable',
                  withBookableTrainingAreas: true
                }
              : {
                  facilityType: formProps.facilityType,
                  status: 'Viewable',
                  withBookableTrainingAreas: true
                }
          }
          getOptionLabel={(option: Facility) => option.facilityName}
          onSelectedOptionChanged={() =>
            setFieldValue('trainingArea', undefined)
          }
          readOnly={formProps.formType === FormType.VIEW}
          component={AsyncAutocompleteV2}
          required={formProps.formType !== FormType.VIEW}
          value={values.facility}
        />
        {formProps.bookingType === RoomBookingType.TRANSPORT && (
          <Field
            {...getFieldErrorProps('driverStaff', formProps.formikHelpers)}
            name="driverStaff"
            size="medium"
            label="Driver"
            variant="outlined"
            getOptions={
              formProps.formType === FormType.VIEW
                ? () => Promise.resolve([])
                : formProps.getDrivers
            }
            getOptionsFilters={{ driver: true }}
            getOptionLabel={(option: Staff) => option.displayName}
            readOnly={formProps.formType === FormType.VIEW}
            component={AsyncAutocompleteV2}
            required={false}
            value={values.driverStaff}
          />
        )}
        <Stack justifyContent="right">
          <Field
            {...getFieldErrorProps('capacity', formProps.formikHelpers)}
            value={values.capacity}
            size="medium"
            label="Capacity"
            variant="outlined"
            as={TextFieldV2}
            InputProps={{
              type: 'number',
              readOnly: formProps.formType === FormType.VIEW
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleCapacityOnChangeEvent(event);
            }}
          />
        </Stack>
        {formProps.formType === FormType.CREATE && (
          <Field
            {...getFieldErrorProps('trainingAreas', formProps.formikHelpers)}
            name="trainingAreas"
            size="medium"
            label={getTrainingAreaName(formProps.bookingType)}
            value={values.trainingAreas}
            multiple={true}
            getOptions={getTrainingAreasSortedByCapacity}
            getOptionLabel={(option: TrainingArea) => {
              const { areaLocation, remainingCapacity } = option;
              return `${areaLocation} - ${Math.max(remainingCapacity ?? 0, 0)}`;
            }}
            getOptionsFilters={
              values.facility
                ? {
                    facilityId: values.facility.id.toString(),
                    capacity: values.capacity,
                    startDateTime: moment(values.startDateTime).format(
                      DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
                    ),
                    endDateTime: moment(values.endDateTime).format(
                      DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
                    ),
                    equipmentIds:
                      values.equipment && values.equipment.length > 0
                        ? values.equipment.map((item) => item.id).join(',')
                        : '',
                    bookable: String(true),
                    countRemainingCapacity: String(true)
                  }
                : {}
            }
            isOptionEqualToValue={(option: TrainingArea, value: TrainingArea) =>
              option.id === value.id
            }
            component={AsyncAutocompleteV2}
            required={true}
          />
        )}
        {(formProps.formType === FormType.VIEW ||
          formProps.formType === FormType.UPDATE) && (
          <Field
            {...getFieldErrorProps('trainingArea', formProps.formikHelpers)}
            name="trainingArea"
            size="medium"
            label={getTrainingAreaName(formProps.bookingType)}
            variant="outlined"
            getOptions={
              formProps.formType === FormType.VIEW
                ? () => Promise.resolve([])
                : formProps.getTrainingAreas
            }
            getOptionsFilters={
              values.facility
                ? {
                    facilityId: values.facility.id.toString(),
                    capacity: values.capacity,
                    startDateTime: moment(values.startDateTime).format(
                      DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
                    ),
                    endDateTime: moment(values.endDateTime).format(
                      DATE_TIME_FORMAT.ABSOLUTE_DATE_FORMAT
                    ),
                    equipmentIds:
                      values.equipment && values.equipment.length > 0
                        ? values.equipment.map((item) => item.id).join(',')
                        : '',
                    bookable: String(true),
                    countRemainingCapacity: String(true)
                  }
                : {}
            }
            getOptionLabel={(option: TrainingArea) => {
              const { areaLocation, remainingCapacity } = option;
              return `${areaLocation} - ${Math.max(remainingCapacity ?? 0, 0)}`;
            }}
            readOnly={formProps.formType === FormType.VIEW}
            component={AsyncAutocompleteV2}
            required={formProps.formType !== FormType.VIEW}
            value={values.trainingArea}
          />
        )}
        {formProps.bookingType === RoomBookingType.TRANSPORT && (
          <Field
            {...getFieldErrorProps(
              'bookingKmTravelled',
              formProps.formikHelpers
            )}
            value={values.bookingKmTravelled}
            size="medium"
            label="Booking Km Travelled"
            variant="outlined"
            as={TextFieldV2}
            InputProps={{
              type: 'number',
              readOnly: formProps.formType === FormType.VIEW
            }}
          />
        )}

        {formProps.isApprover && (
          <Stack spacing={2}>
            <Field
              {...getFieldErrorProps('bookedByUser', formProps.formikHelpers)}
              label="Booked By"
              size="medium"
              variant="outlined"
              required={false}
              as={TextFieldV2}
              InputProps={{ readOnly: true }}
            />

            <Field
              {...getFieldErrorProps(
                'lastUpdatedByUser',
                formProps.formikHelpers
              )}
              label="Last Updated By"
              size="medium"
              variant="outlined"
              required={false}
              as={TextFieldV2}
              InputProps={{ readOnly: true }}
            />

            {values.status === RoomBookingStatus.APPROVED && (
              <Field
                {...getFieldErrorProps(
                  'approvedByUser',
                  formProps.formikHelpers
                )}
                label="Approved By"
                size="medium"
                variant="outlined"
                required={false}
                as={TextFieldV2}
                InputProps={{ readOnly: true }}
              />
            )}
          </Stack>
        )}

        <Field
          {...getFieldErrorProps('notes', formProps.formikHelpers)}
          name="notes"
          label="Notes"
          size="medium"
          variant="outlined"
          InputProps={{
            readOnly: formProps.formType === FormType.VIEW
          }}
          as={TextFieldV2}
          value={values.notes}
          multiline
          rows={4}
        />
        {formProps.formType !== FormType.CREATE &&
          values.recurrence.enabled && (
            <RecurrenceDetails
              weekdays={values.recurrence.daysOfWeek}
              startDateTime={values.recurrence.arrivalDate}
              endDateTime={values.recurrence.departureDate}
            />
          )}
      </Stack>
    </Stack>
  );
}
