import { FC, useState } from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { BorderBox, ShowIf, ShowIfMode, ThemableBox } from 'src/common';

export type ListViewFilterButtonProps = {
  title?: string;
  totalFilterCount: number;
  activeFilterCount?: number;
  clearAllFilters?: () => void;
};

const MAX_NUMBER_FILTERS_TO_INDICATE = 12;

function getButtonLabel(totalFilterCount: number, activeFilterCount?: number) {
  return `Filters: ${
    activeFilterCount !== undefined && activeFilterCount > 0
      ? `${String(activeFilterCount)} /`
      : ''
  } ${
    totalFilterCount > MAX_NUMBER_FILTERS_TO_INDICATE
      ? `${String(MAX_NUMBER_FILTERS_TO_INDICATE)}+`
      : String(totalFilterCount)
  }`;
}

/**
 * Provides the same functionality as {@link ListViewFilter}, except that the title,
 * counts, and behaviour of the clear all filters button, need to be passed in as
 * parameters.
 *
 * @param children
 * @param title
 * @param totalFilterCount
 * @param activeFilterCount
 * @param clearAllFilters
 */
export const ListViewFilterBase: FC<ListViewFilterButtonProps> = ({
  children,
  title,
  totalFilterCount,
  activeFilterCount,
  clearAllFilters
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClearFilters = () => {
    if (clearAllFilters) {
      clearAllFilters();
    }
  };

  return (
    <>
      <ShowIf condition={isOpen} mode={ShowIfMode.HIDE_BY_MAKING_INVISIBLE}>
        <BorderBox variant="generic">
          <ThemableBox type={ThemableBoxType.OPENED_LIST_VIEW_FILTER_CONTAINER}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="h5">Filters</Typography>
              </Grid>
              <Grid item xs="auto">
                <IconButton onClick={toggleDrawer}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Stack spacing={2}>
              {title && <Typography>{title}</Typography>}
              <Stack spacing={1}>{children}</Stack>
              <Button
                variant="delete"
                onClick={() => {
                  handleClearFilters();
                  setIsOpen(false);
                }}
                fullWidth
              >
                Clear all Filters
              </Button>
            </Stack>
          </ThemableBox>
        </BorderBox>
      </ShowIf>

      <ShowIf condition={!isOpen} mode={ShowIfMode.HIDE_BY_MAKING_INVISIBLE}>
        <Button
          variant="contained"
          disableElevation
          onClick={toggleDrawer}
          endIcon={<KeyboardArrowRightIcon />}
        >
          {getButtonLabel(totalFilterCount, activeFilterCount)}
        </Button>
      </ShowIf>
    </>
  );
};
