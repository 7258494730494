import {useEffect, useMemo, useState} from 'react';
import {debounce} from 'lodash';
import {PlacementScheduleGroupBy, PlacementScheduleGroupParams, usePlacementSchedulesService} from 'src/services';
import {DateRange, PlacementScheduleGroup} from 'src/types';
import {useAppContext} from 'src/common';
import {DEBOUNCE_TIMEOUT_MILLISECONDS_LOW} from 'src/lib';
import {BookedBy, Filters} from '../components';
import {getAcademicBookingStatusFromRoomBookingStatus} from '../lib';

export type PlacementScheduleGroups = {
  placementScheduleGroups: PlacementScheduleGroup[];
  isPlacementScheduleGroupsLoading: boolean;
};

export type UsePlacementSchedulesGroupedProps = {
  dateRange: DateRange;
  filters: Filters;
  pageNumber: number;
  pageSize: number;
  onPaginatedDetailsChanged: (
    totalPages: number,
    totalElements: number
  ) => void;
  groupBy: PlacementScheduleGroupBy;
  isApproverViewEnabled: boolean;
  isLoadingEnabled?: boolean;
  debounceDelayMilliseconds?: number;
};

export function usePlacementSchedulesGrouped(
  params: UsePlacementSchedulesGroupedProps
): PlacementScheduleGroups {
  const {
    dateRange,
    filters,
    pageNumber,
    pageSize,
    onPaginatedDetailsChanged,
    groupBy,
    isApproverViewEnabled,
    isLoadingEnabled,
    debounceDelayMilliseconds
  } = params;
  const { handleError } = useAppContext();
  const { getPlacementSchedulesGrouped } = usePlacementSchedulesService();

  const [placementScheduleGroups, setPlacementScheduleGroups] = useState<
    PlacementScheduleGroup[]
  >([]);
  const [
    isPlacementScheduleGroupsLoading,
    setIsPlacementScheduleGroupsLoading
  ] = useState<boolean>(true);

  const fetchPlacementScheduleGroupsDebounced = useMemo(
    () =>
      debounce(
        (
          dateRange: DateRange,
          filters: Filters,
          pageNumber: number,
          pageSize: number,
          groupBy: PlacementScheduleGroupBy,
          abortController: AbortController
        ) => {
          const {
            moduleSchedule,
            themeSchedule,
            facility,
            trainingArea,
            activity,
            activityType,
            student,
            studentGroup,
            status,
            approver,
            bookedBy,
            searchText,
          } = filters;

          const placementScheduleGroupParams: PlacementScheduleGroupParams = {
            facilityId: facility?.id,
            trainingAreaId: trainingArea?.id,
            startDateTime: dateRange.fromDate,
            endDateTime: dateRange.toDate,
            approverId: approver?.id,
            bookingStatus:
              status === undefined
                ? undefined
                : getAcademicBookingStatusFromRoomBookingStatus(status),
            groupBy: groupBy,
            bookedByUser: bookedBy === BookedBy.ME,
            search: searchText
          };

          if (!isApproverViewEnabled && bookedBy !== BookedBy.EVERYONE_ALL) {
            placementScheduleGroupParams.activityId = activity?.id;
            placementScheduleGroupParams.activityTypeId = activityType?.id;
            placementScheduleGroupParams.studentId = student?.id;
            placementScheduleGroupParams.studentGroupId = studentGroup?.id;
            placementScheduleGroupParams.moduleScheduleId = moduleSchedule?.id;
            placementScheduleGroupParams.themeScheduleId = themeSchedule?.id;
          }

          getPlacementSchedulesGrouped({
            pageNumber,
            pageSize,
            filters: placementScheduleGroupParams
          })
            .then((placementScheduleGroupsNew) => {
              const { items, totalPages, totalCount } =
                placementScheduleGroupsNew;
              onPaginatedDetailsChanged(totalPages, totalCount);
              setPlacementScheduleGroups(items);
            })
            .catch(handleError)
            .finally(() => {
              if (!abortController.signal.aborted) {
                setIsPlacementScheduleGroupsLoading(false);
              }
            });
        },
        debounceDelayMilliseconds ?? DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
        { trailing: true, leading: false }
      ),
    [
      isApproverViewEnabled,
      getPlacementSchedulesGrouped,
      onPaginatedDetailsChanged,
      debounceDelayMilliseconds,
      handleError
    ]
  );

  useEffect(() => {
    if (isLoadingEnabled === undefined || isLoadingEnabled) {
      setIsPlacementScheduleGroupsLoading(true);
      setPlacementScheduleGroups([]);
      const abortController = new AbortController();
      fetchPlacementScheduleGroupsDebounced(
          dateRange,
          filters,
          pageNumber,
          pageSize,
          groupBy,
          abortController
      );

      return () => {
        abortController.abort();
      };
    }
  }, [
    fetchPlacementScheduleGroupsDebounced,
    dateRange,
    filters,
    pageNumber,
    pageSize,
    groupBy,
    isLoadingEnabled
  ]);

  return {
    placementScheduleGroups: placementScheduleGroups,
    isPlacementScheduleGroupsLoading: isPlacementScheduleGroupsLoading
  };
}
