import React from 'react';
import { InputLabelProps } from '@mui/material/InputLabel/InputLabel';
import { useTableContext } from './TableContextProvider';
import {
  SelectInputField,
  SelectInputOption
} from 'src/common/select-input-field/SelectInputField';
import { TextInputField } from 'src/common/text-input-field/TextInputField';
import moment from 'moment/moment';
import {
  getDtoDateStringFromDate,
  isValidDate as checkIsValidDate,
  DATE_TIME_FORMAT
} from 'src/lib';

export type TableFilterSelectComponentProps = {
  component: typeof SelectInputField;
  label: string;
  options: SelectInputOption[];
};

export type TableFilterInputComponentProps = {
  component: typeof TextInputField;
  label: string;
  type?: string;
  InputLabelProps?: InputLabelProps;
};

export type TableFilterProps = {
  filterName: string;
} & (TableFilterSelectComponentProps | TableFilterInputComponentProps);

export const TableFilter = (props: TableFilterProps) => {
  const { filters, setFilter } = useTableContext();

  const Component = props.component;

  const getComponentProps = (tableFilterProps: TableFilterProps) => {
    const { filterName, component, ...componentProps } = tableFilterProps;
    const isSelectFilterComponent = 'options' in componentProps;

    if (isSelectFilterComponent) {
      const selectAllFilterOption: SelectInputOption = {
        label: 'Clear Filter',
        key: ''
      };

      const options: SelectInputOption[] = [
        selectAllFilterOption,
        ...componentProps.options
      ];

      return {
        ...componentProps,
        id: filterName,
        value: filters[String(filterName)] || '',
        onChange: (event: { target: { value: string | undefined } }) => {
          setFilter(filterName, event.target.value);
        },
        options: options
      };
    } else {
      const isDateInputComponent = componentProps.type === 'date';
      const filterValue = filters[String(filterName)] || '';

      return {
        ...componentProps,
        id: filterName,
        value: isDateInputComponent
          ? moment(filterValue).format(DATE_TIME_FORMAT.FORMIK_DATE_FIELD_FORMAT)
          : filterValue,
        onChange: (event: { target: { value: string | undefined } }) => {
          const rawValueNew = event.target.value;

          if (isDateInputComponent && rawValueNew === '') {
            setFilter(filterName, '');
            return;
          }

          if (isDateInputComponent) {
            const date = moment(rawValueNew, DATE_TIME_FORMAT.FORMIK_DATE_FIELD_FORMAT).toDate();
            const isValidDate = checkIsValidDate(date);
            const filterValueNew = isValidDate
              ? getDtoDateStringFromDate(date, true)
              : '';
            isValidDate && setFilter(filterName, filterValueNew);
            return;
          }

          setFilter(filterName, rawValueNew);
        },
        options: []
      };
    }
  };

  return <Component {...getComponentProps(props)} />;
};
