import { QueryParamProvider } from 'use-query-params';
import React, { ReactNode } from 'react';
import { HashRouter as Router } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { RouteAdaptor } from 'src/modules/application/components/RouteAdaptor';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { stylesOverride } from 'src/styles-override';
import {
  ErrorBoundary,
  UserContextProvider,
  SessionStorageCacheStoreContextProvider,
  BackdropContextProvider,
  AppContextProvider,
  BreadcrumbsContextProvider,
  useCriticalErrorHandling
} from 'src/common';
import { LoadingContextProvider } from 'src/common/loading-indicator/LoadingContextProvider';
import CriticalErrorScreenModule from 'src/modules/critical-error-screen';
import { useRolesService, useUsersService } from 'src/services';

export type ApplicationEnvironmentProps = {
  children: ReactNode;
};

export const ApplicationEnvironment = ({
  children
}: ApplicationEnvironmentProps) => {
  const { getCurrentUserRoles } = useRolesService();
  const { getCurrentUser } = useUsersService();

  const {
    criticalError,
    handleCriticalError,
    clearCriticalError,
    handleUserLoggedIn
  } = useCriticalErrorHandling();

  if (criticalError) {
    return (
      <ThemeProvider theme={createTheme(stylesOverride)}>
        <CssBaseline />
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          autoHideDuration={3000}
        >
          <Router>
            <CriticalErrorScreenModule
              criticalError={criticalError}
              clearCriticalError={clearCriticalError}
            />
          </Router>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }

  return (
    <ErrorBoundary handleError={handleCriticalError}>
      <LoadingContextProvider>
        <AppContextProvider handleError={handleCriticalError}>

            <ThemeProvider theme={createTheme(stylesOverride)}>
              <SessionStorageCacheStoreContextProvider>
                <UserContextProvider
                  onUserLoggedIn={handleUserLoggedIn}
                  getCurrentUserRoles={getCurrentUserRoles}
                  getCurrentUser={getCurrentUser}
                >
                  <BackdropContextProvider>
                    <SnackbarProvider
                      maxSnack={3}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                      }}
                      autoHideDuration={3000}
                    >
                      <ConfirmProvider>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <Router>
                            <QueryParamProvider ReactRouterRoute={RouteAdaptor}>
                              <BreadcrumbsContextProvider>
                                {children}
                              </BreadcrumbsContextProvider>
                            </QueryParamProvider>
                          </Router>
                        </LocalizationProvider>
                      </ConfirmProvider>
                    </SnackbarProvider>
                  </BackdropContextProvider>
                </UserContextProvider>
              </SessionStorageCacheStoreContextProvider>
            </ThemeProvider>
        </AppContextProvider>
      </LoadingContextProvider>
    </ErrorBoundary>
  );
};
