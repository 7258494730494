import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  StudentDTO
} from 'src/types';
import { useCallback } from 'react';
import { Student } from 'src/types/models/Student.type';
import { useAxiosInstance } from 'src/lib';
import { getStudentFromDto } from './dto-mappers';

export interface IStudentsService {
  getStudent: (
    studentId: number,
    abortController?: AbortController
  ) => Promise<Student>;
  getStudents: GetAPI<Student>;
  getStudentsByModuleScheduleId: GetAPI<Student>;
}

export const useStudentService = (): IStudentsService => {
  const studentsApi = useAxiosInstance('/v2/students');

  const getStudents = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Student>> => {
      return studentsApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request?.abortController?.signal
        })
        .then((response) => {
          const { page, _embedded } = response.data;
          const students: StudentDTO[] = _embedded?.studentDToes
            ? (_embedded.studentDToes as StudentDTO[])
            : [];

          const { number, size, totalElements, totalPages } = page;
          return {
            items: students.map<Student>(getStudentFromDto),
            hasNextPage: number < size,
            hasPrevPage: number > 0,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Student>;
        });
    },
    [studentsApi]
  );

  const getStudent = useCallback(
    (
      studentId: number,
      abortController?: AbortController
    ): Promise<Student> => {
      return studentsApi
        .get(`${studentId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const studentDto = response.data as StudentDTO;
          return getStudentFromDto(studentDto);
        });
    },
    [studentsApi]
  );

  const getStudentsByModuleScheduleId = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Student>> => {
      return studentsApi
        .get('/enrolledForModuleSchedule', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<StudentDTO>;

          return {
            items: content.map<Student>(getStudentFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Student>;
        });
    },
    [studentsApi]
  );

  return { getStudents, getStudent, getStudentsByModuleScheduleId };
};
