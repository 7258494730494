import React from 'react';
import { Box, Grid, Stack } from '@mui/material';

export type FormFooterLayoutProps = {
  leftChildren?: JSX.Element;
  rightChildren?: JSX.Element;
};

export const FormFooterLayout = (props: FormFooterLayoutProps) => {
  return (
    <Stack
      direction="row"
      display="flex"
      spacing={2}
      justifyContent="space-between"
      marginTop="0px"
    >
      <Box flexGrow={0.2} justifyContent="left">
        <Grid
          container
          justifyContent="flex-start"
          spacing={2}
          display="flex"
          flexDirection="row"
          alignItems="flex-start"
        >
          <Grid item>{props.leftChildren && props.leftChildren}</Grid>
        </Grid>
      </Box>

      <Box flexGrow={0.75} justifyContent="right">
        <Grid
          container
          justifyContent="flex-end"
          spacing={2}
          display="flex"
          flexDirection="row"
          alignItems="flex-end"
        >
          <Grid item>{props.rightChildren && props.rightChildren}</Grid>
        </Grid>
      </Box>
    </Stack>
  );
};
