import { Box, Table as MuiTable, TableContainer } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { TableType } from '../Table';
import {
  TableBody,
  useTableContext,
  TableFooter,
  TableHeader,
  RefreshStatus
} from '../components';
import {
  FetchRows,
  PaginatedRequest,
  TableHeaderData,
  TableRowData
} from 'src/types';
import { useAppContext } from '../../app-context-provider/AppContext';
import { useLoadingContext } from 'src/common/loading-indicator/LoadingContextProvider';

export type TablePaginatedProps = {
  type: TableType.PAGINATED;
  headers: { id: string; label: TableHeaderData }[];
  fetchRows: FetchRows<TableRowData>;
  search?: string;
  filters?: Record<string, string>;
  fetchCondition?: boolean;
  rowClass?: (tableRowData: TableRowData) => string;
  onRowClicked?: (row: TableRowData) => void;
};

export const TablePaginatedContainer = ({
  headers,
  fetchRows,
  fetchCondition = true,
  rowClass,
  onRowClicked
}: TablePaginatedProps) => {
  const { handleError } = useAppContext();
  const [currentRows, setCurrentRows] = useState<TableRowData[] | undefined>(
    undefined
  );
  const [totalRows, setTotalRows] = useState<number>(0);
  const tableContext = useTableContext();
  const {
    order,
    orderBy,
    handlePageChange,
    handlePageSizeChange,
    handleRequestSort,
    page,
    pageSize,
    filters,
    search,
    disableSort,
    disableSortColumns,
    refreshStatus,
    setRefreshStatus
  } = tableContext;
  const { setIsLoading } = useLoadingContext();

  const fetchTableDataDebounced = useMemo(() => {
    setIsLoading(true);
    return debounce(
      (queryParams: PaginatedRequest) => {
        setIsLoading(true);
        fetchRows(queryParams)
          .then((result) => {
            const { totalCount, items } = result;
            setTotalRows(totalCount);
            setCurrentRows([...items]);
            setRefreshStatus(RefreshStatus.REFRESHED);
          })
          .catch(handleError)
          .finally(() => setIsLoading(false));
      },
      1000,
      { trailing: true, leading: false }
    );
  }, [fetchRows, handleError, setIsLoading, setRefreshStatus]);

  useEffect(() => {
    if (fetchCondition || refreshStatus === RefreshStatus.REFRESHING) {
      const abortController = new AbortController();
      setTotalRows(0);
      setCurrentRows(undefined);
      fetchTableDataDebounced({
        pageNumber: page,
        pageSize,
        order: order,
        orderBy,
        search,
        filters,
        abortController
      });
      return () => abortController.abort();
    }
  }, [page, pageSize, order, orderBy, search, filters, fetchTableDataDebounced, fetchCondition, refreshStatus]);

  return (
    <Box mt={2}>
      <TableContainer>
        <MuiTable>
          <TableHeader
            headers={headers}
            order={order}
            orderBy={orderBy}
            handleSort={handleRequestSort}
            disableSort={disableSort}
            disableSortColumns={disableSortColumns}
          />
          <TableBody
            rows={currentRows}
            headers={headers}
            rowClass={rowClass}
            onRowClicked={onRowClicked}
          />
        </MuiTable>
      </TableContainer>

      <TableFooter
        rowCount={totalRows}
        pageSize={pageSize}
        page={page}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Box>
  );
};
