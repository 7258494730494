import React, { ErrorInfo } from 'react';

export type ErrorBoundaryProps = Record<string, unknown> & {
  handleError: (error: Error, errorInfo?: ErrorInfo) => void;
};

//This particular component is intentionally left as a class-based component due to restrictions in React 16
export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.handleError(error, errorInfo);
  }
  render() {
    return this.props.children;
  }
}
