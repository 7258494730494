import React from 'react';
import { FC } from 'react';
import { CriticalError } from 'src/types';
import { CriticalErrorScreenContainer } from './containers';

export type CriticalErrorScreenModuleProps = {
  criticalError: CriticalError;
  clearCriticalError: () => void;
};

export const CriticalErrorScreenModule: FC<CriticalErrorScreenModuleProps> = ({
  criticalError,
  clearCriticalError
}) => {
  return (
    <CriticalErrorScreenContainer
      criticalError={criticalError}
      clearCriticalError={clearCriticalError}
    />
  );
};

export default CriticalErrorScreenModule;
