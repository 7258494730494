import { ThemeScheduleDTO } from '../dto';
import { Tag } from './Tag.type';

export type ThemeSchedule = {
  tags?: Tag[];
} & Omit<ThemeScheduleDTO, ''>;

export enum THemeScheduleStatus {
  ACTIVE,
  INACTIVE
}
