import { FileDetails, Tag } from 'src/types';
import { FileTable } from './FileTable';
import React, { useState } from 'react';
import { useFilesService, useUploadFileService } from 'src/services';
import { FileTagsCreateEdit, ImagePreviewModal, TagFile, useResponseHandler } from 'src/common';
import { downloadFile } from 'src/lib';

type Props<T> = {
  files: FileDetails[];
  formValues: T;
  setValues: (values: T) => void;
  tags: Tag[]

};

const ENTITY_NAME = 'File';

export function FileTableUpload<T>({
  files,
  formValues: values,
  setValues,
  tags
}: Props<T>) {
  const { uploadFile } = useUploadFileService();
  const { downloadFileBlob, getFilePreviewImageSource } = useFilesService();
  const { handleServiceCall } = useResponseHandler();
  const handleFileDownload = (fileName: string, fileId: number): void => {
    handleServiceCall({
      failureProps: { title: 'Failed to download the file' },
      successProps: { description: '', title: 'The file has been downloaded' },
      serviceCall: () =>
        downloadFileBlob(fileId).then((fileBlob) => {
          fileBlob && downloadFile(fileBlob, fileName);
        })
    });
  };
  const handleFilePreview = (_: string, fileId: number) => {
    setFilePreviewImageSource(getFilePreviewImageSource(fileId));
  };
  const handleFileDelete = (fileIndex: number) => {
    handleServiceCall({
      failureProps: { title: `Failed to delete ${ENTITY_NAME}` },
      successProps: {
        description: '',
        title: `${ENTITY_NAME} has been deleted`
      },
      serviceCall: () => {
        const filesNew: FileDetails[] = [...(files || [])];
        filesNew.splice(fileIndex, 1);
        setValues({
          ...values,
          files: filesNew
        });
        return Promise.resolve();
      },
      confirmProps: {
        title: `Delete ${ENTITY_NAME}`,
        description: `Are you sure you want to delete this ${ENTITY_NAME}?`,
        confirmButtonLabel: 'Delete',
        cancelButtonLabel: 'Cancel'
      }
    });
  };
  const [filePreviewImageSource, setFilePreviewImageSource] = useState<
    string | undefined
  >();
  const [tagFile, setTagFile] = useState<TagFile | undefined>();

  return (
    <>
      <FileTable
        files={files || []}
        onDownloadFile={(fileIndex: number) => {
          const file = (files || []).at(fileIndex);
          file && handleFileDownload(file.name, file.id);
        }}
        onDeleteFile={(fileIndex: number) => {
          const file = (files || []).at(fileIndex);
          file && handleFileDelete(fileIndex);
        }}
        onPreviewFile={(fileIndex: number) => {
          const file = (files || []).at(fileIndex);
          file && handleFilePreview(file.name, file.id);
        }}
        onEditFileTags={(index) => {
          const fileDetails = (files || []).at(index);
          if (!fileDetails || !setTagFile) {
            return undefined;
          }

          setTagFile({ fileDetails, index });
        }}
        onFileUpload={(fileData: Blob, fileName: string) => {
          return uploadFile(fileData, fileName).then((file) => {
            const filesNew: FileDetails[] = [...(files || []), file];
            setValues({
              ...values,
              files: filesNew
            });
          });
        }}
      />
      <ImagePreviewModal
        imageSourceUrl={filePreviewImageSource}
        onClose={() => setFilePreviewImageSource(undefined)}
      />
      {tagFile && (
        <FileTagsCreateEdit
          file={tagFile.fileDetails}
          fileIndex={tagFile.index}
          files={files}
          formValues={values}
          setFileTags={setTagFile}
          setValues={setValues}
          tagOptions={tags}
        />
      )}
    </>
  );
}
