import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Tooltip
} from '@mui/material';
import React from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';

export type SelectActionOption = {
  label: string;
  key: string | number;
  onClick: () => void;
  isDisabled?: boolean;
  tooltip?: string;
  isHidden?: boolean;
};

export interface ListViewTableActionsProps {
  options: SelectActionOption[];
  label?: string;
  styleVariant?: 'outlined' | 'contained' | undefined;
}

export const ListViewTableActions = (
  props: ListViewTableActionsProps
): JSX.Element => {
  const { options, label, styleVariant } = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  const handleToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setOpen(!open);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    event.stopPropagation();
    setOpen(false);
  };
  return (
    <>
      <ButtonGroup variant={styleVariant ?? 'contained'} ref={anchorRef}>
        <Button
          size="small"
          onClick={handleToggle}
          endIcon={<ArrowDropDownIcon />}
        >
          {label ?? 'Actions'}
        </Button>
      </ButtonGroup>
      <Popper
        sx={{
          zIndex: 2
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ThemableBox type={ThemableBoxType.LIST_VIEW_ACTIONS_DROP_DOWN}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options
                      .filter((item) => !item.isHidden)
                      .map((item, index) => (
                        <Tooltip title={item.tooltip || ''}>
                          <Box>
                            <MenuItem
                              key={`${item.key}${index}`}
                              value={item.key}
                              onClick={(event) => {
                                event.stopPropagation();
                                item.onClick();
                              }}
                              disabled={!!item.isDisabled}
                            >
                              {item.label}
                            </MenuItem>
                          </Box>
                        </Tooltip>
                      ))}
                  </MenuList>
                </ClickAwayListener>
              </ThemableBox>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};
