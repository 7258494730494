import { TextFieldProps } from '@mui/material/TextField/TextField';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { ShowIf } from '../show-if/ShowIf';
import React, { ChangeEvent } from 'react';
import { Clear } from '@mui/icons-material';
import { isTextFieldPopulated } from './is-textfield-populated';

export type TextFieldV2Props = {
  /**
   * If set to <code>true</code>, shows button to clear value of TextField
   * Optional: defaults to <code>false</code>
   * */
  isClearable?: boolean;
} & TextFieldProps;

export const TextFieldV2 = ({ isClearable, ...props }: TextFieldV2Props) => {
  return (
    <TextField
      {...props}
      InputProps={{
        ...props.InputProps,
        endAdornment: props.InputProps?.endAdornment ?? (
          <ShowIf condition={!!isClearable}>
            <InputAdornment position="end">
              <InputAdornment position="end">
                <ShowIf condition={!(props.value === '' || !props.value)}>
                  <IconButton
                    disabled={props.value === '' || !props.value}
                    onClick={() => {
                      const event = {
                        target: {
                          value: ''
                        }
                      };
                      props &&
                        props.onChange &&
                        props.onChange(event as ChangeEvent<HTMLInputElement>);
                    }}
                  >
                    <Clear />
                  </IconButton>
                </ShowIf>
              </InputAdornment>
            </InputAdornment>
          </ShowIf>
        )
      }}
      placeholder={props.placeholder}
      InputLabelProps={{
        ...props.InputLabelProps,
        shrink:
          props.InputLabelProps?.shrink ??
          (isTextFieldPopulated(props.value, props.inputProps?.value) ||
            !!props.placeholder)
      }}
    />
  );
};
