import { FC, ReactNode, useMemo } from 'react';
import { AuthorizedUser } from '../../common';
import {
  FormButtonControl,
  FormButtonControlConfig
} from '../form-button-control/FormButtonControl';
import { FormButtonLayout } from '../form-button-layout/FormButtonLayout';

export type FormButtonGroupProps = {
  children: () => FormButtonControlConfig[] | undefined;
  layoutComponent?: FC<{ children?: ReactNode | undefined }>;
  authorizedUser?: AuthorizedUser;
};

const isUserPermittedToClickOnButton =
  (authorizedUser?: AuthorizedUser) =>
  (buttonConfig: FormButtonControlConfig): boolean => {
    return (
      !authorizedUser ||
      !buttonConfig.requiredPermissions ||
      !buttonConfig.requiredPermissions.every((id) =>
        authorizedUser.isForbiddenTo(id)
      )
    );
  };

export const FormButtonGroup = ({
  children: formButtonConfigs,
  layoutComponent,
  authorizedUser
}: FormButtonGroupProps) => {
  const LayoutComponent = layoutComponent ? layoutComponent : FormButtonLayout;

  return useMemo(
    () => (
      <LayoutComponent>
        {(formButtonConfigs() ?? [])
          .filter(isUserPermittedToClickOnButton(authorizedUser))
          .map((formButtonControlConfig, index) => (
            <FormButtonControl
              buttonConfig={formButtonControlConfig}
              key={index}
            />
          ))}
      </LayoutComponent>
    ),
    [LayoutComponent, formButtonConfigs, authorizedUser]
  );
};
