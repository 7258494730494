import * as React from 'react';
import { FormikContextType, FormikState, getIn } from 'formik';

export type FieldProps = {
  name: string | number | symbol;
  error?: boolean;
  helperText?: React.ReactNode;
};

export function getFieldErrorProps<
  T extends { [key: string]: number | boolean | string | object }
>(
  fieldName: keyof T,
  formikHelpers: FormikState<T> | FormikContextType<unknown>,
  subFieldPath?: string
): FieldProps {
  const { touched, errors, isSubmitting } = formikHelpers;
  const showError = isSubmitting || getIn(touched, String(fieldName));

  const fieldNameWithSubFieldPath = `${String(fieldName)}${subFieldPath || ''}`;

  return {
    name: fieldNameWithSubFieldPath,
    error: showError && Boolean(getIn(errors, fieldNameWithSubFieldPath)),
    helperText: showError && getIn(errors, fieldNameWithSubFieldPath)
  };
}
