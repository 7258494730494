import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { useThemePlacementsService } from 'src/services';
import {
  DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
  getAllFromPaginatedApiV2,
  getDtoDateStringFromDate
} from 'src/lib';
import { DateRange, ModuleSchedule, ThemePlacement } from 'src/types';
import { useAppContext } from 'src/common';

export type FilteredThemePlacements = {
  themePlacements: ThemePlacement[];
  isThemePlacementsLoading: boolean;
};

export function useFilteredThemePlacements(
  dateRange: DateRange,
  moduleSchedule?: ModuleSchedule,
  debounceDelayMilliseconds = DEBOUNCE_TIMEOUT_MILLISECONDS_LOW
): FilteredThemePlacements {
  const { handleError } = useAppContext();
  const { getThemePlacements } = useThemePlacementsService();
  const [themePlacements, setThemePlacements] = useState<ThemePlacement[]>([]);
  const [isThemePlacementsLoading, setIsThemePlacementsLoading] = useState<
    boolean
  >(false);

  const fetchThemePlacementsDebounced = useMemo(
    () =>
      debounce(
        (
          moduleSchedule: ModuleSchedule,
          dateRange: DateRange,
          abortController: AbortController
        ) => {
          const requestFilters: Record<string, string> = {
            moduleScheduleId: moduleSchedule?.id?.toString() || ''
          };

          requestFilters.startDateTime = getDtoDateStringFromDate(
            dateRange.fromDate
          );
          requestFilters.endDateTime = getDtoDateStringFromDate(
            dateRange.toDate
          );

          getAllFromPaginatedApiV2({
            getApi: getThemePlacements,
            filters: requestFilters,
            abortController: abortController
          })
            .then(themePlacementsNew => {
              setThemePlacements(themePlacementsNew);
            })
            .catch(handleError)
            .finally(() => {
              if (!abortController.signal.aborted) {
                setIsThemePlacementsLoading(false);
              }
            });
        },
        debounceDelayMilliseconds,
        { trailing: true, leading: false }
      ),
    [getThemePlacements, debounceDelayMilliseconds, handleError]
  );

  useEffect(() => {
    if (moduleSchedule) {
      const abortController = new AbortController();

      setIsThemePlacementsLoading(true);

      fetchThemePlacementsDebounced(moduleSchedule, dateRange, abortController);

      return () => {
        abortController.abort();
      };
    } else {
      setThemePlacements([]);
    }
  }, [fetchThemePlacementsDebounced, moduleSchedule, dateRange]);

  return { themePlacements, isThemePlacementsLoading };
}
