import {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { transform, isObject } from 'lodash';

const getParamsOnlyTruthyAndValidValues = (params: object) => {
  if (typeof params === 'object' && !!params) {
    return Object.entries(params).reduce((result, [key, value]) => {
      if (value !== undefined && key !== 'abortController') {
        return {
          ...result,
          [key]: value
        };
      }
      return result;
    }, {});
  } else {
    return params;
  }
};

const getSortParameter = (
  order?: string,
  orderBy?: string
): string | undefined => {
  if (orderBy) {
    const prefix = order === 'desc' ? '-' : '';
    return `${prefix}${orderBy}`;
  }
};

/** Converts all null values in a response to undefined */
function getResponseDataWithNullReplacedWithUndefined(
  responseData: Record<string, unknown>
): Record<string, unknown> {
  return transform(responseData, (result, value, key) => {
    if (isObject(value) && !Array.isArray(value)) {
      result[String(key)] = getResponseDataWithNullReplacedWithUndefined(
        value as Record<string, unknown>
      );
    } else if (Array.isArray(value)) {
      result[String(key)] = value.map((arrayItem) => // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        isObject(arrayItem)
          ? getResponseDataWithNullReplacedWithUndefined(
              arrayItem as Record<string, unknown>
            )
          : arrayItem === null
          ? undefined
          : arrayItem
      );
    } else {
      result[String(key)] = value === null ? undefined : value;
    }
  });
}

const successResponseHandler = (response: AxiosResponse) => {
  const isBlobResponse = response.data instanceof Blob;

  if (isBlobResponse) {
    return response;
  }

  const responseData = response.data as Record<string, unknown>;

  return {
    ...response,
    data: getResponseDataWithNullReplacedWithUndefined(responseData)
  };
};

const errorResponseHandler =
  (singleSignOnUrl?: string, apiUrl?: string) => (error: AxiosError) => {
    // if the backend returns a 401 (Unauthorized) response, redirect to the SSO page
    if (
      singleSignOnUrl &&
      apiUrl &&
      error?.response &&
      401 === error.response.status
    ) {
      window.location.href = `${singleSignOnUrl}/cas/login?service=${encodeURIComponent(
        `${apiUrl}/login/cas`
      )}${window.location.hash}`;
    } else {
      return Promise.reject(error);
    }
  };

export const requestHandler = (config: AxiosRequestConfig) => {
  return {
    ...config,
    withCredentials: true, // eslint-disable-next-line @typescript-eslint/no-explicit-any
    paramsSerializer: (params: any) => {
      if (typeof params === 'object') {
        const { filters, ids, order, orderBy, ...rest } = params;
        const sort = getSortParameter(order as string, orderBy as string);
        const restClean = getParamsOnlyTruthyAndValidValues({
          ...(rest as object),
          sort
        });
        const idsQueryParam: string = ids
          ? `id=${(ids as string[]).join(',')}`
          : '';

        return (
          new URLSearchParams({
            ...restClean,
            ...(filters as Record<string, string>)
          }).toString() + `&${idsQueryParam}`
        );
      }
    }
  };
};

export function setupInterceptorsTo(
  axiosInstance: AxiosInstance,
  ssoUrl?: string,
  apiUrl?: string
): AxiosInstance {
  axiosInstance.interceptors.request.use(requestHandler);
  axiosInstance.interceptors.response.use(
    successResponseHandler,
    errorResponseHandler(ssoUrl, apiUrl)
  );
  return axiosInstance;
}
