import {
  Button,
  Stack,
  RadioGroup,
  Radio,
  FormControlLabel,
  Grid
} from '@mui/material';
import { RoomBookingType } from 'src/types';
import { useState } from 'react';
import { EditSeries } from 'src/types/enums/EditSeries.enum';
import { DATE_TIME_FORMAT, getDisplayDateStringFromDate } from 'src/lib';

export type BookingSelectProps = {
  startDateTime: Date;
  type: RoomBookingType;
  handleClose?: () => void;
  handleModalSubmit: (series: EditSeries) => void;
};

export function IsEditBookingAsSeries(bookingSelectProps: BookingSelectProps) {
  const [editAsSeries, setEditAsSeries] = useState<EditSeries>(
    EditSeries.SINGLE
  );

  const updateEditAsSeries = (isEditSeries: EditSeries) => {
    setEditAsSeries(isEditSeries);
  };

  return (
    <Stack padding={4}>
      <RadioGroup
        name="editSeries"
        value={editAsSeries}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          updateEditAsSeries(e.target.value as unknown as EditSeries)
        }
      >
        <FormControlLabel
          value={EditSeries.SINGLE}
          label="Edit only this booking"
          control={<Radio value={EditSeries.SINGLE} />}
        />
        <FormControlLabel
          value={EditSeries.SERIES}
          label={`Edit bookings in the series from
            ${getDisplayDateStringFromDate(
              bookingSelectProps.startDateTime,
              DATE_TIME_FORMAT.DATE_AND_TIME
            )} onwards`}
          control={<Radio value={EditSeries.SERIES} />}
        />
      </RadioGroup>
      <Grid
        container
        spacing={2}
        display="flex"
        justifyContent="right"
        alignItems="center"
      >
        {bookingSelectProps.handleClose && (
          <Grid item xs={3} lg={2}>
            <Button variant="outlined" onClick={bookingSelectProps.handleClose}>
              Cancel
            </Button>
          </Grid>
        )}

        <Grid item xs={3} lg={2}>
          <Button
            variant="contained"
            onClick={() => bookingSelectProps.handleModalSubmit(editAsSeries)}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </Stack>
  );
}
