import { Box } from '@mui/material';

export enum ShowIfMode {
  /* if condition is false, children are not rendered */
  HIDE_BY_REMOVING_FROM_DOM,

  /* if condition is false, children are rendered with display=none */
  HIDE_BY_MAKING_INVISIBLE
}

export type ShowIfProps = {
  condition: boolean;
  /** @param ShowIfMode Optional. Defaults to <code>ShowIfMode.HIDE_BY_REMOVING_FROM_DOM</code> */
  mode?: ShowIfMode;
};

export const ShowIf: React.FC<ShowIfProps> = ({
  condition,
  mode = ShowIfMode.HIDE_BY_REMOVING_FROM_DOM,
  children
}) => {
  switch (mode) {
    case ShowIfMode.HIDE_BY_MAKING_INVISIBLE:
      return (
        <Box component="span" sx={condition ? {} : { display: 'none' }}>
          <>{children}</>
        </Box>
      );

    default:
      return condition ? <>{children}</> : <></>;
  }
};
