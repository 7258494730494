import { useCallback } from 'react';
import {
  ActivityTypeDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';
import { ActivityType } from 'src/types/models';
import {
  getDtoFromWeekdays,
  getWeekdaysFromDto
} from 'src/services/dto-mappers';

export interface IActivitiyTypeService {
  getActivityTypes: GetAPI<ActivityType>;
  getActivityType: (
    activityTypId: number,
    abortController?: AbortController
  ) => Promise<ActivityType>;
  createActivityType: (
    activityType: ActivityType,
    abortController?: AbortController
  ) => Promise<void>;
  updateActivityType: (
    activityType: ActivityType,
    abortController?: AbortController
  ) => Promise<void>;
  deleteActivityType: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useActivityTypesService = (): IActivitiyTypeService => {
  const activityTypeApi = useAxiosInstance('/v2/activityTypes');

  const getActivityTypes = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<ActivityType>> => {
      return activityTypeApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<ActivityTypeDTO>;

          return {
            items: content.map(getActivityTypeFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ActivityType>;
        });
    },
    [activityTypeApi]
  );

  const getActivityType = useCallback(
    (activityTypeId: number, abortController?: AbortController) => {
      return activityTypeApi
        .get(`${activityTypeId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then(response => {
          const activityTypeDto = response.data as ActivityTypeDTO;

          return getActivityTypeFromDto(activityTypeDto);
        });
    },
    [activityTypeApi]
  );

  const deleteActivityType = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      activityTypeApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [activityTypeApi]
  );

  const createActivityType = (
    activityType: ActivityType,
    abortController?: AbortController
  ): Promise<void> =>
    activityTypeApi.post('', getDtoFromActivityType(activityType), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const updateActivityType = (
    activityType: ActivityType,
    abortController?: AbortController
  ): Promise<void> =>
    activityTypeApi.put(
      `/${activityType.id}`,
      getDtoFromActivityType(activityType),
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );

  return {
    getActivityTypes,
    getActivityType,
    deleteActivityType,
    createActivityType,
    updateActivityType
  };

  function getActivityTypeFromDto(
    activityTypeDto: ActivityTypeDTO
  ): ActivityType {
    const { dayOfWeekBitField, startTime, endTime } = activityTypeDto;

    return {
      ...activityTypeDto,
      weekdays: getWeekdaysFromDto(dayOfWeekBitField),
      startTime: getDateFromDtoDateString(startTime),
      endTime: getDateFromDtoDateString(endTime)
    };
  }

  function getDtoFromActivityType(activityType: ActivityType): ActivityTypeDTO {
    const { weekdays, startTime, endTime } = activityType;

    return {
      ...activityType,
      dayOfWeekBitField: getDtoFromWeekdays(weekdays),
      startTime: getDtoDateStringFromDate(startTime),
      endTime: getDtoDateStringFromDate(endTime)
    };
  }
};
