import {PropsWithChildren} from 'react';
import {ThemableBox} from 'src/common/themable-box/ThemableBox';
import {ThemableBoxType} from 'src/types';

export type FormLayoutProps = {
  size?: 'small' | 'large';
};

export const FormLayout = ({
  children,
  size = 'small'
}: PropsWithChildren<FormLayoutProps>) => {
  switch (size) {
    case 'small':
      return (
        <ThemableBox type={ThemableBoxType.FORM_LAYOUT_SMALL}>
          {children}
        </ThemableBox>
      );
    case 'large':
      return (
        <ThemableBox type={ThemableBoxType.FORM_LAYOUT_LARGE}>
          {children}
        </ThemableBox>
      );
  }
};
