import moment from 'moment';
import React, { useMemo } from 'react';
import { MonthPicker, YearPicker } from '@mui/x-date-pickers';
import {
  Button,
  FormControl,
  Modal as MuiModal,
  Stack
} from '@mui/material';
import { Field, Form, Formik, FormikErrors } from 'formik';
import { DateInputField } from 'src/common/date-input-field/DateInputField';
import { Modal } from 'src/common/modal/Modal';
import { getFieldErrorProps, isValidDate } from 'src/lib';
import { DateRange, DateRangeType } from 'src/types';
import { DateRangePicker } from '@mui/lab';

export type DateRangeModalProps = {
  show: boolean;
  onClose: () => void;
  dateRange: DateRange;
  dateRangeType: DateRangeType;
  onSetDateRange: (dateInRange: Date) => void;
};

export type DatePickerModalFormValues = {
  startDate: Date;
  endDate: Date;
};

export const getInitialValues = (
  props: DateRangeModalProps
): DatePickerModalFormValues => {
  const DEFAULT_FORM_VALUES: DatePickerModalFormValues = {
    startDate: props.dateRange.fromDate,
    endDate: props.dateRange.toDate
  };
  return DEFAULT_FORM_VALUES;
};

const getOnSubmit = (
  props: DateRangeModalProps
): ((values: DatePickerModalFormValues) => void) => {
  return (values: DatePickerModalFormValues) => {
    props.onSetDateRange(values.startDate);
    props.onClose();
  };
};

const getValidate =
  (_: DateRangeModalProps) =>
  (
    values: DatePickerModalFormValues
  ): FormikErrors<DatePickerModalFormValues> => {
    const errors: FormikErrors<DatePickerModalFormValues> = {};

    if (!isValidDate(values.startDate)) {
      errors.startDate = 'Valid date required';
    }

    return errors;
  };

const MAX_YEARS = 20;
const MIN_DATE = moment().subtract(MAX_YEARS, 'year').toDate();
const MAX_DATE = moment().add(MAX_YEARS, 'year').toDate();
export const DatePickerModal = (props: DateRangeModalProps) => {
  const { show, onClose, dateRangeType } = props;

  const modalTitle = useMemo(() => {
    switch (dateRangeType) {
      case DateRangeType.YEAR: {
        return 'Choose Year';
      }
      case DateRangeType.MONTH: {
        return 'Choose Month';
      }
      case DateRangeType.WEEK: {
        return 'Choose Week';
      }
      case DateRangeType.DAY: {
        return 'Choose Day';
      }
      default: {
        throw new Error('unknown date range type');
      }
    }
  }, [dateRangeType]);

  return (
    <MuiModal open={show} onClose={onClose}>
      <Modal title={modalTitle}>
        <Formik<DatePickerModalFormValues>
          initialValues={getInitialValues(props)}
          onSubmit={getOnSubmit(props)}
          validate={getValidate(props)}
          enableReinitialize={true}
        >
          {(formikHelpers) => {
            const { setFieldValue, values } = formikHelpers;
            return (
              <Form>
                <Stack padding={2} spacing={2}>
                  {dateRangeType === DateRangeType.WEEK && (
                    <FormControl fullWidth>
                      <Field
                        {...getFieldErrorProps('startDate', formikHelpers)}
                        label=""
                        variant="outlined"
                        as={DateInputField}
                        required
                        fullWidth
                        value={formikHelpers.values.startDate}
                      />
                    </FormControl>
                  )}

                  {dateRangeType === DateRangeType.MONTH && (
                    <MonthPicker<Date>
                      onChange={(value: Date) => {
                        setFieldValue('startDate', value);
                      }}
                      date={
                        isValidDate(values.startDate) ? values.startDate : null
                      }
                    />
                  )}

                  {dateRangeType === DateRangeType.YEAR && (
                    <YearPicker<Date>
                      onChange={(value: Date) => {
                        setFieldValue('startDate', value);
                      }}
                      minDate={MIN_DATE}
                      maxDate={MAX_DATE}
                      date={
                        isValidDate(values.startDate) ? values.startDate : null
                      }
                    />
                  )}

                  {dateRangeType === DateRangeType.DAY && (
                    <DateRangePicker
                      localeText={{
                        start: '',
                        end: ''
                      }}
                    />
                  )}

                  <Stack spacing={1} direction="row" justifyContent="flex-end">
                    <Button onClick={onClose} variant="back">
                      Cancel
                    </Button>

                    <Button type="submit" color="primary" variant="contained">
                      Select
                    </Button>
                  </Stack>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    </MuiModal>
  );
};
