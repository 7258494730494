import { useCallback } from 'react';
import {
  GetAPI,
  getPaginatedResponse,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  Theme,
  ThemeDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IThemesService {
  getThemes: GetAPI<Theme>;
  getAllThemes: GetAPI<Theme>;
  getTheme: (id: number, abortController?: AbortController) => Promise<Theme>;
  updateTheme: (
    theme: Theme,
    abortController?: AbortController
  ) => Promise<void>;
  deleteTheme: (id: number, abortController?: AbortController) => Promise<void>;
  createTheme: (
    theme: Theme,
    abortController?: AbortController
  ) => Promise<void>;
  updateThemesOrder: (themeId: number, order?: number ) => Promise<void>;
}

export const useThemesService = (): IThemesService => {
  const themesApi = useAxiosInstance('/v2/themes');

  const getThemes = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Theme>> => {
      return themesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<Theme>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Theme>;
        });
    },
    [themesApi]
  );

  const getAllThemes = (
    request: PaginatedRequest
  ): Promise<PaginatedResponse<Theme>> =>
    themesApi
      .get('/all', {
        params: request,
        withCredentials: true,
        signal: request?.abortController?.signal
      })
      .then((response) =>
        getPaginatedResponse<ThemeDTO, Theme>(response, getThemeFromDto)
      );

  const getTheme = useCallback(
    (id: number, abortController?: AbortController): Promise<Theme> =>
      themesApi
        .get(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => response.data as Theme),
    [themesApi]
  );

  const deleteTheme = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      themesApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [themesApi]
  );

  const updateTheme = (
    theme: Theme,
    abortController?: AbortController
  ): Promise<void> =>
    themesApi.put(`/${theme.id}`, getDTOFromTheme(theme), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const createTheme = (
    theme: Theme,
    abortController?: AbortController
  ): Promise<void> =>
    themesApi.post('', getDTOFromTheme(theme), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const updateThemesOrder = useCallback(
    (
      themeId: number,
      order?: number,
      abortController?: AbortController
    ): Promise<void> =>
      themesApi.put('themeOrder', {themeId , order}, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [themesApi]
  );

  return {
    getThemes,
    getAllThemes,
    getTheme,
    updateTheme,
    deleteTheme,
    createTheme,
    updateThemesOrder
  };
};

function getDTOFromTheme(theme: Theme): ThemeDTO {
  return {
    ...theme,
    tagIds: theme.tags?.map((tag) => tag.id),
    tags: undefined
  } as unknown as ThemeDTO;
}

function getThemeFromDto(dto: ThemeDTO): Theme {
  return {
    ...dto
  } as Theme;
}
