import {
  TrainingAreaFacilityType,
  TrainingAreaFacilityTypeDTO
} from 'src/types';

export function getDtoFromTrainingAreaFacilityType(
  facilityType: TrainingAreaFacilityType
): string {
  switch (facilityType) {
    case TrainingAreaFacilityType.ACADEMIC:
      return TrainingAreaFacilityTypeDTO.ACADEMIC;
    case TrainingAreaFacilityType.ACCOMMODATION:
      return TrainingAreaFacilityTypeDTO.ACCOMMODATION;
    case TrainingAreaFacilityType.TRANSPORT:
      return TrainingAreaFacilityTypeDTO.TRANSPORT;
  }
}
