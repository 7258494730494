import { useCallback } from 'react';
import {
  Facility,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IFacilityService {
  getFacilities: GetAPI<Facility>;
  getFacility: (
    id: number,
    abortController?: AbortController
  ) => Promise<Facility>;
  createFacility: (
    facility: Facility,
    abortController?: AbortController
  ) => Promise<void>;
  updateFacility: (
    id: number,
    facility: Facility,
    abortController?: AbortController
  ) => Promise<void>;
  deleteFacility: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useFacilityService = (): IFacilityService => {
  const facilityApi = useAxiosInstance('/v2/facilities');

  const getFacilities = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Facility>> => {
      return facilityApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { page, _embedded } = response.data;

          return {
            items: _embedded?.facilityDToes || [],
            hasNextPage: page.number < page.totalElements,
            hasPrevPage: page.number > 0,
            pageNumber: page.number,
            totalCount: page.totalElements,
            totalPages: page.totalPages
          } as PaginatedResponse<Facility>;
        });
    },
    [facilityApi]
  );

  const getFacility = useCallback(
    (id: number, abortController?: AbortController): Promise<Facility> =>
      facilityApi
        .get(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => response.data as Facility),
    [facilityApi]
  );

  const createFacility = useCallback(
    (facility: Facility, abortController?: AbortController): Promise<void> =>
      facilityApi.post('', facility, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [facilityApi]
  );

  const updateFacility = useCallback(
    (
      id: number,
      facility: Facility,
      abortController?: AbortController
    ): Promise<void> =>
      facilityApi.put(`${id}`, facility, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [facilityApi]
  );

  const deleteFacility = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      facilityApi.delete(`${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [facilityApi]
  );

  return {
    getFacility,
    getFacilities,
    createFacility,
    updateFacility,
    deleteFacility
  };
};
