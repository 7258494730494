import { useEffect, useState } from 'react';
import { useAppContext } from 'src/common';
import { getAllFromPaginatedApiV2 } from 'src/lib';
import {
  GetAPI,
  PlacementSchedule,
  PlacementScheduleStudentDetails,
  Student
} from 'src/types';

export type UseBookingStudentsProps = {
  moduleScheduleId?: number;
  roomBookingId?: number;
  placementSchedule?: PlacementSchedule;
  getClassListStudents: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<Student[]>;
  getStudents: GetAPI<Student>;
};

export type BookingStudents = {
  moduleStudents: PlacementScheduleStudentDetails[];
  isModulesStudentsLoading: boolean;
};

export function useBookingStudents({
  moduleScheduleId,
  roomBookingId,
  placementSchedule,
  getClassListStudents,
  getStudents
}: UseBookingStudentsProps): BookingStudents {
  const { handleError } = useAppContext();
  const [moduleStudents, setModuleStudents] = useState<
    PlacementScheduleStudentDetails[] | undefined
  >();

  const [isModulesStudentsLoading, setisModuleStudentsLoading] =
    useState<boolean>(true);

  useEffect(() => {
    const abortController = new AbortController();

    if (moduleScheduleId === undefined && roomBookingId === undefined) {
      return;
    }

    (() => {
      if (moduleScheduleId !== undefined) {
        return getClassListStudents(Number(moduleScheduleId), abortController);
      } else if (roomBookingId !== undefined) {
        return getAllFromPaginatedApiV2({
          // for legacy bookings
          getApi: getStudents,
          filters: { bookingId: roomBookingId.toString() },
          abortController: abortController
        });
      }

      throw new Error(
        'moduleScheduleId and roomBookingId not expected to both be undefined'
      );
    })()
      .then((results) => {
        setModuleStudents(
          results.map((student) => {
            return {
              id: student.id,
              displayName: student.displayName,
              hasOwnTransport: student.hasOwnTransport,
              licenceExpiry: student.licenseExpiry,
              gender: student.gender,
              genderV2: student.genderV2,
              needTransport: placementSchedule
                ? placementSchedule.transportStudentsIds.includes(student.id)
                : false,
              needAccommodation: placementSchedule
                ? placementSchedule.accommodationStudentsIds.includes(
                    student.id
                  )
                : false,
              carRegistration:
                (student.isAvailableForBooking && student.carRegistration) ||
                '-',
              bookable:
                student.hasOwnTransport && student.isAvailableForBooking,
              excluded: false,
              conflictOverridden: false
            };
          })
        );
      })
      .catch(handleError)
      .finally(() => {
        if (!abortController.signal.aborted) {
          setisModuleStudentsLoading(false);
        }
      });
  }, [
    moduleScheduleId,
    roomBookingId,
    placementSchedule,
    getClassListStudents,
    getStudents,
    handleError
  ]);

  return {
    moduleStudents: moduleStudents || [],
    isModulesStudentsLoading
  };
}
