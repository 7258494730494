import { useCallback } from 'react';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  FormDTO,
  Form, FormQuestion
} from 'src/types';

export type FormQuestionCreatePayload = Omit<FormQuestion, 'id' | 'formId'>;

export type FormCreatePayload = Omit<
    Form,
    'id' | 'formQuestions' | 'hasSubmissions'
> & {
  formQuestions: FormQuestionCreatePayload[]
};

export interface IFormsService {
  getForms: GetAPI<Form>;
  getForm: (id: number, abortController?: AbortController) => Promise<Form>;
  updateForm: (form: Form, abortController?: AbortController) => Promise<void>;
  createForm: (form: FormCreatePayload, abortController?: AbortController) => Promise<void>;
  deleteForm: (
    formId: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useFormService = (): IFormsService => {
  const formApi = useAxiosInstance('/api/v2/forms');

  const getForms = useCallback(
    (
      request: PaginatedRequest,
      abortController?: AbortController
    ): Promise<PaginatedResponse<Form>> => {
      return formApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: abortController?.signal
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<FormDTO>;

          return {
            items: content.map<Form>(getFormFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Form>;
        })
        .catch(error => {
          throw error;
        });
    },
    [formApi]
  );

  const getForm = useCallback(
    (id: number, abortController?: AbortController): Promise<Form> =>
      formApi
        .get(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then(response => {
          const form = response.data as FormDTO;
          return getFormFromDto(form);
        }),
    [formApi]
  );

  const updateForm = useCallback(
    (form: Form, abortController?: AbortController): Promise<void> => {
      if (!form.id) {
        throw new Error('updateForm requires value in id field');
      }

      return formApi.put(
        `/${form.id}`,
        {
          ...getDtoFromForm(form)
        },
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [formApi]
  );

  const createForm = useCallback(
    (form: FormCreatePayload, abortController?: AbortController): Promise<void> => {
      return formApi.post(
        '',
        {
          ...getDtoFromForm(form)
        },
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [formApi]
  );

  const deleteForm = useCallback(
    (formId: number, abortController?: AbortController): Promise<void> => {
      return formApi.delete(`/${formId}`, {
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [formApi]
  );

  return { getForm, getForms, updateForm, createForm, deleteForm };
};

function getDtoFromForm(form: Form | FormCreatePayload): FormDTO {
  const { themes, validFrom, validTo, ...rest } = form;

  return {
    ...rest,
    validFrom: getDtoDateStringFromDate(validFrom),
    validTo: getDtoDateStringFromDate(validTo),
    themeIds: form.themeIds || (themes || []).map(theme => theme.id)
  } as FormDTO;
}

function getFormFromDto(form: FormDTO): Form {
  const { validFrom, validTo } = form;

  return {
    ...form,
    validFrom: getDateFromDtoDateString(validFrom),
    validTo: getDateFromDtoDateString(validTo)
  } as Form;
}
