import { ModuleScheduleDTO } from '../dto';
import { Tag } from './Tag.type';

export type ModuleSchedule = {
  dateVerified?: Date;
  startDate?: Date;
  endDate?: Date;
  approvalDateTime?: Date;
  status: ModuleScheduleStatus;
  tags: Tag[];
} & Omit<
  ModuleScheduleDTO,
  'dateVerified' | 'startDate' | 'endDate' | 'approvalDateTime' | 'status'
>;

export enum ModuleScheduleStatus {
  ACTIVE = 1,
  INACTIVE
}
