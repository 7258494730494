import { useEffect } from 'react';
import { useBreadcrumbs } from '../breadcrumbs-context-provider/BreadcrumbsContextProvider';

/**
 * @deprecated - use {@link setBreadcrumbText} from {@link BreadcrumbsContext} or {@link useBreadcrumbs}
 */
export const useBreadcrumbParamValues = (
  params: Record<string, string | undefined>
) => {
  const { setParams } = useBreadcrumbs();

  useEffect(() => {
    setParams(params);
  }, [params, setParams]);
};
