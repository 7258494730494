import { StudentTransport } from 'src/types/models/StudentTransport.type';
import { useCallback } from 'react';
import { useAxiosInstance } from 'src/lib';

interface IStudentTransportService {
  creatStudentTransport: (
    studentTransport: StudentTransport,
    abortController?: AbortController
  ) => Promise<void>;
  updateStudentTransport: (
    id: number,
    studentTransport: StudentTransport,
    abortController?: AbortController
  ) => Promise<void>;
  getStudentTransport: (id: number) => Promise<StudentTransport>;
  deleteStudentTransport: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export function useStudentTransportService(): IStudentTransportService {
  const studentTransportApi = useAxiosInstance('/v2/students/transportation');

  const getStudentTransport = useCallback(
    (
      id: number,
      abortController?: AbortController
    ): Promise<StudentTransport> =>
      studentTransportApi
        .get(`/${id}`, { signal: abortController?.signal })
        .then((response) => response.data as StudentTransport),
    [studentTransportApi]
  );

  const deleteStudentTransport = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      studentTransportApi.delete(`/${id}`, { signal: abortController?.signal }),
    [studentTransportApi]
  );

  const creatStudentTransport = useCallback(
    (
      studentTransport: StudentTransport,
      abortController?: AbortController
    ): Promise<void> =>
      studentTransportApi.post('', studentTransport, {
        signal: abortController?.signal
      }),
    [studentTransportApi]
  );

  const updateStudentTransport = useCallback(
    (
      id: number,
      studentTransport: StudentTransport,
      abortController?: AbortController
    ): Promise<void> =>
      studentTransportApi.put(`/${id}`, studentTransport, {
        signal: abortController?.signal
      }),
    [studentTransportApi]
  );

  return {
    creatStudentTransport,
    updateStudentTransport,
    getStudentTransport,
    deleteStudentTransport
  };
}
