import { AcademicBookingStatus, RoomBookingStatus } from 'src/types';

export function getAcademicBookingStatusFromRoomBookingStatus(
  status: RoomBookingStatus
): AcademicBookingStatus {
  switch (status) {
    case RoomBookingStatus.REJECTED:
      return AcademicBookingStatus.REJECTED;
    case RoomBookingStatus.APPROVED:
      return AcademicBookingStatus.APPROVED;
    case RoomBookingStatus.AWAITING_APPROVAL:
      return AcademicBookingStatus.AWAITING_APPROVAL;
    case RoomBookingStatus.CANCELLED:
      return AcademicBookingStatus.CANCELLED;
  }
}
