import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import { ThemableBoxType } from 'src/types';

export type ThemableBoxProps = {
  type: ThemableBoxType;
  className?: string;
};

/**
 * Although the Material UI Box component can be used to create new custom components,
 * it does not support themes. This component wraps the Box component, and
 * supports theming.
 */
export const ThemableBox = ({
  type,
  children,
  className
}: PropsWithChildren<ThemableBoxProps>) => {
  return <Box className={`${type} ${className ?? ''}`}>{children}</Box>;
};
