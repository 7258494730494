import { FC } from 'react';
import { Link } from '@mui/material';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';

export type TableActionProps = {
  action: string;
  onClickAction: () => void;
  isDisabled?: boolean;
};

export const TableAction: FC<TableActionProps> = ({
  action,
  onClickAction,
  isDisabled
}) => {
  return (
    <ThemableBox type={ThemableBoxType.ACTION_LINK}>
      <Link
        onClick={(event) => {
          event.stopPropagation();
          onClickAction();
        }}
        component="button"
        disabled={isDisabled ?? false}
      >
        {action}
      </Link>
    </ThemableBox>
  );
};
