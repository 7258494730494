import { FC } from 'react';
import { Grid } from '@mui/material';

export const ListViewFilterPanel: FC = ({ children }) => {
  return (
    <Grid item xs="auto">
      {children}
    </Grid>
  );
};
