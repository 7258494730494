import { FC, createContext, useContext, useState } from 'react';
import { HelixLoadingIndicator } from './HelixLoadingIndicator';

type UseLoadingContextInterface = {
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
};

const LoadingContext = createContext<UseLoadingContextInterface | undefined>(undefined);

export const LoadingContextProvider: FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <LoadingContext.Provider value={{ isLoading, setIsLoading }}>
      { isLoading && <HelixLoadingIndicator /> }
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  const loadingContext = useContext(LoadingContext);

  if (!loadingContext) {
    throw new Error(
      'Cannot use Loading Context outside of Loading Context Provider'
    );
  }

  return loadingContext;
};

