import { BookedBy, Filters } from '../components';
import { RoomBookingType } from 'src/types';
import { useMemo } from 'react';

export type FilterTotals = {
  totalFilterCount: number;
  activeFilterCount: number;
};

type FilterVisibility = {
  [Property in keyof Filters]: boolean | undefined;
};

/**
 * Returns the total number of visible filters and the number of
 * filters that have values set, using the below params.
 *
 * @param filters
 * @param bookingType
 * @param isApproverViewEnabled
 */
export function useFilterTotals(
  filters: Filters,
  bookingType: RoomBookingType,
  isApproverViewEnabled: boolean
): FilterTotals {
  const filterTotals: FilterTotals = useMemo(() => {
    const filterFlags = getFilterFlags(
      filters,
      bookingType,
      isApproverViewEnabled
    );

    const totalFilters = Object.keys(filterFlags) as (keyof FilterVisibility)[];

    const activeFiltersCount = totalFilters.reduce((total, filterKey) => {
      // eslint-disable-next-line security/detect-object-injection
      if (filters[filterKey] !== undefined) {
        return total + 1;
      }

      return total;
    }, 0);

    return {
      totalFilterCount: totalFilters.length,
      activeFilterCount: activeFiltersCount
    };
  }, [filters, bookingType, isApproverViewEnabled]);

  return filterTotals;
}

const FILTERS_VISIBILITY_DEFAULT: FilterVisibility = {
  status: true,
  bookedBy: true,
  approver: true,
  facility: true,
  trainingArea: true
};

const FILTERS_VISIBILITY_ACADEMIC_APPROVER: FilterVisibility = {
  ...FILTERS_VISIBILITY_DEFAULT,
  activityType: true
};

const FILTERS_VISIBILITY_ACADEMIC_NON_APPROVER_BOOKED_BY_ME_OR_EVERYONE: FilterVisibility =
  {
    ...FILTERS_VISIBILITY_DEFAULT,
    programmeSchedule: true,
    moduleSchedule: true,
    themeSchedule: true,
    activityType: true,
    activity: true,
    studentGroup: true,
    student: true
  };

function getFilterFlags(
  filters: Filters,
  bookingType: RoomBookingType,
  isApproverViewEnabled: boolean
): FilterVisibility {
  const { bookedBy } = filters;

  switch (bookingType) {
    case RoomBookingType.ACADEMIC: {
      if (isApproverViewEnabled) {
        return FILTERS_VISIBILITY_ACADEMIC_APPROVER;
      }

      if (bookedBy === BookedBy.EVERYONE_ALL) {
        return FILTERS_VISIBILITY_DEFAULT;
      }

      return FILTERS_VISIBILITY_ACADEMIC_NON_APPROVER_BOOKED_BY_ME_OR_EVERYONE;
    }

    default:
      return FILTERS_VISIBILITY_DEFAULT;
  }
}
