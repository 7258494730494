import { User } from '../models';
import { PermissionId } from '../enums';

export enum ErrorType {
  HANDLED,
  UNHANDLED
}

export type CriticalError = {
  errorType: ErrorType;
  dateTime: Date;
  message: string;
  stack?: string;
  debugInfo?: string;
  user?: User;
  userPermissions?: PermissionId[];
};
