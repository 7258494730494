import { useNavigate, useLocation } from 'react-router-dom';

export interface IClearFilterParams {
  clearFilterParameters: () => void;
}

const SEARCH_KEY = 'search';

export const useClearFilterParams = (): IClearFilterParams => {
  const navigate = useNavigate();
  const location = useLocation();

  const clearFilterParameters = () => {
    /**
     * Updates URL to remove all query parameters (filter values), but retains
     * the free-text search parameters, if the search parameter is present
     */
    const urlParams = new URLSearchParams(location.search);
    const searchParam = urlParams.get(SEARCH_KEY);
    if (searchParam) {
      return navigate(`?${SEARCH_KEY}=${searchParam}`, { replace: true });
    } else {
      return navigate('.');
    }
  };

  return {
    clearFilterParameters
  };
};
