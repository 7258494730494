export type FieldTypeProps = {
  type: string;
  onKeyPress?: (event: { key: string; preventDefault: () => void }) => void;
};

export function getFieldTypeProps(fieldType: string): FieldTypeProps {
  const result: FieldTypeProps = {
    type: fieldType
  };

  switch (fieldType) {
    case 'number':
      return {
        type: 'number',
        onKeyPress: (event: { key: string; preventDefault: () => void }) =>
          ['-', '+', 'e', '.'].includes(event?.key.toLowerCase()) &&
          event.preventDefault()
      };
    default:
      return result;
  }
}
