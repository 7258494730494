import React, { ReactNode, useMemo } from 'react';
import { Grid } from '@mui/material';
import { Calendar } from 'src/common-v2';
import {
  DateRange,
  DateRangeType,
  RoomBooking,
  RoomBookingStatus,
  Event,
  TableEventItem
} from 'src/types';
import { getDisplayDateStringFromDate } from 'src/lib';
import {
  getDisplayTextFromRoomBookingStatus,
  getRoomBookingFromType,
  REQUEST_A_TRAINING_AREA_PLACEHOLDER_NAME
} from '../lib';
import { BookingTableRowDetails } from './BookingsTable';

export type BookingsCalendarProps = {
  bookings: RoomBooking[];
  dateRange: DateRange;
  dateRangeType: DateRangeType;
  onViewBooking: (roomBooking: BookingTableRowDetails) => void;
  getBookingUrl?: (roomBooking: RoomBooking) => string | undefined;
  events: Event[];
  getDayCellHeader?: (date: Date) => React.ReactElement;
};

export function BookingsCalendar({
  bookings,
  dateRange,
  dateRangeType,
  onViewBooking,
  getBookingUrl,
  events,
  getDayCellHeader
}: BookingsCalendarProps) {
  function getEventClassName(
    status: RoomBookingStatus,
    isTrainingSiteBookable: boolean
  ) {
    switch (status) {
      case RoomBookingStatus.REJECTED:
        return 'rejected';
      case RoomBookingStatus.APPROVED:
        if (!isTrainingSiteBookable) {
          return 'non_bookable';
        }

        return 'approved';
      case RoomBookingStatus.AWAITING_APPROVAL:
        return 'awaiting-approval';
      case RoomBookingStatus.CANCELLED:
        return 'cancelled';
    }
  }

  function getBookingTooltip(roomBooking: RoomBooking): ReactNode {
    const {
      title,
      moduleName,
      notes,
      bookingType,
      capacity,
      status,
      approvalDateTime,
      facilityName,
      trainingSiteName,
      bookedByUser,
      trainingIsVenueRequest
    } = roomBooking;

    return (
      <Grid container direction="row" spacing={2}>
        <Grid item xs={6}>
          Event Name
        </Grid>

        <Grid item xs={6}>
          {moduleName}
        </Grid>

        <Grid item xs={6}>
          Status
        </Grid>

        <Grid item xs={6}>
          {getDisplayTextFromRoomBookingStatus(status)}
        </Grid>

        <Grid item xs={6}>
          Facility Name
        </Grid>

        <Grid item xs={6}>
          {facilityName}
        </Grid>

        <Grid item xs={6}>
          Training Site Name
        </Grid>

        <Grid item xs={6}>
          {trainingIsVenueRequest
            ? REQUEST_A_TRAINING_AREA_PLACEHOLDER_NAME
            : trainingSiteName}
        </Grid>

        <Grid item xs={6}>
          Booked By User Name
        </Grid>

        <Grid item xs={6}>
          {bookedByUser ? bookedByUser.displayName : '<Not set>'}
        </Grid>

        <Grid item xs={6}>
          Booking Type
        </Grid>

        <Grid item xs={6}>
          {getRoomBookingFromType(bookingType)}
        </Grid>

        <Grid item xs={6}>
          Capacity
        </Grid>

        <Grid item xs={6}>
          {capacity}
        </Grid>

        <Grid item xs={6}>
          Description
        </Grid>

        <Grid item xs={6}>
          {title}
        </Grid>

        <Grid item xs={6}>
          Approval Date Time
        </Grid>

        <Grid item xs={6}>
          {approvalDateTime
            ? getDisplayDateStringFromDate(approvalDateTime)
            : '<Not set>'}
        </Grid>

        <Grid item xs={6}>
          Status Comment
        </Grid>

        <Grid item xs={6}>
          {notes ?? '<Not set>'}
        </Grid>
      </Grid>
    );
  }

  const tableEventItems = useMemo<TableEventItem[]>(
    () =>
      bookings.map<TableEventItem>((roomBooking) => ({
        id: roomBooking.id,
        title: roomBooking.trainingIsVenueRequest
          ? roomBooking.moduleName ?? roomBooking.trainingSiteName
          : roomBooking.trainingSiteName,
        tooltip: getBookingTooltip(roomBooking),
        startTime: roomBooking.startDateTime,
        endTime: roomBooking.endDateTime,
        className: getEventClassName(
          roomBooking.status,
          roomBooking.trainingSiteBookable
        ),
        onDoubleClick: () => onViewBooking(roomBooking),
        url: getBookingUrl ? getBookingUrl(roomBooking) : undefined
      })),
    [bookings, onViewBooking, getBookingUrl]
  );

  return (
    <Calendar
      events={tableEventItems}
      visibleTimeStart={dateRange.fromDate}
      visibleTimeEnd={dateRange.toDate}
      dateRangeType={dateRangeType}
      getDayCellHeader={(date: Date) => {
        return <>{getDayCellHeader && getDayCellHeader(date)}</>;
      }}
    />
  );
}
