import { ReactFragment } from 'react';
import {
  Breakpoint,
  Dialog,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ThemableBoxType } from 'src/types';

type DialogControlProps = {
  children: ReactFragment;
  title: string;
  fullWidth?: boolean;
  maxWidth?: false | Breakpoint;
  handleClose?: () => void;
};

export function DialogControl(dialogProps: DialogControlProps) {
  const { children, title, maxWidth, fullWidth, handleClose } = dialogProps;
  return (
    /* Note: The Dialog component does not render within
     * the root div (under which the rest of the app is rendered).
     * Consequently, we set the class name to in the Dialog component
     * to style it, because if we used the ThemeableBox to wrap the
     * Dialog component, the Dialog would not be rendered
     * as a child of the ThemeableBox. */
    <Dialog
      open={true}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      onClose={handleClose}
      className={ThemableBoxType.DIALOG_CONTROL}
    >
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="headingFourForm" padding={1}>
            {title}
          </Typography>
          {handleClose && (
            <Stack direction="row" justifyContent="flex-end">
              <IconButton
                aria-label="close"
                className="IconBtnClose"
                onClick={dialogProps.handleClose}
              >
                <CloseIcon />
              </IconButton>
            </Stack>
          )}
        </Stack>
      </DialogTitle>
      {children}
    </Dialog>
  );
}
