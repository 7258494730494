import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NotFoundPage } from 'src/common/not-found-page/NotFoundPage';
import { HelixLoadingIndicator } from 'src/common/loading-indicator/HelixLoadingIndicator';

const PeopleModuleLazy = lazy(() => import('src/modules/people'));
const ClassListModuleLazy = lazy(() => import('src/modules/class-list'));
const SettingsModuleLazy = lazy(
  () => import('src/modules/settings/SettingsModule')
);
const StudentsModuleLazy = lazy(() => import('src/modules/students'));
const FacilitiesModuleLazy = lazy(() => import('src/modules/facilities'));
const FormsModuleLazy = lazy(() => import('src/modules/forms'));
const CurriculumModuleLazy = lazy(() => import('src/modules/curriculum'));

const ScheduleThemesModuleLazy = lazy(
  () => import('src/modules/schedule-themes')
);
const CapacityPlansModuleLazy = lazy(
  () => import('src/modules/capacity-plans')
);

const BookingsModuleLazy = lazy(() => import('src/modules/bookings'));
const EventsModuleLazy = lazy(() => import('src/modules/events'));
const StudentLogisticsModuleLazy = lazy(
  () => import('src/modules/student-logistics/StudentLogisticsModule')
);

const ModuleSupervisorsModuleLazy = lazy(
  () => import('src/modules/module-supervisors')
);

const WelcomeModuleLazy = lazy(() => import('src/modules/welcome'));

export const RoutesContainer = () => {
  return (
    <Suspense fallback={<HelixLoadingIndicator />}>
      <Routes>
        <Route path="/" element={<WelcomeModuleLazy />} />
        <Route path="/people/*" element={<PeopleModuleLazy />} />
        <Route path="/settings/*" element={<SettingsModuleLazy />} />
        <Route path="/class-list/*" element={<ClassListModuleLazy />} />
        <Route path="/students/*" element={<StudentsModuleLazy />} />
        <Route path="/facilities/*" element={<FacilitiesModuleLazy />} />
        <Route path="/forms/*" element={<FormsModuleLazy />} />
        <Route path="/curriculum/*" element={<CurriculumModuleLazy />} />
        <Route
          path="/schedule-themes/*"
          element={<ScheduleThemesModuleLazy />}
        />
        <Route path="/bookings/*" element={<BookingsModuleLazy />} />
        <Route path="/capacity-plans/*" element={<CapacityPlansModuleLazy />} />
        <Route path="/events/*" element={<EventsModuleLazy />} />
        <Route
          path="/module-supervisors/*"
          element={<ModuleSupervisorsModuleLazy />}
        />
        <Route
          path="/student-logistics/*"
          element={<StudentLogisticsModuleLazy />}
        />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </Suspense>
  );
};
