import React, { FC } from 'react';
import { useNavigate } from 'react-router';
import { useSnackbar } from 'notistack';
import { CriticalError, ErrorType, PermissionId } from 'src/types';
import {
  copyTextToClipboard,
  DATE_TIME_FORMAT,
  getDisplayDateStringFromDate
} from 'src/lib';
import { useAppContext } from 'src/common';
import { CriticalErrorScreen } from '../components';
import { downloadAsTextFile } from 'src/common-v2';

export type CriticalErrorScreenContainerProps = {
  criticalError: CriticalError;
  clearCriticalError: () => void;
};

const SAFE_URL_LENGTH = 2038;
const MAX_URL_LENGTH = 2047;
const MAX_STACK_TRACE_AT_INDEX = 3;
const AT_SUBSTRING_PATTERN = ' at ';
const ELLIPSIS = '...';
const MAILTO_URL_MAILTO = 'mailto:';
const MAILTO_URL_SUBJECT = 'subject';
const MAILTO_URL_BODY = 'body';

const ERROR_REPORT_SUBJECT_PREFIX =
  'SUN%20Placement%20Plus%20Error%20Report%20-%20';
const ERROR_REPORT_EMAIL_ADDRESS = 'fmhsbm@sun.ac.za';
const ERROR_REPORT_MAILTO_URL = `${MAILTO_URL_MAILTO}${ERROR_REPORT_EMAIL_ADDRESS}`;
const ERROR_REPORT_FILE_NAME = 'SUNPP_ErrorReport';

const timestamp = () =>
  getDisplayDateStringFromDate(
    new Date(),
    DATE_TIME_FORMAT.FILE_TIMESTAMP_FORMAT
  );

const getSubjectLine = (): string => {
  return ERROR_REPORT_SUBJECT_PREFIX.concat(timestamp());
};

const getMailtoUrl = (criticalError: CriticalError): string => {
  const mailtoUrl = `${ERROR_REPORT_MAILTO_URL}?${MAILTO_URL_SUBJECT}=${getSubjectLine()}&${MAILTO_URL_BODY}=${getErrorReportEmailBody(
    criticalError
  )}`;
  return mailtoUrl.slice(0, MAX_URL_LENGTH);
};

export const CriticalErrorScreenContainer: FC<
  CriticalErrorScreenContainerProps
> = ({ criticalError, clearCriticalError }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { handleError } = useAppContext();

  return (
    <CriticalErrorScreen
      onBackToHomeButtonClick={() => {
        clearCriticalError();
        navigate('./');
      }}
      onBackToPreviousPageButtonClick={() => {
        clearCriticalError();
      }}
      onCopyErrorButtonClick={() => {
        copyTextToClipboard(getTextToCopyFromCriticalError(criticalError))
          .then(() =>
            enqueueSnackbar('Error copied', {
              variant: 'success'
            })
          )
          .catch(handleError);
      }}
      onDownloadErrorReportButtonClick={() => {
        Promise.resolve(
          downloadAsTextFile(
            `${ERROR_REPORT_FILE_NAME}_${timestamp()}`,
            getTextToCopyFromCriticalError(criticalError)
          )
        )
          .then(() => {
            enqueueSnackbar('Error report downloaded', {
              variant: 'success'
            });
          })
          .catch(handleError);
      }}
      onSendEmailButtonClick={() => {
        if (criticalError) {
          enqueueSnackbar('Email generated', {
            variant: 'success'
          });
          window.open(getMailtoUrl(criticalError), '_blank');
        }
      }}
    />
  );
};

function getTextToCopyFromCriticalError(criticalError: CriticalError): string {
  return getErrorReportAsArray(criticalError).join('\n');
}

function getErrorReportEmailBody(criticalError: CriticalError): string {
  const { user, dateTime, message, errorType, stack, debugInfo } =
    criticalError;

  const getShortenedStackTrace = (stack: string): string => {
    const stackTraceUpToSecondAt = stack
      .split(AT_SUBSTRING_PATTERN, MAX_STACK_TRACE_AT_INDEX)
      .slice(0, MAX_STACK_TRACE_AT_INDEX)
      .join(AT_SUBSTRING_PATTERN)
      .trim();
    return `${stackTraceUpToSecondAt}${
      stack.lastIndexOf(AT_SUBSTRING_PATTERN) >
      stackTraceUpToSecondAt.length - 1
        ? ELLIPSIS
        : ''
    }`;
  };

  const getShortenedDebugInfo = (
    debugInfo: string,
    currentEmailBodyString: string
  ): string => {
    const currentEmailBodyUriLength = encodeURI(currentEmailBodyString).length;
    const safeLength =
      SAFE_URL_LENGTH -
      encodeURI(
        `${ERROR_REPORT_MAILTO_URL}?${MAILTO_URL_SUBJECT}=${getSubjectLine()}&${MAILTO_URL_BODY}=`
      ).length -
      currentEmailBodyUriLength;
    return `${debugInfo.slice(0, safeLength)}${
      debugInfo.length > safeLength ? ELLIPSIS : ''
    }`;
  };

  const textLines: string[] = [];

  textLines.push(`# SUNPP Frontend Error Report

## Overview
- On ${getDisplayDateStringFromDate(
    dateTime,
    DATE_TIME_FORMAT.FORMAL_DATE_TIME_FORMAT
  )}, ${user ? user.displayName : 'a user'} ran into an "${message}" ${
    errorType === ErrorType.HANDLED ? 'handled' : 'unhandled'
  } error.
`);

  if (user) {
    textLines.push(
      `## User details
- US Number: ${user.usNumber}
- Initials: ${user.initials}
- Surname: ${user.surname}
`
    );
  }

  if (stack) {
    textLines.push(
      `## Stack Trace
\`\`\`\n${getShortenedStackTrace(stack)}\n\`\`\`
`
    );
  }

  if (debugInfo) {
    textLines.push(
      `## Debug Info
\`\`\`\n${getShortenedDebugInfo(debugInfo, textLines.join('\n'))}\n\`\`\`
`
    );
  }

  return encodeURI(textLines.join('\n'));
}

function getErrorReportAsArray(criticalError: CriticalError): string[] {
  const {
    user,
    dateTime,
    message,
    errorType,
    stack,
    debugInfo,
    userPermissions
  } = criticalError;

  const textLines: string[] = [];

  textLines.push(`
# SUNPP Frontend Error Report

###### Note: this text is formatted in Markdown - please use a Markdown viewer for the best experience. 

## Overview
- On ${getDisplayDateStringFromDate(
    dateTime,
    DATE_TIME_FORMAT.FORMAL_DATE_TIME_FORMAT
  )}, ${user ? user.displayName : 'a user'} ran into an "${message}" ${
    errorType === ErrorType.HANDLED ? 'handled' : 'unhandled'
  } error. Please see extra detail below. 
`);

  if (user && userPermissions) {
    textLines.push(
      `## User details
- US Number: ${user.usNumber}
- Initials: ${user.initials}
- Surname: ${user.surname}
- Roles: ${user.roles.map((role) => role.name).join(', ')}
- Permissions:\n${userPermissions
        .map((permissionId) => `    - ${PermissionId[permissionId]}`)
        .join('\n')}
`
    );
  }

  if (stack) {
    textLines.push(
      `## Stack Trace
\`\`\`\n${stack}\n\`\`\`
`
    );
  }

  if (debugInfo) {
    textLines.push(
      `## Debug Info
\`\`\`\n${debugInfo}\n\`\`\`
`
    );
  }

  return textLines;
}
