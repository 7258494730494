import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';

export type BookingsAccordionProps = {
  defaultExpanded: boolean;
  renderSummary: () => React.ReactNode;
  renderDetails: () => React.ReactNode;
};

export function BookingsAccordion({
  defaultExpanded,
  renderSummary,
  renderDetails
}: BookingsAccordionProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(defaultExpanded);

  const handleChange = (_: React.SyntheticEvent, isExpanded: boolean) => {
    setIsExpanded(isExpanded);
  };

  return (
    <Accordion expanded={isExpanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        {renderSummary()}
      </AccordionSummary>

      {isExpanded && <AccordionDetails>{renderDetails()}</AccordionDetails>}
    </Accordion>
  );
}
