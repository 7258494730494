import { useCallback } from 'react';
import {
  NavigateOptions,
  NavigateFunction,
  To,
  useLocation,
  useNavigate,
  matchPath
} from 'react-router-dom';

export interface ILocationState {
  previousLocationPathname: string;
}

/**
 *
 * @param obj
 * @returns boolean, obj has previousLocationPathname
 */
export function isHasPreviousLocationPathName(obj: unknown): obj is ILocationState {
  return (
    (obj as ILocationState)?.previousLocationPathname !== undefined &&
    typeof (obj as ILocationState).previousLocationPathname === 'string'
  );
}

export function isSameRoute(newRoute: string, oldRoute: string): boolean {

  const isSameRoute = matchPath(newRoute, oldRoute);

  return isSameRoute != null;
}

/**
 * Custom implementation of navigate to be able to redirect to urls while maintaining the previous views filters.
 * When attempting to navigate to previous view, will navigate using previous views route parameters.
 * @returns custom implementation of navigate that stores the previous route in Location state
 */
export function useNavigateCustom(): NavigateFunction {
  const location = useLocation();
  const navigate = useNavigate();

  /**
   * Implementation of Navigate function,
   * checks for if the navigation route is the previous route, uses the previous routes route params
   * Otherwise navigates to route.
   * @param to
   * @param options
   */
  const navigateTo = useCallback((to: To | number, options?: NavigateOptions) => {
    if (
      isHasPreviousLocationPathName(location.state) &&
      isSameRoute(to.toString(), location.state.previousLocationPathname)
    ) {
      navigate(-1);
    } else if (typeof to === 'number') {
      navigate(to);
    } else {
      navigate(to, {
        state: {
          ...options?.state,
          previousLocationPathname: location.pathname
        },
        replace: options?.replace
      });
    }
  },[location, navigate]);

  return navigateTo;
}
