import { useCallback } from 'react';
import { useEnv } from 'src/common';
import { getDtoDateStringFromDate, isValidDate } from 'src/lib';
import { DownloadPortfolio } from 'src/types';
import { DownloadPortfolioDTO } from 'src/types/dto/DownloadPortfolioDTO.type';
export interface IStudentPortfolioService {
  downloadPortfolio: (params: DownloadPortfolio) => void;
}

export const useStudentPortfolioService = (): IStudentPortfolioService => {
  const { apiUrl } = useEnv();

  const downloadPortfolio = useCallback(
    (downloadPortfolio: DownloadPortfolio): void => {
      const downloadPortfolioDto = getDtoFromDownloadPortfolio(
        downloadPortfolio
      );
      const queryParams = new URLSearchParams(downloadPortfolioDto); // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(
        `${apiUrl}/v2/students/portfolio?${queryParams.toString()}`,
        '_blank'
      );
    },
    [apiUrl]
  );

  return { downloadPortfolio };

  function getDtoFromDownloadPortfolio(
    downloadPortfolio: DownloadPortfolio
  ): DownloadPortfolioDTO {
    const {
      studentId,
      startDate,
      endDate,
      moduleScheduleId,
      themeScheduleId,
      facilityIds
    } = downloadPortfolio;

    return {
      studentId: studentId.toString(),
      startDate:
        startDate && isValidDate(startDate)
          ? getDtoDateStringFromDate(startDate)
          : '',
      endDate:
        endDate && isValidDate(endDate)
          ? getDtoDateStringFromDate(endDate)
          : '',
      moduleScheduleId: moduleScheduleId ? moduleScheduleId.toString() : '',
      themeScheduleId: themeScheduleId ? themeScheduleId.toString() : '',
      facilityIds: facilityIds
        ? facilityIds.map(id => id.toString()).join(',')
        : ''
    };
  }
};
