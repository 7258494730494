import {
  Table as MuiTable,
  TableCell,
  TableContainer,
  TableHead,
  Typography
} from '@mui/material';
import { debounce, groupBy, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { TableType } from '../Table';
import {
  TableBody,
  useTableContext,
  TableFooter,
  TableHeader,
  RefreshStatus
} from '../components';
import {
  FetchRows,
  PaginatedRequest,
  TableHeaderData,
  TableRowData,
  ThemableBoxType
} from 'src/types';
import { useAppContext } from '../../app-context-provider/AppContext';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

export type TablePaginatedWithGroupByProps = {
  type: TableType.PAGINATED_WITH_GROUP_BY;
  headers: { id: string; label: TableHeaderData }[];
  headerGroupById: string;
  fetchRows: FetchRows<TableRowData>;
  search?: string;
  filters?: Record<string, string>;
  fetchCondition?: boolean;
  onRowClicked?: (row: TableRowData) => void;
};

export const TablePaginatedWithGroupByContainer = ({
  headers,
  fetchRows,
  fetchCondition = true,
  headerGroupById,
  onRowClicked
}: TablePaginatedWithGroupByProps) => {
  const { handleError } = useAppContext();
  const [currentRows, setCurrentRows] = useState<TableRowData[] | undefined>(
    undefined
  );
  const [totalRows, setTotalRows] = useState<number>(0);

  const headersFiltered = headers.filter(
    (header) => header.id !== headerGroupById
  );

  const currentRowsGrouped = groupBy(currentRows, (row) =>
    get(row, headerGroupById)
  );

  const tableContext = useTableContext();
  const {
    order,
    orderBy,
    handlePageChange,
    handlePageSizeChange,
    handleRequestSort,
    page,
    pageSize,
    filters,
    search,
    disableSort,
    refreshStatus,
    setRefreshStatus,
    disableSortColumns
  } = tableContext;

  const fetchTableDataDebounced = useMemo(
    () =>
      debounce(
        (queryParams: PaginatedRequest) => {
          fetchRows(queryParams)
            .then((result) => {
              const { totalCount, items } = result;
              setTotalRows(totalCount);
              setCurrentRows([...items]);
              setRefreshStatus(RefreshStatus.REFRESHED);
            })
            .catch(handleError);
        },
        1000,
        { trailing: true, leading: false }
      ),

    [fetchRows, handleError, setRefreshStatus]
  );

  useEffect(() => {
    if (fetchCondition || refreshStatus === RefreshStatus.REFRESHING) {
      const abortController = new AbortController();
      setTotalRows(0);
      setCurrentRows(undefined);
      fetchTableDataDebounced({
        pageNumber: page,
        pageSize,
        order: order,
        orderBy: orderBy,
        search,
        filters,
        abortController
      });

      return () => abortController.abort();
    }
  }, [page, pageSize, order, orderBy, search, filters, fetchTableDataDebounced, fetchCondition, headerGroupById, refreshStatus]);

  return (
    <ThemableBox type={ThemableBoxType.TABLE_GROUP_BY}>
      <TableContainer>
        <MuiTable>
          {Object.entries(currentRowsGrouped).map(([groupId, rows]) => {
            return (
              <>
                <TableHead className="GroupHeader">
                  <TableCell colSpan={headers.length}>
                    <Typography>{groupId}</Typography>
                  </TableCell>
                </TableHead>

                <TableHeader
                  headers={headersFiltered}
                  order={order}
                  orderBy={orderBy}
                  handleSort={handleRequestSort}
                  disableSort={disableSort}
                  disableSortColumns={disableSortColumns}
                />
                <TableBody
                  rows={rows}
                  headers={headersFiltered}
                  onRowClicked={onRowClicked}
                />
              </>
            );
          })}
        </MuiTable>
      </TableContainer>

      <TableFooter
        rowCount={totalRows}
        pageSize={pageSize}
        page={page}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </ThemableBox>
  );
};
