import {
  RefreshStatus,
  useTableContext
} from 'src/common/table/components/TableContextProvider';
import { Button } from '@mui/material';
import React from 'react';

export const TableRefreshButton = () => {
  const { refreshStatus, setRefreshStatus } = useTableContext();

  return (
    <Button
      onClick={() => setRefreshStatus(RefreshStatus.REFRESHING)}
      disabled={refreshStatus === RefreshStatus.REFRESHING}
      variant="outlined"
    >
      Refresh
    </Button>
  );
};
