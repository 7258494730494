import React, { FC, PropsWithChildren } from 'react';
import { Stack, Typography } from '@mui/material';

export type FilterGroupProps = {
  groupLabel: string;
};

export const FilterGroup: FC<PropsWithChildren<FilterGroupProps>> = ({
  groupLabel,
  children
}) => {
  return (
    <Stack spacing={3}>
      <Typography variant="headingFiveRegular">{groupLabel}</Typography>

      <Stack spacing={1.5}>
        {children}
      </Stack>
    </Stack>
  );
};
