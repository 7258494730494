import { Tooltip, TooltipTrigger } from 'src/common';
import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Info } from '@mui/icons-material';

export type PageTitleProps = {
  label: string;
  tooltipContents: JSX.Element;
  tooltipTrigger?: TooltipTrigger;
};

/**
 * @param label The text to be displayed in the header.
 * @param tooltipContents The contents of the Tooltip element. This can be
 * arranged in other layout elements like a Grid or Stack.
 * @param tooltipTrigger The action that will trigger the Tooltip pop-up.
 * Currently only supports, and defaults to, CLICK.
 * @constructor
 */

export const PageTitle = ({
  label,
  tooltipContents,
  tooltipTrigger = TooltipTrigger.CLICK
}: PageTitleProps) => {
  return (
    <Grid
      container
      direction="row"
      spacing={2}
      alignItems="center"
      alignSelf="start"
    >
      <Grid item>
        <Typography variant="headingPageTitle">{label}</Typography>
      </Grid>
      <Grid item>
        <Tooltip trigger={tooltipTrigger} title={tooltipContents}>
          <Info />
        </Tooltip>
      </Grid>
    </Grid>
  );
};
