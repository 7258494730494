import React, { FC, ReactNode } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common';

export type ApplicationLayoutProps = {
  /** Introductory content placed at the top of the document, such
   * the user profile and notifications buttons */
  header: ReactNode;
  /** Main navigation placed to the left-hand side of the {@link mainContent}  */
  navigation: ReactNode;
  /** Main content of the application, positioned underneath the {@link header}
   * and to the right of the {@link navigation}. This could include
   * elements such as the breadcrumbs, horizontal navigation button group (
   * if required), a list view or a form */
  mainContent: ReactNode;
};

/**
 * @param props of type {@link ApplicationLayoutProps},
 * used to pass in the parts of the application. These
 * include the header, navigation and mainContent.
 *
 * @remarks
 * The parts of the application are positioned as follows:
 *
 * ```
 *   +--------------------------------+
 *   |      |      header             |
 *   | nav  |---------------+---------+
 *   | igat |                         |
 *   | ion  |    mainContent          |
 *   +--------------------------------+
 * ```
 */
export const ApplicationLayout: FC<ApplicationLayoutProps> = (props) => {
  const { header, navigation, mainContent } = props;

  const CLASS_NAMES = {
    NAVIGATION: 'navigation',
    HEADER: 'header',
    MAIN: 'main',
    HEADER_MAIN_CONTAINER: 'header-main-container'
  };

  return (
    <ThemableBox type={ThemableBoxType.APPLICATION_LAYOUT}>
      <CssBaseline />

      <Box component="nav" className={CLASS_NAMES.NAVIGATION}>
        {navigation}
      </Box>

      <Box className={CLASS_NAMES.HEADER_MAIN_CONTAINER}>
        <Box component="header" className={CLASS_NAMES.HEADER}>
          {header}
        </Box>
        <Box component="main" className={CLASS_NAMES.MAIN}>
          {mainContent}
        </Box>
      </Box>
    </ThemableBox>
  );
};
