import { useEffect, useRef, useState } from 'react';
import { ICacheStore, useAppContext } from 'src/common';
import { usePaginatedApiWithFixedParams } from 'src/lib';
import {
  Activity,
  ActivityType,
  GetAPI,
  ModuleSchedule,
  ProgrammeSchedule,
  ThemeSchedule
} from 'src/types';
import { BookedBy, Filters } from '../components';
import {
  getActivityPromise,
  getActivityTypePromise,
  getModuleSchedulePromise,
  getProgrammeSchedulePromise,
  getThemeSchedulesPromise
} from '../lib';

export type UsePrepopulatedFiltersProps = {
  getProgrammeSchedules: GetAPI<ProgrammeSchedule>;
  getModuleSchedules: GetAPI<ModuleSchedule>;
  getThemeSchedules: GetAPI<ThemeSchedule>;
  getActivityTypes: GetAPI<ActivityType>;
  getActivities: GetAPI<Activity>;
  /* Used to persist / reload internal state filters
   * state (e.g. so it is not lost when the page is refreshed)
   * under the key `CACHE_STORE_KEY` */
  cacheStore: ICacheStore;
  initialFilterValues?: InitialFilterValues;
  onInitialFilterValuesChanged: (
    initialFilterValues: InitialFilterValues
  ) => void;
};

export type PrepopulatedFilters = {
  filters: Filters;
  setFilters: (filters: Filters) => void;
  isFiltersLoading: boolean;
};

export type InitialFilterValues = {
  programmeScheduleId: number;
  moduleScheduleId?: number;
  themeScheduleId?: number;
  activityTypeId?: number;
  activityId?: number;
};

const CACHE_STORE_KEY = 'filters';

export function useAutoPopulatedFilters({
  getProgrammeSchedules,
  getModuleSchedules,
  getThemeSchedules,
  getActivityTypes,
  getActivities,
  cacheStore,
  initialFilterValues,
  onInitialFilterValuesChanged
}: UsePrepopulatedFiltersProps): PrepopulatedFilters {
  const { handleError } = useAppContext();
  const isInitialFilterValuesLoaded = useRef<boolean>(false);

  const [filters, setFilters] = useState<Filters>(
    cacheStore.get<Filters>(CACHE_STORE_KEY) || {}
  );

  useEffect(() => {
    if (initialFilterValues) {
      isInitialFilterValuesLoaded.current = false;
      setFilters((filtersOld) => ({
        ...filtersOld,
        moduleSchedule: undefined,
        programmeSchedule: undefined,
        isNoThemeSchedulesLinkedToModuleSchedule: false,
        isNoModuleSchedulesLinkedToProgrammeSchedule: false
      }));
    }
  }, [initialFilterValues]);

  useEffect(() => {
    cacheStore.set(CACHE_STORE_KEY, filters);
  }, [cacheStore, filters]);

  const isFiltersLoadingRef = useRef<boolean>(false);
  const [isFiltersLoading, setIsFiltersLoading] = useState<boolean>(false);

  const getMyModuleSchedulesSortedByCalendarYear: GetAPI<ModuleSchedule> =
    usePaginatedApiWithFixedParams({
      getApi: getModuleSchedules,
      order: 'desc',
      orderBy: 'calendarYearId'
    });

  useEffect(() => {
    if (
      (!filters.programmeSchedule ||
        (!filters.moduleSchedule &&
          !filters.isNoModuleSchedulesLinkedToProgrammeSchedule) ||
        (!filters.themeSchedule &&
          !filters.isNoThemeSchedulesLinkedToModuleSchedule)) &&
      !isFiltersLoadingRef.current
    ) {
      isFiltersLoadingRef.current = true;
      setIsFiltersLoading(true);

      //If filters contains a ProgrammeSchedule return ProgrammeSchedule Else attempt loading initial ProgrammeSchedule
      (filters.programmeSchedule
        ? Promise.resolve(filters.programmeSchedule)
        : getProgrammeSchedulePromise(
            { initialFilterValues, isInitialFilterValuesLoaded },
            getProgrammeSchedules
          )
      )
        .then((programmeSchedule?: ProgrammeSchedule) => {
          //If there is no programme Schedule available, there is no need to fetch other filters, as they depend on the programmeSchedule
          if (!programmeSchedule) {
            return Promise.resolve();
          }

          //If filters contains a ModuleSchedule return ProgrammeSchedule Else attept loading initial ModuleSchedule
          return (
            filters.moduleSchedule
              ? Promise.resolve(filters.moduleSchedule)
              : getModuleSchedulePromise(
                  {
                    initialFilterValues,
                    isInitialFilterValuesLoaded
                  },
                  getModuleSchedules,
                  programmeSchedule
                )
          ).then((moduleSchedule?: ModuleSchedule) => {
            //If exists ModuleSchedule return ThemeSchedule Else attept loading initial ThemeSchedule
            return (
              filters.themeSchedule !== undefined
                ? Promise.resolve(filters.themeSchedule)
                : moduleSchedule
                ? getThemeSchedulesPromise(
                    {
                      initialFilterValues,
                      isInitialFilterValuesLoaded
                    },
                    getThemeSchedules,
                    moduleSchedule
                  )
                : Promise.resolve(undefined)
            ).then((themeSchedule?: ThemeSchedule) => {
              //Loading initial ActivityType and Activity

              const activityType = filters.activityType
                ? Promise.resolve(filters.activityType)
                : getActivityTypePromise(
                    {
                      initialFilterValues,
                      isInitialFilterValuesLoaded
                    },
                    getActivityTypes
                  );

              const activity = filters.activity
                ? Promise.resolve(filters.activity)
                : getActivityPromise(
                    {
                      initialFilterValues,
                      isInitialFilterValuesLoaded
                    },
                    getActivities
                  );

              return Promise.all([activityType, activity]).then((values) => {
                if (initialFilterValues) {
                  const { programmeScheduleId, moduleScheduleId } =
                    initialFilterValues;
                  const isInitialFilterValuesChanged =
                    programmeSchedule.id !== programmeScheduleId ||
                    (moduleSchedule && moduleSchedule.id !== moduleScheduleId);

                  if (isInitialFilterValuesChanged) {
                    isInitialFilterValuesLoaded.current = false;
                    onInitialFilterValuesChanged({
                      programmeScheduleId: programmeSchedule.id,
                      moduleScheduleId:
                        moduleSchedule &&
                        moduleSchedule.programmeScheduleIds.some(
                          (id) => id === programmeSchedule.id
                        )
                          ? moduleSchedule.id
                          : undefined
                    });
                  }
                }

                setFilters({
                  searchText: filters.searchText ?? undefined,
                  bookedBy: filters.bookedBy ?? BookedBy.ME,
                  programmeSchedule: programmeSchedule,
                  moduleSchedule: moduleSchedule?.programmeScheduleIds.some(
                    (id) => id === programmeSchedule.id
                  )
                    ? moduleSchedule
                    : undefined,
                  themeSchedule:
                    moduleSchedule?.programmeScheduleIds.some(
                      (id) => id === programmeSchedule.id
                    ) && themeSchedule?.moduleScheduleId === moduleSchedule.id
                      ? themeSchedule
                      : undefined,
                  activityType: values[0],
                  activity: values[1],
                  isNoThemeSchedulesLinkedToModuleSchedule: !themeSchedule,
                  isNoModuleSchedulesLinkedToProgrammeSchedule: !moduleSchedule,
                  isNoProgrammeScheduleAvailable: !programmeSchedule
                });
              });
            });
          });
        })
        .catch(handleError)
        .finally(() => {
          isFiltersLoadingRef.current = false;
          setIsFiltersLoading(false);
        });
    }
  }, [
    filters,
    setFilters,
    getProgrammeSchedules,
    getMyModuleSchedulesSortedByCalendarYear,
    getThemeSchedules,
    getActivityTypes,
    getActivities,
    handleError,
    initialFilterValues,
    onInitialFilterValuesChanged,
    getModuleSchedules
  ]);

  return { filters, setFilters, isFiltersLoading };
}
