import { useCallback, useRef } from 'react';
import {
  getAllFromPaginatedApiV2,
  getPaginatedResponseFromArray
} from 'src/lib';
import { Activity, GetAPI } from 'src/types';
import {
  ActivityTypeBasic,
  getActivityTypesFromActivities
} from '../components';

export type UseCachedActivitiesProps = {
  getActivities: GetAPI<Activity>;
};

export type CachedActivities = {
  getActivityTypes: GetAPI<ActivityTypeBasic>;
  getActivities: (activityType: ActivityTypeBasic) => Activity[];
  clearCacheActivities: () => void;
};

/**
 Wraps getActivities to provide the following enhanced behavior:
 (1) When the first call to getActivityTypes is made, it retrieves and caches all activities
 available in the paginated response (i.e. all pages).
 (2) Then it computes a distinct list of activity types from the list of all activities, and
 caches that too (Subsequent calls to getActivityTypes uses the cached list).
 (3) Provides a function called getActivities to return a list of activities with a specific activity
 type.
 (4) Provides a function called clearCacheActivities to clear all cached values.

 Assumptions:
 (1) getActivityTypes will be called before getActivities, as it is not possible to
 select an activity without selecting an activity type.
 */

export function useCachedActivitiesAndTypes({
  getActivities: getActivitiesBase
}: UseCachedActivitiesProps): CachedActivities {
  const activities = useRef<Activity[] | undefined>();
  const activityTypes = useRef<ActivityTypeBasic[] | undefined>();

  const getActivityTypes: GetAPI<ActivityTypeBasic> = useCallback(
    request => {
      if (activityTypes.current) {
        return Promise.resolve(
          getPaginatedResponseFromArray(activityTypes.current)
        );
      } else {
        return getAllFromPaginatedApiV2({
          getApi: getActivitiesBase,
          filters: request.filters,
          abortController: request.abortController
        }).then(activitiesNew => {
          const activityTypesNew = getActivityTypesFromActivities(
            activitiesNew
          );
          activityTypes.current = activityTypesNew;
          activities.current = activitiesNew;
          return Promise.resolve(
            getPaginatedResponseFromArray(activityTypesNew)
          );
        });
      }
    },
    [getActivitiesBase]
  );

  function getActivities(activityType: ActivityTypeBasic): Activity[] {
    return (activities.current || []).filter(
      activity => activity.activityTypeId === activityType.id
    );
  }

  function clearCacheActivities() {
    activities.current = undefined;
    activityTypes.current = undefined;
  }

  return {
    getActivityTypes,
    getActivities,
    clearCacheActivities
  };
}
