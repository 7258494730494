import React from 'react';
import { Autocomplete, FormLabel, Stack, TextField } from '@mui/material';
import { FileDetails, Tag } from 'src/types';
import { Formik } from 'formik';
import {
  DialogControl,
  FormButtonGroup,
  FormButtonType,
  FormFooterLayout,
  FormFooterPanel
} from 'src/common-v2';
import { MaxWidthConstant, MinMaxWidthLayout, MinWidthConstant } from '../min-max-width-layout/MinMaxWidthLayout';

export type EditFileTagsModalProps = {
  file: FileDetails;
  options: Tag[];
  onSave: (tags: Tag[]) => void;
  onClose: () => void;
};

export const FileTagsEditModal = ({
  file,
  options,
  onSave,
  onClose
}: EditFileTagsModalProps) => {
  return (
    <DialogControl title="Edit File Tags">
      <Formik<Tag[]>
        initialValues={file.tags || []}
        onSubmit={(values) => onSave(values)}
      >
        {({ values, setValues, submitForm, dirty }) => (
           <MinMaxWidthLayout minWidth={MinWidthConstant.MIN_WIDTH_400PX} maxWidth={MaxWidthConstant.MAX_WIDTH_160PX}>
            <Stack padding={4} spacing={2}>
              <FormLabel>File Name</FormLabel>
              <p>{file.name}</p>

              <Autocomplete
                disableListWrap
                multiple
                size="medium"
                value={values}
                options={options}
                getOptionLabel={(option: Tag) => option.tag}
                isOptionEqualToValue={(option: Tag, value: Tag) =>
                  option.id === value.id
                }
                onChange={(_, values) => {
                  if (values) {
                    setValues(values);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Tag(s)" />
                )}
              />

              <FormFooterPanel>
                <FormFooterLayout
                  leftChildren={
                    <FormButtonGroup authorizedUser={undefined}>
                      {() => [
                        {
                          type: FormButtonType.BASE,
                          label: 'Close',
                          variant: 'back',
                          onClick: onClose
                        }
                      ]}
                    </FormButtonGroup>
                  }
                  rightChildren={
                    <FormButtonGroup authorizedUser={undefined}>
                      {() => {
                        if (!dirty) {
                          /* if the user has edit rights and
                              hasn't touched any fields, don't show
                              the save button */
                          return;
                        }

                        return [
                          {
                            type: FormButtonType.BASE,
                            label: 'Save',
                            variant: 'contained',
                            onClick: submitForm
                          }
                        ];
                      }}
                    </FormButtonGroup>
                  }
                />
              </FormFooterPanel>
            </Stack>
          </MinMaxWidthLayout>
        )}
      </Formik>
    </DialogControl>
  );
};
