import { useCallback, useEffect, useMemo, useState } from 'react';

import { GetAPI, PaginatedRequest, PaginatedResponse, Staff } from 'src/types';
import { useAxiosInstance } from 'src/lib';
import { useParams } from 'react-router';

export interface IStaffService {
  getStaff: GetAPI<Staff>;
  updateStaff: (
    staff: Staff,
    abortController?: AbortController
  ) => Promise<Staff>;
  getStaffMember: (
    staffId: number,
    abortController?: AbortController
  ) => Promise<Staff>;
  addStaffMember: (
    usNumber: number,
    abortController?: AbortController
  ) => Promise<void>;
  refreshStaff: (abortController?: AbortController) => Promise<void>;
  FetchStaffByParamId: () => Staff | undefined;
}

export const useStaffService = (): IStaffService => {
  const staffApi = useAxiosInstance('/v2/staff');

  const getStaff = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Staff>> => {
      return staffApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request?.abortController?.signal
        })
        .then(response => {
          const { page, _embedded } = response.data;

          return {
            items: _embedded?.staffDToes || [],
            hasNextPage: page.number < page.totalElements,
            hasPrevPage: page.number > 0,
            pageNumber: page.number,
            totalCount: page.totalElements,
            totalPages: page.totalPages
          } as PaginatedResponse<Staff>;
        });
    },
    [staffApi]
  );

  const getStaffMember = useCallback(
    (staffId: number, abortController?: AbortController): Promise<Staff> => {
      return staffApi
        .get(`/${staffId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then(response => response.data as Staff);
    },
    [staffApi]
  );

  const updateStaff = useCallback(
    (staff: Staff, abortController?: AbortController): Promise<Staff> => {
      return staffApi
        .put(
          `/${staff.id}`,
          {
            ...staff
          },
          {
            withCredentials: true,
            signal: abortController?.signal
          }
        )
        .then(response => response.data as Staff);
    },
    [staffApi]
  );

  const refreshStaff = useCallback(
    (abortController?: AbortController): Promise<void> =>
      staffApi.post('/refresh', undefined, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [staffApi]
  );

  const addStaffMember = useCallback(
    (usNumber: number, abortController?: AbortController): Promise<void> =>
      staffApi.post(`/addStaffMember?usNumber=${usNumber}`, undefined, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [staffApi]
  );

  const FetchStaffByParamId = (): Staff | undefined => {
    const [staff, setStaff] = useState<Staff | undefined>();
    const { staffId: staffIdUnsafe } = useParams();
    const staffId = useMemo<number | undefined>(() => {
      if (staffIdUnsafe && !isNaN(parseInt(staffIdUnsafe))) {
        return parseInt(staffIdUnsafe);
      }
    }, [staffIdUnsafe]);

    useEffect(() => {
      if (staffId) {
        getStaffMember(staffId)
          .then(staffResponse => setStaff(staffResponse))
          .catch(error => {
            throw error;
          });
      }
    }, [staffId]);

    return staff;
  };

  return {
    getStaff,
    getStaffMember,
    updateStaff,
    FetchStaffByParamId,
    refreshStaff,
    addStaffMember
  };
};
