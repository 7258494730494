import { TabItem } from '../common';

/**
 * @param tabItems array of {@link TabItem}.
 * @returns link of the first item in the {@link tabItems}
 * array that has {@link TabItem.disabled} set to {@link false}.
 * @returns {@link undefined} if there is no such array item.
 */
export function getLinkOfFirstEnabledTabItem(
  tabItems: TabItem[]
): string | undefined {
  const initialTab = tabItems.find((tabItem) => !tabItem.disabled);

  if (initialTab) {
    return initialTab.link;
  }
}
