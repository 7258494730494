import { useCallback } from 'react';
import { getDateFromDtoDateString, useAxiosInstance } from 'src/lib';
import { FormComment, FormCommentDTO } from 'src/types';

export type FormCommentCreate = Pick<
  FormComment,
  'formSubmissionId' | 'formComment'
>;

export interface IFormCommentsService {
  createComment: (comment: FormCommentCreate) => Promise<FormComment>;
}

export const useFormCommentsService = (): IFormCommentsService => {
  const formCommentsApi = useAxiosInstance('/v2/formComments');

  const createComment = useCallback(
    (comment: FormCommentCreate): Promise<FormComment> => {
      return formCommentsApi
        .post(
          '',
          {
            ...getDtoFromFormComment(comment)
          },
          {
            withCredentials: true
          }
        )
        .then(response => {
          const formCommentDto = response.data as FormCommentDTO;
          return getFormCommentFromDto(formCommentDto);
        });
    },
    [formCommentsApi]
  );

  return { createComment };
};

function getDtoFromFormComment(
  formCommentCreate: FormCommentCreate
): FormCommentDTO {
  return {
    ...formCommentCreate
  } as FormCommentDTO;
}

function getFormCommentFromDto(dto: FormCommentDTO): FormComment {
  const { dateAdded } = dto;
  return {
    ...dto,
    dateAdded: getDateFromDtoDateString(dateAdded)
  } as FormComment;
}
