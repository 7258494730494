import { Typography } from '@mui/material';
import {
  DATE_TIME_FORMAT,
  getDisplayDateStringFromDate,
  getStringFromWeekday
} from 'src/lib';
import { Weekday } from 'src/types';

type RecurrenceDetailsProps = {
  weekdays: Weekday[];
  startDateTime: Date;
  endDateTime: Date;
};

export function RecurrenceDetails({
  weekdays,
  startDateTime,
  endDateTime
}: RecurrenceDetailsProps) {
  return (
    <>
      <Typography variant="headingFiveForm">Recurrence details</Typography>

      <Typography
        variant="headingSixForm"
        display={'inline-block'}
        whiteSpace={'pre-line'}
      >
        {`${getDisplayDateStringFromDate(
          startDateTime,
          DATE_TIME_FORMAT.TIME_FORMAT
        )} - ${getDisplayDateStringFromDate(
          endDateTime,
          DATE_TIME_FORMAT.TIME_FORMAT
        )}
            Every ${weekdays
              .sort((a: Weekday, b: Weekday) => a.valueOf() - b.valueOf())
              .map((weekday) => getStringFromWeekday(weekday))
              .join(', ')}
            ${getDisplayDateStringFromDate(
              startDateTime,
              DATE_TIME_FORMAT.DATE_FORMAT
            )} - ${getDisplayDateStringFromDate(
          endDateTime,
          DATE_TIME_FORMAT.DATE_FORMAT
        )}`}
      </Typography>
    </>
  );
}
