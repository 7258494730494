import axios from 'axios';
import { useMemo } from 'react';
import { useEnv } from 'src/common';
import { setupInterceptorsTo } from 'src/lib/api/axios-interceptors';

export function useAxiosInstance(basePath?: string) {
  const { apiUrl, ssoUrl } = useEnv();

  const axiosInstance = useMemo(() => {
    const instance = axios.create({
      baseURL: basePath ? `${apiUrl}${basePath}` : apiUrl
    });

    setupInterceptorsTo(instance, ssoUrl, apiUrl);
    return instance;
  }, [apiUrl, ssoUrl, basePath]);

  return axiosInstance;
}
