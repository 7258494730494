import { useCallback } from 'react';
import {
  AutomationRequestProgress,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  ThemeAutomationRequest,
  ThemeAutomationFixedHolidayDTO,
  ThemeAutomationRequestDTO,
  ThemeAutomationFixedHoliday
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';

export interface IThemeAutomationService {
  getThemeAutomationRequests: GetAPI<ThemeAutomationRequest>;
  getThemeAutomationRequest: (
    themeAutomationRequestId: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationRequest>;
  createThemeAutomationRequest: (
    themeAutomationRequest: Partial<ThemeAutomationRequest>,
    abortController?: AbortController
  ) => Promise<void>;
  deleteThemeAutomationRequest: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useThemeAutomationService = (): IThemeAutomationService => {
  const themeAutomationApi = useAxiosInstance('/v2/themeAutomation');

  const getThemeAutomationRequests = useCallback(
    (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<ThemeAutomationRequest>> => {
      return themeAutomationApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<ThemeAutomationRequestDTO>;

          return {
            items: content.map(getThemeAutomationRequestFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ThemeAutomationRequest>;
        });
    },
    [themeAutomationApi]
  );

  const getThemeAutomationRequest = useCallback(
    (themeAutomationRequestId: number, abortController?: AbortController) => {
      return themeAutomationApi
        .get(`${themeAutomationRequestId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const themeAutomationRequestDTO =
            response.data as ThemeAutomationRequestDTO;

          return getThemeAutomationRequestFromDto(themeAutomationRequestDTO);
        });
    },
    [themeAutomationApi]
  );

  const deleteThemeAutomationRequest = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      themeAutomationApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [themeAutomationApi]
  );

  const createThemeAutomationRequest = (
    themeAutomationRequest: Partial<ThemeAutomationRequest>,
    abortController?: AbortController
  ): Promise<void> =>
    themeAutomationApi.post(
      '',
      getDtoFromThemeAutomationRequest(themeAutomationRequest),
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );

  return {
    getThemeAutomationRequests,
    getThemeAutomationRequest,
    deleteThemeAutomationRequest,
    createThemeAutomationRequest
  };

  function getThemeAutomationRequestFromDto(
    themeAutomationRequestDto: ThemeAutomationRequestDTO
  ): ThemeAutomationRequest {
    const {
      startDate,
      endDate,
      createdAt,
      completedAt,
      progress,
      fixedHolidays
    } = themeAutomationRequestDto;

    return {
      ...themeAutomationRequestDto,
      startDate: getDateFromDtoDateString(startDate),
      endDate: getDateFromDtoDateString(endDate),
      createdAt: getDateFromDtoDateString(createdAt),
      completedAt: completedAt
        ? getDateFromDtoDateString(completedAt)
        : undefined,
      progress: getProgressEnumFromProgressString(progress),
      fixedHolidays: fixedHolidays
        ? fixedHolidays.map((fixedHoliday) =>
            getFixedHolidayFromFixedHolidayDTO(fixedHoliday)
          )
        : undefined
    };
  }

  function getDtoFromThemeAutomationRequest(
    themeAutomationRequest: Partial<ThemeAutomationRequest>
  ): Partial<ThemeAutomationRequestDTO> {
    const { startDate, endDate, createdAt, progress, fixedHolidays } =
      themeAutomationRequest;

    return {
      ...themeAutomationRequest,
      startDate: startDate ? getDtoDateStringFromDate(startDate) : undefined,
      endDate: endDate ? getDtoDateStringFromDate(endDate) : undefined,
      createdAt: createdAt ? getDtoDateStringFromDate(createdAt) : createdAt,
      completedAt: undefined,
      fixedHolidays: fixedHolidays
        ? fixedHolidays.map((fixedHoliday) =>
            getFixedHolidayDTOFromFixedHoliday(fixedHoliday)
          )
        : undefined,
      progress:
        progress === undefined
          ? undefined
          : getProgressStringFromProgressEnum(progress)
    };
  }
};

const ThemeAutomationProgress = {
  AWAITING_START: 'AWAITING_START',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IMPORTED: 'IMPORTED'
};

function getProgressStringFromProgressEnum(
  progress: AutomationRequestProgress
) {
  switch (progress) {
    case AutomationRequestProgress.IN_PROGRESS:
      return ThemeAutomationProgress.IN_PROGRESS;
    case AutomationRequestProgress.ERROR:
      return ThemeAutomationProgress.ERROR;
    case AutomationRequestProgress.AWAITING_START:
      return ThemeAutomationProgress.AWAITING_START;
    case AutomationRequestProgress.SUCCESS:
      return ThemeAutomationProgress.SUCCESS;
    case AutomationRequestProgress.IMPORTED:
      return ThemeAutomationProgress.IMPORTED;
  }
}

function getProgressEnumFromProgressString(
  progress: string
): AutomationRequestProgress {
  switch (progress) {
    case ThemeAutomationProgress.IN_PROGRESS:
      return AutomationRequestProgress.IN_PROGRESS;
    case ThemeAutomationProgress.ERROR:
      return AutomationRequestProgress.ERROR;
    case ThemeAutomationProgress.AWAITING_START:
      return AutomationRequestProgress.AWAITING_START;
    case ThemeAutomationProgress.SUCCESS:
      return AutomationRequestProgress.SUCCESS;
    case ThemeAutomationProgress.IMPORTED:
      return AutomationRequestProgress.IMPORTED;
    default:
      throw new Error('unsupported progress type');
  }
}

function getFixedHolidayFromFixedHolidayDTO(
  fixedHoliday: ThemeAutomationFixedHolidayDTO
): ThemeAutomationFixedHoliday {
  const { startDate, endDate } = fixedHoliday;

  return {
    ...fixedHoliday,
    startDate: getDateFromDtoDateString(startDate),
    endDate: getDateFromDtoDateString(endDate)
  };
}

function getFixedHolidayDTOFromFixedHoliday(
  fixedHoliday: ThemeAutomationFixedHoliday
): ThemeAutomationFixedHolidayDTO {
  const { startDate, endDate } = fixedHoliday;

  return {
    ...fixedHoliday,
    startDate: getDtoDateStringFromDate(startDate),
    endDate: getDtoDateStringFromDate(endDate)
  };
}
