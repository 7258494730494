import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

export enum IconType {
  WARNING,
  CHECKBOX_CHECKED,
  CHECKBOX_UNCHECKED
}

export type AppIconProps = {
  type: IconType;
  style: 'silver' | 'red-light';
};

function getIcon(iconType: IconType) {
  switch (iconType) {
    case IconType.CHECKBOX_CHECKED:
      return <CheckBoxIcon />;
    case IconType.CHECKBOX_UNCHECKED:
      return <CheckBoxOutlineBlankIcon />;
    case IconType.WARNING:
      return <WarningIcon />;
  }
}

export const AppIcon = ({ type, style }: AppIconProps) => {
  return (
    <ThemableBox type={ThemableBoxType.APP_ICON} className={style}>
      {getIcon(type)}
    </ThemableBox>
  );
};
