import { TagCategory, TagCategoryId } from 'src/types';

export const TAG_CATEGORIES: TagCategory[] = [
  {
    displayName: 'Accommodation Accreditation',
    code: TagCategoryId.FACILITY_ACCOMMODATION_DOCUMENT
  },
  {
    displayName: 'Amenities',
    code: TagCategoryId.ROOM_AMENITY
  },
  {
    displayName: 'Basic Science',
    code: TagCategoryId.BASIC_SCIENCE
  },
  {
    displayName: 'Document',
    code: TagCategoryId.DOCUMENT
  },
  {
    displayName: 'Enabling Competencies',
    code: TagCategoryId.ENABLING_COMPETENCIES
  },
  {
    displayName: 'Equipment',
    code: TagCategoryId.EQUIPMENT
  },
  {
    displayName: 'Facility Accreditation',
    code: TagCategoryId.FACILITY_ACCREDITATION
  },
  {
    displayName: 'Graduate Attributes',
    code: TagCategoryId.GRADUATE_ATTRIBUTES
  },
  {
    displayName: 'ICD 10',
    code: TagCategoryId.ICD_10
  },
  {
    displayName: 'ICD 11',
    code: TagCategoryId.ICD_11
  },
  {
    displayName: 'ICPC 2',
    code: TagCategoryId.ICPC_2
  },
  {
    displayName: 'Key Competencies',
    code: TagCategoryId.KEY_COMPETENCIES
  },
  {
    displayName: 'Learning Objectives',
    code: TagCategoryId.LEARNING_OBJECTIVES
  },
  {
    displayName: 'MeSH',
    code: TagCategoryId.MESH
  },
  {
    displayName: 'Outcomes',
    code: TagCategoryId.OUTCOMES
  },
  {
    displayName: 'PILLARS',
    code: TagCategoryId.PILLARS
  },
  {
    displayName: 'Supervisor Accreditation',
    code: TagCategoryId.SUPERVISOR_ACCREDITATION
  }
];
