export type PlacementFormSubmissionDTO = {
  activityName: string;
  formName: string;
  themeId: number;
  themeName: string;
  formId: number;
  placementScheduleId: number;
  numStudents: number;
  submissionStates: FormSubmissionStateDTO[];
  activityStartDate: string;
  activityEndDate: string;
  facility: string;
};

type FormSubmissionStateDTO = {
  state: State;
  numSubmissions: number;
};

enum State {
  IN_PROGRESS = 'In Progress',
  AWAITING_APPROVAL = 'Awaiting Approval',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}
