import { useCallback } from 'react';
import {
  Event,
  EventDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';

export interface IEventService {
  getEvents: GetAPI<Event>;
  getEvent: (
    eventId: number,
    abortController?: AbortController
  ) => Promise<Event>;
  createEvent: (
    event: Event,
    abortController?: AbortController
  ) => Promise<void>;
  updateEvent: (
    event: Event,
    abortController?: AbortController
  ) => Promise<void>;
  deleteEvent: (id: number, abortController?: AbortController) => Promise<void>;
}

export const useEventService = (): IEventService => {
  const eventApi = useAxiosInstance('/v2/events');

  const getEvents = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Event>> => {
      return eventApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<EventDTO>;

          return {
            items: content.map(getEventFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Event>;
        });
    },
    [eventApi]
  );

  const getEvent = useCallback(
    (eventId: number, abortController?: AbortController) => {
      return eventApi
        .get(`${eventId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then(response => {
          const eventDto = response.data as EventDTO;

          return getEventFromDto(eventDto);
        });
    },
    [eventApi]
  );

  const deleteEvent = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
        eventApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [eventApi]
  );

  const createEvent = (
    event: Event,
    abortController?: AbortController
  ): Promise<void> =>
      eventApi.post('', getDtoFromEvent(event), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const updateEvent = (
    event: Event,
    abortController?: AbortController
  ): Promise<void> =>
      eventApi.put(
      `/${event.id}`,
        getDtoFromEvent(event),
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );

  return {
    getEvents,
    getEvent,
    deleteEvent,
    createEvent,
    updateEvent
  };

  function getEventFromDto(
    eventDto: EventDTO
  ): Event {
    const { startDate, endDate } = eventDto;

    return {
      ...eventDto,
      startDate: getDateFromDtoDateString(startDate),
      endDate: getDateFromDtoDateString(endDate)
    };
  }

  function getDtoFromEvent(event: Event): EventDTO {
    const { startDate, endDate } = event;

    return {
      ...event,
      startDate: getDtoDateStringFromDate(startDate, true),
      endDate: getDtoDateStringFromDate(endDate, true)
    };
  }
};
