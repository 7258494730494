import { useCallback } from 'react';
import { useEnv } from 'src/common';
import { useAxiosInstance } from 'src/lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  File,
  PaginatedResponseDTO
} from 'src/types';

export interface IFilesService {
  getFiles: GetAPI<File>;
  downloadFileBlob: (
    fileId: number,
    abortController?: AbortController
  ) => Promise<Blob>;
  uploadFileBlob: (
    file: Blob,
    fileName: string,
    abortController?: AbortController
  ) => Promise<File>;
  updateFile: (file: File, abortController?: AbortController) => Promise<void>;

  getFilePreviewImageSource: (fileId: number) => string;
}

export const useFilesService = (): IFilesService => {
  const { apiUrl } = useEnv();

  const filesApi = useAxiosInstance('/v2/files');

  const getFiles = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<File>> => {
      return filesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<File>;
          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<File>;
        });
    },
    [filesApi]
  );

  const downloadFileBlob = useCallback(
    (fileId: number, abortController?: AbortController): Promise<Blob> => {
      return filesApi
        .get(`download/${fileId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined,
          responseType: 'blob'
        })
        .then(response => {
          const type = response.headers['content-type'];
          return new Blob([response.data] as BlobPart[], { type });
        });
    },
    [filesApi]
  );

  const uploadFileBlob = useCallback(
    (
      file: Blob,
      fileName: string,
      abortController?: AbortController
    ): Promise<File> => {
      const formData = new FormData();
      formData.append('file', file, fileName);

      return filesApi
        .post('uploadMultiPart', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then(response => {
          return response.data as File;
        });
    },
    [filesApi]
  );

  const getFilePreviewImageSource = useCallback(
    (fileId: number): string => {
      return `${apiUrl}/v2/files/download/${fileId}?preview=true`;
    },
    [apiUrl]
  );

  const updateFile = useCallback(
    (file: File, abortController?: AbortController): Promise<void> => {
      return filesApi.put(
        `${file.id}`,
        {
          ...file
        },
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [filesApi]
  );

  return {
    getFiles,
    downloadFileBlob,
    uploadFileBlob,
    getFilePreviewImageSource,
    updateFile
  };
};
