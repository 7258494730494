import { FormikErrors } from 'formik';
import { Activity, RoomBookingStatus, RoomBookingType } from 'src/types';
import {
  ActivityTypeBasic,
  BookedBy,
  FormProps,
  FormValues
} from './form-types';

export const getInitialValues = (props: FormProps): FormValues => {
  const { initialFilters, isApproverViewEnabled } = props;

  const DEFAULT_FORM_VALUES_BASE: FormValues = {
    searchText: '',
    programmeSchedule: undefined,
    moduleSchedule: undefined,
    themeSchedule: undefined,
    activityType: undefined,
    activity: undefined,
    status: undefined
  };

  const DEFAULT_FORM_VALUES_APPROVER_VIEW: FormValues = {
    ...DEFAULT_FORM_VALUES_BASE,
    ...initialFilters,
    status: initialFilters?.status ?? RoomBookingStatus.AWAITING_APPROVAL
  };

  const DEFAULT_FORM_VALUES_NON_APPROVER_VIEW: FormValues = {
    ...DEFAULT_FORM_VALUES_BASE,
    ...initialFilters,
    bookedBy: initialFilters?.bookedBy ?? BookedBy.ME
  };

  const DEFAULT_FORM_VALUES = isApproverViewEnabled
    ? DEFAULT_FORM_VALUES_APPROVER_VIEW
    : DEFAULT_FORM_VALUES_NON_APPROVER_VIEW;

  return DEFAULT_FORM_VALUES;
};

export const getOnSubmit = (
  props: FormProps
): ((values: FormValues) => void) => {
  return (values) => {
    if (Object.keys(values).length > 0) {
      return props.onFiltersChanged(values);
    }
  };
};

export const getValidate =
  ({ roomBookingType, isApproverViewEnabled }: FormProps) =>
  (values: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    const {
      moduleSchedule,
      programmeSchedule,
      isNoModuleSchedulesLinkedToProgrammeSchedule,
      isNoThemeSchedulesLinkedToModuleSchedule
    } = values;

    switch (roomBookingType) {
      case RoomBookingType.ACADEMIC: {
        if (!isApproverViewEnabled && !programmeSchedule) {
            errors.programmeSchedule = `No Programmes scheduled for ${new Date().getFullYear()} or the user is not linked to the programmes`;
        }
      }
    }

    if (programmeSchedule && isNoModuleSchedulesLinkedToProgrammeSchedule) {
      errors.moduleSchedule = `No Modules scheduled for selected programme for ${programmeSchedule.calendarYear.calendarYear} or the user is not linked to the modules`;
    }

    if (moduleSchedule && isNoThemeSchedulesLinkedToModuleSchedule) {
      errors.themeSchedule = 'The selected module is not linked to any themes';
    }

    return errors;
  };
export const getActivityTypesFromActivities = (
  activities: Activity[]
): ActivityTypeBasic[] =>
  Array.from(
    activities
      .reduce<Map<number, ActivityTypeBasic>>(
        (activityTypesResult, activity) => {
          if (!activityTypesResult.has(activity.activityTypeId)) {
            activityTypesResult.set(activity.activityTypeId, {
              id: activity.activityTypeId,
              type: activity.activityType
            });
          }
          return activityTypesResult;
        },
        new Map()
      )
      .entries()
  ).map(([_, activityType]) => activityType).sort((a,b)=>a.type.localeCompare(b.type));

export const getFacilityFieldLabel = (roomBookingType: RoomBookingType) => {
  switch (roomBookingType) {
    case RoomBookingType.TRANSPORT:
      return 'Transport Mode';

    case RoomBookingType.ACCOMMODATION:
    case RoomBookingType.ADHOC:
    case RoomBookingType.ACADEMIC:
      return 'Facility';
  }
};

export const getTrainingAreaFieldLabel = (roomBookingType: RoomBookingType) => {
  switch (roomBookingType) {
    case RoomBookingType.TRANSPORT:
      return 'Seat Number';

    case RoomBookingType.ACCOMMODATION:
      return 'Accommodation Sites';

    case RoomBookingType.ADHOC:
      return 'Facility Area';

    case RoomBookingType.ACADEMIC:
      return 'Training Area';
  }
};
