export enum TagCategoryId {
  OUTCOMES = 'Outcomes',
  ICPC_2 = 'ICPC-2',
  ICD_10 = 'ICD-10',
  ICD_11 = 'ICD-11',
  GRADUATE_ATTRIBUTES = 'GraduateAttributes',
  KEY_COMPETENCIES = 'KeyCompetencies',
  ENABLING_COMPETENCIES = 'EnablingCompetencies',
  PILLARS = 'PILLARS',
  BASIC_SCIENCE = 'BasicScience',
  MESH = 'MeSH',
  EQUIPMENT = 'Equipment',
  ROOM_AMENITY = 'Amenities',
  FACILITY_ACCREDITATION = 'FACILITY_ACCREDITATION_FILE',
  FACILITY_ACCOMMODATION_DOCUMENT = 'ACCOMMODATION_DOCUMENT_FILE',
  SUPERVISOR_ACCREDITATION = 'SUPERVISOR_ACCREDITATION_FILE',
  DOCUMENT = 'DOCUMENT',
  LEARNING_OBJECTIVES = 'LearningObjectives'
}
