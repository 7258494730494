import { useCallback } from 'react';
import {
  GetAPI,
  getPaginatedResponse,
  PaginatedRequest,
  PaginatedResponse,
  Permission,
  PermissionDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IPermissionService {
  getPermissions: GetAPI<Permission>;
}

export const usePermissionService = (): IPermissionService => {
  const permissionApi = useAxiosInstance('/v2/permissions');

  const getPermissions = useCallback(
    (
      request: PaginatedRequest,
      abortController?: AbortController
    ): Promise<PaginatedResponse<Permission>> => {
      return permissionApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) =>
          getPaginatedResponse<PermissionDTO, Permission>(
            response,
            getPermissionFromDTO
          )
        )
        .catch((error) => {
          throw error;
        });
    },
    [permissionApi]
  );

  return {
    getPermissions
  };
};

function getPermissionFromDTO(dto: PermissionDTO): Permission {
  return {
    ...dto,
    permissionGroup: {
      id: dto.permissionGroupDTO.id,
      permissionGroup: dto.permissionGroupDTO.permissionGroup
    }
  } as Permission;
}
