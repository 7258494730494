import { FormControl, StandardTextFieldProps, TextField } from '@mui/material';
import { useField } from 'formik';
import moment from 'moment';
import { DATE_TIME_FORMAT, isValidDate } from 'src/lib';

export const TimeInputField = (props: StandardTextFieldProps): JSX.Element => {
  const { name, label, value, ...otherProps } = props;

  if (!name) {
    throw new Error('value name field expected');
  }

  const [field, , helpers] = useField(name);

  return (
    <FormControl fullWidth>
      <TextField
        {...otherProps}
        name={name}
        value={moment(field.value as Date).format(DATE_TIME_FORMAT.TIME_FORMAT)}
        label={label}
        type="time"
        onChange={(event: { target: { value: string | undefined } }) => {
          const rawValueNew = event.target.value;
          const newTime = moment(rawValueNew, DATE_TIME_FORMAT.TIME_FORMAT);
          const currentDate = field.value as Date;
          const currentValue = moment(
            isValidDate(field.value as Date) ? currentDate : new Date()
          )
            .hours(newTime.hours())
            .minutes(newTime.minutes());
          helpers.setValue(currentValue.toDate());
        }}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
};
