import React from 'react';
import { PermissionId, ThemableBoxType } from 'src/types';
import { PageTitle, ThemableBox, useAuthorizedUser } from 'src/common';
import { NotificationsModule } from 'src/modules/notifications';
import { MyModulesContainer } from 'src/modules/my-modules/containers';
import { UserProfileContainer } from '../components/UserProfileContainer';
import { Box, Link, Stack, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { PageHeadingConfig, usePageHeadingInfo } from '../common';

const { ViewMyModules } = PermissionId;

const CLASS_NAME_MY_MODULES = 'class-my-modules-box';
const CLASS_NAME_TITLE = 'class-header-box';
const CLASS_NAME_PROFILE_BUTTON = 'class-profile-button-box';

export const NavigationHeaderContainer = () => {
  const { isAllowedTo } = useAuthorizedUser();
  const { pathname } = useLocation();

  const pageHeaderInfo = usePageHeadingInfo({
    pageHeaderConfig: PageHeadingConfig,
    urlPath: pathname
  });

  return (
    <ThemableBox type={ThemableBoxType.HEADER_CONTAINER}>
      <Stack direction="row" display="flex" spacing={1}>
        {pageHeaderInfo && (
          <Box className={CLASS_NAME_TITLE}>
            <PageTitle
              label={pageHeaderInfo.title}
              tooltipContents={
                <Stack direction="column">
                  <Typography> {pageHeaderInfo.tooltipText} </Typography>
                  <Link href={pageHeaderInfo.linkUrl} target="_blank">
                    <br /> <u>More Info</u>
                  </Link>
                </Stack>
              }
            />
          </Box>
        )}

        {isAllowedTo(ViewMyModules) && (
          <Box className={CLASS_NAME_MY_MODULES}>
            <MyModulesContainer />
          </Box>
        )}
        <Box className={CLASS_NAME_PROFILE_BUTTON}>
          <UserProfileContainer />
        </Box>
        <NotificationsModule />
      </Stack>
    </ThemableBox>
  );
};
