export type PlacementAutomationRequestDTO = {
  id: number;
  createdAt: string;
  progress: string;
  completedAt?: string;
  themeScheduleId: number;
  description: string;
  activityId: number;
  themeScheduleCalendarYear: string;
  themeScheduleDisplayName: string;
  activityDescription: string;
  calendarYear?: number;
};

export const PlacementAutomationDTO = {
  AWAITING_START: 'AWAITING_START',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IMPORTED: 'IMPORTED'
};
