import { useEffect, useMemo, useState } from 'react';
import { DateRange, DateRangeType, Event, GetAPI } from 'src/types';
import {
  addTimeToDate,
  getAllFromPaginatedApiV2,
  getDtoDateStringFromDate
} from 'src/lib';
import { useAppContext } from '../app-context-provider/AppContext';

export type UseEventsInDateRangeProps = {
  viewDateRange: DateRange;
  getEvents: GetAPI<Event>;
};
export interface IEventDates {
  events: Event[];
  isEventsLoading: boolean;
}

const VIEW_DATE_RANGE_CACHE_PADDING_YEARS = 1;

/**
 * This hook returns events within a date range
 *
 * @param viewDateRange the from and to date/time
 * @param getEvents function used to fetch events
 *
 * @remarks
 * Internally, this hook fetches a wider range of dates than necessary to
 * reduce the number of calls to {@link getEvents}. It adds extra time left and right
 * of the {@link viewDateRange}, using the number of years defined by
 * {@link VIEW_DATE_RANGE_CACHE_PADDING_YEARS}. Another call
 * to {@link getEvents} is only made when the {@link viewDateRange}
 * falls outside of this wider range.
 *
 * @returns events that fall within the {@link viewDateRange}
 *
 */
export const useEventsInDateRange = ({
  viewDateRange,
  getEvents
}: UseEventsInDateRangeProps): IEventDates => {
  const { handleError } = useAppContext();
  const [events, setEvents] = useState<Event[] | undefined>();
  const [eventsDateRange, setEventsDateRange] = useState<DateRange>(
    getCacheDateRange(viewDateRange)
  );
  const [isEventsLoading, setIsEventsLoading] = useState<boolean>(false);

  useEffect(() => {
    const isShouldUpdateEventsDateRange =
      getIsViewDateRangeOutsideOfEventsDateRange(
        viewDateRange,
        eventsDateRange
      );

    if (isShouldUpdateEventsDateRange) {
      setEventsDateRange(getCacheDateRange(viewDateRange));
    }
  }, [eventsDateRange, viewDateRange]);

  const eventsWithinViewDateRange = useMemo(
    () => (events ? getEventsWithinViewRange(events, viewDateRange) : []),
    [viewDateRange, events]
  );

  useEffect(() => {
    const abortController = new AbortController();
    setIsEventsLoading(true);
    getAllFromPaginatedApiV2({
      getApi: getEvents,
      abortController: abortController,
      filters: {
        startDate: getDtoDateStringFromDate(eventsDateRange.fromDate),
        endDate: getDtoDateStringFromDate(eventsDateRange.toDate)
      }
    })
      .then((events) => {
        setEvents(events);
      })
      .catch(handleError)
      .finally(() => {
        setIsEventsLoading(false);
      });

    return () => {
      abortController.abort();
    };
  }, [eventsDateRange, getEvents, handleError]);

  return {
    events: eventsWithinViewDateRange,
    isEventsLoading: isEventsLoading
  };
};

function getIsViewDateRangeOutsideOfEventsDateRange(
  viewDateRange: DateRange,
  eventsDateRange: DateRange
): boolean {
  return (
    viewDateRange.fromDate < eventsDateRange.fromDate ||
    viewDateRange.toDate > eventsDateRange.toDate
  );
}

function getCacheDateRange({ fromDate, toDate }: DateRange): DateRange {
  return {
    fromDate: addTimeToDate(
      fromDate,
      -1 * VIEW_DATE_RANGE_CACHE_PADDING_YEARS,
      DateRangeType.YEAR
    ),
    toDate: addTimeToDate(
      toDate,
      VIEW_DATE_RANGE_CACHE_PADDING_YEARS,
      DateRangeType.YEAR
    )
  };
}

function getEventsWithinViewRange(
  events: Event[],
  dateRange: DateRange
): Event[] {
  const filteredEvents = events.filter((yearEvent) => {
    return (
      yearEvent.startDate >= dateRange.fromDate &&
      yearEvent.endDate <= dateRange.toDate
    );
  });

  return filteredEvents;
}
