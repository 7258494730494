import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

export type FormikValuesListenerProps<T> = {
  children: (newValues: T, oldValues: T) => void;
};

export function FormikValuesListener<T>({
  children: onFormValuesChanged
}: FormikValuesListenerProps<T>) {
  const { values } = useFormikContext<T>();

  const oldValues = useRef<T | undefined>();

  useEffect(() => {
    if (oldValues.current) {
      onFormValuesChanged(values, oldValues.current);
    }

    oldValues.current = values;
  }, [values, onFormValuesChanged]);

  return <></>;
}
