import { useCallback, ReactNode, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Stack } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import BlockIcon from '@mui/icons-material/Block';
import { useFormikContext } from 'formik';
import { FormValidationFailedError, getTitleCase } from 'src/lib';
import { PermissionId } from 'src/types';
import { AuthorizedUser, useResponseHandler } from 'src/common';
import { useButtonDisabledDebounce } from 'src/common-v2';

export enum FormButtonType {
  BASE,
  NAVIGATE_TO_VIEW_SCREEN,
  NAVIGATE_TO_EDIT_SCREEN,
  NAVIGATE_TO_CREATE_SCREEN,
  DELETE_EXISTING_ENTITY,
  SAVE_CHANGES_TO_EXISTING_ENTITY,
  SAVE_NEW_ENTITY,
  NAVIGATE_BACK,
  RESET,
  DOWNLOAD,
  CANCEL
}

type FormButtonBaseProps = {
  type: FormButtonType;
  label?: string;
  disabled?: boolean;
  isHidden?: boolean;
  requiredPermissions?: PermissionId[];
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  variant?:
    | 'text'
    | 'delete'
    | 'outlined'
    | 'contained'
    | 'edit'
    | 'back'
    | 'approve'
    | 'bookingsApprove'
    | 'bookingsReject';
};

type FormButtonBase = {
  type: FormButtonType.BASE;
  label: string;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonBack = {
  type: FormButtonType.NAVIGATE_BACK;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonView = {
  type: FormButtonType.NAVIGATE_TO_VIEW_SCREEN;
  label: string;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonEdit = {
  type: FormButtonType.NAVIGATE_TO_EDIT_SCREEN;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonCreate = {
  type: FormButtonType.NAVIGATE_TO_CREATE_SCREEN;
  onClick?: () => void;
} & FormButtonBaseProps;

type FormButtonDelete = {
  type: FormButtonType.DELETE_EXISTING_ENTITY;
  entityName: string; // eslint-disable-next-line
  serviceCall: () => Promise<any>;
  onSuccessCallback?: () => void;
} & FormButtonBaseProps;

type FormButtonSaveExisting = {
  type: FormButtonType.SAVE_CHANGES_TO_EXISTING_ENTITY;
  entityName: string;
  onClick?: () => void;
  onSuccessCallback?: () => void;
  getSuccessMessageTitle?: (entityName: string) => string;
  getFailureMessageTitle?: (entityName: string) => string;
} & FormButtonBaseProps;

type FormButtonSaveNew = {
  type: FormButtonType.SAVE_NEW_ENTITY;
  entityName: string;
  onClick?: () => void;
  onSuccessCallback?: () => void;
  getSuccessMessageTitle?: (entityName: string) => string;
  getFailureMessageTitle?: (entityName: string) => string;
  isResponseHandlingDisabled?: boolean;
} & FormButtonBaseProps;

type FormButtonDownload = {
  type: FormButtonType.DOWNLOAD;
  entityName: string;
} & FormButtonBaseProps;

type FormButtonReset = {
  type: FormButtonType.RESET;
} & FormButtonBaseProps;

type FormButtonCancel = {
  type: FormButtonType.CANCEL;
  entityName: string;
  onClick?: () => void;
} & FormButtonBaseProps;

export type FormButton =
  | FormButtonBase
  | FormButtonView
  | FormButtonEdit
  | FormButtonBack
  | FormButtonDelete
  | FormButtonCreate
  | FormButtonSaveExisting
  | FormButtonSaveNew
  | FormButtonReset
  | FormButtonDownload
  | FormButtonCancel;

type FormActionButtonsProps = {
  buttons: Array<FormButton>;
  authorizedUser?: AuthorizedUser;
};

export const FormActionButtons = ({
  buttons,
  authorizedUser
}: FormActionButtonsProps) => {
  const { handleServiceCall } = useResponseHandler();
  const navigate = useNavigate();
  const [isDownloadButtonDebouncing, setIsDownloadButtonDebouncing] =
    useState(false);
  const { buttonDisabledDebounce } = useButtonDisabledDebounce();

  const {
    values,
    submitForm: submitFormFormik,
    validateForm,
    setSubmitting
  } = useFormikContext();

  const submitForm = useCallback(() => {
    setSubmitting(true);
    return validateForm(values)
      .then((errors) => {
        if (Object.keys(errors).length === 0) {
          return submitFormFormik();
        }

        throw new FormValidationFailedError();
      })
      .then(() => {
        setSubmitting(false);
      });
  }, [values, validateForm, submitFormFormik, setSubmitting]);

  const handleDeleteButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string,
    onSuccessCallback?: () => void
  ) =>
    handleServiceCall({
      serviceCall: serviceCall,
      failureProps: { title: `Failed to delete ${entityName}` },
      successProps: {
        description: '',
        title: `${entityName} deleted`,
        onSuccessCallback: () =>
          onSuccessCallback ? onSuccessCallback() : navigate(-1)
      },
      confirmProps: {
        title: `Delete ${entityName}`,
        description: `Are you sure you want to delete this ${entityName}?`,
        confirmButtonLabel: 'Delete',
        cancelButtonLabel: 'Cancel'
      }
    });

  const handleSaveExistingButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string,
    onSuccessCallback?: () => void,
    getSuccessMessageTitle?: (entityName: string) => string,
    getFailureMessageTitle?: (entityName: string) => string
  ) => {
    handleServiceCall({
      serviceCall: serviceCall,
      failureProps: {
        title: `Failed to update ${entityName}`
      },
      successProps: {
        description: `The ${entityName} was updated with the changes you specified`,
        title: `${entityName} updated`,
        onSuccessCallback: () =>
          onSuccessCallback ? onSuccessCallback() : navigate(-1)
      }
    });
  };

  /**
   * @param isResponseHandlingDisabled optional parameter, when set
   * to {@link true}, the response handling like toasts and default
   * navigation to the previous screen are skipped. Defaults to
   * {@link false}.
   * */
  const handleSaveNewButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string,
    getSuccessMessageTitle?: (entityName: string) => string,
    getFailureMessageTitle?: (entityName: string) => string,
    onSuccessCallback?: () => void,
    isResponseHandlingDisabled?: boolean
  ) => {
    if (isResponseHandlingDisabled) {
      handleServiceCall({ serviceCall: serviceCall });
      return;
    }

    handleServiceCall({
      serviceCall: serviceCall,
      failureProps: {
        title: getFailureMessageTitle
          ? getFailureMessageTitle(entityName)
          : `Failed to create ${entityName}`
      },
      successProps: {
        description: `The ${entityName} was create with the parameters you specified`,
        title: getSuccessMessageTitle
          ? getSuccessMessageTitle(entityName)
          : `${entityName} created`,
        onSuccessCallback: () =>
          onSuccessCallback ? onSuccessCallback() : navigate(-1)
      }
    });
  };

  const handleDownloadButtonClick = (
    // eslint-disable-next-line
    serviceCall: () => Promise<any>,
    entityName: string
  ) => {
    buttonDisabledDebounce(setIsDownloadButtonDebouncing);
    handleServiceCall({
      serviceCall: serviceCall,
      successProps: {
        title: `${entityName} download starting...`,
        description: `${entityName} download starting...`
      },
      failureProps: {
        title: `Failed to download ${entityName}`
      }
    });
  };

  const handleBackButtonClick = () => navigate(-1);

  const isUserNotPermittedToClickOnButton = (
    permissionIds?: PermissionId[]
  ): boolean => {
    return (
      !!authorizedUser &&
      !!permissionIds &&
      permissionIds.every((id) => authorizedUser.isForbiddenTo(id))
    );
  };

  return (
    <Stack spacing={1} direction="row" justifyContent="flex-end">
      {buttons
        .filter((button) => !button.isHidden)
        .map((buttonProps, index) => {
          const { type, label, disabled, endIcon, startIcon, variant } =
            buttonProps;

          switch (type) {
            case FormButtonType.DELETE_EXISTING_ENTITY:
              return (
                <Button
                  key={index}
                  disabled={
                    disabled ||
                    isUserNotPermittedToClickOnButton(
                      buttonProps.requiredPermissions
                    )
                  }
                  onClick={() =>
                    handleDeleteButtonClick(
                      buttonProps.serviceCall,
                      getTitleCase(buttonProps.entityName),
                      buttonProps.onSuccessCallback
                    )
                  }
                  endIcon={endIcon || <CloseIcon />}
                  variant={variant || 'delete'}
                >
                  {label || 'Delete'}
                </Button>
              );
            case FormButtonType.NAVIGATE_TO_VIEW_SCREEN:
              return (
                <Button
                  key={index}
                  disabled={
                    disabled ||
                    isUserNotPermittedToClickOnButton(
                      buttonProps.requiredPermissions
                    )
                  }
                  onClick={buttonProps.onClick}
                  endIcon={endIcon}
                  variant={variant || 'outlined'}
                >
                  {buttonProps.label}
                </Button>
              );
            case FormButtonType.NAVIGATE_TO_EDIT_SCREEN:
              return (
                <Button
                  key={index}
                  disabled={
                    disabled ||
                    isUserNotPermittedToClickOnButton(
                      buttonProps.requiredPermissions
                    )
                  }
                  onClick={buttonProps.onClick}
                  endIcon={endIcon || <EditIcon />}
                  variant={variant || 'edit'}
                >
                  Edit
                </Button>
              );
            case FormButtonType.NAVIGATE_TO_CREATE_SCREEN:
              return (
                <Button
                  key={index}
                  disabled={
                    disabled ||
                    isUserNotPermittedToClickOnButton(
                      buttonProps.requiredPermissions
                    )
                  }
                  onClick={buttonProps.onClick}
                  endIcon={endIcon}
                  variant={variant || 'contained'}
                >
                  {label || 'Create'}
                </Button>
              );
            case FormButtonType.NAVIGATE_BACK:
              return (
                <Button
                  key={index}
                  disabled={disabled}
                  onClick={buttonProps.onClick || handleBackButtonClick}
                  endIcon={endIcon}
                  variant={variant || 'back'}
                  type="reset"
                >
                  {label || 'Back'}
                </Button>
              );
            case FormButtonType.SAVE_CHANGES_TO_EXISTING_ENTITY:
              return (
                <Button
                  key={index}
                  disabled={disabled}
                  onClick={() => {
                    buttonProps.onClick && buttonProps.onClick();
                    handleSaveExistingButtonClick(
                      submitForm,
                      getTitleCase(buttonProps.entityName),
                      buttonProps.onSuccessCallback
                    );
                  }}
                  endIcon={endIcon}
                  startIcon={startIcon}
                  color="primary"
                  variant={variant || 'contained'}
                >
                  {label || 'Save'}
                </Button>
              );
            case FormButtonType.SAVE_NEW_ENTITY:
              return (
                <Button
                  key={index}
                  disabled={disabled}
                  onClick={() => {
                    buttonProps.onClick && buttonProps.onClick();
                    handleSaveNewButtonClick(
                      submitForm,
                      getTitleCase(buttonProps.entityName),
                      buttonProps.getSuccessMessageTitle,
                      buttonProps.getFailureMessageTitle,
                      buttonProps.onSuccessCallback,
                      !!buttonProps.isResponseHandlingDisabled
                    );
                  }}
                  endIcon={endIcon}
                  color="primary"
                  variant={variant || 'contained'}
                >
                  {label || 'Create'}
                </Button>
              );

            case FormButtonType.RESET:
              return (
                <Button
                  key={index}
                  type="reset"
                  disabled={disabled}
                  endIcon={endIcon}
                  variant={variant || 'back'}
                >
                  {label || 'Reset'}
                </Button>
              );

            case FormButtonType.DOWNLOAD:
              return (
                <Button
                  key={index}
                  disabled={disabled || isDownloadButtonDebouncing}
                  onClick={() =>
                    handleDownloadButtonClick(
                      submitForm,
                      buttonProps.entityName
                    )
                  }
                  endIcon={endIcon}
                  color="primary"
                  variant={variant || 'outlined'}
                >
                  {label || 'Download'}
                </Button>
              );

            case FormButtonType.CANCEL:
              return (
                <Button
                  key={index}
                  disabled={
                    disabled ||
                    isUserNotPermittedToClickOnButton(
                      buttonProps.requiredPermissions
                    )
                  }
                  onClick={buttonProps.onClick}
                  endIcon={endIcon || <BlockIcon />}
                  variant={variant || 'warning'}
                >
                  {label || 'Cancel'}
                </Button>
              );

            case FormButtonType.BASE:
              return (
                <Button
                  key={index}
                  disabled={
                    disabled ||
                    isUserNotPermittedToClickOnButton(
                      buttonProps.requiredPermissions
                    )
                  }
                  onClick={buttonProps.onClick}
                  endIcon={endIcon}
                  color="primary"
                  variant={variant}
                >
                  {label}
                </Button>
              );
            default: {
              throw new Error('unknown form button type');
            }
          }
        })}
    </Stack>
  );
};
