import { Button, Grid } from '@mui/material';
import React from 'react';

type ButtonProps = {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
};

type ComponentProps = {
  buttonsProps: ButtonProps[];
};

export const BlankViewWithActionButtons = ({
  buttonsProps
}: ComponentProps) => {
  return (
    <Grid container direction="column" spacing={5}>
      <Grid
        container
        item
        spacing={2}
        direction="row"
        display="flex"
        justifyContent="right"
        alignItems="right"
      >
        {buttonsProps.map(buttonProps => (
          <Grid item>
            <Button
              disabled={!!buttonProps.isDisabled}
              onClick={buttonProps.onClick}
              variant="contained"
            >
              {buttonProps.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
