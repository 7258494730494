import {
  alpha,
  CSSInterpolation,
  CSSObject,
  ThemeOptions
} from '@mui/material';
import { ThemableBoxType } from 'src/types';
import Logo from 'src/assets/images/logo-true-vector.svg';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    headingFourForm: React.CSSProperties;
    headingFiveForm: React.CSSProperties;
    headingFiveRegular: React.CSSProperties;
    headingSixForm: React.CSSProperties;
    groupHeadingText: React.CSSProperties;
    headingPageTitle: React.CSSProperties;
    bookingConflictsWarningText: React.CSSProperties;
    userProfileIconText: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    headingFourForm?: React.CSSProperties;
    headingFiveForm?: React.CSSProperties;
    headingFiveRegular?: React.CSSProperties;
    headingSixForm?: React.CSSProperties;
    groupHeadingText?: React.CSSProperties;
    headingPageTitle?: React.CSSProperties;
    bookingConflictsWarningText: React.CSSProperties;
    userProfileIconText?: React.CSSProperties;
  }

  interface ButtonVariants {
    bookingsApprove: React.CSSProperties;
    bookingsReject: React.CSSProperties;
  }

  interface ButtonVariantsOptions {
    bookingsApprove?: React.CSSProperties;
    bookingsReject?: React.CSSProperties;
  }

  interface FormHelperTextVariants {
    helperTextWarning: React.CSSProperties;
  }

  interface FormHelperTextOptions {
    helperTextWarning?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    headingFourForm: true;
    headingFiveForm: true;
    headingFiveRegular: true;
    headingSixForm: true;
    groupHeadingText: true;
    headingPageTitle: true;
    bookingConflictsWarningText: true;
    userProfileIconText: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    delete: true;
    edit: true;
    back: true;
    approve: true;
    close: true;
    warning: true;
    bookingsApprove: true;
    bookingsReject: true;
  }
}

declare module '@mui/material/FormHelperText' {
  interface FormHelperTextPropsVariantOverrides {
    helperTextWarning: true;
  }
}
export const COLOR = {
  MAROON_PRIMARY: '#60223a',
  MAROON_10: '#EFE9EB',
  MAROON_20: '#DFD3D8',
  PALE_CHERRY_BLOSSOM: '#FDEDED',
  MAROON_SECONDARY: '#4E182D',
  GOLD_PRIMARY: '#B79862',
  GOLD_PRIMARY_WITH_33_ALPHA: 'rgba(231, 221, 203)',
  MAROON_DARK: '#60223b',
  MAROON_LIGHT: '#906476',
  BROWN_LIGHT: '#ececec',
  WHITE: '#ffffff',
  WHITE_60_ALPHA: '#FFFFFF99',
  GREY_LIGHT_ADDITIONAL: '#d7d7d7',
  GREY_DOVE: '#666666',
  GREY_DORADO: '#5f686e',
  GREY_LIGHT: '#7b7b7b',
  GREY_3: '#f9f9fa',
  GREY_59: 'rgba(0, 0, 0, 0.42)',
  GREY_60: '#878e92',
  GREY_80: '#5f686e',
  GREY_100: '#37424a',
  GREY_300: '#e0e0e0',
  GREY_DARK: '#adacac',
  GREY_TOOLTIP: '#616161',
  GREY_DOLPHIN: '#6e6b7b',
  GREY_LIGHT_100: '#F3F3F3',
  RAG_PILL_GREY: '#5F686D',
  BLACK: '#000000',
  GREY_DISABLED_BACKGROUND: 'rgba(0, 0, 0, 0.12);',
  GREY_DISABLED_TEXT: 'rgba(0, 0, 0, 0.26);',
  GREEN_DARK: '#013220',
  GREEN_MEDIUM: '#036C13',
  RAG_PILL_GREEN: '#2E7D32',
  RAG_PILL_AMBER: '#EF6C00',
  GREEN_APPROVE: '#399F36',
  ORANGE_WARNING: '#ff9800',
  RED_LIGHT: '#f44336',
  TORCH_RED: '#ff004c',
  RAG_PILL_RED: '#D32F2F',
  RED_OXIDE: '#5F2120',
  DANGEROUSLY_RED: '#D84335',
  RED_REJECT: '#D32F2F',
  SILVER: '#d4d4d4',
  METALLIC_SILVER: '#a9aeb3',
  FAUX_GUYABANO: '#faf8f9',
  WHITE_SOLITUDE: '#ebeced',
  RED_LIGHT_CHOCOLATE_COSMOS: '#66213b',
  WHITE_SMOKE: '#f5f5f5',
  RANUNCULUS_WHITE: '#f7e3e8',
  SHRINKING_VIOLET: '#f4e1e6',
  GAINSBORO: '#d9d9d9',
  LIGHT_RED: '#FFEEEE',
  MONOCHROMATIC: '#b9b9c3',
  RED: '#ff0000',
  IRIS_BLUE: '#cf2100',
  GHOST_WHITE: '#cf2100',
  LOUD_LIME: '#83ff24',
  MANGO_MADNESS: '#ff8823',
  GREY: '#808080',
  SNARKY_MINT: '#8FDF82',
  HELPER_TEXT_WARNING_ORANGE: '#FA9948',
  ROYAL_BLUE: '#4169e1'
};

const drawerGradientStyle: CSSObject = {
  overflowX: 'hidden',
  background: 'linear-gradient(180deg, #60223A 0%, #821C43 100%);',
  color: 'red'
};

function getBaseLineCss(
  boxStyle: {
    type: ThemableBoxType;
    style: CSSInterpolation;
  }[]
) {
  const themableBoxCss = boxStyle.map(({ type, style }) => {
    return {
      [`& .${String(type)}`]: style
    };
  });

  const globalCss = {
    input: {
      cursor: 'inherit' // user-agent css takes precedence otherwise
    },
    'html, body': {
      height: '100%',
      margin: 0
    }
  };

  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: themableBoxCss.concat(globalCss)
      }
    }
  };
}

export const stylesOverride: ThemeOptions = {
  typography: {
    fontFamily: 'Roboto',
    button: {
      textTransform: 'none'
    },
    headingFourForm: {
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '19px',
      color: COLOR.MAROON_PRIMARY
    },
    headingFiveRegular: {
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '16px'
    },
    headingFiveForm: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '16px',
      color: COLOR.MAROON_PRIMARY
    },
    headingSixForm: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '14px',
      color: COLOR.MAROON_PRIMARY
    },
    bookingConflictsWarningText: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '14px',
      color: COLOR.RED_LIGHT
    },
    groupHeadingText: {
      fontSize: '16px',
      fontWeight: '600'
    },
    userProfileIconText: {
      display: 'flex',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '14px',
      textAlign: 'center',
      alignItems: 'center',
      justifyContent: 'center',
      height: '24px',
      width: '36px',
      color: COLOR.MAROON_PRIMARY
    },
    headingPageTitle: {
      color: COLOR.BLACK,
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal'
    }
  },
  components: {
    ...getBaseLineCss([
      {
        type: ThemableBoxType.FORM_LAYOUT,
        style: {
          display: 'flex',
          flexGrow: 1,
          flexDirection: 'column',
          overflowY: 'hidden',
          '> .content': {
            display: 'flex',
            flexDirection: 'column',
            overflowY: 'hidden',
            padding: '20px 20px 20px 20px',
            '> *': {
              padding: '12px'
            }
          },
          '> .below-content-area': {
            flexGrow: 1
          },
          '> .footer': {
            '> *': {
              padding: '12px'
            }
          }
        }
      },
      {
        type: ThemableBoxType.FORM_CONTENT_LAYOUT,
        style: {
          height: '100%',
          '> .form-heading-actions-container': {
            padding: '12px 0px 20px 0px',
            flexDirection: 'row',
            display: 'flex',
            '> .form-heading': {
              display: 'flex',
              flexGrow: 1
            },
            '> .form-primary-actions': {}
          },
          '> .form-content-wrapper': {
            display: 'flex',
            flexGrow: 1,
            height: '100%',
            flexDirection: 'column',
            paddingBottom: '75px',

            '> .form-fields': {
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'row',
              paddingTop: '10px'
            },
            '> .form-secondary-actions': {
              paddingTop: '20px'
            }
          }
        }
      },
      {
        type: ThemableBoxType.FORM_CONTAINER,
        style: {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflow: 'hidden',
          backgroundColor: COLOR.WHITE_SMOKE
        }
      },
      {
        type: ThemableBoxType.FORM_ELEMENTS_CONTAINER,
        style: {
          height: '100%',
          borderRadius: 'var(--borderRadius, 4px);',
          backgroundColor: COLOR.WHITE,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25);'
        }
      },
      {
        type: ThemableBoxType.DIALOG_CONTROL,
        style: {
          '& .MuiDialog-paper ': {
            maxWidth: 'unset'
          }
        }
      },
      {
        type: ThemableBoxType.FORM_FOOTER,
        style: {
          borderRadius: 'var(--borderRadius, 4px);',
          backgroundColor: COLOR.WHITE
        }
      },
      {
        type: ThemableBoxType.LIST_VIEW_CONTAINER,
        style: {
          padding: '20px'
        }
      },
      {
        type: ThemableBoxType.OPENED_LIST_VIEW_FILTER_CONTAINER,
        style: {
          width: '300px'
        }
      },
      {
        type: ThemableBoxType.LIST_VIEW_ACTIONS_DROP_DOWN,
        style: {
          minWidth: '100px'
        }
      },
      {
        type: ThemableBoxType.ACTION_LINK,
        style: {
          '.MuiLink-root': {
            fontFamily: 'Roboto',
            fontSize: '14px',
            textDecoration: 'underline',
            padding: '6px',
            position: 'relative',
            left: '-6px',
            ':hover': {
              borderRadius: '12px',
              backgroundColor: COLOR.MAROON_20
            },
            '&[disabled]': {
              color: 'grey',
              cursor: 'default',
              '&:hover': {
                textDecoration: 'none'
              }
            }
          },
          ':hover': {
            cursor: 'pointer'
          }
        }
      },
      {
        type: ThemableBoxType.APPLICATION_LAYOUT,
        style: {
          display: 'flex',
          flexDirection: 'row',
          ':has(.FormContainer)': {
            // only apply fixed height to pages that have forms, which need this to get scrolling + flexbox to work
            height: '100vh'
          },
          '> .navigation': {},
          '> .header-main-container': {
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,

            '> .header': {
              padding: '40px 20px 0px 20px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
              '> :not(:first-child)': {
                marginLeft: '8px'
              }
            },
            '> .main': {
              display: 'flex',
              flexDirection: 'column',
              flexGrow: 1,
              overflowY: 'hidden',
              form: {
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                overflowY: 'hidden'
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.DRAG_TOOLTIP,
        style: {
          position: 'fixed',
          left: '150px',
          bottom: '25px',
          background: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          padding: '10px',
          fontSize: '20px',
          borderRadius: '5px',
          zIndex: '99'
        }
      },
      {
        type: ThemableBoxType.FORM_LAYOUT_SMALL,
        style: {
          maxWidth: '900px',
          minWidth: '500px',
          padding: '20px',
          borderRadius: '4px',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
        }
      },
      {
        type: ThemableBoxType.ICON_BUTTON_WITH_LABEL,
        style: {
          '& .MuiIconButton-root': {
            color: COLOR.MAROON_PRIMARY
          },
          '& .MuiTypography-root': {
            paddingLeft: '3.25px',
            fontWeight: '400',
            fontSize: '13px',
            lineHeight: '15.23px',
            color: COLOR.MAROON_PRIMARY
          },
          '&.multiline': {
            '& .MuiIconButton-root': {
              wordWrap: 'break-word'
            },
            '&.icon-left': {
              '& .MuiTypography-root': {
                textAlign: 'left',
                paddingLeft: '4px'
              }
            },
            '&.icon-right': {
              '& .MuiTypography-root': {
                textAlign: 'right',
                paddingRight: '4px'
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.MIN_MAX_WIDTH_LAYOUT,
        style: {
          '&.MAX_WIDTH_120PX': {
            maxWidth: '120px'
          },
          '&.MAX_WIDTH_160PX': {
            maxWidth: '160px'
          },
          '&.MIN_WIDTH_200PX': {
            minWidth: '200px'
          },
          '&.MIN_WIDTH_300PX': {
            minWidth: '300px'
          },
          '&.MIN_WIDTH_400PX': {
            minWidth: '400px'
          }
        }
      },
      {
        type: ThemableBoxType.CRITICAL_ERROR_SCREEN,
        style: {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          alignItems: 'center',
          height: '100vh',
          backgroundColor: COLOR.WHITE_SMOKE,
          justifyContent: 'center',
          backgroundPosition: 'left center',
          backgroundImage: `url(${Logo})`,
          '> .error-screen-content': {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '675px',
            '> .error-screen-heading-main': {
              '> p': {
                fontSize: '248px',
                fontWeight: '600',
                color: COLOR.MAROON_PRIMARY,
                textShadow: `30px 30px ${alpha(COLOR.MAROON_PRIMARY, 0.1)}`
              }
            },
            '> .error-screen-heading-subtitle': {
              paddingBottom: '50px',
              '> p': {
                fontSize: '32px',
                fontWeight: '600',
                color: COLOR.RAG_PILL_GREY
              }
            },
            '> .error-screen-error-details': {
              backgroundColor: COLOR.WHITE,
              marginLeft: '50px',
              marginRight: '50px',
              borderRadius: '4px',
              padding: '20px',
              boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.08);',
              '> :not(:last-child)': {
                paddingBottom: '10px'
              },
              '> .error-screen-error-details-heading': {
                '> p': {
                  fontSize: '20px',
                  lineHeight: '23.44px',
                  fontWeight: '600',
                  color: COLOR.MAROON_PRIMARY
                }
              },
              '> .error-screen-error-details-body': {
                display: 'flex',
                flexDirection: 'column',
                '> :not(:last-child)': {
                  paddingBottom: '10px'
                },
                '> .error-screen-error-details-body-button': {
                  paddingTop: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  ' .MuiButtonBase-root': {
                    fontSize: '13px',
                    fontWeight: '500',
                    height: '50px',
                    lineHeight: '16px'
                  }
                },
                '> p': {
                  fontSize: '14px',
                  lineHeight: '16.41px',
                  fontWeight: '400',
                  color: COLOR.RAG_PILL_GREY
                }
              }
            },
            '> .error-screen-buttons': {
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              paddingTop: '50px',
              '> :not(:last-child)': {
                marginRight: '15px'
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.BORDER_BOX,
        style: {
          '&.success': {
            padding: '15px',
            boxShadow:
              'rgba(45, 255, 0, 0.3) 0px 0px 6px 3px inset, rgba(0, 0, 0, 0.12) 0px 1px 4px'
          },
          '&.failure': {
            padding: '15px',
            boxShadow:
              'rgba(255, 0, 0, 0.3) 0px 0px 6px 3px inset, rgba(0, 0, 0, 0.12) 0px 1px 4px'
          },
          '&.generic': {
            padding: '10px',
            borderRadius: '4px',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2)'
          }
        }
      },
      {
        type: ThemableBoxType.TOOLTIP,
        style: {
          '& .MuiSvgIcon-root': {
            color: COLOR.MAROON_PRIMARY
          },
          '& .MuiTooltip-tooltip': {
            display: 'flex',

            backgroundColor: alpha(COLOR.GREY_TOOLTIP, 0.9),
            ' .MuiTypography-root': {
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '14px'
            },
            ' .MuiLink-root': {
              ':link': {
                color: COLOR.WHITE,
                textDecorationStyle: 'none'
              },
              ':visited': {
                color: COLOR.GAINSBORO,
                textDecorationStyle: 'none'
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.MY_MODULES,
        style: {
          alignSelf: 'end',
          '> .MuiBox-root': {
            borderRadius: '10px',
            background: COLOR.WHITE,
            boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10);'
          },
          'label.MuiInputLabel-outlined:not(.MuiFormLabel-filled):not(.MuiInputLabel-shrink)':
            {
              transform: 'translate(14px, 9px) scale(1)'
            },
          ' .MuiInputLabel-root.Mui-focused': {
            color: `${COLOR.WHITE_60_ALPHA} !important`
          },
          '& .MuiFormLabel-root': {
            color: COLOR.WHITE,
            lineHeight: '23px'
          },
          ' .MuiAutocomplete-root:only-child': {
            ' .MuiInputBase-root': {
              borderBottomRightRadius: '10px',
              borderTopRightRadius: '10px'
            }
          },
          '& .MuiInputBase-root': {
            height: '40px',
            width: '15vw',
            maxWidth: '350px',
            borderBottomRightRadius: 'unset',
            borderTopRightRadius: 'unset',
            borderBottomLeftRadius: '10px',
            borderTopLeftRadius: '10px',
            background: COLOR.MAROON_PRIMARY,
            color: COLOR.WHITE,
            '> input': {
              color: COLOR.WHITE
            },
            '> .MuiAutocomplete-endAdornment': {
              '> .MuiIconButton-root': {
                color: COLOR.GREY_LIGHT_100
              }
            },
            '& ::placeholder': {
              color: COLOR.WHITE,
              opacity: '100%'
            }
          },
          '& .MuiToggleButtonGroup-root': {
            '> :not(:last-child)': {
              borderBottomRightRadius: 'unset',
              borderTopRightRadius: 'unset',
              marginRight: 0
            },
            '> :last-child': {
              borderBottomRightRadius: '10px',
              borderTopRightRadius: '10px'
            },
            '> :first-child': {
              borderBottomLeftRadius: 'unset',
              borderTopLeftRadius: 'unset',
              borderLeft: 0
            },
            '> .MuiToggleButton-root.MuiButtonBase-root': {
              height: '40px',
              boxShadow: 'unset',
              marginBottom: '0px',
              lineHeight: '16px',
              '& .Mui-selected': {
                color: COLOR.BLACK
              },

              '& .MuiButtonBase-root': {
                borderRadius: '0px'
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.FORM_LAYOUT_LARGE,
        style: {
          width: '100%',
          padding: '20px',
          borderRadius: '4px',
          boxShadow:
            '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
        }
      },
      {
        type: ThemableBoxType.LIST_VIEW_TYPE_PICKER,
        style: {
          '> .MuiToggleButtonGroup-root': {
            height: '35px'
          }
        }
      },
      {
        type: ThemableBoxType.BOOKINGS_HEADER_TOOLBAR,
        style: {
          display: 'flex',
          flexDirection: 'column',
          borderRadius: '4px',
          width: '100%',
          '> .first-row-container': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '16px',

            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
            '> .title': {
              display: 'flex'
            }
          },
          '> .second-row-container': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '16px',

            boxShadow:
              '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
          }
        }
      },
      {
        type: ThemableBoxType.MODAL,
        style: {
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: COLOR.WHITE,
          padding: '15px',
          boxShadow: '24',
          pt: '2',
          px: '4',
          pb: '3',
          overflowY: 'auto',
          minWidth: '300px',
          maxHeight: '600px'
        }
      },
      {
        type: ThemableBoxType.NAVIGATION_LINK,
        style: {
          '& a': {
            textDecoration: 'none'
          },
          '& .MuiListItemIcon-root': {
            justifyContent: 'center',
            paddingRight: 0,
            color: 'white'
          },
          '& .MuiListItemButton-root': {
            display: 'flex',
            flexDirection: 'column',
            textDecoration: 'none',
            color: 'white',
            '&.Mui-selected': {
              backgroundColor: alpha(COLOR.GOLD_PRIMARY, 0.7),
              '&:hover': {
                backgroundColor: alpha(COLOR.GOLD_PRIMARY, 0.9)
              }
            },
            ':hover': {
              backgroundColor: alpha(COLOR.GOLD_PRIMARY, 0.4)
            },
            '> .MuiListItemText-root': {
              whiteSpace: 'normal',
              overflow: 'hidden',
              textAlign: 'center'
            }
          }
        }
      },

      {
        type: ThemableBoxType.NAVIGATION_LOGO,
        style: {
          '& .MuiCardMedia-root': {
            maxHeight: 100,
            width: '100%',
            objectFit: 'contain',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingBottom: '10px',
            '&.Mui-selected': {
              backgroundColor: alpha(COLOR.GOLD_PRIMARY, 0.7),
              '&:hover': {
                backgroundColor: alpha(COLOR.GOLD_PRIMARY, 0.9)
              }
            },
            ':hover': {
              backgroundColor: alpha(COLOR.GOLD_PRIMARY, 0.4)
            }
          }
        }
      },
      {
        type: ThemableBoxType.DATE_RANGE_CONTROL,
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '& .MuiIconButton-root': {
            color: COLOR.MAROON_PRIMARY
          },
          height: '100%',
          '& .MuiBox-root:has(.MuiTypography-root)': {
            color: COLOR.MAROON_PRIMARY,
            fontWeight: '500',
            fontSize: '16px',
            lineHeight: '16px',
            textDecorationLine: 'underline',
            cursor: 'pointer'
          }
        }
      },
      {
        type: ThemableBoxType.SEARCH_BOX_CONTAINER,
        style: {
          '& .MuiTextField-root': {
            width: '350px'
          }
        }
      },
      {
        type: ThemableBoxType.SWITCH_MODE_CHECKBOX,
        style: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          '& .MuiBox-root:has(.MuiTypography-root)': {
            color: COLOR.MAROON_PRIMARY,
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '16px'
          },
          '& .MuiSwitch-thumb': {
            backgroundColor: COLOR.MAROON_PRIMARY
          },
          '& .MuiSwitch-track': {
            backgroundColor: COLOR.MAROON_PRIMARY,
            opacity: 0.5
          }
        }
      },
      {
        type: ThemableBoxType.CRITICAL_ERROR_MODAL_DISPLAY,
        style: {
          display: 'flex',
          borderRadius: '4px',
          flexDirection: 'row',
          padding: '18px 16px 18px 6px',
          backgroundColor: COLOR.PALE_CHERRY_BLOSSOM,
          '> .icon-area': {
            display: 'flex',
            alignItems: 'start',
            justifyContent: 'center',
            width: '50px',
            '> .MuiSvgIcon-root': {
              color: COLOR.RED_REJECT
            }
          },
          '> .details-area': {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            '> .details-area-heading > .MuiTypography-root': {
              color: COLOR.RED_OXIDE,
              fontWeight: '500',
              fontSize: '16px',
              lineHeight: '24px'
            },
            '> .details-area-subtitle > .MuiTypography-root': {
              color: COLOR.RED_OXIDE,
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '20.02px'
            }
          }
        }
      },
      {
        type: ThemableBoxType.CALENDAR_TIMELINE,
        style: {
          width: '63vw',
          '> .react-calendar-timeline *': {
            boxSizing: 'border-box'
          },
          '> .react-calendar-timeline .rct-outer': {
            display: 'block',
            overflow: 'hidden',
            whiteSpace: 'nowrap'
          },
          '.calendarPublicHolidays': {
            color: '#c32c08',
            backgroundColor: '#ece4e7'
          },
          '> .react-calendar-timeline .rct-scroll': {
            display: 'inline-block',
            whiteSpace: 'normal',
            verticalAlign: 'top',
            overflowX: 'scroll',
            overflowY: 'hidden',
            msTouchAction: 'none',
            touchAction: 'none'
          },
          '> .react-calendar-timeline .rct-item:hover': {
            zIndex: 88
          },
          '> .react-calendar-timeline .rct-item': {
            '&.customColour1': {
              background: '#bd33a4 !important'
            }, //  Byzantine
            '&.customColour2': {
              background: '#6495ed !important'
            }, //  Cornflower Blue
            '&.customColour3': {
              background: '#00755e !important'
            }, //  Tropical Rain Forest
            '&.customColour4': {
              background: '#e9d66b !important'
            }, //  Arylide Yellow
            '&.customColour5': {
              background: '#964b00 !important'
            }, //  Brown
            '&.customColour6': {
              background: '#cc7722 !important'
            }, //  Ochre
            '&.customColour7': {
              background: '#de5d83 !important'
            }, //  Blush
            '&.customColour8': {
              background: '#702963 !important'
            }, //  Byzantium
            '&.customColour9': {
              background: '#e34234 !important'
            }, //  Cinnabar
            '&.customColour10': {
              background: '#ff9966 !important'
            }, //  Atomic Tangerine
            '&.customColour11': {
              background: '#98777b !important'
            }, //  Bazaar
            '&.customColour12': {
              background: '#8db600 !important'
            }, //  Apple Green
            '&.customColour13': {
              background: '#006b3c !important'
            }, //  Cadmium Green
            '&.customColour14': {
              background: '#008b8b !important'
            }, //  Dark Cyan
            '&.customColour15': {
              background: '#cd7f32 !important'
            }, //  Bronze
            '&.customColour16': {
              background: '#bf94e4 !important'
            }, //  Bright Lavender
            '&.customColour17': {
              background: '#cd5b45 !important'
            }, //  Dark Coral
            '&.customColour18': {
              background: '#8c92ac !important'
            }, //  Cool Grey
            '&.customColour19': {
              background: '#4682b4 !important'
            }, //  Steel Blue
            '&.customColour20': {
              background: '#808000 !important'
            }, //  Olive
            '&.customColour21': {
              background: '#ff7f00 !important'
            }, //  Orange
            '&.customColour22': {
              background: '#08457e !important'
            }, //  Dark Cerulean
            '&.customColour23': {
              background: '#b5a642 !important'
            }, //  Brass
            '&.customColour24': {
              background: '#87a96b !important'
            }, //  Asparagus
            '&.customColour25': {
              background: '#ed872d !important'
            }, //  Cadmium Orange
            '&.customColour26': {
              background: '#78866b !important'
            }, //  Camouflage Green
            '&.customColour27': {
              background: '#b87333 !important'
            }, //  Copper
            '&.customColour28': {
              background: '#c04000 !important'
            }, //  Mahogany
            '&.customColour29': {
              background: '#893f45 !important'
            }, //  Cordovan
            '&.rejected': {
              background: '#ff0000 !important',
              color: '#ffffff !important',
              borderColor: '#cf2100 !important'
            },
            '&.approved': {
              background: '#83ff24 !important',
              color: '#ffffff !important',
              borderColor: '#83ff24 !important'
            },
            '&.awaiting-approval': {
              background: '#ff8823 !important',
              color: '#ffffff !important',
              borderColor: '#ff8823 !important'
            },
            '&.cancelled': {
              background: '#808080 !important',
              color: '#ffffff !important',
              borderColor: '#808080 !important'
            }
          },
          '> .react-calendar-timeline .rct-item .rct-item-content': {
            //position: 'sticky',
            position: '-webkit-sticky',
            left: '0px',
            overflow: 'hidden',
            display: 'inline-block',
            borderRadius: '2px',
            padding: '0 6px',
            height: '100%'
          },
          '> .react-calendar-timeline .rct-sidebar': {
            overflow: 'hidden',
            whiteSpace: 'normal',
            display: 'inline-block',
            verticalAlign: 'top',
            position: 'relative',
            boxSizing: 'border-box',
            borderRight: `1px solid ${COLOR.WHITE_SOLITUDE}`,
            color: COLOR.MAROON_PRIMARY,
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px'
          },
          '> .react-calendar-timeline .rct-sidebar.rct-sidebar-right': {
            borderRight: '0',
            borderLeft: `1px solid ${COLOR.WHITE_SOLITUDE}`
          },
          '> .react-calendar-timeline .rct-sidebar .rct-sidebar-row': {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            boxSizing: 'border-box',
            margin: '0',
            borderBottom: `1px solid ${COLOR.WHITE_SOLITUDE}`
          },
          '> .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-odd':
            {
              background: 'rgba(0, 0, 0, 0.05)'
            },
          '> .react-calendar-timeline .rct-sidebar .rct-sidebar-row.rct-sidebar-row-even':
            {
              background: 'transparent'
            },
          '> .react-calendar-timeline .rct-vertical-lines .rct-vl': {
            position: 'absolute',
            borderLeft: `1px solid ${COLOR.WHITE_SOLITUDE}`,
            zIndex: '30'
          },
          '> .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-vl-first':
            {
              borderLeftWidth: '2px'
            },
          '> .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-6, .react-calendar-timeline .rct-vertical-lines .rct-vl.rct-day-0':
            {
              background: 'rgba(250, 246, 225, 0.5)'
            },
          '> .react-calendar-timeline .rct-horizontal-lines': {
            webkitUserSelect: 'none',
            mozUserSelect: '-moz-none',
            msUserSelect: 'none',
            userSelect: 'none'
          },
          '> .react-calendar-timeline .rct-horizontal-lines .rct-hl-even, .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd':
            {
              borderBottom: `1px solid ${COLOR.WHITE_SOLITUDE}`,
              boxSizing: 'border-box',
              zIndex: '40'
            },
          '> .react-calendar-timeline .rct-horizontal-lines .rct-hl-odd': {
            background: 'rgba(0, 0, 0, 0.05)'
          },
          '> .react-calendar-timeline .rct-horizontal-lines .rct-hl-even': {
            background: 'transparent'
          },
          '> .react-calendar-timeline .rct-cursor-line': {
            position: 'absolute',
            width: '2px',
            background: '#2196f3',
            zIndex: '51'
          },
          '> .react-calendar-timeline .rct-dateHeader': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            borderBottom: `1px solid ${COLOR.WHITE_SOLITUDE}`,
            cursor: 'pointer',
            color: COLOR.MAROON_PRIMARY,
            backgroundColor: COLOR.FAUX_GUYABANO,
            borderLeft: `2px solid ${COLOR.WHITE_SOLITUDE}`
          },
          '> .react-calendar-timeline .rct-dateHeader-primary': {
            backgroundColor: COLOR.FAUX_GUYABANO,
            borderLeft: `1px solid ${COLOR.WHITE_SOLITUDE}`,
            borderRight: `1px solid ${COLOR.WHITE_SOLITUDE}`,
            color: COLOR.MAROON_PRIMARY
          },
          '> .react-calendar-timeline .rct-header-root': {
            background: COLOR.FAUX_GUYABANO,
            borderBottom: `1px solid ${COLOR.WHITE_SOLITUDE}`,
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '16px',
            color: COLOR.MAROON_PRIMARY
          },
          '> .react-calendar-timeline .rct-calendar-header': {
            border: `1px solid ${COLOR.WHITE_SOLITUDE}`
          }
        }
      },
      {
        type: ThemableBoxType.CALENDAR,
        style: {
          '& .fc-more-popover': {
            overflowY: 'auto',
            maxHeight: '200px'
          },
          '& .fc-popover': {
            zIndex: 99
          },
          '& .fc-day-disabled': {
            backgroundColor: COLOR.WHITE
          },
          '& .fc-daygrid-day-number': {
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '21px',
            textAlign: 'left',
            color: COLOR.GREY_DOLPHIN,
            flex: '1'
          },
          '& .fc-daygrid-day-top': {
            flexWrap: 'wrap'
          },

          '& .fc-daygrid-day': {
            borderColour: COLOR.GAINSBORO
          },
          thead: {
            height: '27px',
            backgroundColor: COLOR.WHITE_SMOKE
          },
          '& .fc-col-header-cell-cushion': {
            fontSize: '14px',
            fontWeight: '600',
            lineHeight: '21px',
            textAlign: 'center',
            color: COLOR.GREY_DOLPHIN
          },
          '.fc-event': {
            marginTop: '5px',
            '.fc-event-main-frame .fc-event-time': {
              overflow: 'visible'
            },
            '&.rejected': {
              background: `${COLOR.RED} !important`,
              color: `${COLOR.WHITE} !important`,
              borderColor: `${COLOR.IRIS_BLUE} !important`
            },
            '&.approved': {
              background: `${COLOR.LOUD_LIME} !important`,
              color: `${COLOR.WHITE} !important`,
              borderColor: `${COLOR.LOUD_LIME} !important`
            },
            '&.non_bookable': {
              background: `${COLOR.ROYAL_BLUE} !important`,
              color: `${COLOR.WHITE} !important`,
              borderColor: `${COLOR.ROYAL_BLUE} !important`
            },
            '&.awaiting-approval': {
              background: `${COLOR.MANGO_MADNESS} !important`,
              color: `${COLOR.WHITE} !important`,
              borderColor: `${COLOR.MANGO_MADNESS} !important`
            },
            '&.cancelled': {
              background: `${COLOR.GREY} !important`,
              c808080olor: `${COLOR.WHITE} !important`,
              borderColor: `${COLOR.GREY} !important`
            },
            '&.calendar-event': {
              background: `${COLOR.SNARKY_MINT} !important`,
              color: `${COLOR.BLACK} !important`,
              borderColor: `${COLOR.GREY} !important`,
              opacity: 0.5,
              '& .fc-event-main': {
                color: `${COLOR.BLACK} !important`
              }
            }
          },
          '& .calendar-day-cell-header': {
            color: `${COLOR.GREY_300} !important`
          }
        }
      },
      {
        type: ThemableBoxType.EMBEDDED_CONTAINER,
        style: {
          paddingTop: '10px',
          paddingLeft: '24px',
          paddingRight: '24px',
          backgroundColor: 'background.paper'
        }
      },
      {
        type: ThemableBoxType.USER_PROFILE_MENU_WITH_INITIALS,
        style: {
          backgroundColor: COLOR.MAROON_10,
          borderRadius: '50%',
          maxWidth: '40px',
          maxHeight: '40px',
          '& .MuiIconButton-root': {
            paddingLeft: '2px',
            paddingRight: '2px'
          }
        }
      },
      {
        type: ThemableBoxType.TABLE_LAYOUT_SMALL,
        style: {
          maxWidth: '900px',
          minWidth: '500px',
          maxHeight: '500px',
          minHeight: '500px',
          overflowY: 'scroll'
        }
      },
      {
        type: ThemableBoxType.TABLE_LAYOUT_LARGE,
        style: {
          width: '100%',
          height: '100%'
        }
      },
      {
        type: ThemableBoxType.TABLE_LAYOUT_CARD,
        style: {
          '.MuiTable-root': {
            minHeight: 415,
            border: `2px solid ${COLOR.GREY_LIGHT_100}`
          },
          '.MuiTableRow-root': {
            '&:last-child td, &:last-child th': {
              border: 0
            }
          }
        }
      },
      {
        type: ThemableBoxType.WELCOME_BANNER,
        style: {
          padding: '16px',
          '& .MuiCardMedia-root': {
            height: '300px',
            objectFit: 'cover'
          }
        }
      },
      {
        type: ThemableBoxType.WELCOME_GREETER,
        style: {
          margin: '4px 16px 16px 16px',
          '& .MuiCard-root': {
            minWidth: 500
          },
          '& .MuiDivider-root': {
            marginTop: '10px',
            marginBottom: '10px'
          }
        }
      },
      {
        type: ThemableBoxType.PILL,
        style: {
          '& .MuiChip-root': {
            height: '100%',
            color: COLOR.WHITE,
            '> .MuiChip-label': {
              whiteSpace: 'nowrap'
            },
            '&.amber, .green, .grey, .red': {
              padding: '3px 4px'
            },
            '&.amber': {
              backgroundColor: COLOR.RAG_PILL_AMBER
            },
            '&.grey': {
              backgroundColor: COLOR.RAG_PILL_GREY
            },
            '&.green': {
              backgroundColor: COLOR.RAG_PILL_GREEN
            },
            '&.red': {
              backgroundColor: COLOR.RAG_PILL_RED,
              '&.is-clickable': {
                '&:hover': {
                  color: COLOR.WHITE,
                  backgroundColor: COLOR.MAROON_LIGHT
                }
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.APP_ICON,
        style: {
          display: 'flex',
          '&.red-light': {
            color: COLOR.RED_LIGHT
          },
          '&.silver': {
            color: COLOR.SILVER
          }
        }
      },
      {
        type: ThemableBoxType.NOTIFICATIONS_MENU_BUTTON,
        style: {
          '> .MuiBadge-root': {
            backgroundColor: COLOR.GOLD_PRIMARY_WITH_33_ALPHA,
            borderRadius: '50%',
            '> .MuiBadge-badge': {
              color: COLOR.GOLD_PRIMARY,
              backgroundColor: COLOR.GOLD_PRIMARY_WITH_33_ALPHA
            },
            '> .MuiButtonBase-root': {
              height: '40px',
              width: '40px',
              zIndex: 99,
              '> .MuiSvgIcon-root': {
                color: COLOR.GOLD_PRIMARY
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.HEADER_CONTAINER,
        style: {
          width: '100%',
          ' .MuiStack-root': {
            width: '100%'
          },
          ' .class-header-box': {
            flexGrow: 1
          },
          ' .class-my-modules-box': {
            flexGrow: 1
          },
          ' .class-header-box+.class-my-modules-box': {
            flexGrow: 9
          },
          ' .class-profile-button-box:first-child': {
            display: 'flex',
            justifyContent: 'end',
            flexGrow: 1
          }
        }
      },
      {
        type: ThemableBoxType.TRANSFER_TABLE_BUTTON,
        style: {
          width: '100%',
          '& .MuiButtonBase-root': {
            width: '100%',
            justifyContent: 'flex-start'
          }
        }
      },
      {
        type: ThemableBoxType.NOTIFICATIONS_LIST,
        style: {
          width: 500,
          '> .MuiList-root': {
            position: 'relative',
            overflow: 'auto',
            maxHeight: '600px'
          },
          '& .MuiListItem-root': {
            width: 'inherit',
            '&:hover': {
              backgroundColor: alpha(COLOR.MAROON_PRIMARY, 0.1),
              cursor: 'pointer'
            },
            '> .MuiListItemText-root': {
              maxWidth: 350
            },
            '& .MuiListItemIcon-root': {
              minWidth: 'inherit'
            }
          },
          '& .MuiListItem-root.unread': {
            '& .MuiListItemText-primary': {
              color: COLOR.BLACK
            },
            '& .MuiListItemIcon-root': {
              color: COLOR.BLACK
            }
          },
          '& .MuiListItem-root.read': {
            '& .MuiListItemIcon-root': {
              color: COLOR.BLACK
            }
          },

          '& .MuiListItemIcon-root.updating': {
            cursor: 'wait'
          }
        }
      },
      {
        type: ThemableBoxType.NOTIFICATIONS_HEADER,
        style: {
          backgroundColor: COLOR.MAROON_PRIMARY,
          color: COLOR.WHITE,
          paddingRight: '20px',
          paddingLeft: '15px',
          paddingTop: '15px',
          paddingBottom: '15px',
          '& .MuiGrid-root': {
            margin: 0,
            padding: 0
          },
          '& .MuiSvgIcon-root': {
            color: COLOR.WHITE
          }
        }
      },
      {
        type: ThemableBoxType.TABLE_GROUP_BY,
        style: {
          marginTop: 2,
          '& .MuiGrid-root > .MuiGrid-root > .MuiTypography-root': {
            color: COLOR.MAROON_PRIMARY
          },
          '& .GroupHeader': {
            '> .MuiTableCell-root': {
              borderBottom: 0
            },

            ':not(:first-child)': {
              '> .MuiTableCell-root': {
                paddingTop: '50px'
              }
            }
          }
        }
      },
      {
        type: ThemableBoxType.HELIX_LOADER,
        style: {
          display: 'block',
          position: 'absolute',
          width: '2.75vw',
          height: '11vw',
          'z-index': 100,
          top: '40vh',
          left: '47.5vw',
          '& .nucleus': {
            width: '100%',
            height: '100%'
          },
          '& .nucleobase': {
            display: 'block',
            position: 'relative',
            width: '100%',
            height: 'calc(100% / 10)',
            'vertical-align': 'middle'
          } as CSSInterpolation,
          '& .nucleobase:not(:last-child)': {
            marginRight: '2.43vw'
          },
          '& .nucleobase:before': {
            content: '""',
            display: 'block',
            width: '0.7vw',
            height: '0.7vw',
            borderRadius: '50%',
            position: 'absolute'
          } as CSSInterpolation,
          '& .nucleobase:after': {
            content: '""',
            display: 'inline-block',
            width: '0.7vw',
            height: '0.7vw',
            borderRadius: '50%',
            position: 'absolute'
          } as CSSInterpolation,
          '& .nucleobase:nth-child(1)': {
            animationDelay: '-1.869s',
            '&.nucleobase:nth-child(1):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-1.869s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(1):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-1.869s',
              backgroundColor: '#60223b'
            }
          } as CSSInterpolation,
          '& .nucleobase:nth-child(2)': {
            animationDelay: '-3.738s',
            '&.nucleobase:nth-child(2):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-3.738s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(2):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-3.738s',
              backgroundColor: '#60223b'
            }
          } as CSSInterpolation,
          '& .nucleobase:nth-child(3)': {
            animationDelay: '-5.607s',
            '&.nucleobase:nth-child(3):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-5.607s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(3):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-5.607s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(4)': {
            animationDelay: '-7.476s',
            '&.nucleobase:nth-child(4):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-7.476s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(4):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-7.476s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(5)': {
            animationDelay: '-9.345s',
            '&.nucleobase:nth-child(5):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-9.345s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(5):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-9.345s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(6)': {
            animationDelay: '-11.214s',
            '&.nucleobase:nth-child(6):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-11.214s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(6):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-11.214s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(7)': {
            animationDelay: '-13.083s',
            '&.nucleobase:nth-child(7):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-13.083s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(7):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-13.083s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(8)': {
            animationDelay: '-14.952s',
            '&.nucleobase:nth-child(8):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-14.952s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(8):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-14.952s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(9)': {
            animationDelay: '-16.821s',
            '&.nucleobase:nth-child(9):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-16.821s',
              'background-color': '#ff6e97'
            },
            '&.nucleobase:nth-child(9):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-16.821s',
              backgroundColor: '#60223b'
            }
          },
          '& .nucleobase:nth-child(10)': {
            animationDelay: '-18.69s',
            '&.nucleobase:nth-child(10):before': {
              animation:
                'animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-18.69s',
              backgroundColor: '#ff6e97'
            },
            '&.nucleobase:nth-child(10):after': {
              animation:
                'animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite',
              animationDelay: '-18.69s',
              backgroundColor: '#60223b'
            }
          },
          '@keyframes animBefore': {
            '0%': {
              left: '100%',
              'z-index': '1'
            },
            '25%': {
              transform: 'scale(1.2)',
              'z-index': '1'
            },
            '50%': {
              left: '0%',
              'z-index': '-1'
            },
            '75%': {
              'background-color': '#906476',
              transform: 'scale(0.8)',
              'z-index': '-1'
            },
            '100%': {
              left: '100%',
              'z-index': '-1'
            }
          },
          '@keyframes animAfter': {
            '0%': {
              left: '0%',
              'z-index': -1
            },
            '25%': {
              'background-color': '#784358',
              transform: 'scale(0.8)',
              'z-index': '-1'
            },
            '50%': {
              left: '100%',
              'z-index': 1
            },
            '75%': {
              transform: 'scale(1.2)',
              'z-index': '1'
            },
            '100%': {
              left: '0%',
              'z-index': 1
            }
          },
          '@keyframes animDotBar': {
            '0%': {
              height: '4.125vw'
            },
            '25%': {
              height: '0'
            },
            '50%': {
              height: '4.125vw'
            },
            '75%': {
              height: '0'
            },
            '100%': {
              height: '4.125vw'
            }
          }
        }
      },
      {
        type: ThemableBoxType.COLLAPSIBLE_TABLE,
        style: {
          table: {
            borderCollapse: 'separate',
            padding: '2px'
          },
          'tr:not(.blank-row)': {
            border: '20px solid transparent;',
            boxShadow:
              '0px 2px 1px -1px #00000033,0px 1px 1px 0px #00000024,0px 1px 3px 0px #0000001F;'
          },
          'tr.blank-row': {
            height: '10px;'
          },
          'tr.expanded': {
            backgroundColor: COLOR.MAROON_10,
            boxShadow: 'none'
          },
          '.last-sub-row > td': {
            borderBottom: `10px solid ${COLOR.MAROON_10};`
          },
          '.sub-table-row:hover': {
            backgroundColor: `${COLOR.MAROON_20} !important;`
          },
          '.sub-table-row td': {
            bordeTop: `10px solid ${COLOR.MAROON_10};`
          },
          '.sub-table-row td:first-child': {
            borderLeft: `10px solid ${COLOR.MAROON_10};`
          },
          '.sub-table-row td:last-child': {
            borderRight: `10px solid ${COLOR.MAROON_10};`
          },
          '.btn-expand': {
            height: '20px'
          }
        }
      },
      {
        type: ThemableBoxType.TABLE_COUNT,
        style: {
          width: '70px'
        }
      },
      {
        type: ThemableBoxType.SEARCH_FIELD,
        style: {
          '& :placeholder-shown': {
            textOverflow: 'ellipsis'
          }
        }
      },
      {
        type: ThemableBoxType.FILTER_FIELD,
        style: {
          backgroundColor: COLOR.WHITE
        }
      },
      {
        type: ThemableBoxType.CALENDAR_ACCORDIAN,
        style: {
          width: '23vw'
        }
      },
      {
        type: ThemableBoxType.CALENDAR_ACCORDIAN_ITEM,
        style: {
          backgroundColor: 'background.paper',
          '.MuiSvgIcon-root': {
            color: COLOR.WHITE
          },
          '.MuiListItem-root': {
            backgroundColor: COLOR.MAROON_PRIMARY,
            width: '19vw'
          },
          '.MuiTypography-root': {
            color: COLOR.WHITE
          },
          '.MuiListSubheader-root': {
            paddingLeft: '5px'
          }
        }
      }
    ]),
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          width: 130,
          flexShrink: 0,
          whiteSpace: 'nowrap',
          boxSizing: 'border-box',
          ...drawerGradientStyle,
          '& .MuiDrawer-paper': drawerGradientStyle,
          '& .MuiCardMedia-root': {
            width: 62,
            height: 80
          }
        }
      }
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          color: COLOR.WHITE
        }
      }
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          alignItems: 'center',
          justifyContent: 'center',
          '& .MuiDialog-paper': {
            borderRadius: '12px',
            maxWidth: '475px'
          },
          '& .MuiDialogTitle-root': {
            color: COLOR.BLACK,
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '32px'
          },
          '& .MuiDialogContent-root': {
            '& .MuiTypography-root.MuiDialogContentText-root': {
              color: COLOR.RAG_PILL_GREY,
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '16.41px',
              whiteSpace: 'pre-line'
            }
          }
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.WHITE,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          borderRadius: '5px',
          zIndex: 99,
          margin: '5px',
          color: COLOR.GREY_DORADO,
          '& .MuiList-subheader': {
            color: COLOR.GREY_LIGHT
          },
          '& .IconButton-root': {
            paddingLeft: '5px',
            paddingRight: '5px'
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          /* work around: fontFamily was being set to Ariel for IconButtons in tables,
          whereas the rest of the site uses Roboto. Going forward, we will use
          IconButtonWithLabel in tables instead of IconButton directly. Remove
          this styling once IconButtonWithLabel is used in all tables*/
          fontFamily: 'Roboto',

          fontWeight: '400',
          fontSize: '13px',
          lineHeight: '15.23px',
          color: COLOR.MAROON_PRIMARY
        }
      },
      variants: [
        {
          props: { className: 'IconBtnClose' },
          style: {
            color: COLOR.GREY_DISABLED_TEXT
          }
        }
      ]
    },
    MuiListItemText: {
      styleOverrides: {
        root: {}
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiDrawer-paper': {
            width: 130
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          padding: '0px 20px 0px 20px',
          display: 'flex',
          alignItems: 'center',
          fontFamily: 'Roboto',
          minHeight: '64px',
          height: 'auto',
          backgroundColor: COLOR.WHITE,
          color: COLOR.GOLD_PRIMARY
        },
        li: {
          lineHeight: '18.75px',
          fontSize: '16px',
          height: '24px',
          textAlign: 'center',
          fontWeight: 600,
          cursor: 'pointer',
          '>a': {
            color: COLOR.GOLD_PRIMARY
          },
          '&:last-child': {
            color: COLOR.MAROON_PRIMARY,
            lineHeight: '23.44px',
            fontWeight: 600,
            fontSize: 20,
            minHeight: '24px',
            height: 'auto',
            paddingBottom: '4px',
            textAlign: 'left'
          }
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '100%'
        },
        label: {
          whiteSpace: 'normal'
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        contained: {
          color: COLOR.HELPER_TEXT_WARNING_ORANGE
        }
      },
      variants: [
        {
          props: { variant: 'helperTextWarning' },
          style: {
            '&.Mui-disabled': {
              color: COLOR.HELPER_TEXT_WARNING_ORANGE
            }
          }
        }
      ]
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          '&.MuiToggleButton-sizeSmall': {
            height: 40
          },
          '&.MuiToggleButton-primary': {
            border: `1px solid ${COLOR.MAROON_PRIMARY}`,
            background: COLOR.WHITE,
            color: COLOR.GREY_DOVE,
            '&.Mui-selected ': {
              background: COLOR.MAROON_PRIMARY,
              color: COLOR.WHITE
            }
          }
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          height: 42,
          minWidth: 85,
          borderRadius: 4,
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            border: 'none',
            backgroundColor: COLOR.GREY_DISABLED_BACKGROUND
          }
        },
        sizeSmall: {
          height: 40
        }
      },
      variants: [
        {
          props: { variant: 'bookingsReject' },
          style: {
            background: COLOR.RED_REJECT,
            color: COLOR.WHITE,
            '&:hover': {
              background: alpha(COLOR.RED_REJECT, 0.8)
            }
          }
        },
        {
          props: { variant: 'bookingsApprove' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.GREEN_APPROVE}`,
            color: COLOR.GREEN_APPROVE,
            '&:hover': {
              background: alpha(COLOR.GREEN_APPROVE, 0.2)
            }
          }
        },
        {
          props: { variant: 'outlined' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.RED_LIGHT_CHOCOLATE_COSMOS}`,
            color: COLOR.MAROON_PRIMARY
          }
        },
        {
          props: { variant: 'back' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.RED_LIGHT_CHOCOLATE_COSMOS}`,
            color: COLOR.RED_LIGHT_CHOCOLATE_COSMOS
          }
        },
        {
          props: { variant: 'delete' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.DANGEROUSLY_RED}`,
            color: COLOR.DANGEROUSLY_RED
          }
        },
        {
          props: { variant: 'edit' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.BLACK}`,
            color: COLOR.BLACK
          }
        },
        {
          props: { variant: 'approve' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.GREEN_MEDIUM}`,
            color: COLOR.GREEN_MEDIUM
          }
        },
        {
          props: { variant: 'warning' },
          style: {
            background: COLOR.WHITE,
            border: `1px solid ${COLOR.ORANGE_WARNING}`,
            color: COLOR.ORANGE_WARNING
          }
        },
        {
          props: { variant: 'close' },
          style: {
            color: COLOR.GREY_DARK
          }
        }
      ]
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderSpacing: 0,
          '> .MuiGrid-root > .MuiGrid-root > .MuiBox-root > .MuiTypography-root':
            {
              color: COLOR.MAROON_PRIMARY,
              fontWeight: 500,
              fontSize: 16
            },
          '> :not(:first-child)': {
            paddingTop: '40px'
          }
        }
      }
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          fontWeight: 500
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& [class*="MuiTypography-root"]': {
            fontWeight: 500,
            fontSize: '14px',
            fontColor: 'black'
          },
          backgroundColor: COLOR.GREY_LIGHT_100,
          height: '56px'
        }
      }
    },
    MuiTablePagination: {
      styleOverrides: {
        toolbar: {
          backgroundColor: COLOR.GREY_LIGHT_100,
          '& .MuiInputBase-root': {
            height: 30
          }
        },
        // There is a conflict with MUISelect so we have to use important here
        select: {
          fontSize: '12px !important'
        },
        selectLabel: {
          fontSize: '12px',
          color: COLOR.GREY_100
        },
        displayedRows: {
          fontSize: '12px',
          color: COLOR.GREY_100
        }
      }
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&Mui-selected': {
            background: COLOR.GREY_300
          },
          '&:not(.MuiTableRow-head):hover': {
            background: `${COLOR.MAROON_10} !important`,
            cursor: 'pointer'
          }
        }
      },
      variants: [
        {
          props: { className: 'Error' },
          style: {
            backgroundColor: COLOR.LIGHT_RED
          }
        },
        {
          props: { className: 'expanded' },
          style: {
            backgroundColor: COLOR.MAROON_10
          }
        },
        {
          props: { className: 'SubTableRow' },
          style: {
            '> :first-child': {
              paddingLeft: '30px'
            }
          }
        }
      ]
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingTop: 8,
          paddingBottom: 8,
          '&.row-cell-error': {
            backgroundColor: COLOR.LIGHT_RED
          }
        },
        head: {
          fontSize: '14px',
          fontWeight: 500
        },
        body: {
          color: '#5F686E',
          whiteSpace: 'pre-line',
          wordWrap: 'break-word'
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& label': {
            color: COLOR.GREY_60,
            ':not(.Mui-error)': {
              '&.MuiFormLabel-filled': {
                color: COLOR.GREY_80
              },
              '&.Mui-focused ': {
                color: COLOR.GREY_80
              }
            }
          },
          '& fieldset': {
            borderWidth: 0
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          background: 'transparent',
          '&.MuiInputBase-readOnly': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            backgroundColor: COLOR.GREY_3,
            color: COLOR.BLACK
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px !important',
            borderRadius: '4x !important',
            borderColor: COLOR.GREY_LIGHT_ADDITIONAL
          },
          '&.MuiOutlinedInput-input': {
            padding: '16px 14px',
            height: '16px'
          }
        },
        input: {
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '16px',
          ':not(input[readonly])': {
            color: COLOR.GREY_100
          },
          '&.MuiInputBase-readOnly': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            backgroundColor: COLOR.GREY_3,
            color: COLOR.BLACK
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          display: 'flex',
          '> .MuiInputBase-root': {
            minHeight: '56px',

            '&.MuiInputBase-sizeSmall': {
              minHeight: '40px'
            }
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '16px',
          minHeight: '16px',
          lineHeight: '16px'
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          minHeight: '16px',
          '&.MuiFormLabel-colorSuccess': {
            color: COLOR.GREEN_MEDIUM
          },
          '&.MuiFormLabel-colorWarning': {
            color: COLOR.RED_REJECT
          }
        }
      }
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          headingFourForm: 'h4',
          headingFiveForm: 'h5',
          headingSixForm: 'h6',
          bookingConflictsWarningText: 'p'
        }
      },
      styleOverrides: {
        h2: {
          fontSize: 28,
          fontWeight: 500,
          color: '#60223A'
        },
        h3: {
          fontSize: 15
        },
        h4: {
          fontWeight: 'bold'
        },
        h5: {
          fontSize: 24,
          fontWeight: 400,
          fontStyle: 'normal'
        }
      }
    },

    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          '& .highlighted-option': {
            '> .MuiAutocomplete-option': {
              color: `${COLOR.TORCH_RED}`
            }
          }
        },
        root: {
          'div:has(input[readonly]), input:has(input[readonly]), .MuiInputBase-input:has(input[readonly])':
            {
              cursor: 'not-allowed !important',
              webkitAppearance: 'none',
              appearance: 'none',
              mozAppearance: 'none',
              mozUserSelect: 'none',
              msUserSelect: 'none',
              webkitUserSelect: 'none',
              userSelect: 'none',

              pointerEvents: 'all !important',
              backgroundColor: `${COLOR.GREY_3} !important`,
              color: `${COLOR.BLACK} !important`,

              '& .MuiChip-root': {
                backgroundColor: `${COLOR.BROWN_LIGHT} !important`
              },

              '& .MuiAutocomplete-endAdornment': {
                display: 'none'
              }
            },
          'label.MuiInputLabel-outlined:not(.MuiFormLabel-filled):not(.MuiInputLabel-shrink)':
            {
              transform: 'translate(14px, 16px) scale(1)'
            }
        }
      }
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.WHITE,
          color: COLOR.GREY_DORADO,
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1);',
          borderRadius: '5px'
        },
        region: {
          backgroundColor: COLOR.WHITE
        }
      }
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          color: COLOR.BLACK
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: COLOR.WHITE,
          minHeight: 40,
          boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.10);',
          marginBottom: 0,
          marginLeft: 20,
          marginRight: 20,
          borderRadius: 5
        },
        flexContainer: {
          minHeight: 40,
          '> :not(:last-child)': {
            marginRight: 3
          }
        },

        indicator: {
          backgroundColor: 'transparent'
        }
      }
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minHeight: 40,
          minWidth: 100,
          fontWeight: 400,
          fontSize: '14px',
          lineHeight: '16px',
          '&:hover': {
            backgroundColor: alpha(COLOR.MAROON_20, 0.8),
            borderRadius: 10,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1);'
          },
          '&.Mui-selected': {
            backgroundColor: COLOR.MAROON_20,
            color: COLOR.MAROON_SECONDARY,
            borderRadius: 10,
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1);'
          },
          '&.Mui-disabled': {
            cursor: 'not-allowed',
            pointerEvents: 'all !important',
            backgroundColor: COLOR.GREY_DISABLED_BACKGROUND,
            color: COLOR.GREY_DISABLED_TEXT
          }
        }
      }
    }
  },
  palette: {
    primary: {
      light: COLOR.RANUNCULUS_WHITE,
      main: COLOR.MAROON_PRIMARY,
      dark: COLOR.MAROON_PRIMARY,
      contrastText: COLOR.WHITE
    },
    secondary: {
      light: COLOR.SHRINKING_VIOLET,
      main: COLOR.WHITE,
      dark: COLOR.GAINSBORO,
      contrastText: COLOR.BLACK
    }
  }
};
