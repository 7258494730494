import { ErrorInfo, useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { useErrorLoggingService } from 'src/services';
import { CriticalError, ErrorType, PermissionId, User } from 'src/types';

export type UseCriticalErrorHandlingResult = {
  /** an instance of {@link CriticalError} if a critical
   * error has occurred, otherwise {@link undefined} **/
  criticalError?: CriticalError;

  /** sets {@link criticalError} to {@link undefined} **/
  clearCriticalError: () => void;

  /** function to explicitly handle a critical error **/
  handleCriticalError: (error: Error, errorInfo?: ErrorInfo) => void;

  /** function set to the details of the currently logged-in user. **/
  handleUserLoggedIn: (user: User, userPermissions: PermissionId[]) => void;
};

const EVENT_NAME_UNHANDLED_REJECTION = 'unhandledrejection';

/**
 * A custom hook that does the following:
 * - Sets up an event listener for events of type `unhandledrejection`
 * and returns any received event in the
 * {@link UseCriticalErrorHandlingResult.criticalError}result.
 *
 * - Provides access to the last received critical event
 * via the {@link UseCriticalErrorHandlingResult.criticalError}
 * field of the result.
 *
 * - Provides the function
 * {@link UseCriticalErrorHandlingResult.clearCriticalError} to clear
 * the current critical error, as returned in the
 * {@link UseCriticalErrorHandlingResult.criticalError} field of
 * the result.
 *
 * - Provides the function
 * {@link UseCriticalErrorHandlingResult.handleCriticalError} to
 * be used in {@link ErrorBoundary} components and error handling.
 *
 * - Provides the function
 * {@link UseCriticalErrorHandlingResult.handleUserLoggedIn} to set the
 * currently logged-in user, which is returned in the
 * {@link UseCriticalErrorHandlingResult.criticalError}
 * field of the result, when a critical error is logged.
 *
 * @returns an instance of {@link UseCriticalErrorHandlingResult}.
 */
export const useCriticalErrorHandling = (): UseCriticalErrorHandlingResult => {
  const { logError } = useErrorLoggingService();

  const [criticalError, setCriticalError] = useState<
    CriticalError | undefined
  >();

  const user = useRef<User | undefined>();
  const userPermissions = useRef<PermissionId[] | undefined>();

  function handleUserLoggedIn(
    currentUser: User,
    currentUserPermissions: PermissionId[]
  ): void {
    user.current = currentUser;
    userPermissions.current = currentUserPermissions;
  }

  const clearCriticalError = useCallback(() => {
    setCriticalError(undefined);
  }, [setCriticalError]);

  const handleErrorGlobal = useCallback(
    (error: Error, errorInfo?: ErrorInfo) => {
      if (axios.isCancel(error)) {
        // rejected promises from aborted axios requests should be ignored
        return;
      }

      if (axios.isAxiosError(error)) {
        logError(error, errorInfo);
        setCriticalError(
          getCriticalErrorFromNetworkError(
            error,
            errorInfo,
            user.current,
            userPermissions.current
          )
        );
      } else {
        throw error;
      }
    },
    [logError, user]
  );

  const handleUnhandledRejectionGlobal = useCallback(
    (event: PromiseRejectionEvent) => {
      setCriticalError(
        getCriticalErrorFromUnhandledPromiseError(
          event,
          user.current,
          userPermissions.current
        )
      );
    },
    [user]
  );

  useEffect(() => {
    window.addEventListener(
      EVENT_NAME_UNHANDLED_REJECTION,
      handleUnhandledRejectionGlobal
    );

    return () => {
      window.removeEventListener(
        EVENT_NAME_UNHANDLED_REJECTION,
        handleUnhandledRejectionGlobal
      );
    };
  }, [handleUnhandledRejectionGlobal]);

  return {
    criticalError: criticalError,
    handleCriticalError: handleErrorGlobal,
    clearCriticalError: clearCriticalError,
    handleUserLoggedIn: handleUserLoggedIn
  };
};

function getCriticalErrorFromNetworkError(
  error: Error,
  errorInfo?: ErrorInfo,
  user?: User,
  userPermissions?: PermissionId[]
): CriticalError {
  const { message, stack } = error;
  return {
    errorType: ErrorType.HANDLED,
    dateTime: new Date(),
    user: user,
    userPermissions: userPermissions,
    message: message,
    stack: stack,
    debugInfo: JSON.stringify({ error, errorInfo })
  };
}

function getCriticalErrorFromUnhandledPromiseError(
  event: PromiseRejectionEvent,
  user?: User,
  userPermissions?: PermissionId[]
): CriticalError {
  const { reason } = event;
  const { message, stack } = reason;
  return {
    errorType: ErrorType.UNHANDLED,
    dateTime: new Date(),
    user: user,
    userPermissions: userPermissions,
    message: message,
    stack: stack,
  };
}
