import { useCallback } from 'react';
import {
  CalendarYear,
  CalendarYearDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface ICalendarYearsService {
  getCalendarYears: GetAPI<CalendarYear>;
  createCalendarYear: (
    calendarYear: CalendarYearDTO,
    abortController?: AbortController
  ) => Promise<void>;
  getCalendarYear: (
    yearId: number,
    abortController?: AbortController
  ) => Promise<CalendarYear>;
  getCalendarYearFromYear: (
      year: string,
      abortController?: AbortController
  ) => Promise<CalendarYear>;
}

export const useCalendarYearsService = (): ICalendarYearsService => {
  const calendarYearsApi = useAxiosInstance('/v2/calendarYears');

  const getCalendarYears = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<CalendarYear>> => {
      return calendarYearsApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<CalendarYear>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<CalendarYear>;
        });
    },
    [calendarYearsApi]
  );

  const createCalendarYear = useCallback(
    (
      calendarYear: CalendarYearDTO,
      abortController?: AbortController
    ): Promise<void> =>
      calendarYearsApi.post('', calendarYear, {
        signal: abortController?.signal,
        withCredentials: true
      }),
    [calendarYearsApi]
  );

  const getCalendarYear = useCallback(
    (
      yearId: number,
      abortController?: AbortController
    ): Promise<CalendarYear> => {
      return calendarYearsApi
        .get(`${yearId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => getCalendarYearFromDto(response.data as CalendarYearDTO));
    },
    [calendarYearsApi]
  );

  const getCalendarYearFromYear = useCallback(
      (
          year: string,
          abortController?: AbortController
      ): Promise<CalendarYear> => {
        return calendarYearsApi
            .get(`calendarYear/${year}`, {
              withCredentials: true,
              signal: abortController ? abortController.signal : undefined
            })
            .then((response) => getCalendarYearFromDto(response.data as CalendarYearDTO));
      },
      [calendarYearsApi]
  );

  return { getCalendarYears, createCalendarYear, getCalendarYear , getCalendarYearFromYear};
};

function getCalendarYearFromDto(calendarYearDto: CalendarYearDTO): CalendarYear {
  return {
    ...calendarYearDto,
    calendarYearNo: parseInt(calendarYearDto.calendarYear)
  };
}
