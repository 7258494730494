import { TableCell, TableRow } from '@mui/material';
import { debounce, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useLoadingContext, LoadingIndicator } from 'src/common';

import { ErrorHandler, TableHeaderData, TableRowData } from 'src/types';

export type TableBodySubRowsProps = {
  headers: { id: string; label: TableHeaderData }[];
  fetchSubRows: (
    abortController?: AbortController
  ) => Promise<TableRowData[] | void>;
  onRowClicked?: (row: TableRowData) => void;
  checkIsRowSelected?: (row: TableRowData) => boolean;
  rowClass?: (tableRow: TableRowData) => string;
  handleError: ErrorHandler;
};

export function TableBodySubRows(tableBodySubRowsProps: TableBodySubRowsProps) {
  const [rows, setRows] = useState<TableRowData[] | undefined>(undefined);
  const { isLoading, setIsLoading } = useLoadingContext();
  const {
    headers,
    fetchSubRows,
    onRowClicked,
    handleError,
    checkIsRowSelected,
    rowClass
  } = tableBodySubRowsProps;

  const fetchTableDataDebounced = useMemo(() => {
    return debounce(
      (abortController: AbortController) => {
        return fetchSubRows(abortController).then((result) => {
          if (result) {
            setRows([...result]);
          }
        });
      },
      1000,
      { trailing: true, leading: false }
    );
  }, [fetchSubRows]);

  useEffect(() => {
    if (rows !== undefined) {
      return;
    }
    setIsLoading(true);

    const abortController = new AbortController();
    const fetchData = fetchTableDataDebounced(abortController);

    if (fetchData) {
      fetchData.catch(handleError).finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }

    return () => abortController.abort();
  }, [fetchSubRows, fetchTableDataDebounced, handleError, rows, setIsLoading]);

  if (isLoading || !rows) {
    return (
      <TableRow>
        <TableCell colSpan={headers.length}>
          <LoadingIndicator />
        </TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {rows?.map((row, rowIndex) => {
        const isSelected = checkIsRowSelected && checkIsRowSelected(row);
        return (
          <TableRow
            selected={isSelected}
            hover={!!onRowClicked}
            onClick={onRowClicked ? () => onRowClicked(row) : undefined}
            tabIndex={-1}
            key={`row-${rowIndex}-${isSelected ? 'selected' : 'unselected'}`}
            data-testid={`row-${rowIndex}`}
            className={`${rowClass ? rowClass(row) : ''} ${
              rowIndex === rows.length - 1 ? 'last-sub-row' : ''
            } sub-table-row`}
          >
            {headers.map((header, cellIndex) => {
              const cellValue = get(row, header.id, '');

              return (
                <TableCell
                  key={`${rowIndex}-${cellIndex}-${
                    cellValue ? cellValue.toString() : 'undefined'
                  }`}
                  component="td"
                  id={`row-${rowIndex}-cell-${header.id}`}
                  scope="row"
                  padding="normal"
                >
                  {cellValue}
                </TableCell>
              );
            })}
            <TableCell />
          </TableRow>
        );
      })}
    </>
  );
}
