import { AxiosResponse } from 'axios';
import { PaginatedResponseDTO } from '../dto';
import { PaginatedResponse } from '.';

export function getPaginatedResponse<X, Y>(
  response: AxiosResponse,
  dtoToDomainMapper: (dto: X) => Y
) {
  const {
    content,
    first,
    last,
    number,
    totalElements,
    totalPages
  } = response.data as PaginatedResponseDTO<X>;

  return {
    items: content.map<Y>(dtoToDomainMapper),
    hasNextPage: !last,
    hasPrevPage: !first,
    pageNumber: number,
    totalCount: totalElements,
    totalPages: totalPages
  } as PaginatedResponse<Y>;
}
