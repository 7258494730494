import { useCallback, useEffect, useReducer } from 'react';
import { ICacheStore } from '../cache-store-provider/CacheStoreProvider';
import {
  PaginatedControlActionType,
  paginatedControlReducer,
  PaginatedControlState
} from './paginated-control-reducer';

export type UsePaginatedControlProps = {
  cacheStore?: ICacheStore;
  cacheStoreKeySuffix?: string;
};
export interface IPaginatedControl extends PaginatedControlState {
  goToPage: (pageNumber: number) => void;
  setTotalRows: (totalRows: number) => void;
  setRowsPerPage: (rowsPerPage: number) => void;
  setTotalPages: (totalPages: number) => void;
}

const CACHE_STORE_KEY = 'paginated-control';
const DEFAULT_NUMBER_OF_ROWS_PER_PAGE = 10;

function getCacheStoreKey(cacheStoreKeySuffix?: string): string {
  return `${CACHE_STORE_KEY}${cacheStoreKeySuffix ?? ''}`;
}

export const usePaginatedControl = ({
  cacheStore,
  cacheStoreKeySuffix
}: UsePaginatedControlProps = {}): IPaginatedControl => {
  const [state, dispatch] = useReducer(
    paginatedControlReducer,
    cacheStore?.get<PaginatedControlState>(
      getCacheStoreKey(cacheStoreKeySuffix)
    ) || {
      totalPages: 0,
      totalRows: 0,
      rowsPerPage: DEFAULT_NUMBER_OF_ROWS_PER_PAGE,
      currentPage: 0
    }
  );

  useEffect(() => {
    cacheStore?.set(getCacheStoreKey(cacheStoreKeySuffix), state);
  }, [cacheStore, state, cacheStoreKeySuffix]);

  const goToPage = useCallback((pageNumber: number) => {
    dispatch({ type: PaginatedControlActionType.GO_TO_PAGE, pageNumber });
  }, []);

  const setTotalRows = useCallback((totalRows: number) => {
    dispatch({ type: PaginatedControlActionType.SET_TOTAL_ROWS, totalRows });
  }, []);

  const setRowsPerPage = useCallback((rowsPerPage: number) => {
    dispatch({
      type: PaginatedControlActionType.SET_ROWS_PER_PAGE,
      rowsPerPage
    });
  }, []);

  const setTotalPages = useCallback((totalPages: number) => {
    dispatch({ type: PaginatedControlActionType.SET_TOTAL_PAGES, totalPages });
  }, []);

  const { currentPage, rowsPerPage, totalPages, totalRows } = state;

  return {
    goToPage,
    setTotalRows,
    setRowsPerPage,
    setTotalPages,
    currentPage,
    rowsPerPage,
    totalPages,
    totalRows
  };
};
