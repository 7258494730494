import { Box, Stack, Tooltip } from '@mui/material';
import { EventContentArg } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {
  DateRangeType,
  TableEventItem,
  ThemableBoxType
} from 'src/types';
import { ThemableBox } from 'src/common';
import { ReactElement } from 'react';

export type CalendarProps = {
  events: TableEventItem[];
  dateRangeType: DateRangeType;
  visibleTimeStart?: Date;
  visibleTimeEnd?: Date;
  maxEventsPerDay?: number;
  getDayCellHeader?: (date: Date) => ReactElement;
};

const MAX_EVENTS_PER_DAY = 3;

export function Calendar({
  events,
  visibleTimeStart,
  visibleTimeEnd,
  dateRangeType,
  maxEventsPerDay,
  getDayCellHeader
}: CalendarProps) {
  const renderEventContent = (eventInfo: EventContentArg) => {
    return (
      <Tooltip
        title={
          eventInfo.event.extendedProps.tooltip ? (
            <Box>{eventInfo.event.extendedProps.tooltip}</Box>
          ) : (
            ''
          )
        }
      >
        <Box className="fc-event-main-frame">
          <Box className="fc-event-time">{eventInfo.timeText}</Box>
          <Box className="fc-event-title-container">
            <Box className="fc-event-title fc-sticky">
              {eventInfo.event.title}
            </Box>
          </Box>
        </Box>
      </Tooltip>
    );
  };

  function getInitialView(
    dateRangeType: DateRangeType
  ): 'dayGridMonth' | 'dayGridWeek' | 'dayGridDay' {
    switch (dateRangeType) {
      case DateRangeType.DAY:
        return 'dayGridDay';

      case DateRangeType.MONTH:
        return 'dayGridMonth';

      case DateRangeType.WEEK:
        return 'dayGridWeek';

      default:
        throw new Error('unsupported date range type');
    }
  }

  return (
    <ThemableBox type={ThemableBoxType.CALENDAR}>
      <FullCalendar
        dayMaxEvents={maxEventsPerDay ?? MAX_EVENTS_PER_DAY}
        moreLinkClick="popover"
        headerToolbar={false}
        eventContent={renderEventContent}
        contentHeight="auto"
        validRange={{
          start: visibleTimeStart,
          end: visibleTimeEnd
        }}
        eventTimeFormat={{
          hour: 'numeric',
          minute: '2-digit',
          meridiem: false
        }}
        plugins={[dayGridPlugin]}
        initialView={getInitialView(dateRangeType)}
        events={events.map((event) => {
          const eventDetails = {
            title: event.title,
            start: event.startTime,
            end: event.endTime,
            classNames: event.className ? [event.className] : undefined,
            display: 'block',
            allDay: !!event.isCalendarEvent,
            extendedProps: {
              tooltip: event.tooltip
            }
          };

          if (!event.isCalendarEvent && event.url) {
            return {
              ...eventDetails,
              url: event.url
            };
          }

          return eventDetails;
        })}
        dayCellContent={
          getDayCellHeader
            ? ({ date, dayNumberText }) => (
                <Stack width={1}>
                  <Stack direction="row-reverse">{dayNumberText}</Stack>
                  <Stack alignItems="center">
                    <Box className="calendar-day-cell-header">
                      {getDayCellHeader(date)}{' '}
                    </Box>
                  </Stack>
                </Stack>
              )
            : undefined
        }
      />
    </ThemableBox>
  );
}
