/**
 * Checks to see if a text input field is populated
 * @param {unknown} value contains the value of the input that was not entered via text (e.g.,
 * picking from a dropdown, selected elements in a multiselect, etc.). This value often contains
 * collections of objects.
 * @param {unknown} inputValue contains the typed input that was entered (e.g., search filter
 * text, numeric values, etc.). This value won't feature collections of objects, only primitives
 * and literals.
 */

export const isTextFieldPopulated = (value: unknown, inputValue: unknown) => {
  const isNumber =
    !isNaN(parseInt(String(value))) || !isNaN(parseInt(String(inputValue)));
  const isNotEmptyArray = Array.isArray(value) && value.length > 0;
  const isEmptyArray = Array.isArray(value) && value.length === 0;
  return (
    isNumber || (!!value && !isEmptyArray) || !!inputValue || isNotEmptyArray
  );
};
