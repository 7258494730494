import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import { RoomBookingParams } from 'src/services';
import {
  DateRange,
  DateRangeType,
  GetAPI,
  RoomBooking,
  RoomBookingType
} from 'src/types';
import {
  DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
  getAllFromPaginatedApiV2,
  getDateRangeFromDateAndDateRangeType
} from 'src/lib';
import { useAppContext } from 'src/common';
import { BookedBy, Filters } from '../components';

export type BookingsResult = {
  bookings: RoomBooking[];
  isBookingsLoading: boolean;
};

export type UseBookingsProps = {
  dateRange: DateRange;
  filters: Filters;
  bookingType: RoomBookingType;
  getRoomBookings: GetAPI<RoomBooking, RoomBookingParams>;
  isApproverViewEnabled: boolean;
  pageNumber?: number;
  pageSize?: number;
  onPaginatedDetailsChanged?: (
    totalPages: number,
    totalElements: number
  ) => void;
  isHasConflicts?: boolean;
  isPopulateConflicts?: boolean;
  facilityId?: number;
  date?: Date;
  debounceDelayMilliseconds?: number;
};

export function useBookings(params: UseBookingsProps): BookingsResult {
  const { handleError } = useAppContext();

  const [roomBookings, setRoomBookings] = useState<RoomBooking[]>([]);
  const [isBookingsLoading, setIsBookingsLoading] = useState<boolean>(true);

  const {
    getRoomBookings,
    isHasConflicts,
    bookingType,
    dateRange,
    filters,
    pageNumber,
    pageSize,
    onPaginatedDetailsChanged,
    facilityId,
    date,
    debounceDelayMilliseconds,
    isPopulateConflicts
  } = params;

  const fetchBookingsDebounced = useMemo(
    () =>
      debounce(
        (
          dateRange: DateRange,
          filters: Filters,
          abortController: AbortController,
          pageNumber?: number,
          pageSize?: number,
          date?: Date,
          facilityId?: number
        ) => {
          setIsBookingsLoading(true);

          const {
            facility,
            trainingArea,
            activity,
            activityType,
            student,
            studentGroup,
            status,
            bookedBy,
            searchText,
            approver
          } = filters;

          const dateRangeGroupBy = date
            ? getDateRangeFromDateAndDateRangeType(date, DateRangeType.DAY)
            : undefined;

          const roomBookingParams: RoomBookingParams = {
            facilityId: facility?.id ?? facilityId,
            bookingType: bookingType,
            trainingAreaId: trainingArea?.id,
            approverId: approver?.id,
            activityId: activity?.activityId,
            activityTypeId: activityType?.id,
            studentId: student?.id,
            startDateTime: dateRangeGroupBy?.fromDate ?? dateRange.fromDate,
            endDateTime: dateRangeGroupBy?.toDate ?? dateRange.toDate,
            studentGroupId: studentGroup?.id,
            bookingStatus: status,
            bookedByUser: bookedBy === BookedBy.ME,
            searchText: searchText,
            isHasConflicts: isHasConflicts,
            simpleType: !isHasConflicts && !isPopulateConflicts
          };

          if (pageNumber !== undefined && pageSize !== undefined) {
            getRoomBookings({
              pageNumber: pageNumber,
              pageSize: pageSize,
              filters: roomBookingParams,
              abortController: abortController
            })
              .then((bookingsNew) => {
                const { items, totalPages, totalCount } = bookingsNew;
                setRoomBookings(items);
                onPaginatedDetailsChanged &&
                  onPaginatedDetailsChanged(totalPages, totalCount);
              })
              .catch(handleError)
              .finally(() => {
                if (!abortController.signal.aborted) {
                  setIsBookingsLoading(false);
                }
              });
          } else {
            getAllFromPaginatedApiV2({
              getApi: getRoomBookings,
              filters: roomBookingParams,
              abortController: abortController
            })
              .then((bookingsNew) => {
                setRoomBookings(bookingsNew);
              })
              .catch(handleError)
              .finally(() => {
                if (!abortController.signal.aborted) {
                  setIsBookingsLoading(false);
                }
              });
          }
        },
        debounceDelayMilliseconds ?? DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
        { trailing: true, leading: false }
      ),
    [
      getRoomBookings,
      isHasConflicts,
      isPopulateConflicts,
      bookingType,
      onPaginatedDetailsChanged,
      handleError,
      debounceDelayMilliseconds
    ]
  );

  useEffect(() => {
    setIsBookingsLoading(true);
    setRoomBookings([]);
    const abortController = new AbortController();

    fetchBookingsDebounced(
      dateRange,
      filters,
      abortController,
      pageNumber,
      pageSize,
      date,
      facilityId
    );
    return () => {
      abortController.abort();
    };
  }, [
    dateRange,
    filters,
    pageNumber,
    pageSize,
    facilityId,
    date,
    fetchBookingsDebounced
  ]);

  return {
    bookings: roomBookings,
    isBookingsLoading: isBookingsLoading
  };
}
