import { getDateFromDtoDateString } from 'src/lib/date-utils';
import { ProgrammeScheduleDTO } from 'src/types/dto';
import {
  ProgrammeSchedule,
  ProgrammeScheduleStatus
} from 'src/types/models/ProgrammeSchedule.type';

export function getProgrammeScheduleFromDto(
  dto: ProgrammeScheduleDTO
): ProgrammeSchedule {
  return {
    ...dto,
    dateVerified: dto.dateVerified
      ? getDateFromDtoDateString(dto.dateVerified)
      : undefined,
    startDate: getDateFromDtoDateString(dto.startDate),
    endDate: getDateFromDtoDateString(dto.endDate),
    status: getProgrammeScheduleStatusFromDto(dto.status),
    tags: []
  };
}

export function getProgrammeScheduleStatusFromDto(
  status: string
): ProgrammeScheduleStatus {
  switch (status) {
    case 'Active':
      return ProgrammeScheduleStatus.ACTIVE;
    case 'Inactive':
      return ProgrammeScheduleStatus.INACTIVE;
    default:
      throw new Error(`unknown programme schedule status: ${status}`);
  }
}
