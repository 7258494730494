import { Reducer } from 'react';
import { DateRange, DateRangeType } from 'src/types';
import { addTimeToDate, getDateRangeFromDateAndDateRangeType } from 'src/lib';

export enum DateRangeControlActionType {
  GO_TO_NEXT_DATE_RANGE,
  GO_TO_PREVIOUS_DATE_RANGE,
  GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE,
  SET_DATE_RANGE_TYPE,
  SET_DATE_RANGE
}

export type NextDateRangeAction = {
  type: DateRangeControlActionType.GO_TO_NEXT_DATE_RANGE;
};

export type PreviousDateRangeAction = {
  type: DateRangeControlActionType.GO_TO_PREVIOUS_DATE_RANGE;
};

export type SpecificDateRangeContainingDate = {
  type: DateRangeControlActionType.GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE;
  payload: Date;
};

export type SetDateRangeContainingDate = {
  type: DateRangeControlActionType.SET_DATE_RANGE;
  payload: Date;
};

export type SetDateRangeTypeAction = {
  type: DateRangeControlActionType.SET_DATE_RANGE_TYPE;
  payload: DateRangeType;
};

export type SetDateRange = {
  type: DateRangeControlActionType.SET_DATE_RANGE;
  payload: DateRange;
};

export type DateRangeControlAction =
  | NextDateRangeAction
  | PreviousDateRangeAction
  | SetDateRangeTypeAction
  | SpecificDateRangeContainingDate
  | SetDateRange;

export type DateRangeControlState = {
  dateRange: DateRange;
  dateRangeType: DateRangeType;
};

export const dateRangeControlReducer: Reducer<
  DateRangeControlState,
  DateRangeControlAction
> = (state: DateRangeControlState, action: DateRangeControlAction) => {
  const { type } = action;

  switch (type) {
    case DateRangeControlActionType.GO_TO_PREVIOUS_DATE_RANGE: {
      const fromDateNew = addTimeToDate(
        state.dateRange.fromDate,
        -1,
        state.dateRangeType
      );

      const dateRange = getDateRangeFromDateAndDateRangeType(
        fromDateNew,
        state.dateRangeType
      );

      return {
        ...state,
        dateRange: {
          fromDate: dateRange.fromDate,
          toDate: dateRange.toDate
        }
      };
    }
    case DateRangeControlActionType.GO_TO_NEXT_DATE_RANGE: {
      const fromDateNew = addTimeToDate(
        state.dateRange.fromDate,
        1,
        state.dateRangeType
      );

      const dateRange = getDateRangeFromDateAndDateRangeType(
        fromDateNew,
        state.dateRangeType
      );

      return {
        ...state,
        dateRange: {
          fromDate: dateRange.fromDate,
          toDate: dateRange.toDate
        }
      };
    }
    case DateRangeControlActionType.GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE: {
      const { payload } = action;
      const dateRange = getDateRangeFromDateAndDateRangeType(
        payload,
        state.dateRangeType
      );

      return {
        ...state,
        dateRange: dateRange
      };
    }
    case DateRangeControlActionType.SET_DATE_RANGE_TYPE: {
      const dateRange = getDateRangeFromDateAndDateRangeType(
        state.dateRange.fromDate,
        action.payload
      );

      return {
        ...state,
        dateRange: dateRange,
        dateRangeType: action.payload
      };
    }
    case DateRangeControlActionType.SET_DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload
      };
    }
    default: {
      return state;
    }
  }
};
