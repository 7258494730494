import { useCallback } from 'react';
import { useAxiosInstance } from 'src/lib';
import { getProgrammeScheduleFromDto } from 'src/services/dto-mappers';
import {
  BulkProgrammeScheduleDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { ProgrammeScheduleDTO } from 'src/types/dto';
import { ProgrammeSchedule } from 'src/types/models/ProgrammeSchedule.type';

export interface IProgrammeSchedulesService {
  getProgrammeSchedules: GetAPI<ProgrammeSchedule>;
  getMyProgrammeSchedules: GetAPI<ProgrammeSchedule>;
  editProgrammeScheduleStatus: (
    id: number,
    active: string,
    abortController?: AbortController
  ) => Promise<void>;
  scheduleBulkProgrammes: (
    data: BulkProgrammeScheduleDTO,
    abortController?: AbortController
  ) => Promise<void>;
  getProgrammeSchedule: (
    programmeScheduleId: number,
    abortController?: AbortController
  ) => Promise<ProgrammeSchedule>;
  updateProgrammeSchedule: (
    id: number,
    programmeSchedule: ProgrammeSchedule,
    abortController?: AbortController
  ) => Promise<void>;
  deleteProgrammeSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useProgrammeSchedulesService = (): IProgrammeSchedulesService => {
  const programmeSchedulesApi = useAxiosInstance('/v2/programmeSchedules');

  const getProgrammeSchedules = useCallback(
    (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<ProgrammeSchedule>> => {
      return programmeSchedulesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<ProgrammeScheduleDTO>;

          return {
            items: content.map(getProgrammeScheduleFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ProgrammeSchedule>;
        });
    },
    [programmeSchedulesApi]
  );

  const getMyProgrammeSchedules = useCallback(
    (
      request: PaginatedRequest
    ): Promise<PaginatedResponse<ProgrammeSchedule>> => {
      return programmeSchedulesApi
        .get('myProgrammeSchedules', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<ProgrammeScheduleDTO>;

          return {
            items: content.map(getProgrammeScheduleFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ProgrammeSchedule>;
        });
    },
    [programmeSchedulesApi]
  );

  const editProgrammeScheduleStatus = useCallback(
    (
      id: number,
      active: string,
      abortController?: AbortController
    ): Promise<void> =>
      programmeSchedulesApi.put(`toggleStatus/${id}/${active}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [programmeSchedulesApi]
  );

  const scheduleBulkProgrammes = (
    data: BulkProgrammeScheduleDTO,
    abortController?: AbortController
  ): Promise<void> =>
    programmeSchedulesApi.post(
      'bulkProgrammeSchedules',
      { ...data },
      { withCredentials: true, signal: abortController?.signal }
    );

  const getProgrammeSchedule = useCallback(
    (
      programmeScheduleId: number,
      abortController?: AbortController
    ): Promise<ProgrammeSchedule> => {
      return programmeSchedulesApi
        .get(`/${programmeScheduleId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then(response => response.data as ProgrammeSchedule);
    },
    [programmeSchedulesApi]
  );

  const updateProgrammeSchedule = useCallback(
    (
      id: number,
      programmeSchedule: ProgrammeSchedule,
      abortController?: AbortController
    ): Promise<void> =>
      programmeSchedulesApi.put(
        `${id}`,
        getDTOfromProgrammeSchedule(programmeSchedule),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      ),
    [programmeSchedulesApi]
  );

  const deleteProgrammeSchedule = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      programmeSchedulesApi.delete(`${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [programmeSchedulesApi]
  );

  return {
    getProgrammeSchedules,
    scheduleBulkProgrammes,
    editProgrammeScheduleStatus,
    getProgrammeSchedule,
    updateProgrammeSchedule,
    deleteProgrammeSchedule,
    getMyProgrammeSchedules
  };

  function getDTOfromProgrammeSchedule(
    programmeSchedule: ProgrammeSchedule
  ): ProgrammeScheduleDTO {
    return ({ ...programmeSchedule } as unknown) as ProgrammeScheduleDTO;
  }
};
