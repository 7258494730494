import { FC, ReactNode } from 'react';
import { Box, Typography } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common';

export type HeaderToolbarProps = {
  title?: string;
  primaryButtons: ReactNode;
  secondaryButtons: ReactNode;
  tertiaryButtons: ReactNode;
};

const CLASS_NAMES = {
  FIRST_ROW_CONTAINER: 'first-row-container',
  SECOND_ROW_CONTAINER: 'second-row-container',
  TITLE: 'title'
};

export const HeaderToolbar: FC<HeaderToolbarProps> = (
  props: HeaderToolbarProps
) => {
  const { title, primaryButtons, secondaryButtons, tertiaryButtons } = props;

  return (
    <ThemableBox type={ThemableBoxType.BOOKINGS_HEADER_TOOLBAR}>
      <Box className={CLASS_NAMES.FIRST_ROW_CONTAINER}>
        <Box className={CLASS_NAMES.TITLE}>
          {title && <Typography variant="h5">{title}</Typography>}
        </Box>

        <Box>{primaryButtons}</Box>
      </Box>

      <Box className={CLASS_NAMES.SECOND_ROW_CONTAINER}>
        <Box>{tertiaryButtons}</Box>
        <Box>{secondaryButtons}</Box>
      </Box>
    </ThemableBox>
  );
};
