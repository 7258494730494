import { SortDirection } from '@mui/material';
import { FiltersAdapter, GetAPI } from 'src/types';

export type GetAllFromPaginatedApiV2Props<T, F=undefined> = {
  getApi: GetAPI<T, F>;
  pageSize?: number;
  abortController?: AbortController;
  filters?: FiltersAdapter<F>;
  order?: SortDirection;
  orderBy?: string;
  search?: string;
  ids?: number[];
};

export const getAllFromPaginatedApiV2 = async <T, F=undefined>({
  getApi,
  pageSize = 500,
  abortController,
  filters,
  order,
  orderBy,
  search,
  ids
}: GetAllFromPaginatedApiV2Props<T, F>): Promise<Array<T>> => {
  const allResults: Array<T> = [];
  const { totalCount } = await getApi({
    pageNumber: 0,
    pageSize: 1,
    abortController,
    filters,
    order: order,
    orderBy: orderBy,
    search,
    ids: ids
  });

  const { items } = await getApi({
      pageNumber: 0,
      pageSize: totalCount > 0 ? totalCount : 1,
      abortController,
      filters,
      order: order,
      orderBy: orderBy,
      search,
      ids: ids
    });
    allResults.push(...items);

  return allResults;
};
