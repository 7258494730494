import React, { FC } from 'react';
import {
  VerticalNavigationBar,
  VerticalNavigationBarButton
} from '../components';
import { useAuthorizedUser } from 'src/common';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SettingsIcon from '@mui/icons-material/Settings';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import RecentActorsOutlinedIcon from '@mui/icons-material/RecentActorsOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ArticleIcon from '@mui/icons-material/Article';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import EventIcon from '@mui/icons-material/Event';
import { PermissionId } from 'src/types';

const {
  ViewSettings,
  ViewUsers,
  ViewStaff,
  ViewSupervisor,
  ViewFacility,
  ViewForms,
  ViewTrainingArea,
  DoSystemAdmin,
  ViewStudent,
  ViewClassList,
  ViewProgramme,
  ViewModule,
  ViewCapacity,
  ViewTags,
  ViewAcademicBooking,
  ViewAccommodationBooking,
  ViewAdhocBooking,
  ViewTransportBooking,
  ViewPlacementSchedule,
  ViewThemeAutomation,
  ViewEvents,
  ViewActivityTypes,
  ViewThemeSchedule,
  ViewPlacementAutomation
} = PermissionId;

export const VerticalNavigationBarContainer: FC = () => {
  const authorizedUser = useAuthorizedUser();

  const buttons: VerticalNavigationBarButton[] = [
    {
      buttonURL: '/people',
      buttonLabel: 'People',
      buttonIcon: <PeopleOutlineIcon fontSize="large" />,
      isDisabled:
        authorizedUser.isForbiddenTo(ViewStaff) &&
        authorizedUser.isForbiddenTo(ViewSupervisor)
    },
    {
      buttonURL: '/settings',
      buttonLabel: 'Settings',
      buttonIcon: <SettingsIcon fontSize="large" />,
      isDisabled:
        authorizedUser.isForbiddenTo(ViewSettings) ||
        (authorizedUser.isForbiddenTo(ViewUsers) &&
          authorizedUser.isForbiddenTo(ViewPlacementAutomation) &&
          authorizedUser.isForbiddenTo(ViewThemeAutomation) &&
          authorizedUser.isForbiddenTo(DoSystemAdmin) &&
          authorizedUser.isForbiddenTo(ViewProgramme) &&
          authorizedUser.isForbiddenTo(ViewModule) &&
          authorizedUser.isForbiddenTo(ViewTags) &&
          authorizedUser.isForbiddenTo(ViewActivityTypes))
    },
    {
      buttonURL: '/students',
      buttonLabel: 'Students',
      buttonIcon: <SchoolOutlinedIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewStudent)
    },
    {
      buttonURL: '/class-list',
      buttonLabel: 'Class List',
      buttonIcon: <RecentActorsOutlinedIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewClassList)
    },
    {
      buttonURL: '/facilities',
      buttonLabel: 'Facilities',
      buttonIcon: <AccountBalanceIcon fontSize="large" />,
      isDisabled:
        authorizedUser.isForbiddenTo(ViewFacility) &&
        authorizedUser.isForbiddenTo(ViewTrainingArea)
    },
    {
      buttonURL: '/forms',
      buttonLabel: 'Forms',
      buttonIcon: <ArticleIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewForms)
    },
    {
      buttonURL: '/curriculum',
      buttonLabel: 'Curriculum',
      buttonIcon: <CalendarMonthOutlinedIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewProgramme)
    },
    {
      buttonURL: '/schedule-themes',
      buttonLabel: 'Schedule Themes',
      buttonIcon: <ScheduleOutlinedIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewThemeSchedule)
    },
    {
      buttonURL: '/bookings',
      buttonLabel: 'Bookings',
      buttonIcon: <EventIcon fontSize="large" />,
      isDisabled:
        authorizedUser.isForbiddenTo(ViewAcademicBooking) &&
        authorizedUser.isForbiddenTo(ViewAccommodationBooking) &&
        authorizedUser.isForbiddenTo(ViewAdhocBooking) &&
        authorizedUser.isForbiddenTo(ViewTransportBooking) &&
        authorizedUser.isForbiddenTo(ViewPlacementSchedule)
    },
    {
      buttonURL: '/capacity-plans',
      buttonLabel: 'Capacity Plans',
      buttonIcon: <CalendarMonthOutlinedIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewCapacity)
    },
    {
      buttonURL: '/events',
      buttonLabel: 'Events',
      buttonIcon: <EventIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewEvents)
    },
    {
      buttonURL: '/student-logistics',
      buttonLabel: 'Student Logistics',
      buttonIcon: <EventIcon fontSize="large" />,
      isDisabled: authorizedUser.isForbiddenTo(ViewStudent)
    }
  ];

  return <VerticalNavigationBar buttons={buttons} />;
};
