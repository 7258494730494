import { Student, StudentDTO } from 'src/types';
import { getDateFromDtoDateString } from 'src/lib';
import { getGenderFromDto } from './get-gender-from-dto';
import { StudentStatus } from 'src/types/enums/StudentStatus.enum';

export function getStudentFromDto(studentDto: StudentDTO): Student {
  return {
    ...studentDto,
    licenseExpiry: studentDto.licenseExpiry
      ? getDateFromDtoDateString(studentDto.licenseExpiry)
      : undefined,
    genderV2: getGenderFromDto(studentDto.gender),
    isAvailableForBooking: studentDto.availableForBooking,
    studentInGroup: studentDto.studentInGroup,
    status: studentDto.status ? getStudentStatusFromString(studentDto.status): undefined
  };
}

function getStudentStatusFromString(status: string): StudentStatus {
  switch (status) {
    case 'Not Registered':
      return StudentStatus.NOT_REGISTERED;

    case 'Registered':
      return StudentStatus.REGISTERED;     

    case 'New Registration':
      return StudentStatus.NEW_REGISTRATION;     

    case 'Provisional':
      return StudentStatus.PROVISIONAL;
  
    default:
      throw new Error('unexpected student status');
  } 
}