import { PageHeader } from 'src/types';

const linkUrl =
  'https://stellenbosch.sharepoint.com/sites/PlacementPlusTraining/SitePages/LearningTeamHome.aspx';

export const PageHeadingConfig: PageHeader[] = [
  {
    route: '/events/*',
    title: 'Manage Events',
    tooltipText:
      'Establish system-wide events, such as open days, for universal awareness when users create bookings.',
    linkUrl: linkUrl
  },
  {
    route: '/facilities/facilities/*',
    title: 'Manage Facilities',
    tooltipText: 'Manage Facilities',
    linkUrl: linkUrl
  },
  {
    route: '/facilities/training-areas/*',
    title: 'Manage Training Areas',
    tooltipText: 'Manage Training Areas',
    linkUrl: linkUrl
  },
  {
    route: '/people/staff/*',
    title: 'Manage Staff',
    tooltipText: 'Manage staff members and create supervisors',
    linkUrl: linkUrl
  },
  {
    route: '/people/supervisors/*',
    title: 'Manage Supervisors',
    tooltipText: 'Enable module supervisors for academic bookings.',
    linkUrl: linkUrl
  },
  {
    route: '/forms/forms/*',
    title: 'Manage Forms',
    tooltipText: 'Establish and efficiently manage forms.',
    linkUrl: linkUrl
  },
  {
    route: '/forms/form-submissions/*',
    title: 'Manage Form Submissions',
    tooltipText: 'Access all form submissions effortlessly.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/users/*',
    title: 'Manage Users',
    tooltipText:
      'Utilize this screen for efficient user management. Assign roles, update rights, and allocate modules as needed.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/roles/*',
    title: 'Manage Roles',
    tooltipText: 'Administrate user access through role management.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/tags/*',
    title: 'Manage Tags',
    tooltipText:
      'Track, load, and update module outcomes through the tag management feature.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/activity-types/*',
    title: 'Manage Activity Types',
    tooltipText: 'Administer assignable activity types.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/theme-automation/*',
    title: 'Manage Theme Automation',
    tooltipText: 'Automate Themes.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/placement-automation/*',
    title: 'Manage Placement Automation ',
    tooltipText: 'Automate Placements.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/manage-programmes/*',
    title: 'Manage Programmes ',
    tooltipText:
      'Efficiently handle all programs: view, edit, create, and schedule.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/manage-modules/*',
    title: 'Manage Modules ',
    tooltipText:
      'Efficiently handle all modules: view, edit, create, and schedule. Update class lists seamlessly.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/manage-themes/*',
    title: 'Manage Themes ',
    tooltipText:
      'Efficiently manage all themes: view, edit, create, and schedule.',
    linkUrl: linkUrl
  },
  {
    route: '/settings/download-audit/*',
    title: 'Manage Audit Logs ',
    tooltipText:
      'Download comprehensive audit logs for all user interactions with the system.',
    linkUrl: linkUrl
  },
  {
    route: '/module-supervisors/*',
    title: 'Manage Module Supervisors',
    tooltipText:
      'View active supervisors assigned to your module for placement as overseers in academic bookings.',
    linkUrl: linkUrl
  },
  {
    route: '/curriculum/programmes-schedules',
    title: 'Manage Programme Schedules',
    tooltipText:
      'Effortlessly review program schedules, validate programs, and swiftly navigate to relevant pages.',
    linkUrl: linkUrl
  },
  {
    route: '/curriculum/programmes-schedules/create-calendar-year',
    title: 'Manage Programme Schedules',
    tooltipText:
      'Effortlessly review program schedules, validate programs, and swiftly navigate to relevant pages.',
    linkUrl: linkUrl
  },
  {
    route: '/curriculum/programmes-schedules/calendar-year/*',
    title: 'Manage Programme Schedules',
    tooltipText:
      'Effortlessly review program schedules, validate programs, and swiftly navigate to relevant pages.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/view-programme-schedule',
    title: 'Manage Programme Schedules',
    tooltipText:
      'Effortlessly review program schedules, validate programs, and swiftly navigate to relevant pages.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules',
    title: 'Manage Module Schedules',
    tooltipText:
      'Quickly verify modules, overview themes and activities, and manage module status with ease.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/calendar-year/*',
    title: 'Manage Module Schedules',
    tooltipText:
      'Quickly verify modules, overview themes and activities, and manage module status with ease.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/view-module-schedule',
    title: 'Manage Module Schedules',
    tooltipText:
      'Quickly verify modules, overview themes and activities, and manage module status with ease.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/theme-schedules',
    title: 'Manage Theme Schedules',
    tooltipText: 'Efficiently create and oversee themes assigned to modules.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/theme-schedules/calendar-year/*',
    title: 'Manage Theme Schedules',
    tooltipText: 'Efficiently create and oversee themes assigned to modules.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/theme-schedules/:themeScheduleId/view-theme-schedule',
    title: 'Manage Theme Schedules',
    tooltipText: 'Efficiently create and oversee themes assigned to modules.',
    linkUrl: linkUrl
  },
  {
    route:
        '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/theme-schedules/delete-themes',
    title: 'Manage Theme Schedules',
    tooltipText: 'Efficiently create and oversee themes assigned to modules.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/theme-schedules/:themeScheduleId/activities/*',
    title: 'Manage Activities',
    tooltipText:
      'Effectively create and oversee activities. Import from other modules, check activity type definitions, and not only view but also create academic bookings.',
    linkUrl: linkUrl
  },
  {
    route:
      '/curriculum/programmes-schedules/:programmeScheduleId/module-schedules/:moduleScheduleId/activities/*',
    title: 'Manage Activities',
    tooltipText:
      'Effectively create and oversee activities. Import from other modules, check activity type definitions, and not only view but also create academic bookings.',
    linkUrl: linkUrl
  },
  {
    route: '/schedule-themes/*',
    title: 'Manage Scheduled Themes',
    tooltipText: 'Schedule the activation of a theme for a specific period.',
    linkUrl: linkUrl
  },
  {
    route: '/capacity-plans/*',
    title: 'Manage Capacity Plan',
    tooltipText:
      'Assign training areas to modules with designated times and capacities. Transfer between years and download for analysis purposes.',
    linkUrl: linkUrl
  },
  {
    route: '/students/*',
    title: 'Manage Students',
    tooltipText:
      'Gain a comprehensive overview of students, including registration details, ongoing rotations, and transport needs. Manage student supervisors seamlessly.',
    linkUrl: linkUrl
  },
  {
    route: '/class-list/*',
    title: 'Manage Class List',
    tooltipText:
      'View module class lists, administer groups and subgroups, and manage students seamlessly.',
    linkUrl: linkUrl
  },
  {
    route: '/student-logistics/*',
    title: 'Manage Student Logistics',
    tooltipText:
        'Access reports on private vehicle usage and other student logistics effortlessly.',
    linkUrl: linkUrl
  },
  {
    route: '/bookings/academic/*',
    title: 'Manage Academic Bookings',
    tooltipText:
      'Obtain a comprehensive overview of all academic bookings. Import the calendar into your personal calendar or download it for analysis purposes.',
    linkUrl: linkUrl
  },
  {
    route: '/bookings/transport/*',
    title: 'Manage Transport Bookings',
    tooltipText: 'Access a concise overview of all transport bookings.',
    linkUrl: linkUrl
  },
  {
    route: '/bookings/accommodation/*',
    title: 'Manage Accommodation Bookings',
    tooltipText: 'Access a concise overview of all accommodation bookings.',
    linkUrl: linkUrl
  },
  {
    route: '/bookings/adhoc/*',
    title: 'Manage Adhoc Bookings',
    tooltipText: 'Effortlessly create, manage, and view all adhoc bookings.',
    linkUrl: linkUrl
  }
];
