import React, { FC } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';
import MoreVertIcon from '@mui/icons-material/MoreVert';

export const ListHeaderSkeleton: FC = () => {
  return (
    <ThemableBox type={ThemableBoxType.NOTIFICATIONS_HEADER}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <Typography variant="h3">Loading notifications...</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton disabled={true}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ThemableBox>
  );
};
