export enum AssignmentMethodType {
  NO_ASSIGNMENT_SPECIFIED,
  ASSIGN_NUMBER_OF_STUDENTS,
  ASSIGN_NUMBER_OF_GROUPS
}

export type AssignmentOfStudents = {
  type: AssignmentMethodType.ASSIGN_NUMBER_OF_STUDENTS;
  numberOfStudents: number;
};

export type AssignmentOfGroups = {
  type: AssignmentMethodType.ASSIGN_NUMBER_OF_GROUPS;
  numberOfGroups: number;
};

export type NoAssignmentSpecified = {
  type: AssignmentMethodType.NO_ASSIGNMENT_SPECIFIED;
};

export type AssignmentMethod =
  | AssignmentOfStudents
  | AssignmentOfGroups
  | NoAssignmentSpecified;

export type StudentGroupCreate = {
  groupName: string;
  moduleScheduleId: number;
  assignmentMethod: AssignmentMethod;
  groupId?: number;
  isPlacementGroup?: boolean;
  isAutomationLocked?: boolean;
};
