import React, { useCallback } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Form as FormikForm, Formik } from 'formik';
import {
  EditSeries,
  FormType,
  GetAPI,
  PaginatedRequest,
  PlacementScheduleStudentDetails,
  RoomBookingStatus,
  RoomBookingType
} from 'src/types';
import { Pill, PillStyle } from 'src/common';
import {
  DialogControl,
  FormButtonControlConfig,
  FormButtonGroup,
  FormButtonType,
  FormElementsLayout,
  FormElementsPanel,
  FormFooterLayout,
  FormFooterPanel,
  FormOuterLayout,
  FormOuterPanel
} from 'src/common-v2';
import {
  getConflictsPillLabel,
  getPlacementScheduleStudentDetailsFromStudent,
  getRoomBookingFromType,
  REVIEW_DISABLED_TOOLTIP
} from '../../lib';
import { IsEditBookingAsSeries } from '../IsEditBookingAsSeries';
import { BookingDetailsForm } from './BookingDetailsForm';
import { useBookingStudents } from './useBookingStudents';
import {
  getApprovePermission,
  getDeletePermission,
  getInitialValues,
  getOnSubmit,
  getValidate,
  isUpdateFormSaveButtonVisible
} from './form-functions';
import { FormProps, FormValues, PlacementPlusVersion } from './form-types';
import { StudentsForm } from './StudentsForm';
import { getDateTimeWithDate, getDateTimeWithTime } from 'src/lib';

export function BookingForm(formProps: FormProps) {
  const ENTITY_NAME = `${formProps.facilityType} Booking`;

  /**
   * @returns {@link true} if the booking was created by legacy / pre-rebuild placement-plus.
   * @returns {@link false} otherwise
   */
  function isLegacyBooking(formProps: FormProps) {
    return formProps.initialValues?.moduleScheduleId === undefined;
  }

  const { moduleStudents, isModulesStudentsLoading } = useBookingStudents({
    moduleScheduleId:
      formProps.initialValues?.moduleScheduleId ??
      formProps.placementSchedule?.moduleScheduleId,
    roomBookingId: formProps.initialValues?.id,
    placementSchedule: formProps.placementSchedule,
    getClassListStudents: formProps.getClassListStudents,
    getStudents: formProps.getStudents
  });

  const getIndividualStudents = useCallback<
    GetAPI<PlacementScheduleStudentDetails>
  >(
    (request: PaginatedRequest) => {
      return formProps.getStudents(request).then((response) => {
        return {
          ...response,
          items: response.items.map(
            getPlacementScheduleStudentDetailsFromStudent
          )
        };
      });
    },
    [formProps]
  );

  function getFormTitle(roomBookingType: RoomBookingType): string {
    return `${formProps.isApprover ? 'Submitted' : ''} ${getRoomBookingFromType(
      roomBookingType
    )}  Booking`;
  }

  return (
    <Formik<FormValues>
      initialValues={getInitialValues(formProps, moduleStudents)}
      onSubmit={getOnSubmit(formProps)}
      validate={getValidate(formProps)}
      enableReinitialize={true}
    >
      {(formikHelpers) => {
        const { initialValues, setValues, values, dirty } = formikHelpers;
        return (
          <FormikForm>
            <FormOuterPanel>
              <FormOuterLayout
                formElements={
                  <FormElementsPanel>
                    <FormElementsLayout
                      formHeading={
                        <Stack direction="row" spacing={2}>
                          <Typography variant="h5">
                            {formProps.formType === FormType.CREATE
                              ? `Create ${getRoomBookingFromType(
                                  formProps.bookingType
                                )}  Booking`
                              : getFormTitle(formProps.bookingType)}
                          </Typography>

                          {formProps.formType !== FormType.CREATE &&
                            formProps.initialValues.conflictsCount !==
                              undefined &&
                            formProps.initialValues.conflictsCount > 0 && (
                              <Pill
                                label={getConflictsPillLabel(
                                  formProps.initialValues.conflictsCount
                                )}
                                onClick={formProps.onConflictsPillClick}
                                pillStyle={PillStyle.RED}
                              />
                            )}
                        </Stack>
                      }
                      formPrimaryActions={
                        <FormButtonGroup
                          authorizedUser={formProps.authorizedUser}
                        >
                          {() => {
                            switch (formProps.formType) {
                              case FormType.VIEW:
                              case FormType.UPDATE: {
                                const APPROVE_REJECT_BUTTON: FormButtonControlConfig =
                                  {
                                    type: FormButtonType.BASE,
                                    label: 'Approve/Reject',
                                    variant: 'approve',
                                    requiredPermissions: [
                                      getApprovePermission(
                                        formProps.bookingType
                                      )
                                    ],
                                    disabledTooltip: REVIEW_DISABLED_TOOLTIP,
                                    disabled:
                                      formProps.initialValues.studentIds
                                        ?.length === 0 ||
                                      formProps.initialValues.status !==
                                        RoomBookingStatus.AWAITING_APPROVAL ||
                                      !formProps.isUserAbleToApproveBooking,
                                    onClick: formProps.onApproveButtonClick
                                  };

                                const DELETE_BUTTON: FormButtonControlConfig = {
                                  type: FormButtonType.BASE,
                                  onClick: formProps.onDeleteButtonClick,
                                  label: 'Delete',
                                  variant: 'delete',
                                  endIcon: <CloseIcon />,
                                  requiredPermissions: [
                                    getDeletePermission(formProps.bookingType)
                                  ]
                                };

                                if (formProps.isApprover) {
                                  return [APPROVE_REJECT_BUTTON, DELETE_BUTTON];
                                } else {
                                  return [DELETE_BUTTON];
                                }
                              }
                            }
                          }}
                        </FormButtonGroup>
                      }
                      formFields={
                        <Grid container>
                          <Grid
                            item
                            xs={
                              formProps.bookingType === RoomBookingType.ADHOC
                                ? 12
                                : 4
                            }
                          >
                            {formProps.formType === FormType.UPDATE &&
                              initialValues.recurrence.enabled &&
                              values.editSeries === undefined &&
                              dirty && (
                                <DialogControl title="Recurrence">
                                  <IsEditBookingAsSeries
                                    startDateTime={initialValues.startDateTime}
                                    type={initialValues.bookingType}
                                    handleModalSubmit={(
                                      isEditSeries: EditSeries
                                    ) => {
                                      const {
                                        recurrence,
                                        startDateTime,
                                        endDateTime
                                      } = initialValues;
                                      const { departureDate } = recurrence;

                                      if (isEditSeries === EditSeries.SERIES) {
                                        const endDateTimeNew =
                                          getDateTimeWithDate(
                                            endDateTime,
                                            departureDate
                                          );

                                        setValues({
                                          ...values,
                                          editSeries: isEditSeries,
                                          endDateTime: getDateTimeWithTime(
                                            endDateTimeNew,
                                            values.endDateTime
                                          )
                                        });
                                      } else {
                                        setValues({
                                          ...values,
                                          editSeries: isEditSeries,
                                          startDateTime: getDateTimeWithTime(
                                            startDateTime,
                                            values.startDateTime
                                          ),
                                          endDateTime: getDateTimeWithTime(
                                            endDateTime,
                                            values.endDateTime
                                          )
                                        });
                                      }
                                    }}
                                  />
                                </DialogControl>
                              )}

                            <BookingDetailsForm
                              formikHelpers={formikHelpers}
                              formType={formProps.formType}
                              bookingType={formProps.bookingType}
                              facilityType={formProps.facilityType}
                              getFacilities={formProps.getFacilities}
                              getDrivers={formProps.getDrivers}
                              getTags={formProps.getTags}
                              getTrainingAreas={formProps.getTrainingAreas}
                              isApprover={formProps.isApprover}
                              authorizedUser={formProps.authorizedUser}
                            />
                          </Grid>
                          {formProps.bookingType !== RoomBookingType.ADHOC && (
                            <Grid item xs={8}>
                              {isLegacyBooking(formProps) &&
                              formProps.formType === FormType.UPDATE ? (
                                <StudentsForm
                                  placementPlusVersion={
                                    PlacementPlusVersion.LEGACY
                                  }
                                  formikHelpers={formikHelpers}
                                  type={formProps.formType}
                                  getModuleScheduleStudents={
                                    getIndividualStudents
                                  }
                                  isModuleStudentsLoading={
                                    isModulesStudentsLoading
                                  }
                                  isApprover={formProps.isApprover}
                                />
                              ) : (
                                <StudentsForm
                                  placementPlusVersion={
                                    PlacementPlusVersion.REBUILD
                                  }
                                  formikHelpers={formikHelpers}
                                  type={formProps.formType}
                                  moduleScheduleStudents={moduleStudents}
                                  isModuleStudentsLoading={
                                    isModulesStudentsLoading
                                  }
                                  isApprover={formProps.isApprover}
                                />
                              )}
                            </Grid>
                          )}
                        </Grid>
                      }
                    />
                  </FormElementsPanel>
                }
                formFooter={
                  <FormFooterPanel>
                    <FormFooterLayout
                      leftChildren={
                        <FormButtonGroup
                          authorizedUser={formProps.authorizedUser}
                        >
                          {() => [
                            {
                              type: FormButtonType.NAVIGATE_BACK,
                              onClick: formProps.onCloseButtonClick
                            }
                          ]}
                        </FormButtonGroup>
                      }
                      rightChildren={
                        <FormButtonGroup
                          authorizedUser={formProps.authorizedUser}
                        >
                          {() => {
                            switch (formProps.formType) {
                              case FormType.CREATE:
                                return [
                                  {
                                    type: FormButtonType.SAVE_NEW_ENTITY,
                                    entityName: ENTITY_NAME
                                  }
                                ];
                              case FormType.UPDATE:
                                if (
                                  !isUpdateFormSaveButtonVisible(
                                    initialValues,
                                    values
                                  )
                                ) {
                                  return;
                                }

                                return [
                                  {
                                    type: FormButtonType.SAVE_CHANGES_TO_EXISTING_ENTITY,
                                    entityName: ENTITY_NAME,
                                    onSuccessCallback: () => {
                                      /* Empty Success Callback method to prevent navigating away from the manage booking page*/
                                    },
                                    disabled: isModulesStudentsLoading
                                  }
                                ];
                            }
                          }}
                        </FormButtonGroup>
                      }
                    />
                  </FormFooterPanel>
                }
              />
            </FormOuterPanel>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
