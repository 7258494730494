import { Button, Stack } from '@mui/material';
import { FormLayout, Table, TableType } from 'src/common';
import { RoomBookingType, TableRowData } from 'src/types';
import { useNavigate } from 'react-router-dom';

export type BookingConflictsProps = {
  tableRowData: TableRowData[];
  bookingType: RoomBookingType;
};

const headersCommon = [
  { id: 'capacity', label: 'Capacity' },
  { id: 'facility', label: 'Facility' },
  { id: 'trainingSite', label: 'Training Site' },
  { id: 'startDate', label: 'Start Date and Time' },
  { id: 'endDate', label: 'End Date and Time' },
  { id: 'lastUpdated', label: 'Last Updated' },
  { id: 'status', label: 'Status' },
  { id: 'bookedBy', label: 'Booked By' },
  { id: 'editButton', label: 'Actions' }
];

const headersOther = [{ id: 'title', label: 'Title' }, ...headersCommon];

const headersAcademic = [
  { id: 'module', label: 'Module' },
  { id: 'theme', label: 'Theme' },
  { id: 'activity', label: 'Activity' },
  ...headersCommon
];

export function BookingConflicts(props: BookingConflictsProps) {
  const navigate = useNavigate();

  return (
    <FormLayout size="large">
      <Stack spacing={2}>
        <Table
          type={TableType.STATIC}
          headers={
            props.bookingType === RoomBookingType.ACADEMIC
              ? headersAcademic
              : headersOther
          }
          rows={props.tableRowData}
        />
        <Stack spacing={1} direction="row" justifyContent="flex-end">
          <Button onClick={() => navigate(-1)} variant="back">
            OK
          </Button>
        </Stack>
      </Stack>
    </FormLayout>
  );
}
