export const compareStringsIgnoreCase = (
  stringOne: string,
  stringTwo: string
): number => {
  const stringOneLowerCase = stringOne.toLowerCase();
  const stringTwoLowerCase = stringTwo.toLowerCase();

  return stringOneLowerCase === stringTwoLowerCase
    ? 0
    : stringOneLowerCase < stringTwoLowerCase
    ? -1
    : 1;
};

export function getNumberWithOrdinal(n: number) {
  let ord = 'th';
  if (n % 10 == 1 && n % 100 != 11) {
    ord = 'st';
  } else if (n % 10 == 2 && n % 100 != 12) {
    ord = 'nd';
  } else if (n % 10 == 3 && n % 100 != 13) {
    ord = 'rd';
  }
  return `${n}${ord}`;
}

const SPACE = ' ';

export function getTitleCase(sentence: string) {
  if(!sentence)
    return sentence;
  return sentence
    .toLowerCase()
    .split(SPACE)
    .map((word) => word.replace(word[0], word[0].toUpperCase()))
    .join(SPACE);
}