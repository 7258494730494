import {
  Box,
  Table as MuiTable,
  TableContainer,
  TableBody as MuiTableBody,
  TableCell,
  TableRow,
  Button,
  SortDirection
} from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { debounce, get } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { TableType } from '../Table';
import {
  useTableContext,
  TableFooter,
  TableHeader,
  RefreshStatus
} from '../components';
import {
  FetchRows,
  PaginatedRequest,
  TableHeaderData,
  TableRowData,
  ThemableBoxType
} from 'src/types';
import { useAppContext } from '../../app-context-provider/AppContext';
import { useLoadingContext, TableBodySubRows } from 'src/common';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

export type TablePaginatedCollapsibleRowProps = {
  type: TableType.PAGINATED_COLLAPSIBLE_ROW;
  headers: { id: string; label: TableHeaderData }[];
  fetchRows: FetchRows<TableRowData>;
  search?: string;
  filters?: Record<string, string>;
  fetchCondition?: boolean;
  rowClass?: (tableRowData: TableRowData) => string;
  onRowClicked?: (row: TableRowData) => void;
  checkIsRowSelected?: (row: TableRowData) => boolean;
  isHasSubRows: (row: TableRowData) => boolean;
  fetchSubRows: (
    row: TableRowData,
    filters: Record<string, string>,
    order: SortDirection,
    orderBy: string,
    search: string | undefined,
    abortController?: AbortController
  ) => Promise<TableRowData[] | void>;
};

export const TablePaginatedCollapsibleRowContainer = ({
  headers,
  fetchRows,
  fetchCondition = true,
  rowClass,
  checkIsRowSelected,
  onRowClicked,
  isHasSubRows,
  fetchSubRows
}: TablePaginatedCollapsibleRowProps) => {
  const { handleError } = useAppContext();
  const [isExpanded, setIsExpanded] = useState<Set<number>>(new Set());
  const [currentRows, setCurrentRows] = useState<TableRowData[] | undefined>(
    undefined
  );
  const [totalRows, setTotalRows] = useState<number>(0);
  const tableContext = useTableContext();
  const {
    order,
    orderBy,
    handlePageChange,
    handlePageSizeChange,
    handleRequestSort,
    page,
    pageSize,
    filters,
    search,
    disableSort,
    disableSortColumns,
    refreshStatus,
    setRefreshStatus
  } = tableContext;
  const { setIsLoading } = useLoadingContext();
  const handleOnGroupHeaderClick = (groupId: number) =>
    setIsExpanded((currentValue) => {
      const newValue = new Set<number>(Array.from(currentValue.values()));
      if (newValue.has(groupId)) {
        newValue.delete(groupId);
      } else {
        newValue.add(groupId);
      }
      return newValue;
    });

  const fetchTableDataDebounced = useMemo(() => {
    setIsLoading(true);
    return debounce(
      (queryParams: PaginatedRequest) => {
        setIsLoading(true);
        fetchRows(queryParams)
          .then((result) => {
            const { totalCount, items } = result;
            setTotalRows(totalCount);
            setIsExpanded(new Set<number>());
            setCurrentRows([...items]);
            setRefreshStatus(RefreshStatus.REFRESHED);
          })
          .catch(handleError)
          .finally(() => setIsLoading(false));
      },
      1000,
      { trailing: true, leading: false }
    );
  }, [fetchRows, handleError, setIsLoading, setRefreshStatus]);

  useEffect(() => {
    if (fetchCondition || refreshStatus === RefreshStatus.REFRESHING) {
      const abortController = new AbortController();
      setTotalRows(0);
      setCurrentRows(undefined);
      fetchTableDataDebounced({
        pageNumber: page,
        pageSize,
        order: order,
        orderBy,
        search,
        filters,
        abortController
      });
      return () => abortController.abort();
    }
  }, [page, pageSize, order, orderBy, search, filters, fetchTableDataDebounced, fetchCondition, refreshStatus]);

  return (
    <Box mt={2}>
      <TableContainer>
        <ThemableBox type={ThemableBoxType.COLLAPSIBLE_TABLE}>
          <MuiTable>
            <TableHeader
              headers={[...headers, { id: 'expanded', label: '' }]}
              order={order}
              orderBy={orderBy}
              handleSort={handleRequestSort}
              disableSort={disableSort}
              disableSortColumns={disableSortColumns}
            />
            {currentRows === undefined ? (
              <MuiTableBody>
                <TableRow key={1} data-testid={`row-${1}`}>
                  <TableCell colSpan={headers.length} align="center">
                    <></>
                  </TableCell>
                </TableRow>
              </MuiTableBody>
            ) : currentRows.length <= 0 ? (
              <MuiTableBody>
                <TableRow
                  key={`row-${1}-no-data-to-display`}
                  data-testid={`row-${1}`}
                >
                  <TableCell colSpan={headers.length} align="center">
                    No data to display
                  </TableCell>
                </TableRow>
              </MuiTableBody>
            ) : (
              <MuiTableBody>
                <TableRow className="blank-row" />
                {currentRows.map((row, rowIndex) => {
                  const isSelected =
                    checkIsRowSelected && checkIsRowSelected(row);
                  return (
                    <>
                      <TableRow
                        selected={isSelected}
                        hover={!!onRowClicked}
                        onClick={
                          onRowClicked ? () => onRowClicked(row) : undefined
                        }
                        tabIndex={-1}
                        key={`row-${rowIndex}-${
                          isSelected ? 'selected' : 'unselected'
                        }`}
                        data-testid={`row-${rowIndex}`}
                        className={`${rowClass ? rowClass(row) : ''} ${
                          isExpanded.has(rowIndex) ? 'expanded' : ''
                        }`}
                      >
                        {headers.map((header, cellIndex) => {
                          const cellValue = get(row, header.id, '');
                          return (
                            <TableCell
                              key={`${rowIndex}-${cellIndex}-${
                                cellValue ? cellValue.toString() : 'undefined'
                              }`}
                              component="td"
                              id={`row-${rowIndex}-cell-${header.id}`}
                              data-testid={`row-${rowIndex}-cell-${header.id}`}
                              scope="row"
                              padding="normal"
                            >
                              {cellValue}
                            </TableCell>
                          );
                        })}
                        {isHasSubRows(row) ? (
                          <TableCell
                            key={`${rowIndex}`}
                            component="td"
                            id={`row-${rowIndex}-cell-expand`}
                            data-testid={`row-${rowIndex}-cell-expand`}
                            scope="row"
                            padding="normal"
                            align="right"
                          >
                            <Button
                              className="btn-expand"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOnGroupHeaderClick(rowIndex);
                              }}
                            >
                              {isExpanded.has(rowIndex) ? (
                                <ExpandLessIcon />
                              ) : (
                                <ExpandMoreIcon />
                              )}
                            </Button>
                          </TableCell>
                        ) : (
                          <TableCell
                            key={`${rowIndex}`}
                            component="td"
                            id={`row-${rowIndex}-cell-expand`}
                            data-testid={`row-${rowIndex}-cell-expand`}
                            scope="row"
                            padding="normal"
                          />
                        )}
                      </TableRow>
                      {isHasSubRows(row) && isExpanded.has(rowIndex) && (
                        <TableBodySubRows
                          headers={headers}
                          fetchSubRows={(abortController) =>
                            fetchSubRows(
                              row,
                              filters,
                              order,
                              orderBy,
                              search,
                              abortController
                            )
                          }
                          onRowClicked={onRowClicked}
                          handleError={handleError}
                        />
                      )}
                      <TableRow className="blank-row">
                        <></>
                      </TableRow>
                    </>
                  );
                })}
              </MuiTableBody>
            )}
          </MuiTable>
        </ThemableBox>
      </TableContainer>
      <TableFooter
        rowCount={totalRows}
        pageSize={pageSize}
        page={page}
        handlePageChange={handlePageChange}
        handlePageSizeChange={handlePageSizeChange}
      />
    </Box>
  );
};
