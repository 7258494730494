import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common';
import { ListViewTableActions, SelectActionOption } from 'src/common-v2';

export type CriticalErrorScreenProps = {
  onCopyErrorButtonClick: () => void;
  onDownloadErrorReportButtonClick: () => void;
  onSendEmailButtonClick: () => void;
  onBackToHomeButtonClick: () => void;
  onBackToPreviousPageButtonClick?: () => void;
};

const ERROR_SCREEN_DESCRIPTION_1 =
  'The details of what went wrong might be quite technical and not mean much to you, but it will help ' +
  'the administrator understand the cause and provide you with support. Please click below to send an ' +
  'email to the administrator.';
const ERROR_SCREEN_DESCRIPTION_2 =
  'While you wait for a response, you can attempt to continue using Placement+ by clicking the ' +
  '"Back to Home" button, or the "Back to Previous Page" button at the bottom of the page.';

/*
 * This component is displayed when a critical error occurs in the
 * application. The component itself doesn't control when it's displayed.
 */
export const CriticalErrorScreen = ({
  onCopyErrorButtonClick,
  onDownloadErrorReportButtonClick,
  onSendEmailButtonClick,
  onBackToHomeButtonClick,
  onBackToPreviousPageButtonClick
}: CriticalErrorScreenProps) => {
  const CLASS_NAMES = {
    HEADING_MAIN: 'error-screen-heading-main',
    CONTENT: 'error-screen-content',
    HEADING_SUBTITLE: 'error-screen-heading-subtitle',
    ERROR_DETAILS: 'error-screen-error-details',
    ERROR_DETAILS_HEADING: 'error-screen-error-details-heading',
    ERROR_DETAILS_BODY: 'error-screen-error-details-body',
    ERROR_DETAILS_BODY_BUTTON: 'error-screen-error-details-body-button',
    BUTTONS: 'error-screen-buttons'
  };

  const advancedOptionActions: SelectActionOption[] = [
    {
      label: 'Download Error Report',
      onClick: onDownloadErrorReportButtonClick,
      key: 0
    },
    {
      label: 'Copy Error Report to Clipboard',
      onClick: onCopyErrorButtonClick,
      key: 1
    }
  ];

  return (
    <ThemableBox type={ThemableBoxType.CRITICAL_ERROR_SCREEN}>
      <Box className={CLASS_NAMES.CONTENT}>
        <Box className={CLASS_NAMES.HEADING_MAIN}>
          <Typography>Oops!</Typography>
        </Box>

        <Box className={CLASS_NAMES.HEADING_SUBTITLE}>
          <Typography>Something went wrong!</Typography>
        </Box>

        <Box className={CLASS_NAMES.ERROR_DETAILS}>
          <Box className={CLASS_NAMES.ERROR_DETAILS_HEADING}>
            <Typography>Error details</Typography>
          </Box>

          <Box className={CLASS_NAMES.ERROR_DETAILS_BODY}>
            <Typography whiteSpace="pre-wrap">
              {ERROR_SCREEN_DESCRIPTION_1}
            </Typography>
            <Typography whiteSpace="pre-wrap">
              {ERROR_SCREEN_DESCRIPTION_2}
            </Typography>
            <Box className={CLASS_NAMES.ERROR_DETAILS_BODY_BUTTON}>
              <Stack direction="row" display="flex" spacing={4}>
                <Button
                  onClick={onSendEmailButtonClick}
                  color="primary"
                  variant="contained"
                >
                  Send Email To Administrator
                </Button>
                <ListViewTableActions
                  options={advancedOptionActions}
                  label="Advanced Options"
                  styleVariant="outlined"
                />
              </Stack>
            </Box>
          </Box>
        </Box>

        <Box className={CLASS_NAMES.BUTTONS}>
          <Button onClick={onBackToHomeButtonClick} variant="outlined">
            Back to home
          </Button>

          {onBackToPreviousPageButtonClick && (
            <Button
              onClick={onBackToPreviousPageButtonClick}
              color="primary"
              variant="contained"
            >
              Back to previous page
            </Button>
          )}
        </Box>
      </Box>
    </ThemableBox>
  );
};
