import { FC } from 'react';
import { Box, Drawer, Stack } from '@mui/material';
import Logo from 'src/assets/images/logo.svg';
import { ShowIf } from 'src/common';
import { NavigationLogo } from './NavigationLogo';
import { NavigationLink } from './NavigationLink';

export type VerticalNavigationBarButton = {
  buttonURL: string;
  buttonLabel: string;
  buttonIcon: JSX.Element;
  isDisabled?: boolean;
};

export type VerticalNavigationBarProps = {
  buttons: VerticalNavigationBarButton[];
};

export const VerticalNavigationBar: FC<VerticalNavigationBarProps> = ({
  buttons
}) => {
  return (
    <Drawer variant="permanent" PaperProps={{ sx: { borderRight: 0 } }}>
      <Box
        sx={{
          boxSizing: 'border-box'
        }}
      >
        <Stack>
          <NavigationLogo logoPath={Logo} navigateTo="/" altText="SUN Logo" />
          {buttons.map((item, index) => (
            <ShowIf condition={!item.isDisabled} key={index}>
              <NavigationLink
                to={item.buttonURL}
                primary={item.buttonLabel}
                icon={item.buttonIcon}
                key={index}
              />
            </ShowIf>
          ))}
        </Stack>
      </Box>
    </Drawer>
  );
};
