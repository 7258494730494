import { TrainingAreaDTO } from 'src/types/dto/TrainingAreaDTO.type';

export type TrainingArea = Omit<TrainingAreaDTO, 'facilityType'> & {
  facilityType: TrainingAreaFacilityType;
};

export enum TrainingAreaFacilityType {
  ACCOMMODATION = 'Accommodation',
  ACADEMIC = 'Academic',
  TRANSPORT = 'Transport'
}
