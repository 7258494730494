import { useCallback, useRef } from 'react';
import {
  getAllFromPaginatedApiV2,
  getPaginatedResponseFromArray
} from 'src/lib';
import {
  GetAPI,
  PaginatedRequest,
  Student,
  StudentGroup,
  StudentGroupDTO
} from 'src/types';

export type UseCachedStudentGroupsProps = {
  getStudentGroups: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<StudentGroup[]>;

  getStudents: GetAPI<Student>;
};

export type CachedStudentsAndGroups = {
  getStudentGroups: GetAPI<StudentGroupDTO>;
  getStudents: GetAPI<Student>;
  clearCachedStudentsAndGroups: () => void;
};

/**
  Wraps getStudentGroups and getStudents to provide the following enhanced behavior:
  (1) Makes getStudentGroups appear and behave like paginated service, while also
  caching the entire list of student groups internally.

  (2) When the first call to getStudents is made, it retrieves and caches all students
  available in the paginated response (i.e. all pages).

  (3) If getStudentGroups is called before getStudents, and if the call to
  getStudents includes a filter for studentGroupId, then the cached list
  of studentGroups is used to work out and return which students are in
  the studentGroup.

  (4) Provides a function called clearCachedStudentsAndGroups to clear all cached values.

  Assumptions:
  (1) getStudentGroups will be called before getStudents, if the user
  chooses to filter the students by a specific student group.
 */
export function useCachedStudentsAndGroups({
  getStudentGroups: getStudentGroupsBase,
  getStudents: getStudentsBase
}: UseCachedStudentGroupsProps): CachedStudentsAndGroups {
  const studentGroups = useRef<StudentGroup[] | undefined>();
  const students = useRef<Student[] | undefined>();

  const getStudentGroups: GetAPI<StudentGroupDTO> = useCallback(
    request => {
      if (studentGroups.current) {
        return Promise.resolve(
          getPaginatedResponseFromArray(studentGroups.current)
        );
      } else {
        const moduleScheduleId = request.filters?.['moduleScheduleId'];

        if (!moduleScheduleId) {
          throw new Error('module schedule id in filters expected');
        }

        return getStudentGroupsBase(
          parseInt(moduleScheduleId),
          request.abortController
        ).then(studentGroupsNew => {
          const groupsAndSubGroups = studentGroupsNew.concat(
            studentGroupsNew.flatMap(
              studentGroup => studentGroup.subStudentGroups || []
            )
          );

          studentGroups.current = groupsAndSubGroups;

          return Promise.resolve(
            getPaginatedResponseFromArray(groupsAndSubGroups)
          );
        });
      }
    },
    [getStudentGroupsBase]
  );

  function getFilteredStudents(
    request: PaginatedRequest,
    students: Student[],
    studentGroups?: StudentGroup[]
  ) {
    const studentGroupId = request.filters?.['studentGroupId'];

    const studentGroup = studentGroupId
      ? (studentGroups || []).find(
          group => group.id === parseInt(studentGroupId)
        )
      : undefined;

    return studentGroup
      ? students.filter(student => studentGroup.studentIds.includes(student.id))
      : students;
  }

  const getStudents: GetAPI<Student> = useCallback(
    request => {
      if (students.current) {
        const filteredStudents = getFilteredStudents(
          request,
          students.current,
          studentGroups.current
        );
        return Promise.resolve(getPaginatedResponseFromArray(filteredStudents));
      } else {
        const moduleScheduleId = request.filters?.['moduleScheduleId'];

        if (!moduleScheduleId) {
          throw new Error('module schedule id in filters expected');
        }

        return getAllFromPaginatedApiV2({
          getApi: getStudentsBase,
          abortController: request.abortController,
          filters: {
            moduleScheduleId: moduleScheduleId
          }
        }).then(studentsNew => {
          students.current = studentsNew;
          const filteredStudents = getFilteredStudents(
            request,
            studentsNew,
            studentGroups.current
          );
          return Promise.resolve(
            getPaginatedResponseFromArray(filteredStudents)
          );
        });
      }
    },
    [getStudentsBase]
  );

  function clearCachedStudentsAndGroups() {
    students.current = undefined;
    studentGroups.current = undefined;
  }

  return { getStudentGroups, getStudents, clearCachedStudentsAndGroups };
}
