import { GetAPI } from '../../types';

export type GetAllFromPaginatedApiProps<T> = {
  getApi: GetAPI<T>;
  pageSize?: number;
  maxElementsSize?: number;
  ignoreMaxElementSize?: boolean;
  abortController?: AbortController;
  filters?: Record<string, string>;
  search?: string;
  ids?: number[];
};

/**
 * @deprecated please use v2(get-all-from-paginated-api-v2) of the same function
 */
export const getAllFromPaginatedApi = async <T>({
  getApi,
  pageSize = 500,
  maxElementsSize = 1000,
  ignoreMaxElementSize = false, //TODO: Possibly reverse this to allow most calls to return all results by default, set this to true for calls that return thousands of results (themes)
  abortController,
  filters,
  search,
  ids
}: GetAllFromPaginatedApiProps<T>): Promise<Array<T>> => {
  const allResults: Array<T> = [];
  let totalPagesCount = 1;

  for (
    let pageNumber = 0;
    pageNumber < totalPagesCount &&
    (ignoreMaxElementSize || allResults.length < maxElementsSize);
    pageNumber++
  ) {
    const { totalPages, items } = await getApi({
      pageNumber,
      pageSize,
      abortController,
      filters,
      search,
      ids: ids
    });
    totalPagesCount = totalPages;
    allResults.push(...items);
  }

  return allResults;
};
