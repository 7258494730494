import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState
} from 'react';
import { BusyBackdrop } from '../busy-backdrop/BusyBackdrop';

export interface IBackdropContext {
  showBusyBackdrop: () => void;
  hideBusyBackdrop: () => void;
}

export const BackdropContext = createContext<IBackdropContext | undefined>(
  undefined
);

export const BackdropContextProvider = ({
  children
}: {
  children?: ReactNode;
}) => {
  const [isBusyBackdropVisible, setIsBusyBackdropVisible] =
    useState<boolean>(false);

  const showBusyBackdrop = useCallback(
    () => setIsBusyBackdropVisible(true),
    []
  );
  const hideBusyBackdrop = useCallback(
    () => setIsBusyBackdropVisible(false),
    []
  );

  return (
    <BackdropContext.Provider value={{ showBusyBackdrop, hideBusyBackdrop }}>
      <BusyBackdrop show={isBusyBackdropVisible} />

      {children}
    </BackdropContext.Provider>
  );
};

export const useBackdrop = () => {
  const context = useContext(BackdropContext);

  if (!context) {
    throw new Error(
      'Backdrop Context cannot be used outside of Backdrop Context Provider'
    );
  }

  return context;
};
