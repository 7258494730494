import { FormControl, StandardTextFieldProps, TextField } from '@mui/material';
import { useField } from 'formik';
import moment from 'moment';
import { DATE_TIME_FORMAT } from 'src/lib';

interface DateInputProps extends Omit<StandardTextFieldProps, 'value'> {
  value: Date;
  /** callback function called whenever the current date value for the date input changes */
  onValueChanged?: (value: Date) => void;
}

export const DateInputField = (props: DateInputProps): JSX.Element => {
  const { name, label, value, ...otherProps } = props;

  if (!name) {
    throw new Error('value name field expected');
  }

  const [, , helpers] = useField(name);

  return (
    <FormControl fullWidth>
      <TextField
        {...otherProps}
        name={name}
        value={moment(new Date(value)).format(
          DATE_TIME_FORMAT.FORMIK_DATE_FIELD_FORMAT
        )}
        label={label}
        type="date"
        onChange={(event: { target: { value: string | undefined } }) => {
          const selectedValue = moment(event.target.value).toDate();
          const newValue = new Date(value) || new Date();
          newValue.setFullYear(selectedValue.getFullYear());
          newValue.setMonth(selectedValue.getMonth());
          newValue.setDate(selectedValue.getDate());
          helpers.setValue(newValue);
          props.onValueChanged && props.onValueChanged(newValue);
        }}
        InputLabelProps={{ shrink: true }}
      />
    </FormControl>
  );
};
