import { Reducer } from 'react';

export enum PaginatedControlActionType {
  GO_TO_PAGE,
  SET_TOTAL_ROWS,
  SET_ROWS_PER_PAGE,
  SET_TOTAL_PAGES
}

export type NavigateToPageAction = {
  type: PaginatedControlActionType.GO_TO_PAGE;
  pageNumber: number;
};

export type SetTotalRowsAction = {
  type: PaginatedControlActionType.SET_TOTAL_ROWS;
  totalRows: number;
};

export type SetRowsPerPageAction = {
  type: PaginatedControlActionType.SET_ROWS_PER_PAGE;
  rowsPerPage: number;
};

export type SetTotalPagesAction = {
  type: PaginatedControlActionType.SET_TOTAL_PAGES;
  totalPages: number;
};

export type PaginatedControlAction =
  | NavigateToPageAction
  | SetTotalRowsAction
  | SetRowsPerPageAction
  | SetTotalPagesAction;

export type PaginatedControlState = {
  currentPage: number;
  rowsPerPage: number;
  totalPages: number;
  totalRows: number;
};

export const paginatedControlReducer: Reducer<
  PaginatedControlState,
  PaginatedControlAction
> = (state: PaginatedControlState, action: PaginatedControlAction) => {
  const { type } = action;

  switch (type) {
    case PaginatedControlActionType.GO_TO_PAGE: {
      const { pageNumber } = action;

      return {
        ...state,
        currentPage: pageNumber
      };
    }

    case PaginatedControlActionType.SET_TOTAL_PAGES: {
      return {
        ...state,
        totalPages: action.totalPages
      };
    }

    case PaginatedControlActionType.SET_TOTAL_ROWS: {
      return {
        ...state,
        totalRows: action.totalRows
      };
    }

    case PaginatedControlActionType.SET_ROWS_PER_PAGE: {
      return {
        ...state,
        rowsPerPage: action.rowsPerPage
      };
    }

    default: {
      return state;
    }
  }
};
