import { useCallback } from 'react';
import { useAxiosInstance } from 'src/lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  FormQuestionType,
  PaginatedResponseDTO
} from 'src/types';

export interface IFormQuestionInputsService {
  getFormQuestionInputs: GetAPI<FormQuestionType>;
}

export const useFormQuestionInputsService = (): IFormQuestionInputsService => {
  const formApi = useAxiosInstance('/v2/formQuestionInputs');

  const getFormQuestionInputs = useCallback(
    (
      request: PaginatedRequest,
      abortController?: AbortController
    ): Promise<PaginatedResponse<FormQuestionType>> => {
      return formApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: abortController?.signal
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<FormQuestionType>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<FormQuestionType>;
        })
        .catch(error => {
          throw error;
        });
    },
    [formApi]
  );

  return { getFormQuestionInputs };
};
