import React, { PropsWithChildren } from 'react';
import {
  Box,
  ClickAwayListener,
  Tooltip as ReactTooltip,
  TooltipProps
} from '@mui/material';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';

export enum TooltipTrigger {
  CLICK,
  HOVER
}

export type IconWithLinkTooltipProps = TooltipProps & {
  trigger: TooltipTrigger;
};

export const Tooltip = ({
  trigger,
  ...otherProps
}: PropsWithChildren<IconWithLinkTooltipProps>) => {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  switch (trigger) {
    case TooltipTrigger.HOVER:
      return (
        <ThemableBox type={ThemableBoxType.TOOLTIP}>
          <ReactTooltip
            arrow={true}
            title={otherProps.title}
            placement="bottom"
          >
            {otherProps.children}
          </ReactTooltip>
        </ThemableBox>
      );
    case TooltipTrigger.CLICK:
      return (
        <ThemableBox type={ThemableBoxType.TOOLTIP}>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <ReactTooltip
              arrow={true}
              title={otherProps.title}
              placement="right"
              disableFocusListener={true}
              disableHoverListener={true}
              disableTouchListener={true}
              open={open}
              onClose={handleTooltipClose}
              PopperProps={{
                disablePortal: true,
                modifiers: [
                  {
                    name: 'preventOverflow',
                    options: {
                      enabled: true,
                      escapeWithReference: true,
                      boundary: 'viewport'
                    }
                  }
                ]
              }}
            >
              <Box onClick={handleTooltipOpen}>{otherProps.children}</Box>
            </ReactTooltip>
          </ClickAwayListener>
        </ThemableBox>
      );
  }
};
