import { useCallback } from 'react';
import {
  FormSubmission,
  FormSubmissionStateEnum,
  FormSubmissionStateDTO,
  GetAPI,
  getPaginatedResponse,
  PaginatedRequest,
  PaginatedResponse,
  PlacementFormSubmission,
  PlacementFormSubmissionDTO,
  FormSubmissionState
} from 'src/types';
import { getDateFromDtoDateString, useAxiosInstance } from 'src/lib';
import { FormSubmissionDTO } from 'src/types/dto';

export interface IFormSubmissionService {
  getFormSubmissionStates: (
    abortController?: AbortController
  ) => Promise<FormSubmissionState[]>;
  getFormSubmissions: GetAPI<FormSubmission>;
  getGroupedFormSubmissions: GetAPI<PlacementFormSubmission>;
  bulkApprove: (
    ids: number[],
    abortController?: AbortController
  ) => Promise<void>;
  bulkReject: (
    ids: number[],
    abortController?: AbortController
  ) => Promise<void>;
}

export const useFormSubmissionService = (): IFormSubmissionService => {
  const formSubmissionApi = useAxiosInstance('/v2/formSubmissions');

  const getFormSubmissionStates = useCallback(
    (abortController?: AbortController): Promise<FormSubmissionState[]> => {
      return formSubmissionApi
        .get('states', {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => response.data as FormSubmissionState[]);
    },
    [formSubmissionApi]
  );

  const getGroupedFormSubmissions = useCallback(
    (
      request: PaginatedRequest,
      abortController?: AbortController
    ): Promise<PaginatedResponse<PlacementFormSubmission>> => {
      return formSubmissionApi
        .get('grouped', {
          params: request,
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) =>
          getPaginatedResponse<
            PlacementFormSubmissionDTO,
            PlacementFormSubmission
          >(response, getPlacementFormSubmissionFromDTO)
        )
        .catch((error) => {
          throw error;
        });
    },
    [formSubmissionApi]
  );

  const getFormSubmissions = useCallback(
    (
      request: PaginatedRequest,
      abortController?: AbortController
    ): Promise<PaginatedResponse<FormSubmission>> => {
      return formSubmissionApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) =>
          getPaginatedResponse<FormSubmissionDTO, FormSubmission>(
            response,
            getFormSubmissionFromDto
          )
        )
        .catch((error) => {
          throw error;
        });
    },
    [formSubmissionApi]
  );

  const bulkApprove = useCallback(
    (ids: number[], abortController?: AbortController): Promise<void> =>
      formSubmissionApi.put(
        `bulk/approve?id=${ids.toString()}`,
        {},
        { withCredentials: true, signal: abortController?.signal }
      ),
    [formSubmissionApi]
  );

  const bulkReject = useCallback(
    (ids: number[], abortController?: AbortController): Promise<void> =>
      formSubmissionApi.put(
        `bulk/reject?id=${ids.toString()}`,
        {},
        { withCredentials: true, signal: abortController?.signal }
      ),
    [formSubmissionApi]
  );

  return {
    getFormSubmissions,
    getFormSubmissionStates,
    getGroupedFormSubmissions,
    bulkApprove,
    bulkReject
  };
};

function getPlacementFormSubmissionFromDTO(
  dto: PlacementFormSubmissionDTO
): PlacementFormSubmission {
  const { activityStartDate, activityEndDate } = dto;

  return {
    ...dto,
    activityStartDate: getDateFromDtoDateString(activityStartDate),
    activityEndDate: getDateFromDtoDateString(activityEndDate)
  };
}

function getFormSubmissionStateFromDto(state: string) {
  switch (state) {
    case FormSubmissionStateDTO.IN_PROGRESS:
      return FormSubmissionStateEnum.IN_PROGRESS;

    case FormSubmissionStateDTO.APPROVED:
      return FormSubmissionStateEnum.APPROVED;

    case FormSubmissionStateDTO.AWAITING_APPROVAL:
      return FormSubmissionStateEnum.AWAITING_APPROVAL;

    case FormSubmissionStateDTO.REJECTED:
      return FormSubmissionStateEnum.REJECTED;

    default:
      throw new Error(`unknown form submission state: ${state}`);
  }
}

function getFormSubmissionFromDto(
  formSubmission: FormSubmissionDTO
): FormSubmission {
  return {
    ...formSubmission,
    formComments: formSubmission.formComments.map((comment) => ({
      ...comment,
      dateAdded: getDateFromDtoDateString(comment.dateAdded)
    })),
    state: getFormSubmissionStateFromDto(formSubmission.state)
  } as FormSubmission;
}
