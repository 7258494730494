import { Reducer } from 'react';
import { isRecordsEqual } from 'src/lib';

export enum AutoCompleteActionType {
  SET_IS_LIST_OPEN,
  SET_SEARCH_TEXT,
  SET_OPTIONS_FROM_SERVICE_RESPONSE,
  MULTIPLE_ACTIONS,
  SET_IS_NO_MATCHING_OPTIONS,
  ON_FILTERS_CHANGED,
  ON_OPTION_SELECTION_CHANGED,
  ON_MORE_PAGES_NEEDED,
  ON_OPTION_CLEARED
}

export type MultipleActions<T> = {
  type: AutoCompleteActionType.MULTIPLE_ACTIONS;
  payload: Exclude<AutoCompleteAction<T>, MultipleActions<T>>[];
};

export type SetIsListOpenAction = {
  type: AutoCompleteActionType.SET_IS_LIST_OPEN;
  payload: {
    isListOpen: boolean;
  };
};

export type SetInputValue = {
  type: AutoCompleteActionType.SET_SEARCH_TEXT;
  payload: {
    inputValue: string;
    isInputFromUser: boolean;
  };
};

export type SetOptions<T> = {
  type: AutoCompleteActionType.SET_OPTIONS_FROM_SERVICE_RESPONSE;
  payload: {
    options: T[];
    pageNumber: number;
    totalPages: number;
  };
};

export type SetIsNoMatchingOptions = {
  type: AutoCompleteActionType.SET_IS_NO_MATCHING_OPTIONS;
  payload: {
    isNoMatchingOptions: boolean;
  };
};

export type OnFiltersChanged = {
  type: AutoCompleteActionType.ON_FILTERS_CHANGED;
  payload: {
    filters?: Record<string, string>;
  };
};

export type OnOptionSelectionChanged = {
  type: AutoCompleteActionType.ON_OPTION_SELECTION_CHANGED;
};

export type OnOptionCleared = {
  type: AutoCompleteActionType.ON_OPTION_CLEARED;
};

export type OnMorePagesNeeded = {
  type: AutoCompleteActionType.ON_MORE_PAGES_NEEDED;
};

export type AutoCompleteAction<T> =
  | SetIsListOpenAction
  | SetInputValue
  | SetOptions<T>
  | MultipleActions<T>
  | SetIsNoMatchingOptions
  | OnFiltersChanged
  | OnOptionSelectionChanged
  | OnOptionCleared
  | OnMorePagesNeeded;

export type AutoCompleteState<T> = {
  selectedOption?: T;
  isListOpen: boolean;
  isMultiSelect: boolean;
  isFetchingOptions: boolean;
  totalOptionPages?: number;
  lastOptionPageFetched?: number;
  nextOptionPageCanFetch?: number;
  isNoMatchingOptions: boolean;
  searchText: string;
  options: T[];
  filters?: Record<string, string>;
};

export const getAutocompleteReducer: <T>() => Reducer<
  AutoCompleteState<T>,
  AutoCompleteAction<T>
> =
  <T>() =>
  (state: AutoCompleteState<T>, action: AutoCompleteAction<T>) => {
    function getNewState(
      state: AutoCompleteState<T>,
      action: AutoCompleteAction<T>
    ): AutoCompleteState<T> {
      switch (action.type) {
        case AutoCompleteActionType.SET_OPTIONS_FROM_SERVICE_RESPONSE: {
          const { options, totalPages, pageNumber } = action.payload;
          return {
            ...state,
            options: [...state.options, ...options],
            isFetchingOptions: false,
            lastOptionPageFetched: pageNumber,
            totalOptionPages: totalPages
          };
        }
        case AutoCompleteActionType.SET_IS_LIST_OPEN: {
          const { isListOpen } = action.payload;
          return {
            ...state,
            isListOpen: isListOpen,
            isFetchingOptions:
              isListOpen &&
              ((!state.isNoMatchingOptions && state.options.length === 0) ||
                (state.nextOptionPageCanFetch || 0) >
                  (state.lastOptionPageFetched || 0))
          };
        }
        case AutoCompleteActionType.SET_SEARCH_TEXT: {
          const { isInputFromUser, inputValue } = action.payload;
          return {
            ...state,
            searchText: isInputFromUser ? inputValue : '',
            options:
              isInputFromUser ||
              inputValue.length === 0 ||
              state.searchText.length > 0
                ? []
                : state.options,
            isFetchingOptions: isInputFromUser,
            isNoMatchingOptions: false,
            lastOptionPageFetched: isInputFromUser
              ? undefined
              : state.lastOptionPageFetched,
            totalOptionPages: isInputFromUser
              ? undefined
              : state.totalOptionPages,
            nextOptionPageCanFetch: isInputFromUser
              ? undefined
              : state.nextOptionPageCanFetch
          };
        }
        case AutoCompleteActionType.ON_OPTION_SELECTION_CHANGED: {
          return {
            ...state,
            searchText: ''
          };
        }

        case AutoCompleteActionType.ON_OPTION_CLEARED: {
          return {
            ...state,
            nextOptionPageCanFetch: undefined
          };
        }

        case AutoCompleteActionType.SET_IS_NO_MATCHING_OPTIONS: {
          const { isNoMatchingOptions } = action.payload;
          return {
            ...state,
            isNoMatchingOptions
          };
        }

        case AutoCompleteActionType.ON_MORE_PAGES_NEEDED: {
          const nextOptionPageCanFetchNew =
            (state.lastOptionPageFetched || 0) <
            (state.totalOptionPages || 0) - 1
              ? (state.lastOptionPageFetched || 0) + 1
              : state.nextOptionPageCanFetch;
          return {
            ...state,
            nextOptionPageCanFetch: nextOptionPageCanFetchNew,
            isFetchingOptions:
              state.isListOpen &&
              (nextOptionPageCanFetchNew || 0) >
                (state.lastOptionPageFetched || 0)
          };
        }

        case AutoCompleteActionType.ON_FILTERS_CHANGED: {
          const { filters } = action.payload;
          const isFiltersUnchanged = isRecordsEqual(filters, state.filters);
          if (isFiltersUnchanged) {
            return state;
          } else {
            return {
              ...state,
              isNoMatchingOptions: false,
              options: [],
              lastOptionPageFetched: undefined,
              nextOptionPageCanFetch: undefined,
              totalOptionPages: undefined,
              filters: filters
            };
          }
        }

        default:
          return state;
      }
    }

    switch (action.type) {
      case AutoCompleteActionType.MULTIPLE_ACTIONS:
        return action.payload.reduce((finalResult, action) => {
          return getNewState(finalResult, action);
        }, state);

      default:
        return getNewState(state, action);
    }
  };
