import { ProgrammeScheduleDTO } from '../dto';
import { Tag } from './Tag.type';

export type ProgrammeSchedule = {
  dateVerified?: Date;
  startDate: Date;
  endDate: Date;
  status: ProgrammeScheduleStatus;
  tags: Tag[];
} & Omit<
  ProgrammeScheduleDTO,
  'dateVerified' | 'startDate' | 'endDate' | 'status'
>;

export enum ProgrammeScheduleStatus {
  ACTIVE,
  INACTIVE
}
