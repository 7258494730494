import { useCallback } from 'react';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';
import { DistrictDTO } from 'src/types/dto';

export interface IDistrictService {
  getDistricts: GetAPI<DistrictDTO>;
}

export const useDistrictService = (): IDistrictService => {

  const districtAPI = useAxiosInstance('/api/v2/districts');

  const getDistricts = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<DistrictDTO>> => {
      return districtAPI
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController?.signal
        })
        .then(response => {
          const {
            content,
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<DistrictDTO>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<DistrictDTO>;
        });
    },
    [districtAPI]
  );

  return { getDistricts };
};
