import { Tag } from '../models';

export type TrainingAreaDTO = {
  id: number;
  status: string;
  department: string;
  areaLocation: string;
  roomCode: string;
  contactPerson: string;
  tel: string;
  emailAddress: string;
  equipment: Tag[];
  equipmentIds: number[];
  facilityId: number;
  facilityName: string;
  facilityType: string;
  approverUserIds?: number[];
  bookable: boolean;
  capacity?: number;
  remainingCapacity: number;
  isVenueRequestTrainingArea: boolean;
};

export const TrainingAreaFacilityTypeDTO = {
  ACCOMMODATION: 'Accommodation',
  ACADEMIC: 'Academic',
  TRANSPORT: 'Transport'
};
