export type AccreditationDTO = {
  id: number;
  dateCreated: string;
  validFrom: string;
  validTo: string;
  facilityId: number;
  facilityType: string;
  status: string;
  themeIds?: number[];
  fileIds?: number[];
  createdUserId?: number;
};

export const AccreditationStatusDTO = {
  NONE: 'NONE',
  PENDING_APPROVAL: 'PENDING_APPROVAL',
  ACCREDITED_VALID: 'ACCREDITED_VALID',
  ACCREDITED_REVOKED: 'ACCREDITED_REVOKED',
  ACCREDITED_EXPIRED: 'ACCREDITED_EXPIRED'
};
