import { PropsWithChildren, ReactFragment } from 'react';
import { Box } from '@mui/material';

export const CenteredBox = ({ children }: PropsWithChildren<ReactFragment>) => {
  const boxStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    overflow: 'scroll',
    p: 4
  };

  return <Box sx={boxStyle}> {children}</Box>;
};
