import { useEffect, useMemo } from 'react';
import { generatePath, matchPath, useLocation } from 'react-router-dom';
import { useBreadcrumbs } from '../breadcrumbs-context-provider/BreadcrumbsContextProvider';
import { Breadcrumb } from 'src/types';
import { isTextValidPositiveInteger } from 'src/lib';

export type BreadcrumbConfig = {
  route: string;
  /* the id of the route pattern that should be used to replace
   * the label of this breadcrumb with a matching value from the URL. */
  id?: string;
  /* The text displayed in the breadcrumb. Only specified if the id parameter 
  is not specified */
  label?: string;
  /* Config for breadcrumbs that may follow the current breadcrumb */
  children?: BreadcrumbConfig[];
  /*This is an optional property that allows you to provide a specific path/page
  for the breadcrumb to navigate the user to.*/
  link?: string;
};

/* This type is used internally by useBreadcrumbConfigAsBreadcrumbs */
type BreadcrumbConfigInternal = BreadcrumbConfig & {
  parents?: BreadcrumbConfig[];
};

/**
 * An alternative to {@link useRouteConfigAsBreadcrumbs}. Also used to set the
 * breadcrumbs for the current page, but instead of generating the breadcrumbs
 * from the routes, it requires separate config. Whereas {@link useRouteConfigAsBreadcrumbs}
 * would only require the routes (which you need to define anyway), this approach
 * requires a bit more effort. However, in exchange, one can customise the breadcrumbs
 * to meet the client's specific needs, without updating the routes.
 *
 * @param breadcrumbConfigItems {@link Array} of {@link BreadcrumbConfigInternal} t
 */
export const useBreadcrumbConfigAsBreadcrumbs = (
  breadcrumbConfigItems: BreadcrumbConfig[]
) => {
  const { pathname } = useLocation();
  const { setBreadcrumbs } = useBreadcrumbs();

  const breadcrumbConfigItemsFlattened = useMemo(
    () => getFlattenedBreadcrumbConfigItems(breadcrumbConfigItems),
    [breadcrumbConfigItems]
  );

  useEffect(() => {
    const breadcrumbConfigMatchedOnPath = getBreadcrumbConfigMatchedOnPath(
      breadcrumbConfigItemsFlattened,
      pathname
    );

    if (breadcrumbConfigMatchedOnPath) {
      const breadcrumbConfigMatchedOnPathWithParents =
        getBreadcrumbConfigWithParents(breadcrumbConfigMatchedOnPath);

      const breadcrumbs = getBreadcrumbs(
        breadcrumbConfigMatchedOnPathWithParents,
        pathname
      );
      setBreadcrumbs(breadcrumbs);
    } else {
      setBreadcrumbs([]);
    }
  }, [breadcrumbConfigItemsFlattened, pathname, setBreadcrumbs]);
};

/**
 * Function that transforms a tree of {@link BreadcrumbConfigInternal} items into a flat
 * array where:
 *
 * (1) the {@link parent} field is to an array of its ancestors
 * (2) the {@link children} field is set to undefined.
 *
 * @param breadcrumbConfigItems
 * @param parentBreadcrumbConfigItems - {@link Array} of {@link BreadcrumbConfigInternal} that are
 * the ancestors of {@link breadcrumbConfigItems}. For example, the last, second last, and third
 * last items in the array are the parent, grandparent, and great-grandparent of
 * {@link breadcrumbConfigItems}. This parameter should only be called by the function itself.
 */
function getFlattenedBreadcrumbConfigItems(
  breadcrumbConfigItems: BreadcrumbConfigInternal[],
  parentBreadcrumbConfigItems: BreadcrumbConfigInternal[] = []
): BreadcrumbConfigInternal[] {
  const breadcrumbConfigsFlattened = breadcrumbConfigItems.map(
    (breadcrumbConfig) => {
      return {
        ...getBreadcrumbConfigWithoutChildren(breadcrumbConfig),
        parents: parentBreadcrumbConfigItems
      };
    }
  );

  const childBreadcrumbConfigItemsFlattened = breadcrumbConfigItems.flatMap(
    (breadcrumbConfig) => {
      if (!breadcrumbConfig.children) {
        return [];
      }

      return getFlattenedBreadcrumbConfigItems(breadcrumbConfig.children, [
        ...parentBreadcrumbConfigItems,
        getBreadcrumbConfigWithoutChildren(breadcrumbConfig)
      ]);
    }
  );

  return [
    ...breadcrumbConfigsFlattened,
    ...childBreadcrumbConfigItemsFlattened
  ];
}

/**
 * @param breadcrumbConfig a {@link BreadcrumbConfigInternal}
 * @return a {@link BreadcrumbConfigInternal} with the {@link children} field set to undefined.
 */
function getBreadcrumbConfigWithoutChildren(
  breadcrumbConfig: BreadcrumbConfigInternal
) {
  const { children: _, ...configWithoutChildren } = breadcrumbConfig;
  return configWithoutChildren;
}

/**
 * @param breadcrumbConfigItems an {@link Array} of {@link BreadcrumbConfigInternal}
 * @param path the current browser URL.
 * @returns a {@link BreadcrumbConfigInternal} from the array that matches the {@link path} if one exists.
 * @returns {@link undefined} if no match is found.
 */
function getBreadcrumbConfigMatchedOnPath(
  breadcrumbConfigItems: BreadcrumbConfigInternal[],
  path: string
): BreadcrumbConfigInternal | undefined {
  return breadcrumbConfigItems.find((breadcrumbConfigItem) => {
    const breadcrumbRoute = getBreadcrumbConfigRoute(
      getBreadcrumbConfigWithParents(breadcrumbConfigItem)
    );
    const routeMatch = matchPath(breadcrumbRoute, path);

    /* detects a false positive match by checking that ids in the URL
     * that matches the route are integers. The route /module/:moduleId should
     * not match URL /module/create because 'create' is not an integer.
     */
    const isMatchingParamsAllIntegerIds = Object.values(
      routeMatch?.params ?? {}
    ).every((param) => (param ? isTextValidPositiveInteger(param) : false));

    return !!routeMatch && isMatchingParamsAllIntegerIds;
  });
}

/**
 * Returns an {@link array} of {@link Breadcrumb} items
 * according to the breadcrumb configuration items passed in.
 *
 * @param breadcrumbConfig an {@link array} of {@link BreadcrumbConfig}.
 * @param path the current browser URL.
 */
function getBreadcrumbs(
  breadcrumbConfigItems: BreadcrumbConfig[],
  path: string
): Breadcrumb[] {
  return breadcrumbConfigItems.map<Breadcrumb>((config, index) => {
    const configCrumbWithParentsFiltered = breadcrumbConfigItems.slice(
      0,
      index + 1
    );
    const breadcrumbRoute = getBreadcrumbConfigRoute(
      configCrumbWithParentsFiltered
    );
    const pathMatch = matchPath({ path: breadcrumbRoute, end: false }, path);
    const link = config.link
      ? config.link
      : generatePath(breadcrumbRoute, pathMatch?.params);

    const breadcrumb: Breadcrumb = {
      text: config.label ?? '',
      link: link,
      id: config.id
    };

    return breadcrumb;
  });
}

/**
 * @param breadcrumbConfig a {@link BreadcrumbConfigInternal}.
 * @return an {@link Array} of {@link BreadcrumbConfigInternal} formed by
 * appending the {@link BreadcrumbConfigInternal} parameter value, with
 * the parents field of the parameter value.
 */
function getBreadcrumbConfigWithParents(
  breadcrumbConfig: BreadcrumbConfigInternal
) {
  return [...(breadcrumbConfig.parents ?? []), breadcrumbConfig];
}

/**
 * Returns a complete route by combining the route segments of
 * multiple {@link BreadcrumbConfig} items.
 *
 * @param breadcrumbConfigItems an {@link Array} of {@link BreadcrumbConfig}
 * items.
 * @return a route formed by combining the routes of all the array items. The
 * function goes through each array item to build up a route. For a given array
 * item, if the route segment is a relative path, it is appended to route being
 * built up. If the route segment is an absolute path, it replaces the route
 * being built up.
 */
function getBreadcrumbConfigRoute(
  breadcrumbConfigItems: BreadcrumbConfig[]
): string {
  return breadcrumbConfigItems.reduce((result, breadcrumbConfigItem) => {
    if (breadcrumbConfigItem.route.startsWith('/')) {
      return breadcrumbConfigItem.route;
    }

    return result
      ? `${result}/${breadcrumbConfigItem.route}`
      : breadcrumbConfigItem.route;
  }, '');
}
