import React, { useContext } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography
} from '@mui/material';
import {
  CacheStoreContext,
  useAppContext,
  useAuthorizedUser,
  ThemableBox
} from 'src/common';
import { ThemableBoxType } from 'src/types';
import { getUserFullInitials } from 'src/lib';

export const UserProfileContainer = (): JSX.Element => {
  const { handleError } = useAppContext();
  const { logout, user } = useAuthorizedUser();
  const cacheStoreContext = useContext(CacheStoreContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    cacheStoreContext && cacheStoreContext.clearAll();
    logout().catch(handleError);
  };
  const userInitials = getUserFullInitials(user);

  return (
    <Box>
      <>
        <ThemableBox type={ThemableBoxType.USER_PROFILE_MENU_WITH_INITIALS}>
          <Tooltip title="User Profile">
            <IconButton onClick={handleClick} size="medium">
              <Typography noWrap={true} variant="userProfileIconText">
                {userInitials}
              </Typography>
            </IconButton>
          </Tooltip>
        </ThemableBox>

        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          onClick={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </>
    </Box>
  );
};
