import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ShowIf } from 'src/common/show-if/ShowIf';

export type FormContentLayout = {
  formHeading: ReactNode;
  formPrimaryActions?: ReactNode;
  formSecondaryActions?: ReactNode;
  formFields?: ReactNode;
};

const CLASS_NAMES = {
  FORM_HEADING_ACTIONS_CONTAINER: 'form-heading-actions-container',
  FORM_CONTENT_WRAPPER: 'form-content-wrapper',
  FORM_HEADING: 'form-heading',
  FORM_PRIMARY_ACTIONS: 'form-primary-actions',
  FORM_FIELDS: 'form-fields',
  FORM_FIELDS_CONTAINER: 'form-fields-container',
  FORM_SECONDARY_ACTIONS: 'form-secondary-actions'
};

export const FormElementsLayout: FC<FormContentLayout> = (props) => {
  const { formHeading, formPrimaryActions, formSecondaryActions, formFields } =
    props;

  return (
    <ThemableBox type={ThemableBoxType.FORM_CONTENT_LAYOUT}>
      <Box className={CLASS_NAMES.FORM_HEADING_ACTIONS_CONTAINER}>
        <Box className={CLASS_NAMES.FORM_HEADING}>{formHeading}</Box>

        <ShowIf condition={!!props.formPrimaryActions}>
          <Box className={CLASS_NAMES.FORM_PRIMARY_ACTIONS}>
            {formPrimaryActions}
          </Box>
        </ShowIf>
      </Box>


      <Box className={CLASS_NAMES.FORM_CONTENT_WRAPPER}>

        <Box className={CLASS_NAMES.FORM_FIELDS}>{formFields}</Box>

        <ShowIf condition={!!props.formSecondaryActions}>
          <Box className={CLASS_NAMES.FORM_SECONDARY_ACTIONS}>
            {formSecondaryActions}
          </Box>
        </ShowIf>
      </Box>
    </ThemableBox>
  );
};
