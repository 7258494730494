import { FC } from 'react';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { Grid } from '@mui/material';

export const ListViewPanel: FC = ({ children }) => {
  return (
    <ThemableBox type={ThemableBoxType.LIST_VIEW_CONTAINER}>
      <Grid container spacing={1} wrap="nowrap">
        {children}
      </Grid>
    </ThemableBox>
  );
};
