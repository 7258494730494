import { PropsWithChildren } from 'react';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';
import { NavLink } from 'react-router-dom';
import { CardMedia } from '@mui/material';

interface NavigationLogoProps {
  logoPath: string;
  altText?: string;
  navigateTo: string;
}

export const NavigationLogo = ({
  logoPath,
  navigateTo,
  altText
}: PropsWithChildren<NavigationLogoProps>) => {
  return (
    <ThemableBox type={ThemableBoxType.NAVIGATION_LOGO}>
      <NavLink
        to={navigateTo}
        children={() => (
          <CardMedia component="img" alt={altText} src={logoPath} />
        )}
      />
    </ThemableBox>
  );
};
