import {
  Box,
  Button,
  Grid,
  Modal as MaterialModal,
  Typography
} from '@mui/material';
import React from 'react';
import { CenteredBox } from '../centered-box/CenteredBox';

export type FilePreviewModalProps = {
  imageSourceUrl?: string;
  onClose: () => void;
};

export const ImagePreviewModal = ({
  imageSourceUrl,
  onClose
}: FilePreviewModalProps) => {
  return (
    <MaterialModal open={!!imageSourceUrl} onClose={onClose}>
      <>
        <CenteredBox>
          <Grid container direction="column" spacing={5}>
            <Grid item>
              <Typography variant="h2">File Preview</Typography>
            </Grid>
            <Grid item>
              {imageSourceUrl && (
                <Box
                  component="img"
                  src={imageSourceUrl}
                  sx={{ width: '100%', height: 'fit-content' }}
                />
              )}
            </Grid>
            <Grid item>
              <Button
                variant="back"
                onClick={(event) => {
                  event.stopPropagation();
                  onClose();
                }}
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </CenteredBox>
      </>
    </MaterialModal>
  );
};
