import { useCallback } from 'react';
import { useAxiosInstance } from '../lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  ThemeScheduleFacilitySelection,
  ThemeFacilityPreferenceRequest
} from 'src/types';

export interface IThemesScheduleFacilitySelectionService {
  getThemesScheduleFacilitySelection: GetAPI<ThemeScheduleFacilitySelection>;
  createThemesScheduleFacilitySelection: (
    selection: ThemeFacilityPreferenceRequest,
    abortController?: AbortController
  ) => Promise<void>;
  deleteFacilityPreference: (
    facility: Array<ThemeScheduleFacilitySelection>,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useThemesScheduleFacilitySelectionService =
  (): IThemesScheduleFacilitySelectionService => {
    const themesScheduleFacilitySelectionApi = useAxiosInstance('/v2/themeScheduleFacilitySelection');

    const getThemesScheduleFacilitySelection = useCallback(
      async (
        request: PaginatedRequest
      ): Promise<PaginatedResponse<ThemeScheduleFacilitySelection>> => {
        const controller = request.abortController || new AbortController();

        return themesScheduleFacilitySelectionApi
          .get('', {
            params: request,
            withCredentials: true,
            signal: controller.signal
          })
          .then((response) => {
            const { content } = response.data;

            return {
              items: content || [],
              hasNextPage: false,
              hasPrevPage: false,
              pageNumber: 0,
              totalCount: response.data.length,
              totalPages: 1
            } as PaginatedResponse<ThemeScheduleFacilitySelection>;
          });
      },
      [themesScheduleFacilitySelectionApi]
    );

    const createThemesScheduleFacilitySelection = useCallback(
      (
        selection: ThemeFacilityPreferenceRequest,
        abortController?: AbortController
      ): Promise<void> =>
        themesScheduleFacilitySelectionApi.post('', selection, {
          signal: abortController?.signal
        }),
      [themesScheduleFacilitySelectionApi]
    );

    const deleteFacilityPreference = useCallback(
      (
        facility: Array<ThemeScheduleFacilitySelection>,
        abortController?: AbortController
      ): Promise<void> => {
        return Promise.all(
          facility.map((singleFacility) => {
            return themesScheduleFacilitySelectionApi.delete(
              `/${singleFacility.id.toString()}`,
              {
                withCredentials: true,
                signal: abortController ? abortController.signal : undefined
              }
            );
          })
        ).then(() => {
          return Promise.resolve();
        });
      },
      [themesScheduleFacilitySelectionApi]
    );

    return {
      getThemesScheduleFacilitySelection,
      createThemesScheduleFacilitySelection,
      deleteFacilityPreference
    };
  };
