import { Gender, GenderDTO } from 'src/types';

export function getGenderFromDto(gender?: string): Gender | undefined {
  switch (gender) {
    case undefined:
    case null:
      return undefined;
    case GenderDTO.F:
    case GenderDTO.V:
      return Gender.FEMALE;
    case GenderDTO.M:
      return Gender.MALE;
    case GenderDTO.N:
      return Gender.NONE;
    default:
      throw new Error(`unknown gender: ${gender}`);
  }
}
