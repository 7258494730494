import React, { FC } from 'react';
import { Grid, IconButton, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';

export type ListHeaderProps = {
  totalAllNotifications: number;
  totalUnreadNotifications: number;
  onMenuOpenButtonOnClick: (mouseEvent: React.MouseEvent<HTMLElement>) => void;
};

export const ListHeader: FC<ListHeaderProps> = ({
  totalAllNotifications,
  totalUnreadNotifications,
  onMenuOpenButtonOnClick
}) => {
  return (
    <ThemableBox type={ThemableBoxType.NOTIFICATIONS_HEADER}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <Typography variant="h3">{`My Notifications: ${totalAllNotifications} (${totalUnreadNotifications} Unread)`}</Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={onMenuOpenButtonOnClick}>
            <MoreVertIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ThemableBox>
  );
};
