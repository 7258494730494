import { Stack, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useCallback } from 'react';
import {
  AsyncAutocompleteV2,
  FormikValuesChangeListener,
  StaticAutocomplete
} from 'src/common';
import { FilterGroup } from 'src/common-v2';
import {
  DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
  getFieldErrorProps,
  usePaginatedApiWithFixedParams
} from 'src/lib';
import {
  Activity,
  ActivityType,
  Facility,
  FacilityDTO,
  GetAPI,
  ModuleSchedule,
  ProgrammeSchedule,
  RoomBookingStatus,
  RoomBookingType,
  Staff,
  Student,
  StudentGroup,
  ThemeSchedule,
  TrainingArea
} from 'src/types';
import {
  useCachedActivitiesAndTypes,
  useCachedStudentsAndGroups
} from '../../hooks';
import {
  getFacilityFieldLabel,
  getInitialValues,
  getOnSubmit,
  getTrainingAreaFieldLabel,
  getValidate
} from './form-functions';
import { BookedBy, FormProps, FormValues } from './form-types';
import { getRoomBookingFromType } from '../../lib';

// specifies the time the form should wait when a field value changes until the form is submitted;
const FIELD_CHANGE_SUBMIT_DELAYS = new Map<keyof FormValues, number>([
  ['searchText', DEBOUNCE_TIMEOUT_MILLISECONDS_LOW]
]);

export function FiltersForm(formProps: FormProps) {
  const { roomBookingType, getStaff, getFacilities, getTrainingAreas } =
    formProps;

  const getApprovers: GetAPI<Staff> = usePaginatedApiWithFixedParams({
    getApi: getStaff,
    order: 'asc',
    orderBy: 'surname',
    filters: {
      isApprover: String(true)
    }
  });

  const getViewableFacilities: GetAPI<Facility> =
    usePaginatedApiWithFixedParams({
      getApi: getFacilities,
      order: 'asc',
      orderBy: 'facilityName',
      filters: {
        facilityType: getRoomBookingFromType(formProps.roomBookingType),
        status: 'Viewable'
      }
    });

  const getFacilitiesWithBookableTrainingAreas: GetAPI<Facility> =
    usePaginatedApiWithFixedParams({
      getApi: getFacilities,
      order: 'asc',
      orderBy: 'facilityName',
      filters: {
        facilityType: getRoomBookingFromType(formProps.roomBookingType),
        status: 'Viewable',
        withBookableTrainingAreas: String(true)
      }
    });

  const getViewableTrainingAreas: GetAPI<TrainingArea> =
    usePaginatedApiWithFixedParams({
      getApi: getTrainingAreas,
      order: 'asc',
      orderBy: 'areaLocation',
      filters: {
        status: 'Viewable'
      }
    });

  const getBookableTrainingAreas: GetAPI<TrainingArea> =
    usePaginatedApiWithFixedParams({
      getApi: getTrainingAreas,
      order: 'asc',
      orderBy: 'areaLocation',
      filters: {
        status: 'Viewable',
        bookable: String(true)
      }
    });

  const getStudentGroupsWrapper = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<StudentGroup[]> => {
      switch (roomBookingType) {
        case RoomBookingType.ACADEMIC:
          return formProps.getStudentGroups(moduleScheduleId, abortController);
        default:
          throw new Error('This function only works for non-academic bookings');
      }
    },
    [roomBookingType, formProps]
  );

  const getActivitiesWrapper = useCallback<GetAPI<Activity>>(
    (request) => {
      switch (roomBookingType) {
        case RoomBookingType.ACADEMIC:
          return formProps.getActivities(request);
        default:
          throw new Error('This function only works for non-academic bookings');
      }
    },
    [roomBookingType, formProps]
  );

  const { getActivities, getActivityTypes, clearCacheActivities } =
    useCachedActivitiesAndTypes({
      getActivities: getActivitiesWrapper
    });

  const { getStudents, getStudentGroups, clearCachedStudentsAndGroups } =
    useCachedStudentsAndGroups({
      getStudentGroups: getStudentGroupsWrapper,
      getStudents: formProps.getStudents
    });

  return (
    <Formik<FormValues>
      initialValues={getInitialValues(formProps)}
      onSubmit={getOnSubmit(formProps)}
      validate={getValidate(formProps)}
      enableReinitialize={true}
    >
      {(formikHelpers) => {
        const { values, submitForm, setValues } = formikHelpers;
        return (
          <Form>
            <Stack spacing={2}>
              <FormikValuesChangeListener<FormValues>
                handlerFieldDelays={FIELD_CHANGE_SUBMIT_DELAYS}
              >
                {submitForm}
              </FormikValuesChangeListener>

              {roomBookingType === RoomBookingType.ACADEMIC &&
                !formProps.isApproverViewEnabled &&
                values.bookedBy !== BookedBy.EVERYONE_ALL && (
                  <>
                    <FilterGroup groupLabel="Curriculum filters">
                      <Field
                        {...getFieldErrorProps(
                          'programmeSchedule',
                          formikHelpers
                        )}
                        label="Programme"
                        value={values.programmeSchedule}
                        getOptions={formProps.getProgrammeSchedules}
                        getOptionLabel={(option: ProgrammeSchedule) =>
                          `${option.calendarYear.calendarYear} - ${option.displayName}`
                        }
                        isOptionEqualToValue={(
                          option: ProgrammeSchedule,
                          value: ProgrammeSchedule
                        ) => option.id === value.id}
                        onSelectedOptionChanged={() => {
                          setValues((values) => {
                            return {
                              ...values,
                              moduleSchedule: undefined,
                              themeSchedule: undefined,
                              activityType: undefined,
                              activity: undefined,
                              studentGroup: undefined,
                              student: undefined,
                              isNoModuleSchedulesLinkedToProgrammeSchedule:
                                undefined
                            };
                          });
                          clearCacheActivities();
                          clearCachedStudentsAndGroups();
                        }}
                        required={true}
                        component={AsyncAutocompleteV2}
                        disableClearable
                        fullWidth
                      />

                      <Field
                        {...getFieldErrorProps('moduleSchedule', formikHelpers)}
                        label="Module"
                        value={values.moduleSchedule}
                        getOptions={formProps.getModuleSchedules}
                        getOptionsFilters={{
                          year: values?.programmeSchedule?.calendarYear
                            ?.calendarYear,
                          programId:
                            values?.programmeSchedule?.programmeId.toString()
                        }}
                        disabled={
                          !values.programmeSchedule ||
                          values.isNoModuleSchedulesLinkedToProgrammeSchedule
                        }
                        getOptionLabel={(option: ModuleSchedule) =>
                          option.displayName
                        }
                        isOptionEqualToValue={(
                          option: ModuleSchedule,
                          value: ModuleSchedule
                        ) => option.id === value.id}
                        onSelectedOptionChanged={() => {
                          setValues((values) => {
                            return {
                              ...values,
                              themeSchedule: undefined,
                              activityType: undefined,
                              activity: undefined,
                              studentGroup: undefined,
                              student: undefined,
                              isNoThemeSchedulesLinkedToModuleSchedule:
                                undefined
                            };
                          });
                          clearCacheActivities();
                          clearCachedStudentsAndGroups();
                        }}
                        required={true}
                        component={AsyncAutocompleteV2}
                        disableClearable
                        fullWidth
                      />

                      <Field
                        {...getFieldErrorProps('themeSchedule', formikHelpers)}
                        label="Theme"
                        value={values.themeSchedule}
                        getOptions={formProps.getThemeSchedules}
                        getOptionsFilters={{
                          moduleScheduleId:
                            values?.moduleSchedule?.id.toString(),
                          active: String(true)
                        }}
                        disabled={
                          !values.moduleSchedule ||
                          !values.programmeSchedule ||
                          values.isNoThemeSchedulesLinkedToModuleSchedule
                        }
                        getOptionLabel={(option: ThemeSchedule) =>
                          option.themeName
                        }
                        isOptionEqualToValue={(
                          option: ThemeSchedule,
                          value: ThemeSchedule
                        ) => option.id === value.id}
                        required={true}
                        component={AsyncAutocompleteV2}
                        onSelectedOptionChanged={() => {
                          clearCacheActivities();
                          setValues((values) => {
                            return {
                              ...values,
                              activityType: undefined,
                              activity: undefined
                            };
                          });
                        }}
                        disableClearable
                        fullWidth
                      />

                      <Field
                        {...getFieldErrorProps('activityType', formikHelpers)}
                        label="Activity Type"
                        value={values.activityType}
                        getOptions={getActivityTypes}
                        getOptionsFilters={{
                          moduleScheduleId:
                            values?.moduleSchedule?.id.toString(),
                          themeScheduleId: values?.themeSchedule?.id.toString()
                        }}
                        disabled={
                          !values.moduleSchedule ||
                          !values.programmeSchedule ||
                          !values.themeSchedule
                        }
                        getOptionLabel={(option: ActivityType) => option.type}
                        isOptionEqualToValue={(
                          option: ActivityType,
                          value: ActivityType
                        ) => option.id === value.id}
                        component={AsyncAutocompleteV2}
                        onSelectedOptionChanged={() => {
                          setValues({
                            ...values,
                            activity: undefined
                          });
                        }}
                        fullWidth
                      />

                      <Field
                        {...getFieldErrorProps('activity', formikHelpers)}
                        label="Activity"
                        value={values.activity}
                        options={
                          values.activityType
                            ? getActivities(values.activityType)
                            : []
                        }
                        disabled={!values.activityType}
                        getOptionLabel={(option: Activity) =>
                          option.activityDescription
                        }
                        isOptionEqualToValue={(
                          option: Activity,
                          value: Activity
                        ) => option.id === value.id}
                        component={StaticAutocomplete}
                        fullWidth
                      />
                    </FilterGroup>

                    <FilterGroup groupLabel="Attendance filters">
                      <Field
                        {...getFieldErrorProps('studentGroup', formikHelpers)}
                        label="Student Group"
                        value={values.studentGroup}
                        getOptions={getStudentGroups}
                        getOptionsFilters={{
                          moduleScheduleId: values.moduleSchedule?.id.toString()
                        }}
                        getOptionLabel={(option: StudentGroup) => option.name}
                        isOptionEqualToValue={(
                          option: StudentGroup,
                          value: StudentGroup
                        ) => option.id === value.id}
                        component={AsyncAutocompleteV2}
                        onSelectedOptionChanged={() => {
                          setValues({
                            ...values,
                            student: undefined
                          });
                        }}
                        fullWidth
                      />
                    </FilterGroup>
                  </>
                )}

              {roomBookingType === RoomBookingType.TRANSPORT &&
                !formProps.isApproverViewEnabled && (
                  <Typography variant="headingFiveRegular">
                    Attendance filters
                  </Typography>
                )}

              {(roomBookingType === RoomBookingType.ACADEMIC ||
                roomBookingType === RoomBookingType.TRANSPORT ||
                roomBookingType === RoomBookingType.ACCOMMODATION) &&
                !formProps.isApproverViewEnabled && (
                  <Field
                    {...getFieldErrorProps('student', formikHelpers)}
                    label="Search Student"
                    value={values.student}
                    getOptions={
                      roomBookingType === RoomBookingType.ACADEMIC
                        ? getStudents
                        : formProps.getStudents
                    }
                    getOptionsFilters={
                      roomBookingType === RoomBookingType.ACADEMIC
                        ? {
                            moduleScheduleId:
                              values.moduleSchedule?.id.toString(),
                            studentGroupId: values.studentGroup?.id.toString()
                          }
                        : {}
                    }
                    getOptionLabel={(option: Student) => option.displayName}
                    isOptionEqualToValue={(option: Student, value: Student) =>
                      option.id === value.id
                    }
                    component={AsyncAutocompleteV2}
                    fullWidth
                  />
                )}

              <Typography variant="headingFiveRegular">
                Booking filters
              </Typography>

              <Field
                {...getFieldErrorProps('status', formikHelpers)}
                label="Status"
                value={values.status}
                options={[
                  RoomBookingStatus.APPROVED,
                  RoomBookingStatus.AWAITING_APPROVAL,
                  RoomBookingStatus.CANCELLED,
                  RoomBookingStatus.REJECTED
                ].filter(
                  (status) =>
                    roomBookingType === RoomBookingType.ACADEMIC ||
                    status !== RoomBookingStatus.CANCELLED
                )}
                getOptionLabel={(option: RoomBookingStatus) => {
                  switch (option) {
                    case RoomBookingStatus.APPROVED:
                      return 'Approved';
                    case RoomBookingStatus.AWAITING_APPROVAL:
                      return 'Awaiting Approval';
                    case RoomBookingStatus.REJECTED:
                      return 'Rejected';
                    case RoomBookingStatus.CANCELLED:
                      return 'Cancelled';
                  }
                }}
                isOptionEqualToValue={(
                  option: RoomBookingStatus,
                  value: RoomBookingStatus
                ) => option === value}
                component={StaticAutocomplete}
                fullWidth
              />

              <Field
                {...getFieldErrorProps('bookedBy', formikHelpers)}
                label="Booked By"
                value={values.bookedBy}
                options={[
                  BookedBy.EVERYONE,
                  BookedBy.EVERYONE_ALL,
                  BookedBy.ME
                ]}
                getOptionLabel={(option: BookedBy) => {
                  switch (option) {
                    case BookedBy.ME:
                      return 'Me';
                    case BookedBy.EVERYONE:
                      return 'Everyone';
                    case BookedBy.EVERYONE_ALL:
                      return 'Everyone (All Bookings)';
                  }
                }}
                isOptionEqualToValue={(option: BookedBy, value: BookedBy) =>
                  option === value
                }
                required={!formProps.isApproverViewEnabled}
                component={StaticAutocomplete}
                disableClearable={!formProps.isApproverViewEnabled}
                fullWidth
              />

              <Field
                {...getFieldErrorProps('approver', formikHelpers)}
                label="Approver"
                value={values.approver}
                getOptions={getApprovers}
                getOptionLabel={(option: Staff) => option.displayName}
                isOptionEqualToValue={(option: Staff, value: Staff) =>
                  option.id === value.id
                }
                component={AsyncAutocompleteV2}
                fullWidth
              />

              <Field
                {...getFieldErrorProps('facility', formikHelpers)}
                label={getFacilityFieldLabel(formProps.roomBookingType)}
                value={values.facility}
                getOptions={
                  roomBookingType === RoomBookingType.ACADEMIC
                    ? getViewableFacilities
                    : getFacilitiesWithBookableTrainingAreas
                }
                getOptionLabel={(option: FacilityDTO) => option.facilityName}
                isOptionEqualToValue={(
                  option: FacilityDTO,
                  value: FacilityDTO
                ) => option.id === value.id}
                onSelectedOptionChanged={() => {
                  setValues({
                    ...values,
                    trainingArea: undefined
                  });
                }}
                component={AsyncAutocompleteV2}
                fullWidth
              />

              <Field
                {...getFieldErrorProps('trainingArea', formikHelpers)}
                label={getTrainingAreaFieldLabel(formProps.roomBookingType)}
                value={values.trainingArea}
                getOptions={
                  roomBookingType === RoomBookingType.ACADEMIC
                    ? getViewableTrainingAreas
                    : getBookableTrainingAreas
                }
                getOptionsFilters={
                  values.facility
                    ? {
                        facilityId: values.facility?.id?.toString()
                      }
                    : {}
                }
                getOptionLabel={(option: TrainingArea) => option.areaLocation}
                isOptionEqualToValue={(
                  option: TrainingArea,
                  value: TrainingArea
                ) => option.id === value.id}
                component={AsyncAutocompleteV2}
                fullWidth
              />

              {roomBookingType === RoomBookingType.ACADEMIC &&
                formProps.isApproverViewEnabled && (
                  <Field
                    {...getFieldErrorProps('activityType', formikHelpers)}
                    label="Activity Type"
                    value={values.activityType}
                    getOptions={formProps.getActivityTypes}
                    getOptionLabel={(option: ActivityType) => option.type}
                    isOptionEqualToValue={(
                      option: ActivityType,
                      value: ActivityType
                    ) => option.id === value.id}
                    component={AsyncAutocompleteV2}
                    fullWidth
                  />
                )}
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
}
