import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { ListViewType } from 'src/types';

export type ListViewTypePickerProps = {
  /** the current list view type */
  listViewType: ListViewType;
  /** called when the list view type changes */
  onListViewTypeChange: (listViewType: ListViewType) => void;
};

export const ListViewTypePicker = ({
  listViewType,
  onListViewTypeChange
}: ListViewTypePickerProps) => {
  return (
    <ToggleButtonGroup
      value={listViewType}
      exclusive
      onChange={(event, value) => {
        if (value !== null) {
          onListViewTypeChange(value as ListViewType);
        }
      }}
    >
      <ToggleButton color="primary" size="small" value={ListViewType.TABLE}>
        List
      </ToggleButton>

      <ToggleButton color="primary" size="small" value={ListViewType.CALENDAR}>
        Calendar
      </ToggleButton>
    </ToggleButtonGroup>
  );
};
