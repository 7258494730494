import { useCallback } from 'react';
import { AxiosResponse } from 'axios';
import {
  AreaCapacityDTO,
  BulkAreaCapacityDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';
import { AreaCapacity } from 'src/types/models/AreaCapacity.type';

export interface ICapacityService {
  getAreaCapacities: GetAPI<AreaCapacity>;
  copyAreaCapacitiesFromModule: (
    bulkAreaCapacityDTO: BulkAreaCapacityDTO,
    abortController?: AbortController
  ) => Promise<void>;
  getAreaCapacity: (
    id: number,
    abortController?: AbortController
  ) => Promise<AreaCapacity>;
  createAreaCapacity: (
    areaCapacity: AreaCapacity,
    abortController?: AbortController
  ) => Promise<void>;
  updateAreaCapacity: (
    areaCapacity: AreaCapacity,
    abortController?: AbortController
  ) => Promise<void>;
  deleteAreaCapacity: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  downloadAreaCapacities: (
    showBackDrop: () => void,
    hideBackDrop: () => void,
    abortController?: AbortController,
    filters?: Record<string, string>,
    search?: string,
    ids?: number[]
  ) => Promise<void>;
}

export const useAreaCapacityService = (): ICapacityService => {
  const areaCapacityApi = useAxiosInstance('/v2/areaCapacities');

  const getAreaCapacities = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<AreaCapacity>> => {
      return areaCapacityApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<AreaCapacity>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<AreaCapacity>;
        });
    },
    [areaCapacityApi]
  );

  const copyAreaCapacitiesFromModule = (
    bulkAreaCapacityDTO: BulkAreaCapacityDTO,
    abortController?: AbortController
  ): Promise<void> =>
    areaCapacityApi.post('/bulk', bulkAreaCapacityDTO, {
      withCredentials: true,
      signal: abortController?.signal
    });

  const getAreaCapacity = useCallback(
    (id: number, abortController?: AbortController): Promise<AreaCapacity> =>
      areaCapacityApi
        .get(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) =>
          getAreaCapacityFromDTO(response.data as AreaCapacityDTO)
        ),
    [areaCapacityApi]
  );

  const deleteAreaCapacity = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      areaCapacityApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [areaCapacityApi]
  );

  const createAreaCapacity = (
    areaCapacity: AreaCapacity,
    abortController?: AbortController
  ): Promise<void> =>
    areaCapacityApi.post('', getAreaCapacityDto(areaCapacity), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const updateAreaCapacity = (
    areaCapacity: AreaCapacity,
    abortController?: AbortController
  ): Promise<void> =>
    areaCapacityApi.put(
      `/${areaCapacity.id}`,
      getEditModelFromAreaCapacity(areaCapacity),
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );

  const downloadAreaCapacities = (
    showBackDrop: () => void,
    hideBackDrop: () => void,
    abortController?: AbortController,
    filters?: Record<string, string>,
    search?: string,
    ids?: number[]
  ): Promise<void> => {
    showBackDrop();
    return areaCapacityApi
      .get('download', {
        params: {
          filters,
          search: search,
          ids: ids
        },
        responseType: 'blob',
        withCredentials: true,
        signal: abortController?.signal
      })
      .then((response) => {
        getFileFromResponse(response);
      })
      .catch((error) => {
        throw error;
      })
      .finally(() => {
        hideBackDrop();
      });
  };

  return {
    getAreaCapacities,
    copyAreaCapacitiesFromModule,
    getAreaCapacity,
    createAreaCapacity,
    updateAreaCapacity,
    deleteAreaCapacity,
    downloadAreaCapacities
  };
};

function getEditModelFromAreaCapacity(areaCapacity: AreaCapacity) {
  return {
    id: areaCapacity.id,
    moduleScheduleId: areaCapacity.moduleSchedule?.id,
    themeScheduleId: areaCapacity.themeSchedule?.id,
    facilityId: areaCapacity.facility?.id,
    trainingAreaId: areaCapacity.trainingArea?.id,
    capacity: areaCapacity.capacity,
    startDate: areaCapacity.startDate,
    endDate: areaCapacity.endDate
  };
}

function getAreaCapacityDto(areaCapacity: AreaCapacity) {
  return {
    moduleScheduleId: areaCapacity.moduleSchedule?.id,
    themeScheduleIds: areaCapacity.themeSchedules?.map(
      (themeSchedule) => themeSchedule.id
    ),
    facilityId: areaCapacity.facility?.id,
    trainingAreaId: areaCapacity.trainingArea?.id,
    capacity: areaCapacity.capacity.toString(),
    startDate: getDtoDateStringFromDate(areaCapacity.startDate),
    endDate: getDtoDateStringFromDate(areaCapacity.endDate)
  };
}

function getAreaCapacityFromDTO(
  areaCapacityDTO: AreaCapacityDTO
): AreaCapacity {
  const { startDate, endDate } = areaCapacityDTO;
  return {
    ...areaCapacityDTO,
    startDate: getDateFromDtoDateString(startDate),
    endDate: getDateFromDtoDateString(endDate)
  };
}

function getFileFromResponse(response: AxiosResponse) {
  const blob = new Blob([response.data], {
    type: response.headers['content-type']
  });
  const downloadUrl = window.URL.createObjectURL(blob);
  const disposition = response.headers['content-disposition'];
  let filename = 'capacity.xls';
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
      matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  const a = document.createElement('a');
  if (typeof a.download === 'undefined') {
    window.location.href = downloadUrl;
  } else {
    a.href = downloadUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    a.remove();
  }
}
