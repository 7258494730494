import { PageHeader } from 'src/types';
import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';

export type UsePageHeadingInfoProps = {
  pageHeaderConfig: PageHeader[];
  urlPath: string;
};

export const usePageHeadingInfo = ({
  pageHeaderConfig,
  urlPath
}: UsePageHeadingInfoProps): PageHeader | undefined => {
  const pageHeader = useMemo(() => {
    return pageHeaderConfig.find((pageheaderconfig) => {
      const matchedPath = matchPath(pageheaderconfig.route, urlPath);
      return !!matchedPath;
    });
  }, [pageHeaderConfig, urlPath]);

  return pageHeader;
};
