import { DownloadAuditLogs } from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IAuditService {
  downloadAuditLogs: (
    downloadAuditLogs: DownloadAuditLogs,
    abortController?: AbortController
  ) => Promise<Blob>;
}

export const useAuditService = (): IAuditService => {
  const auditApi = useAxiosInstance('/v2/auditLogs');

  const downloadAuditLogs = (
    downloadAuditLogs: DownloadAuditLogs,
    abortController?: AbortController
  ): Promise<Blob> => {
    const auditLogsParams: AuditLogsParams = {
      startDate: downloadAuditLogs.startDate.toISOString(),
      endDate: downloadAuditLogs.endDate.toISOString()
    };
    return auditApi
      .get('/download', {
        params: auditLogsParams,
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined,
        responseType: 'blob'
      })
      .then((response) => {
        const type = response.headers['content-type'];
        return new Blob([response.data] as BlobPart[], { type });
      });
  };

  return { downloadAuditLogs };
};

export type AuditLogsParams = {
  startDate: string;
  endDate: string;
};
