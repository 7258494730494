import React, {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { RoomBookingType } from 'src/types';
import { useCacheStore } from 'src/common';

type BookingsContextInterface = {
  /**
   * @returns <code>true</code> if the 'approvals' view is enabled for the list view screen.
   * @returns <code>false</code> otherwise.
   */
  getIsApproverViewEnabled: () => boolean;

  /**
   * Updates whether the 'approvals' view is enabled for the list view screen.
   *
   * @param isEnabled <code>true</code> if the 'approvals' view is enabled. <code>false</code> otherwise.
   */
  setIsApproverViewEnabled: (isEnabled: boolean) => void;

  /**
   * A <code>Map</code> for looking up the total number of bookings waiting for approval
   * for different booking types.
   */
  bookingsTypeCount: Map<RoomBookingType, number | undefined>;

  /**
   * Updates the total number of bookings waiting for approval for a specific bookings type/
   *
   * @param roomBookingType the booking type  e.g. <code>RoomBookingType.ACCOMMODATION</code>
   * @param count the total number of bookings waiting for approval for a specific bookings type
   */
  setBookingsTypeCount: (
    roomBookingType: RoomBookingType,
    count: number
  ) => void;
};

const BookingsContext = createContext<BookingsContextInterface | undefined>(
  undefined
);
BookingsContext.displayName = 'Bookings Context';

const CACHE_STORE_ROOT_KEY = 'bookings';
const CACHE_STORE_BOOKINGS_TYPE_COUNT = 'bookings-type-count';

const BOOKING_TYPES = [
  RoomBookingType.ACADEMIC,
  RoomBookingType.ADHOC,
  RoomBookingType.ACCOMMODATION,
  RoomBookingType.TRANSPORT
];

/**
 * Enables approval bookings screens to maintain certain state that needs to
 * persist between mounting and unmounting the associated containers. This includes:
 *
 * (1) A boolean for each booking type, indicating whether the approval view is enabled
 * on the list view screen for said booking type (use `getIsApproverViewEnabled` and
 * `setIsApproverViewEnabled` to maintain this state).
 *
 * (2) The total number of bookings waiting for approval, for each booking type. This total
 * is displayed in the navigation tab heading of each booking type on the list view screen
 * when the approval view is enabled. e.g. Accommodation (5). (use `bookingsTypeCount` and
 * `setBookingsTypeCount` to maintain this state).
 *
 * See the documentation above for type `BookingsContextInterface` for more details.
 */
export const BookingsContextProvider: FC = ({ children }) => {
  const cacheStore = useCacheStore(CACHE_STORE_ROOT_KEY);
  const [bookingsTypeCount, setBookingsTypeCountInternal] = useState<
    Map<RoomBookingType, number | undefined>
  >(
    BOOKING_TYPES.reduce((result, roomBookingType) => {
      if (!result.has(roomBookingType)) {
        result.set(roomBookingType, undefined);
      }
      return result;
    }, cacheStore.get<Map<RoomBookingType, number | undefined>>(CACHE_STORE_BOOKINGS_TYPE_COUNT) || new Map())
  );

  const [bookingsIsApproverViewEnabled, setBookingsIsApproverViewEnabled] =
    useState<boolean>(false);

  useEffect(() => {
    const bookingsTypeCountCurrentValue = cacheStore.get<
      Map<RoomBookingType, number>
    >(CACHE_STORE_BOOKINGS_TYPE_COUNT);

    const isBookingsTypeCountValueChanged = Object.keys(bookingsTypeCount)
      .map(parseInt)
      .some((roomBookingType) => {
        const currentValue =
          bookingsTypeCountCurrentValue?.get(roomBookingType);
        const newValue = bookingsTypeCount.get(roomBookingType);
        return currentValue !== newValue;
      });

    if (isBookingsTypeCountValueChanged) {
      cacheStore.set(CACHE_STORE_BOOKINGS_TYPE_COUNT, bookingsTypeCount);
    }
  }, [cacheStore, bookingsTypeCount]);

  const setBookingsTypeCount = useCallback(
    (roomBookingType: RoomBookingType, count: number) => {
      const bookingsTypeCountNew = new Map(bookingsTypeCount.entries());
      bookingsTypeCountNew.set(roomBookingType, count);
      setBookingsTypeCountInternal(bookingsTypeCountNew);
    },
    [bookingsTypeCount]
  );

  const getIsApproverViewEnabled = useCallback(() => {
    return bookingsIsApproverViewEnabled || false;
  }, [bookingsIsApproverViewEnabled]);

  const setIsApproverViewEnabled = useCallback((isEnabled: boolean) => {
    setBookingsIsApproverViewEnabled(isEnabled);
  }, []);

  return (
    <BookingsContext.Provider
      value={{
        bookingsTypeCount,
        setBookingsTypeCount,
        getIsApproverViewEnabled,
        setIsApproverViewEnabled
      }}
    >
      {children}
    </BookingsContext.Provider>
  );
};

export const useBookingsContext = (): BookingsContextInterface => {
  const context = useContext(BookingsContext);

  if (!context) {
    throw new Error(
      'Bookings Context cannot be used outside of Bookings Context Provider'
    );
  }

  return context;
};
