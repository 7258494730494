import { ReactNode, useCallback } from 'react';
import axios, { AxiosError } from 'axios';
import { useConfirm } from 'material-ui-confirm';
import { useSnackbar } from 'notistack';
import { FormValidationFailedError } from 'src/lib';
import { useErrorLoggingService } from 'src/services';
import { useBackdrop } from '../backdrop-context-provider/BackdropContextProvider';
import { BAD_REQUEST } from '../../lib/constants';
import { CriticalErrorModalDisplay } from './CriticalErrorModalDisplay';

type ResponseHandler<T> = {
  /** @throws {@link FormValidationFailedError}
   * This exception should be thrown when the serviceCall fails
   * because the parameters for it as capture by the form failed
   * one or more validation checks. In such a case, the operation
   * outcome is not portrayed to the user as a success (i.e. no
   * success toast is shown), or a failure (i.e. no error is shown).
   * Instead, only the backdrop is hidden.
   */
  serviceCall: () => Promise<T>;
  successProps?: {
    title?: string;
    description?: string;
    onSuccessCallback?: () => void;
    onResponseReceivedCallback?: (response: T) => ResponseHandlerProps;
  };
  confirmProps?: {
    title: string;
    titleProps?: object;
    description?: string | JSX.Element;
    confirmButtonLabel?: string;
    cancelButtonLabel?: string;
  };
  failureProps?: { title: string; onAcknowledge?: () => void };
};

export enum ResponseHandlerPropsType {
  TOAST_RESPONSE_HANDLER_PROPS,
  MODAL_RESPONSE_HANDLER_PROPS
}

type ToastResponseHandlerProps = {
  type: ResponseHandlerPropsType.TOAST_RESPONSE_HANDLER_PROPS;
  title: string;
  description: string;
};

export type ModalResponseHandlerProps = {
  type: ResponseHandlerPropsType.MODAL_RESPONSE_HANDLER_PROPS;
  title: string;
  modalBody: ReactNode;
};

export type ResponseHandlerProps =
  | ToastResponseHandlerProps
  | ModalResponseHandlerProps;

interface IResponseHandler<T> {
  handleServiceCall: ({
    serviceCall,
    failureProps,
    confirmProps,
    successProps
  }: ResponseHandler<T>) => void;
}

export function useResponseHandler<T>(): IResponseHandler<T> {
  const confirm = useConfirm();
  const { logError } = useErrorLoggingService();
  const { enqueueSnackbar } = useSnackbar();
  const { showBusyBackdrop, hideBusyBackdrop } = useBackdrop();

  const getUserConfirmation = useCallback(
    (title: string, errorMessage: string, isUserRecoveryPossible?: boolean) => {
      return confirm({
        title: isUserRecoveryPossible ? 'Whoops!' : 'Critical Error!',
        titleProps: {
          variant: 'h6',
          color: 'primary'
        },
        description: isUserRecoveryPossible ? (
          <p>{errorMessage}</p>
        ) : (
          <CriticalErrorModalDisplay
            headingText="Please try again later"
            contentText={`${title}. ${errorMessage}`}
          />
        ),
        confirmationText: 'OK',
        confirmationButtonProps: {
          color: 'primary',
          variant: 'contained'
        },
        cancellationText: ''
      });
    },
    [confirm]
  );

  const getServiceCallSuccessModal = useCallback(
    (modalResponseHandlerProps: ModalResponseHandlerProps): Promise<void> => {
      return confirm({
        title: modalResponseHandlerProps.title,
        titleProps: {
          variant: 'h6',
          color: 'primary'
        },
        description: modalResponseHandlerProps.modalBody,
        confirmationText: 'CLOSE',
        confirmationButtonProps: {
          color: 'primary',
          variant: 'contained'
        },
        cancellationText: ''
      });
    },
    [confirm]
  );

  const handleServiceCall = useCallback(
    ({
      serviceCall,
      successProps,
      failureProps,
      confirmProps
    }: ResponseHandler<T>) => {
      showBusyBackdrop();

      Promise.resolve()
        .then(() => {
          if (confirmProps) {
            return confirm({
              title: confirmProps.title,
              titleProps: {
                ...confirmProps.titleProps,
                color: 'primary',
                variant: 'h6'
              },
              description: confirmProps.description || '',
              confirmationText: confirmProps.confirmButtonLabel || 'Confirm',
              confirmationButtonProps: {
                color: 'primary',
                variant: 'contained'
              },
              cancellationText: confirmProps.cancelButtonLabel || 'Cancel',
              cancellationButtonProps: {
                color: 'secondary',
                variant: 'contained'
              },
              allowClose: false
            });
          }
          return Promise.resolve();
        })
        .then(() =>
          serviceCall().then(
            (response) => {
              hideBusyBackdrop();
              if (!successProps) {
                return;
              }

              const { title, description } = successProps;

              if (successProps.onResponseReceivedCallback) {
                const responseHandler =
                  successProps.onResponseReceivedCallback(response);

                switch (responseHandler.type) {
                  case ResponseHandlerPropsType.TOAST_RESPONSE_HANDLER_PROPS: {
                    const { title, description } = responseHandler;
                    enqueueSnackbar(title, {
                      children: <div>{description}</div>,
                      variant: 'success'
                    });
                    successProps.onSuccessCallback &&
                      successProps.onSuccessCallback();
                    break;
                  }

                  case ResponseHandlerPropsType.MODAL_RESPONSE_HANDLER_PROPS: {
                    return getServiceCallSuccessModal(responseHandler).then(
                      () => {
                        successProps.onSuccessCallback &&
                          successProps.onSuccessCallback();
                      }
                    );
                  }

                  default:
                    throw new Error('unknown response handler type');
                }
              } else if (title !== undefined && description !== undefined) {
                enqueueSnackbar(title, {
                  children: <div>{description}</div>,
                  variant: 'success'
                });
                successProps.onSuccessCallback &&
                  successProps.onSuccessCallback();
              } else {
                throw new Error(
                  'successProps specified, but expected either ' +
                    'title & description or onResponseReceivedCallback to be specified'
                );
              }
            },
            (error) => {
              if (error instanceof FormValidationFailedError) {
                hideBusyBackdrop();
              } else {
                throw error;
              }
            }
          )
        )
        .catch((error: Error | AxiosError) => {
          if (!error) {
            // if the user clicked on the confirmation dialog cancel button
            hideBusyBackdrop();
            return;
          }

          logError(error);

          let errorMessage: string;
          if (axios.isAxiosError(error)) {
            errorMessage =
              error?.response?.data.message || 'a critical error occurred';
            const errorResponseCode = error.response?.status;
            hideBusyBackdrop();
            return getUserConfirmation(
              failureProps?.title ?? 'Error',
              errorMessage,
              errorResponseCode === BAD_REQUEST
            );
          } else {
            errorMessage = error.message;
            hideBusyBackdrop();
            return getUserConfirmation(
              failureProps?.title ?? 'Error',
              errorMessage
            );
          }
        });
    },
    [
      confirm,
      enqueueSnackbar,
      getUserConfirmation,
      hideBusyBackdrop,
      logError,
      showBusyBackdrop,
      getServiceCallSuccessModal
    ]
  );

  return { handleServiceCall };
}
