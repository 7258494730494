import { SortDirection } from '@mui/material';
import { FiltersAdapter, GetAPI } from 'src/types';

export type GetFirstFromPaginatedApiProps<T, F=undefined> = {
  getApi: GetAPI<T, F>;
  filters?: FiltersAdapter<F>;
  search?: string;
  order?: SortDirection;
  orderBy?: string;
  abortController?: AbortController;
};

export const getFirstFromPaginatedApi = <T, F=undefined>({
  getApi,
  filters,
  search,
  order,
  orderBy,
  abortController
}: GetFirstFromPaginatedApiProps<T, F>): Promise<T | undefined> => {
  return getApi({
    pageNumber: 0,
    pageSize: 5, // TODO: figure out why setting page size to 1 changes the ordering
    filters,
    search,
    order,
    orderBy,
    abortController
  }).then(({ items }) => {
    return items.at(0);
  });
};
