import { AreaCapacity, RoomBookingStatus } from 'src/types';

export function getDisplayTextFromRoomBookingStatus(status: RoomBookingStatus) {
  switch (status) {
    case RoomBookingStatus.REJECTED:
      return 'Rejected';
    case RoomBookingStatus.APPROVED:
      return 'Approved';
    case RoomBookingStatus.AWAITING_APPROVAL:
      return 'Awaiting Approval';
    case RoomBookingStatus.CANCELLED:
      return 'Cancelled';
  }
}

export function getConflictsPillLabel(conflictsCount: number) {
  if (conflictsCount === 1) {
    return `${conflictsCount} Conflict`;
  } else {
    return `${conflictsCount} Conflicts`;
  }
}

/**
 * Returns sum of capacity for list of areaCapacities for a certain date and Facility.
 *
 * @remarks
 * This function return a number of the sum total of all areaCapacities within the given array that have a certain facilityId and a startDate and endDate wrapping a certain date
 *
 * @param date - the date that the areaCapacities must fall between
 * 
 * @param facilityId - the Id of the facility to filter area capacities by
 * 
 * @param areaCapacities - the array of areaCapacities to filter to get the sum total.
 * 
 * @returns a {@link number} that is the sum total of the capacities for all relevant areaCapacities
 */
export function getFacilityTotalAreaCapacity(date: Date, facilityId: number, areaCapacities: AreaCapacity[]) {
  return areaCapacities
        .filter(
          (areaCapacity) =>
            areaCapacity.facilityId === facilityId &&
            new Date(date).getTime() > new Date(areaCapacity.startDate).getTime() &&
            new Date(date).getTime() < new Date(areaCapacity.endDate).getTime()
        )
        .reduce((total, areaCapacity) => (total = total + areaCapacity.capacity), 0);
}