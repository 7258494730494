export enum ThemableBoxType {
  FORM_LAYOUT_SMALL = 'FormLayoutSmall',
  FORM_LAYOUT_LARGE = 'FormLayoutLarge',
  EMBEDDED_CONTAINER = 'EmbeddedContainer',
  NAVIGATION_LINK = 'NavigationLink',
  NAVIGATION_LOGO = 'NavigationLogo',
  CALENDAR = 'Calendar',
  CALENDAR_TIMELINE = 'CalendarTimeline',
  DATE_RANGE_CONTROL = 'DateRangeControl',
  MODAL = 'Modal',
  USER_PROFILE_MENU_WITH_INITIALS = 'UserProfileMenuWithInitials',
  WELCOME_BANNER = 'WelcomeBanner',
  WELCOME_GREETER = 'WelcomeGreeter',
  TABLE_LAYOUT_SMALL = 'TableLayoutSmall',
  TABLE_LAYOUT_LARGE = 'TableLayoutLarge',
  TABLE_LAYOUT_CARD = 'TableLayoutCard',
  SWITCH_MODE_CHECKBOX = 'SwitchModeCheckbox',
  PILL = 'Pill',
  APP_ICON = 'AppIcon',
  NOTIFICATIONS_MENU_BUTTON = 'NotificationsMenu',
  NOTIFICATIONS_LIST = 'NotificationsList',
  NOTIFICATIONS_HEADER = 'NotificationsHeader',
  HEADER_CONTAINER = 'HeaderContainer',
  SEARCH_BOX_CONTAINER = 'SearchBoxContainer',
  TRANSFER_TABLE_BUTTON = 'TransferTable',
  MIN_MAX_WIDTH_LAYOUT = 'MinMaxWidthLayout',
  MY_MODULES = 'MyModules',
  DRAG_TOOLTIP = 'DragTooltip',
  BORDER_BOX = 'BorderBox',
  TABLE_GROUP_BY = 'TableGroupBy',
  HELIX_LOADER = 'HelixLoader',
  ICON_BUTTON_WITH_LABEL = 'IconButtonWithLabel',
  FORM_LAYOUT = 'FormLayout',
  FORM_CONTAINER = 'FormContainer',
  FORM_ELEMENTS_CONTAINER = 'FormElementsContainer',
  FORM_FOOTER = 'FormFooter',
  FORM_CONTENT_LAYOUT = 'FormContentLayout',
  APPLICATION_LAYOUT = 'ApplicationLayout',
  TOOLTIP = 'Tooltip',
  LIST_VIEW_CONTAINER = 'ListViewContainer',
  OPENED_LIST_VIEW_FILTER_CONTAINER = 'OpenedListViewFilterContainer',
  ACTION_LINK = 'ActionLink',
  LIST_VIEW_ACTIONS_DROP_DOWN = 'ListViewActionsDropDown',
  CRITICAL_ERROR_SCREEN = 'CriticalErrorScreen',
  CRITICAL_ERROR_MODAL_DISPLAY = 'CriticalErrorModalDisplay',
  DIALOG_CONTROL = 'DialogControl',
  COLLAPSIBLE_TABLE = 'collapsibleTable',
  BOOKINGS_HEADER_TOOLBAR = 'BookingsHeaderToolbar',
  LIST_VIEW_TYPE_PICKER = 'ListViewTypePicker',
  TABLE_COUNT = 'TableCount',
  SEARCH_FIELD = 'SearchField',
  FILTER_FIELD = 'FilterField',
  CALENDAR_ACCORDIAN = 'CalendarAccordian',
  CALENDAR_ACCORDIAN_ITEM = 'CalendarAccordianItem'
}
