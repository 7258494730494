import React, { FC } from 'react';
import { Grid, Typography } from '@mui/material';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';

export const ListHeaderNoNotifications: FC = () => {
  return (
    <ThemableBox type={ThemableBoxType.NOTIFICATIONS_HEADER}>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={11}>
          <Typography variant="h3">You have no new notifications</Typography>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </ThemableBox>
  );
};
