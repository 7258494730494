import { StudentGroup } from './StudentGroup.type';
import { ThemeSchedule } from './ThemeSchedule.type';

export enum ValidationType {
  STUDENT_GROUP_VALIDATION = 'STUDENT_GROUP_VALIDATION',
  CLINICAL_THEME_SCHEDULES_VALIDATION = 'CLINICAL_THEME_SCHEDULES_VALIDATION',
  THEME_SCHEDULES_MATCH_VALIDATION = 'THEME_SCHEDULES_MATCH_VALIDATION',
  GROUP_NAMES_MATCH_VALIDATION = 'GROUP_NAMES_MATCH_VALIDATION',
  MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION = 'MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION',
  AREA_CAPACITIES_VALIDATION = 'AREA_CAPACITIES_VALIDATION'
}

type BaseValidation = {
  isValid: boolean;
  validationMessage: string;
};

export type StudentGroupValidation = BaseValidation & {
  validationType: ValidationType.STUDENT_GROUP_VALIDATION;
  visualizeValidation: StudentGroup[];
};

export type ClinicalThemeSchedulesValidation = BaseValidation & {
  validationType: ValidationType.CLINICAL_THEME_SCHEDULES_VALIDATION;
  visualizeValidation: ThemeSchedule[];
};

export type ThemeSchedulesNamesMatchValidation = BaseValidation & {
  validationType: ValidationType.THEME_SCHEDULES_MATCH_VALIDATION;
};

export type GroupNamesMatchValidation = BaseValidation & {
  validationType: ValidationType.GROUP_NAMES_MATCH_VALIDATION;
};

export type MinRequiredWeeksAgainstAvailableValidation = BaseValidation & {
  validationType: ValidationType.MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION;
  visualizeValidation: ThemeSchedule[];
};

export type AreaCapacitiesValidation = BaseValidation & {
  validationType: ValidationType.AREA_CAPACITIES_VALIDATION;
  visualizeValidation: ThemeSchedule[];
};

export type ThemeAutomationValidationResult =
  | StudentGroupValidation
  | ClinicalThemeSchedulesValidation
  | ThemeSchedulesNamesMatchValidation
  | GroupNamesMatchValidation
  | MinRequiredWeeksAgainstAvailableValidation
  | AreaCapacitiesValidation;
