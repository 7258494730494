import { ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { PropsWithChildren, ReactElement } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';

interface NavigationLinkProps {
  icon?: ReactElement;
  to: string;
  primary: string;
}

export const NavigationLink = ({
  icon,
  to,
  primary
}: PropsWithChildren<NavigationLinkProps>) => {
  return (
    <ThemableBox type={ThemableBoxType.NAVIGATION_LINK}>
      <RouterLink
        to={to}
        children={({ isActive }) => (
          <ListItemButton selected={isActive}>
            {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
            <ListItemText primary={primary} />
          </ListItemButton>
        )}
      />
    </ThemableBox>
  );
};
