import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { matchPath, useLocation } from 'react-router-dom';
import { ModuleSchedule } from 'src/types';
import { useModuleSchedulesService } from 'src/services';
import { MyModulesForm, MyModulesTabId } from '../components';

type MyModulesRouting = {
  /** The initial URL navigated to after the user clicks on one of the MyModules buttons. */
  initialUrl: string;
  /**
   * An optional array of routes or route patterns that should
   * match URLs that the user can navigate to from the initial URL, whilst
   * staying within the boundary of the functionality represented by the
   * MyModules button clicked. May be different to {@link initialUrl}. */
  routes?: string[];
};

function getMyModulesRoutingFromParameters(
  myModulesTabId: MyModulesTabId,
  moduleSchedule: ModuleSchedule
): MyModulesRouting {
  const {
    id: moduleScheduleId,
    calendarYear,
    programmeScheduleIds
  } = moduleSchedule;

  const programmeScheduleId = programmeScheduleIds.at(0);

  if (!programmeScheduleId) {
    throw new Error('programmeScheduleId expected');
  }

  switch (myModulesTabId) {
    case MyModulesTabId.PLACEMENT_SCHEDULES: {
      const baseUrl = '/bookings/academic';
      return {
        initialUrl: `${baseUrl}?programmeScheduleId=${programmeScheduleId}&moduleScheduleId=${moduleScheduleId}&isApproverView=false`,
        routes: [`${baseUrl}/booking/*`]
      };
    }

    case MyModulesTabId.SCHEDULE_THEME: {
      const baseUrl = `/schedule-themes/programmes/${calendarYear.id}/${programmeScheduleId}/modules/${moduleScheduleId}/themes`;
      return {
        initialUrl: baseUrl,
        routes: [`${baseUrl}/*`]
      };
    }

    case MyModulesTabId.CLASS_LIST: {
      const baseUrl = `/class-list/module/${moduleScheduleId}`;
      return { initialUrl: baseUrl, routes: [`${baseUrl}/*`] };
    }

    case MyModulesTabId.ACTIVITIES: {
      const baseUrl = `/curriculum/programmes-schedules/${programmeScheduleId}/module-schedules/${moduleScheduleId}/activities`;
      return {
        initialUrl: baseUrl,
        routes: [`${baseUrl}/*`]
      };
    }

    case MyModulesTabId.SUPERVISORS: {
      const baseUrl = `/module-supervisors/module/${moduleScheduleId}`;
      return { initialUrl: baseUrl, routes: [`${baseUrl}/*`] };
    }

    default:
      throw new Error('unknown module schedule id');
  }
}

export const MyModulesContainer: FC = () => {
  const { getMyModuleSchedules } = useModuleSchedulesService();

  const navigate = useNavigate();
  const location = useLocation();

  const [state, setState] = useState<{
    isNavigationButtonsVisible: boolean;
    moduleSchedule?: ModuleSchedule;
    moduleScheduleLastSelected?: ModuleSchedule;
    myModulesTabId?: MyModulesTabId;
  }>({
    isNavigationButtonsVisible: false
  });

  const stateRef = useRef<{
    routing?: MyModulesRouting;
    moduleSchedule?: ModuleSchedule;
    myModulesTabId?: MyModulesTabId;
  }>();

  const isAnyRoutesMatch = (path: string, routes: string[]) => {
    return routes.some((route) => {
      return !!matchPath(route, path);
    });
  };

  useEffect(() => {
    if (!stateRef.current) {
      // handleOnSubmit hasn't run yet
      return;
    }

    const { pathname, search } = location;
    const { routing, myModulesTabId, moduleSchedule } = stateRef.current;

    /** MyModules buttons should remain visible if the user has
     * not navigated outside the functionality defined by the
     * current MyModules tab. i.e. if the user had clicked on the
     * activities MyModules button, by clicking on the settings side
     * navigation button, they are navigated to a page outside of
     * activities. */
    const isButtonsVisible =
      !!routing &&
      (routing.initialUrl === `${pathname}${search}` ||
        (!!routing.routes && isAnyRoutesMatch(pathname, routing.routes))) &&
      !!myModulesTabId &&
      !!moduleSchedule;

    setState((stateOld) => ({
      ...stateOld,
      isNavigationButtonsVisible: isButtonsVisible,
      moduleSchedule: isButtonsVisible ? stateOld.moduleSchedule : undefined,
      myModulesTabId: isButtonsVisible ? stateOld.myModulesTabId : undefined
    }));
  }, [location]);

  const handleOnSubmit = useCallback(
    (myModulesTabId?: MyModulesTabId, moduleSchedule?: ModuleSchedule) => {
      const routing =
        myModulesTabId && moduleSchedule
          ? getMyModulesRoutingFromParameters(myModulesTabId, moduleSchedule)
          : undefined;

      setState((stateOld) => ({
        ...stateOld,
        moduleSchedule,
        moduleScheduleLastSelected: moduleSchedule
          ? moduleSchedule
          : stateOld.moduleScheduleLastSelected,
        myModulesTabId: moduleSchedule ? stateOld.myModulesTabId : undefined,
        isNavigationButtonsVisible: !!myModulesTabId && !!moduleSchedule
      }));

      stateRef.current = {
        ...stateRef.current,
        moduleSchedule,
        myModulesTabId,
        routing: routing
      };

      routing && navigate(routing.initialUrl);
    },
    [navigate]
  );

  return (
    <MyModulesForm
      {...state}
      getMyModuleSchedules={getMyModuleSchedules}
      onSubmit={handleOnSubmit}
    />
  );
};
