import { FormType, RoomBookingStatus, RoomBookingType } from 'src/types';

export function getRoomBookingFromType(type: RoomBookingType): string {
  switch (type) {
    case RoomBookingType.ACADEMIC:
      return 'Academic';
    case RoomBookingType.ACCOMMODATION:
      return 'Accommodation';
    case RoomBookingType.TRANSPORT:
      return 'Transport';
    case RoomBookingType.ADHOC:
      return 'Adhoc';
    default:
      throw new Error('RoomBookingType not valid');
  }
}

export function getRoomBookingTypeFromString(type: string): RoomBookingType | undefined {
  switch(type){
    case 'Academic':
      return RoomBookingType.ACADEMIC;
    case 'Accommodation':
      return RoomBookingType.ACCOMMODATION;
    case 'Transport':
      return RoomBookingType.TRANSPORT;
    case 'Adhoc':
      return RoomBookingType.ADHOC;
    default:
      return undefined;
  }
}

export function getUrlFromType(formType: FormType): string {
  switch (formType) {
    case FormType.UPDATE:
      return 'edit-booking';
    case FormType.CREATE:
      return 'create';
    case FormType.VIEW:
      return 'view-booking';
    case FormType.DOWNLOAD:
      return 'download';
    case FormType.DELETE:
      return 'delete';
    default:
      throw new Error('Form type not valid');
  }
}

/**
 * A type used to return formatted URL string for navigating to RoomBooking form pages
 *
 * @remarks
 */
type UrlSegment = {
  /**
   * The string value representing the roomBooking Type formatted for use in URLs e.g. academic, transport, acommodation, adhoc
   */
  roomBookingType: string;
  /**
   * The string value representing the form type, formatted for use in URLs e.g. view, edit, delete
   */
  bookingFormType: string;
  /**
   * When this booking is being editted as a series the field is set to '-series' else '', affecting the URL formatting.
   *
   * This field is used as a suffix for a URL.
   */
  editAsSeries: string;
};

/**
 * Returns a formatted string used in a URL, that indicates the type of booking of a form.
 *
 * @remarks
 * This function return a string that can be used in the URLs to navigate to Transport/Adhoc/Acommodation/Academic forms
 *
 * @param bookingType - an enum value representing the booking type currently being routed to e.g. Transport, Adhoc, Acommodation, Academic
 * 
 * @returns a {@link string} that is used to create formatted URLs to navigate to Transport, Adhoc, Acommodation, Academic forms
 */
function getUrlBookingTypeText(bookingType: RoomBookingType) {
  switch (bookingType) {
    case RoomBookingType.ACADEMIC:
      return 'academic';
    case RoomBookingType.ACCOMMODATION:
      return 'accommodation';
    case RoomBookingType.TRANSPORT:
      return 'transport';
    case RoomBookingType.ADHOC:
      return 'adhoc';
    default:
      throw new Error('RoomBookingType not valid');
  }
}

/**
 * Returns a formatted string used in a URL, that indicates the type of action of a form.
 *
 * @remarks
 * This function return a string that can be used in the URLs to navigate to View/Edit/Delete forms
 *
 * @param formType - an enum value representing the form type currently being routed to e.g. (View, Edit, Create, Delete)
 * 
 * @returns a {@link string} that is used to create formatted URLs to navigate to View/Edit/Delete forms
 */
function getUrlFormTypeText(formType: FormType) {
  switch (formType) {
    case FormType.UPDATE:
      return 'edit-booking';
    case FormType.CREATE:
      return 'create';
    case FormType.VIEW:
      return 'view-booking';
    case FormType.DOWNLOAD:
      return 'download';
    case FormType.DELETE:
      return 'delete';
    default:
      throw new Error('Form type not valid');
  }
}

/**
 * Returns a set of formatted strings to be used in creating bookings URLs
 *
 * @remarks
 * This function return that can be used in the URLs to navigate to View/Edit/Delete forms
 *
 * @param bookingType - an enum value for the type of booking currently being used e.g. (Transport, Adhoc, Accommodation)
 * @param formType - an enum value representing the form type currently being routed to e.g. (View, Edit, Create, Delete)
 * @param isEditAsSeries - boolean value representing if the current booking being used,
 * is being edited as a series (only used for edit pages)
 * @returns a {@link UrlSegment} that is used to create formatted URLs to navigate to View/Edit/Delete forms
 */
export function getUrlSegments(
  bookingType: RoomBookingType,
  formType: FormType,
  isEditAsSeries?: boolean
): UrlSegment {
  const bookingTypeText = getUrlBookingTypeText(bookingType);
  const formTypeText = getUrlFormTypeText(formType);
  const editAsSeriesText = isEditAsSeries ? '-series' : '';

  return {
    roomBookingType: bookingTypeText,
    bookingFormType: formTypeText,
    editAsSeries: editAsSeriesText
  };
}

export function getStatusColumnText(status: RoomBookingStatus) {
  switch (status) {
    case RoomBookingStatus.REJECTED:
      return 'Rejected';
    case RoomBookingStatus.APPROVED:
      return 'Approved';
    case RoomBookingStatus.AWAITING_APPROVAL:
      return 'Awaiting Approval';
    case RoomBookingStatus.CANCELLED:
      return 'Cancelled';
  }
}
