import React from 'react';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';
import { Chip, ChipProps } from '@mui/material';

export enum PillStyle {
  GREY = 'grey',
  RED = 'red',
  AMBER = 'amber',
  GREEN = 'green'
}

enum PrivatePillStyle {
  IS_CLICKABLE = 'is-clickable'
}

export type PillProps = ChipProps & {
  label: string;
  onClick?: () => void;
  pillStyle?: PillStyle;
};

export const Pill = ({ label, onClick, pillStyle }: PillProps) => {
  return (
    <ThemableBox type={ThemableBoxType.PILL}>
      <Chip
        label={label}
        onClick={onClick}
        variant={onClick ? 'outlined' : 'filled'}
        className={`${pillStyle?.toString()?? ''}${
          onClick ? ` ${PrivatePillStyle.IS_CLICKABLE}` : ''
        }`}
      />
    </ThemableBox>
  );
};
