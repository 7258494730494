import {
  TrainingAreaFacilityType,
  TrainingAreaFacilityTypeDTO
} from 'src/types';

export function getTrainingAreaFacilityTypeFromDto(
  facilityType: string
): TrainingAreaFacilityType {
  switch (facilityType) {
    case TrainingAreaFacilityTypeDTO.ACADEMIC:
      return TrainingAreaFacilityType.ACADEMIC;
    case TrainingAreaFacilityTypeDTO.ACCOMMODATION:
      return TrainingAreaFacilityType.ACCOMMODATION;
    case TrainingAreaFacilityTypeDTO.TRANSPORT:
      return TrainingAreaFacilityType.TRANSPORT;
    default:
      throw new Error(`unknown facility type: ${facilityType}`);
  }
}
