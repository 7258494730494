import {
  PlacementSchedule,
  PlacementScheduleStatus,
  RoomBooking,
  RoomBookingStatus
} from 'src/types';

export type GetBookingsProcessedProps = {
  bookings: RoomBooking[];
  placementSchedules?: PlacementSchedule[];
};

/**
 * @param bookings
 * @param placementSchedules Optional. If supplied, the status of each room booking will be adjusted as follows:
 *  if the associated placement schedule is cancelled, then the room booking status will be set to CANCELLED.
 *  else the room booking will be returned as is.
 *  If not supplied, the room booking status will be set to CANCELLED if the field placementScheduleStatus indicates so,
 *  else the room booking will be returned as is.
 */
export function getBookingsProcessed({
  bookings,
  placementSchedules
}: GetBookingsProcessedProps): RoomBooking[] {
  return bookings.map((booking) => {
    const placementSchedule = placementSchedules?.find(
      (placementSchedule) => placementSchedule.roomBookingId === booking.id
    );

    return {
      ...booking,
      status:
        (placementSchedule && placementSchedule.isCancelled) ||
        booking.placementScheduleStatus === PlacementScheduleStatus.CANCELLED
          ? RoomBookingStatus.CANCELLED
          : booking.status
    };
  });
}
