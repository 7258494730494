import { PlacementScheduleStudentDetails, Student } from 'src/types';

export function getPlacementScheduleStudentDetailsFromStudent(
  student: Student
): PlacementScheduleStudentDetails {
  return {
    id: student.id,
    displayName: student.displayName,
    hasOwnTransport: student.hasOwnTransport,
    licenceExpiry: student.licenseExpiry,
    gender: student.gender,
    genderV2: student.genderV2,
    needTransport: false,
    needAccommodation: false,
    carRegistration:
      (student.isAvailableForBooking && student.carRegistration) || '-',
    bookable: student.hasOwnTransport && student.isAvailableForBooking,
    excluded: false,
    conflictOverridden: false
  };
}
