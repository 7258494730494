import { useCallback, useEffect, useReducer } from 'react';
import { ICacheStore } from 'src/common/cache-store-provider/CacheStoreProvider';
import { DateRange, DateRangeType } from 'src/types';
import { getDateRangeFromDateAndDateRangeType } from 'src/lib';
import {
  DateRangeControlActionType,
  dateRangeControlReducer,
  DateRangeControlState
} from './date-range-control-reducer';

export type UseDateRangeControlProps = {
  initialDate?: Date;
  initialDateRangeType?: DateRangeType;
  /* If supplied, used to persist / reload internal dateRange
   * and dateRangeType state values (e.g. so it is not lost when
   * the page is refreshed) under the key `CACHE_STORE_KEY` */
  cacheStore?: ICacheStore;
};
export interface IDateRangeControl extends DateRangeControlState {
  goToNextDateRange: () => void;
  goToPreviousDateRange: () => void;
  goToSpecificDateRangeContainingDate: (date: Date) => void;
  setDateRangeType: (dateRangeType: DateRangeType) => void;
  setDateRange: (DateRange: DateRange) => void;
}

const CACHE_STORE_KEY = 'date-range';

export const useDateRangeControl = ({
  initialDate,
  initialDateRangeType,
  cacheStore
}: UseDateRangeControlProps = {}): IDateRangeControl => {
  const [state, dispatch] = useReducer(
    dateRangeControlReducer,
    cacheStore?.get<DateRangeControlState>(CACHE_STORE_KEY) || {
      dateRange: getDateRangeFromDateAndDateRangeType(
        initialDate || new Date(),
        initialDateRangeType || DateRangeType.WEEK
      ),
      dateRangeType: initialDateRangeType || DateRangeType.WEEK
    }
  );

  useEffect(() => {
    cacheStore?.set(CACHE_STORE_KEY, state);
  }, [cacheStore, state]);

  const goToNextDateRange = () => {
    dispatch({ type: DateRangeControlActionType.GO_TO_NEXT_DATE_RANGE });
  };

  const goToPreviousDateRange = () => {
    dispatch({ type: DateRangeControlActionType.GO_TO_PREVIOUS_DATE_RANGE });
  };

  const goToSpecificDateRangeContainingDate = useCallback((date: Date) => {
    dispatch({
      type:
        DateRangeControlActionType.GO_TO_SPECIFIC_DATE_RANGE_CONTAINING_DATE,
      payload: date
    });
  }, []);

  const setDateRange = useCallback((dateRange: DateRange) => {
    dispatch({
      type: DateRangeControlActionType.SET_DATE_RANGE,
      payload: dateRange
    });
  },[]);

  const setDateRangeType = useCallback((dateRangeType: DateRangeType) => {
    dispatch({
      type: DateRangeControlActionType.SET_DATE_RANGE_TYPE,
      payload: dateRangeType
    });
  }, []);

  return {
    goToNextDateRange,
    goToPreviousDateRange,
    goToSpecificDateRangeContainingDate,
    setDateRangeType,
    setDateRange,
    ...state
  };
};
