import { useMemo } from 'react';
import { Pill, Table, TableType } from 'src/common';
import { DATE_TIME_FORMAT, getDisplayDateStringFromDate } from 'src/lib';
import { RoomBooking, TableRowData } from 'src/types';
import {
  getStatusColumnText,
  getPillStyleFromBookingStatus,
  REQUEST_A_TRAINING_AREA_PLACEHOLDER_NAME
} from '../lib';

export type BookingTableRowDetails = {
  id: number;
  placementScheduleId?: number;
}

export type BookingsTableProps = {
  bookings: RoomBooking[];
  onViewBooking: (booking: BookingTableRowDetails) => void;
};

const headers = [
  { id: 'status', label: 'Status' },
  { id: 'trainingSite', label: 'Training Site' },
  { id: 'startDate', label: 'Start Date' },
  { id: 'endDate', label: 'End Date' },
  { id: 'bookedBy', label: 'Booked By' },
  { id: 'title', label: 'Title' }
];

export function BookingsTable({ bookings, onViewBooking }: BookingsTableProps) {
  const tableRows = useMemo<TableRowData[]>(() => {
    return bookings.map<TableRowData>((booking) => {
      return {
        status: (
          <Pill
            label={getStatusColumnText(booking.status)}
            pillStyle={getPillStyleFromBookingStatus(booking.status)}
          />
        ),
        trainingSite: booking.trainingIsVenueRequest
          ? REQUEST_A_TRAINING_AREA_PLACEHOLDER_NAME
          : booking.trainingSiteName,
        startDate: getDisplayDateStringFromDate(
          booking.startDateTime,
          DATE_TIME_FORMAT.FORMAL_DATE_TIME_FORMAT
        ),
        endDate: getDisplayDateStringFromDate(
          booking.endDateTime,
          DATE_TIME_FORMAT.FORMAL_DATE_TIME_FORMAT
        ),
        bookedBy: booking.bookedByUser?.displayName ?? '',
        title: booking.title,
        bookingId: booking.id,
        placementScheduleId: booking.placementScheduleId
      };
    });
  }, [bookings]);

  return (
    <Table
      type={TableType.STATIC}
      headers={headers}
      rows={tableRows}
      onRowClicked={(row) => {
        const { bookingId, placementScheduleId } = row;

        if (typeof bookingId !== 'number') {
          throw new Error('booking id expected to have number type');
        }

        if (placementScheduleId !== undefined && typeof placementScheduleId !== 'number') {
          throw new Error('placement schedule id expected to have number type');
        }

        onViewBooking({
          id: bookingId,
          placementScheduleId: placementScheduleId
        });
      }}
    />
  );
}
