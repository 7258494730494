import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from '../themable-box/ThemableBox';

export type CriticalErrorModalDisplayProps = {
  headingText: string;
  contentText: string;
};

const CLASS_NAMES = {
  ICON_AREA: 'icon-area',
  DETAILS_AREA: 'details-area',
  DETAILS_AREA_HEADING: 'details-area-heading',
  DETAILS_AREA_SUBTITLE: 'details-area-subtitle'
};

export const CriticalErrorModalDisplay: FC<CriticalErrorModalDisplayProps> = ({
  headingText,
  contentText
}) => {
  return (
    <ThemableBox type={ThemableBoxType.CRITICAL_ERROR_MODAL_DISPLAY}>
      <Box className={CLASS_NAMES.ICON_AREA}>
        <ErrorOutlineIcon />
      </Box>

      <Box className={CLASS_NAMES.DETAILS_AREA}>
        <Box className={CLASS_NAMES.DETAILS_AREA_HEADING}>
          <Typography>{headingText}</Typography>
        </Box>

        <Box className={CLASS_NAMES.DETAILS_AREA_SUBTITLE}>
          <Typography>{contentText}</Typography>
        </Box>
      </Box>
    </ThemableBox>
  );
};
