import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'lodash';
import {
  BookingGroupParams,
  BookingsGroupBy,
  useRoomBookingService
} from 'src/services';
import { BookingGroup, DateRange, RoomBookingType } from 'src/types';
import { useAppContext } from 'src/common';
import { DEBOUNCE_TIMEOUT_MILLISECONDS_LOW } from 'src/lib';
import { BookedBy, Filters } from '../components';

export type BookingsGroups = {
  bookingGroups: BookingGroup[];
  isBookingGroupsLoading: boolean;
};

export type UseBookingsGroupedProps = {
  dateRange: DateRange;
  filters: Filters;
  pageNumber: number;
  pageSize: number;
  onPaginatedDetailsChanged: (
    totalPages: number,
    totalElements: number
  ) => void;
  bookingType: RoomBookingType;
  groupBy: BookingsGroupBy;
  isApproverViewEnabled: boolean;
  debounceDelayMilliseconds?: number;
};

export function useBookingsGrouped(
  params: UseBookingsGroupedProps
): BookingsGroups {
  const {
    dateRange,
    filters,
    pageNumber,
    pageSize,
    onPaginatedDetailsChanged,
    groupBy,
    isApproverViewEnabled,
    debounceDelayMilliseconds,
    bookingType
  } = params;
  const { handleError } = useAppContext();
  const { getRoomBookingsGrouped } = useRoomBookingService();

  const [bookingGroups, setBookingGroups] = useState<BookingGroup[]>([]);
  const [isBookingGroupsLoading, setIsBookingGroupsLoading] =
    useState<boolean>(true);

  const fetchBookingGroupsDebounced = useMemo(
    () =>
      debounce(
        (
          dateRange: DateRange,
          filters: Filters,
          pageNumber: number,
          pageSize: number,
          groupBy: BookingsGroupBy,
          abortController: AbortController
        ) => {
          const {
            facility,
            trainingArea,
            activity,
            activityType,
            student,
            studentGroup,
            status,
            bookedBy,
            searchText,
            approver
          } = filters;

          const bookingGroupParams: BookingGroupParams = {
            facilityId: facility?.id,
            bookingType: bookingType,
            trainingAreaId: trainingArea?.id,
            approverId: approver?.id,
            activityId: activity?.activityId,
            activityTypeId: activityType?.id,
            studentId: student?.id,
            startDateTime: dateRange.fromDate,
            endDateTime: dateRange.toDate,
            studentGroupId: studentGroup?.id,
            bookingStatus: status,
            bookedByUser: bookedBy === BookedBy.ME,
            searchText: searchText,
            groupBy: groupBy
          };

          if (!isApproverViewEnabled && bookedBy !== BookedBy.EVERYONE_ALL) {
            bookingGroupParams.activityId = activity?.id;
            bookingGroupParams.activityTypeId = activityType?.id;
            bookingGroupParams.studentId = student?.id;
            bookingGroupParams.studentGroupId = studentGroup?.id;
          }

          getRoomBookingsGrouped({
            pageNumber,
            pageSize,
            filters: bookingGroupParams
          })
            .then((roomBookingsGrouped) => {
              const { items, totalPages, totalCount } = roomBookingsGrouped;
              onPaginatedDetailsChanged(totalPages, totalCount);
              setBookingGroups(items);
            })
            .catch(handleError)
            .finally(() => {
              if (!abortController.signal.aborted) {
                setIsBookingGroupsLoading(false);
              }
            });
        },
        debounceDelayMilliseconds ?? DEBOUNCE_TIMEOUT_MILLISECONDS_LOW,
        { trailing: true, leading: false }
      ),
    [
      isApproverViewEnabled,
      getRoomBookingsGrouped,
      onPaginatedDetailsChanged,
      debounceDelayMilliseconds,
      handleError,
      bookingType
    ]
  );

  useEffect(() => {
    setIsBookingGroupsLoading(true);
    setBookingGroups([]);
    const abortController = new AbortController();
    fetchBookingGroupsDebounced(
      dateRange,
      filters,
      pageNumber,
      pageSize,
      groupBy,
      abortController
    );

    return () => {
      abortController.abort();
    };
  }, [
    fetchBookingGroupsDebounced,
    dateRange,
    filters,
    pageNumber,
    pageSize,
    groupBy
  ]);

  return {
    bookingGroups: bookingGroups,
    isBookingGroupsLoading: isBookingGroupsLoading
  };
}
