export type StudentDTO = {
  id: number;
  title: string;
  name: string;
  surname: string;
  initials: string;
  displayName: string;
  emailAddress?: string;
  telephoneNumber: string;
  gender?: string;
  studentNumber: string;
  residentialAddressAddressLine1?: string;
  residentialAddressAddressLine2?: string;
  residentialAddressSurburb?: string;
  residentialAddressCity?: string;
  residentialPostalCode?: string;
  status?: string;
  transportId?: string;
  hasOwnTransport: boolean;
  availableForBooking: boolean;
  licenseExpiry?: string;
  carRegistration?: string;
  studentInGroup: boolean;
};

export const GenderDTO = {
  F: 'F',
  M: 'M',
  V: 'V',
  N: 'N'
};
