import React, { FC  } from 'react';
import { Field, Form, Formik } from 'formik';
import {
  Box,
  Grow,
  Stack,
  ToggleButton,
  ToggleButtonGroup
} from '@mui/material';
import { ModuleSchedule, ThemableBoxType } from 'src/types';
import {
  AsyncAutocompleteV2,
  FormikValuesChangeListener,
  ThemableBox
} from 'src/common';
import { FormProps, FormValues, MyModulesTabId } from './form-types';
import {
  getInitialValues,
  getMyModulesTabIdFromString,
  getOnSubmit,
  getValidate
} from './form-functions';

export const MyModulesForm: FC<FormProps> = (formProps) => {

  return (
    <Formik<FormValues>
      initialValues={getInitialValues(formProps)}
      onSubmit={getOnSubmit(formProps)}
      validate={getValidate(formProps)}
      enableReinitialize={true}
    >
      {(formikHelpers) => {
        const { values, setValues, submitForm } = formikHelpers;

        return (
          <Form>
            <FormikValuesChangeListener<FormValues>>
              {submitForm}
            </FormikValuesChangeListener>

            <ThemableBox type={ThemableBoxType.MY_MODULES}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                spacing={0}
              >
                <Field
                  name="moduleSchedule"
                  size="small"
                  placeholder="My Modules"
                  value={values.moduleSchedule}
                  multiple={false}
                  getOptions={formProps.getMyModuleSchedules}
                  getOptionLabel={(option: ModuleSchedule) =>
                    option.displayName
                  }
                  isOptionHighlighted={(option: ModuleSchedule) =>
                    option.id === values.moduleScheduleLastSelected?.id
                  }
                  isOptionEqualToValue={(
                    option: ModuleSchedule,
                    value: ModuleSchedule
                  ) => option.id === value.id}
                  component={AsyncAutocompleteV2}
                />

                <Grow
                  in={values.isNavigationButtonsVisible}
                  unmountOnExit
                  timeout={{ appear: 100, enter: 100, exit: 100 }}
                >
                  <Box>
                    <ToggleButtonGroup
                      value={values.selectedTab}
                      exclusive
                      onChange={(_, myModulesTabIdString: string) => {
                        setValues({
                          ...values,
                          selectedTab:
                            getMyModulesTabIdFromString(myModulesTabIdString)
                        });
                      }}
                    >
                      <ToggleButton value={MyModulesTabId.PLACEMENT_SCHEDULES}>
                        Academic Bookings
                      </ToggleButton>
                      <ToggleButton value={MyModulesTabId.SCHEDULE_THEME}>
                        Schedule Theme
                      </ToggleButton>
                      <ToggleButton value={MyModulesTabId.CLASS_LIST}>
                        Class List
                      </ToggleButton>
                      <ToggleButton value={MyModulesTabId.ACTIVITIES}>
                        Activities
                      </ToggleButton>
                      <ToggleButton value={MyModulesTabId.SUPERVISORS}>
                        Supervisors
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                </Grow>
              </Stack>
            </ThemableBox>
          </Form>
        );
      }}
    </Formik>
  );
};
