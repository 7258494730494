import { Grid } from '@mui/material';

export type FlexGridLayoutProps = {
  children?: JSX.Element[];
  spacing?: number;
};

export const FlexGridLayout = (props: FlexGridLayoutProps) => {
  return (
    <Grid
      container
      flexDirection="row"
      flexWrap="wrap"
      spacing={props.spacing ?? 0.5}
    >
      {props.children?.map((child) => (
        <Grid item>{child}</Grid>
      ))}
    </Grid>
  );
};
