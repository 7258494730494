import React, { FC } from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';
import { NotificationsConfig } from '../types';
import { NotificationConfigState } from '../enums';

export type ListBodyProps = {
  notificationConfigs: NotificationsConfig[];
  onNotificationClick: (notificationConfig: NotificationsConfig) => void;
  onNotificationButtonClick: (notificationConfig: NotificationsConfig) => void;
};

export const ListBody: FC<ListBodyProps> = ({
  notificationConfigs,
  onNotificationClick,
  onNotificationButtonClick
}) => {
  return (
    <ThemableBox type={ThemableBoxType.NOTIFICATIONS_LIST}>
      <List>
        {notificationConfigs
          .filter(
            (notificationConfig) =>
              notificationConfig.state !== NotificationConfigState.COMPLETED
          )
          .map((notificationConfig) => (
            <ListItem
              key={notificationConfig.notificationId}
              className={getListItemClassName(notificationConfig.state)}
              secondaryAction={
                <Box>
                  <ListItemIcon
                    className={getListItemButtonClassName(
                      notificationConfig.isUpdating
                    )}
                    onClick={(mouseEvent) => {
                      mouseEvent.stopPropagation();
                      onNotificationButtonClick(notificationConfig);
                    }}
                  >
                    {notificationConfig.state ===
                    NotificationConfigState.UNREAD ? (
                      <Tooltip title="Mark as read">
                        <NotificationsIcon />
                      </Tooltip>
                    ) : (
                      <Tooltip title="Mark as completed">
                        <NotificationsOutlinedIcon />
                      </Tooltip>
                    )}
                  </ListItemIcon>
                </Box>
              }
              onClick={(mouseEvent) => {
                mouseEvent.stopPropagation();
                onNotificationClick(notificationConfig);
              }}
            >
              <ListItemText
                primary={notificationConfig.heading}
                secondary={notificationConfig.detail}
              />
            </ListItem>
          ))}
      </List>
    </ThemableBox>
  );
};

function getListItemClassName(
  notificationConfigState: NotificationConfigState
): string {
  switch (notificationConfigState) {
    case NotificationConfigState.COMPLETED:
      return 'completed';
    case NotificationConfigState.READ:
      return 'read';
    case NotificationConfigState.UNREAD:
      return 'unread';
  }
}

function getListItemButtonClassName(isUpdating: boolean): string {
  return isUpdating ? 'updating' : '';
}
