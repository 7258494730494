import { GetAPI, PaginatedRequest, PaginatedResponse } from 'src/types';

export function getPaginatedApiFromGetAll<T, F=undefined>(
  serviceCall: () => Promise<T[]>
): GetAPI<T, F> {
  return (_: PaginatedRequest<F>): Promise<PaginatedResponse<T>> => {
    return serviceCall().then(response => {
      return {
        items: response,
        hasNextPage: false,
        hasPrevPage: false,
        pageNumber: 0,
        totalCount: response.length,
        totalPages: 1
      };
    });
  };
}
