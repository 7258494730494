import { useCallback } from 'react';
import {
  AssignmentMethodType,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  StudentGroup,
  StudentGroupCreate
} from 'src/types';
import { useAxiosInstance } from 'src/lib';
import { StudentGroupCreateDTO, StudentGroupDTO } from 'src/types/dto';

export interface IStudentGroupsService {
  getStudentGroups: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<StudentGroup[]>;

  getStudentGroupsAndSubgroups: (
    moduleScheduleId: number,
    includeSubGroups: boolean,
    abortController?: AbortController
  ) => Promise<StudentGroup[]>;

  getStudentGroup: (
    studentGroupId: number,
    abortController?: AbortController
  ) => Promise<StudentGroup>;

  getStudentGroupsStudentCounts: (
    request: PaginatedRequest
  ) => Promise<PaginatedResponse<StudentGroup>>;

  createStudentGroup: (params: CreateStudentGroupParams) => Promise<void>;

  updateStudentGroup: (params: UpdateStudentGroupParams) => Promise<void>;

  deleteStudentGroup: (
    groupId: number,
    abortController?: AbortController
  ) => Promise<void>;

  addStudentToGroup: (
    studentGroupId: number,
    studentNumber: string,
    abortController?: AbortController
  ) => Promise<void>;

  removeStudentFromGroup: (
    studentNumber: string,
    studentGroupId: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export type CreateStudentGroupParams = StudentGroupCreate & {
  abortController?: AbortController;
};

export type UpdateStudentGroupParams = StudentGroup & {
  abortController?: AbortController;
};

export const useStudentGroupsService = (): IStudentGroupsService => {
  const studentGroupsApi = useAxiosInstance('/v2/classLists');

  const createStudentGroup = useCallback(
    (params: CreateStudentGroupParams): Promise<void> => {
      const { abortController, ...postParams } = params;

      return studentGroupsApi.post('/studentGroups', undefined, {
        params: getStudentGroupCreateDto(postParams),
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [studentGroupsApi]
  );

  const getStudentGroupsAndSubgroups = useCallback(
    (
      moduleScheduleId: number,
      includeSubGroups: boolean,
      abortController?: AbortController
    ): Promise<StudentGroup[]> => {
      return studentGroupsApi
        .get('/studentGroups', {
          params: {
            orderBy: 'surname',
            order: 'desc',
            moduleScheduleId: moduleScheduleId,
            includeSubgroups: includeSubGroups
          },
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => {
          const studentGroupsDto = response.data as StudentGroupDTO[];
          const studentGroups = studentGroupsDto.map(getStudentGroupFromDto);
          return studentGroups;
        });
    },
    [studentGroupsApi]
  );

  const getStudentGroups = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<StudentGroup[]> => {
      return studentGroupsApi
        .get('/studentGroups', {
          params: {
            orderBy: 'surname',
            order: 'desc',
            moduleScheduleId: moduleScheduleId
          },
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => {
          const studentGroupsDto = response.data as StudentGroupDTO[];
          const studentGroups = studentGroupsDto.map(getStudentGroupFromDto);
          return studentGroups;
        });
    },
    [studentGroupsApi]
  );

  const getStudentGroup = useCallback(
    (
      studentGroupId: number,
      abortController?: AbortController
    ): Promise<StudentGroup> => {
      return studentGroupsApi
        .get(`/studentGroups/${studentGroupId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const studentGroupDto = response.data as StudentGroupDTO;
          return getStudentGroupFromDto(studentGroupDto);
        });
    },
    [studentGroupsApi]
  );

  const getStudentGroupsStudentCounts = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<StudentGroup>> => {
      return studentGroupsApi
        .get('/studentGroups/groups', {
          params: {
            ...request,
            moduleScheduleId: request.filters
              ? request.filters['moduleScheduleId']
              : '',
            parentGroupId: request.filters
              ? request.filters['parentGroupId']
              : ''
          },
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<StudentGroup>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<StudentGroup>;
        });
    },
    [studentGroupsApi]
  );

  const updateStudentGroup = useCallback(
    (params: UpdateStudentGroupParams): Promise<void> => {
      const { abortController, ...putParams } = params;

      return studentGroupsApi.put(`/studentGroups/${params.id}`, putParams, {
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [studentGroupsApi]
  );

  const deleteStudentGroup = useCallback(
    (groupId: number, abortController?: AbortController): Promise<void> => {
      return studentGroupsApi.delete(`/studentGroups/${groupId}`, {
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [studentGroupsApi]
  );

  const addStudentToGroup = useCallback(
    (
      studentGroupId: number,
      studentNumber: string,
      abortController?: AbortController
    ): Promise<void> => {
      return studentGroupsApi.post('/studentGroups/addStudent', undefined, {
        params: {
          studentGroupId: studentGroupId,
          studentNumber: studentNumber
        },
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [studentGroupsApi]
  );

  const removeStudentFromGroup = useCallback(
    (
      studentNumber: string,
      studentGroupId: number,
      abortController?: AbortController
    ): Promise<void> => {
      return studentGroupsApi.post('studentGroups/removeStudent', undefined, {
        params: {
          studentNumber,
          studentGroupId
        },
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [studentGroupsApi]
  );

  return {
    getStudentGroups,
    getStudentGroupsAndSubgroups,
    getStudentGroup,
    getStudentGroupsStudentCounts,
    createStudentGroup,
    updateStudentGroup,
    deleteStudentGroup,
    addStudentToGroup,
    removeStudentFromGroup
  };
};

function getStudentGroupFromDto(
  studentGroupDto: StudentGroupDTO
): StudentGroup {
  return {
    ...studentGroupDto
  } as StudentGroup;
}

function getStudentGroupCreateDto(
  studentGroupCreate: StudentGroupCreate
): StudentGroupCreateDTO {
  const {
    groupId,
    groupName,
    moduleScheduleId,
    isPlacementGroup,
    isAutomationLocked,
    assignmentMethod
  } = studentGroupCreate;

  const result = {
    groupId,
    groupName,
    moduleScheduleId,
    placementGroup: isPlacementGroup,
    automationLocked: isAutomationLocked
  } as StudentGroupCreateDTO;

  switch (assignmentMethod.type) {
    case AssignmentMethodType.ASSIGN_NUMBER_OF_STUDENTS:
      return {
        ...result,
        numStudents: assignmentMethod.numberOfStudents
      };

    case AssignmentMethodType.ASSIGN_NUMBER_OF_GROUPS:
      return {
        ...result,
        numGroups: assignmentMethod.numberOfGroups
      };

    case AssignmentMethodType.NO_ASSIGNMENT_SPECIFIED:
      return result;
  }
}
