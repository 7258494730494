import { Box, Table as MuiTable, TableContainer } from '@mui/material';
import { TableHeaderData, TableRowData } from 'src/types';
import { TableType } from '../Table';
import { TableBody, TableHeader } from '../components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export type TableStaticMultiSelectProps = {
  type: TableType.STATIC_MULTI_SELECT;
  headers: { id: string; label: TableHeaderData }[];
  rows: TableRowData[];
  isAllRowsSelected: boolean;
  handleOnRowClicked: (row: TableRowData) => void;
  handleOnSelectAllRowsClicked: () => void;
  checkIsRowSelected: (row: TableRowData) => boolean;
};

const CHECKBOX_COLUMN_NAME = 'isSelected';

export const TableStaticMultiSelect = ({
  headers,
  rows,
  isAllRowsSelected,
  checkIsRowSelected,
  handleOnRowClicked,
  handleOnSelectAllRowsClicked
}: TableStaticMultiSelectProps) => {
  const headersWithCheckbox = (() => {
    return [
      {
        id: CHECKBOX_COLUMN_NAME,
        label:
          rows.length === 0 ? (
            ''
          ) : isAllRowsSelected ? (
            <CheckBoxIcon onClick={handleOnSelectAllRowsClicked} />
          ) : (
            <CheckBoxOutlineBlankIcon onClick={handleOnSelectAllRowsClicked} />
          )
      },
      ...headers
    ];
  })();

  const rowsWithCheckbox = (() => {
    return rows.map((row) => {
      return {
        ...row,
        [CHECKBOX_COLUMN_NAME]: checkIsRowSelected(row) ? (
          <CheckBoxIcon />
        ) : (
          <CheckBoxOutlineBlankIcon />
        )
      };
    });
  })();

  return (
    <Box mt={2}>
      <TableContainer>
        <MuiTable>
          <TableHeader headers={headersWithCheckbox} disableSort={true} />
          <TableBody
            rows={rowsWithCheckbox}
            headers={headersWithCheckbox}
            onRowClicked={handleOnRowClicked}
          />
        </MuiTable>
      </TableContainer>
    </Box>
  );
};
