import { PermissionId, RoomBookingType } from 'src/types';

const {
  ApproveAllAdhocBookings,
  ApproveAllTransportBookings,
  ApproveAllAccommodationBookings
} = PermissionId;

export function getApproverPermission(
  roomBookingType: Exclude<RoomBookingType, RoomBookingType.ACADEMIC>
) {
  switch (roomBookingType) {
    case RoomBookingType.TRANSPORT:
      return ApproveAllTransportBookings;

    case RoomBookingType.ACCOMMODATION:
      return ApproveAllAccommodationBookings;

    case RoomBookingType.ADHOC:
      return ApproveAllAdhocBookings;

    default:
      throw new Error('booking type is not supported');
  }
}
