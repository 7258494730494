import React from 'react';
import { Grid } from '@mui/material';

export type FormButtonLayoutProps = {
  children: React.ReactNode[];
};

export const FormButtonLayout = (props: FormButtonLayoutProps) => {
  return (
    <Grid
      container
      spacing={2}
      display="flex"
      flexDirection="row"
    >
      {props.children.map(mapGridItem)}
    </Grid>
  );
};

function mapGridItem(element: React.ReactNode, index: number): React.ReactNode {
  return <Grid item key={index}>{element}</Grid>;
}
