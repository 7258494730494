import { useCallback } from 'react';
import {
  User,
  PaginatedRequest,
  PaginatedResponse,
  GetAPI,
  PaginatedResponseDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IUsersService {
  getUsers: GetAPI<User>;
  getUser: (userId: number, abortController?: AbortController) => Promise<User>;
  saveUser: (user: User, abortController?: AbortController) => Promise<void>;
  deleteUser: (
    userId: number,
    abortController?: AbortController
  ) => Promise<void>;
  createUser: (user: User, abortController?: AbortController) => Promise<void>;
  getCurrentUser: (abortController?: AbortController) => Promise<User>;
}

export const useUsersService = (): IUsersService => {
  const usersApi = useAxiosInstance('/v2/users');

  const getUsers = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<User>> => {
      return usersApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<User>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<User>;
        });
    },
    [usersApi]
  );

  const getUser = useCallback(
    (userId: number, abortController?: AbortController): Promise<User> => {
      return usersApi
        .get(`${userId}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => response.data as User);
    },
    [usersApi]
  );

  const getCurrentUser = useCallback(
    (abortController?: AbortController): Promise<User> => {
      return usersApi
        .get('current-user', {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => response.data as User);
    },
    [usersApi]
  );

  const saveUser = useCallback(
    (user: User, abortController?: AbortController): Promise<void> => {
      return usersApi.put(
        `${user.id}`,
        {
          ...user
        },
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      );
    },
    [usersApi]
  );

  const deleteUser = useCallback(
    (userId: number, abortController?: AbortController): Promise<void> => {
      return usersApi.delete(`${userId}`, {
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [usersApi]
  );

  const createUser = useCallback(
    (user: User, abortController?: AbortController): Promise<void> => {
      return usersApi.post(
        '',
        {
          ...user
        },
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      );
    },
    [usersApi]
  );

  return {
    getUsers,
    getUser,
    saveUser,
    deleteUser,
    createUser,
    getCurrentUser
  };
};
