import { SortDirection } from '@mui/material';
import { useCallback } from 'react';
import { FiltersAdapter, GetAPI, PaginatedRequest } from 'src/types';

export type UsePaginatedApiWithFixedParamProps<T, F=undefined> = {
  getApi: GetAPI<T, F>;
  filters?: FiltersAdapter<F>;
  order?: SortDirection;
  orderBy?: string;
  search?: string;
};

export function usePaginatedApiWithFixedParams<T, F=undefined>({
  getApi,
  filters,
  order,
  orderBy,
  search
}: UsePaginatedApiWithFixedParamProps<T, F>): GetAPI<T, F> {
  return useCallback<GetAPI<T, F>>(
    (request: PaginatedRequest<F>) =>
      getApi({
        ...request,
        filters: filters ? { ...request.filters, ...filters } : request.filters,
        order: order || request.order,
        orderBy: orderBy || request.orderBy,
        search: search || request.search
      }),

    [getApi, filters, order, orderBy, search]
  );
}
