import { getFirstFromPaginatedApi } from 'src/lib';
import { InitialFilterValues } from '../hooks';
import {
  Activity,
  ActivityType,
  GetAPI,
  ModuleSchedule,
  ProgrammeSchedule,
  ThemeSchedule
} from 'src/types';

type PromiseParams = {
  initialFilterValues: InitialFilterValues | undefined;
  isInitialFilterValuesLoaded: React.MutableRefObject<boolean>;
};

/**
 *
 * @param filters list of filters
 * @param initialValues list of initial values
 * @returns returns a Promise to fetch a ProgrammeSchedule
 */
export function getProgrammeSchedulePromise(
  { initialFilterValues, isInitialFilterValuesLoaded }: PromiseParams,
  getProgramSchedules: GetAPI<ProgrammeSchedule>
): Promise<ProgrammeSchedule | undefined> {
  return getFirstFromPaginatedApi({
    getApi: getProgramSchedules,
    filters:
      initialFilterValues && !isInitialFilterValuesLoaded.current
        ? {
            id: initialFilterValues.programmeScheduleId.toString()
          }
        : {
            year: new Date().getFullYear().toString()
          }
  }).then((programmeSchedule) => {
    // If ProgrammeSchedule for current year has been fetched, return ProgrammeSchedule
    if (programmeSchedule) {
      return Promise.resolve(programmeSchedule);
    }
    // Else attempt to fetch first ProgrammeSchedule available for any year
    else {
      return getFirstFromPaginatedApi({
        getApi: getProgramSchedules
      });
    }
  });
}

export function getModuleSchedulePromise(
  { initialFilterValues, isInitialFilterValuesLoaded }: PromiseParams,
  getMyModuleSchedulesSortedByCalendarYear: GetAPI<ModuleSchedule>,
  programmeSchedule: ProgrammeSchedule
) {
  return getFirstFromPaginatedApi({
    getApi: getMyModuleSchedulesSortedByCalendarYear,
    filters:
      initialFilterValues?.moduleScheduleId !== undefined &&
      !isInitialFilterValuesLoaded.current
        ? { id: initialFilterValues.moduleScheduleId.toString() }
        : {
            year: programmeSchedule.calendarYear.calendarYear,
            programId: programmeSchedule.programmeId.toString()
          }
  });
}

export function getThemeSchedulesPromise(
  { initialFilterValues, isInitialFilterValuesLoaded }: PromiseParams,
  getThemeSchedules: GetAPI<ThemeSchedule>,
  moduleSchedule: ModuleSchedule
) {
  return getFirstFromPaginatedApi({
    getApi: getThemeSchedules,
    filters:
      initialFilterValues?.themeScheduleId !== undefined &&
      !isInitialFilterValuesLoaded.current
        ? {
            id: initialFilterValues.themeScheduleId.toString(),
            active: String(true)
          }
        : {
            moduleScheduleId: moduleSchedule?.id.toString(),
            active: String(true)
          }
  }).then((themeSchedule) => {
    return themeSchedule;
  });
}

export function getActivityTypePromise(
  { initialFilterValues, isInitialFilterValuesLoaded }: PromiseParams,
  getActivityTypes: GetAPI<ActivityType>
) {
  if (
    initialFilterValues?.activityTypeId !== undefined &&
    !isInitialFilterValuesLoaded.current
  ) {
    return getFirstFromPaginatedApi({
      getApi: getActivityTypes,
      filters: {
        id: initialFilterValues.activityTypeId.toString()
      }
    }).then((activityType?: ActivityType) => {
      return activityType;
    });
  } else {
    return Promise.resolve(undefined);
  }
}

export function getActivityPromise(
  promiseParams: PromiseParams,
  getActivities: GetAPI<Activity>
) {

  const {initialFilterValues, isInitialFilterValuesLoaded} = promiseParams;

  return initialFilterValues?.activityId !== undefined &&
    !isInitialFilterValuesLoaded.current
    ? getFirstFromPaginatedApi({
        getApi: getActivities,
        filters: {
          id: initialFilterValues.activityId.toString()
        }
      }).then((activity?: Activity) => {
        return activity;
      })
    : Promise.resolve(undefined);
}
