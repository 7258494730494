import { SelectInputOption } from 'src/common';

type EnumValueType = string | number | symbol;
type EnumType = { [key in EnumValueType]: EnumValueType };

/**
 * @deprecated
 */
export function convertEnumtoSelectInputOptions<T>(
  enumVariable: EnumType & T
): SelectInputOption[] {
  return Object.values(enumVariable).map<SelectInputOption>(obj => {
    return { key: obj.toString(), label: obj.toString() };
  });
}
