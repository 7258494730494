import { Box, Typography } from '@mui/material';
import { ThemableBox } from '../themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';

export type HelixLoaderProps = {
  text?: string;
};
/**
 * This component is used to display the helix loader on the screen when page/table is loading
 * @param text is used to display text below Helix Loader
 * @returns
 */
export const HelixLoadingIndicator = (props: HelixLoaderProps) => {
  return (
    <ThemableBox type={ThemableBoxType.HELIX_LOADER}>
      <Box className="nucleus">
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
        <Box className="nucleobase" />
      </Box>
      <Typography variant="h5" align="center">
        {props.text}
      </Typography>
    </ThemableBox>
  );
};
