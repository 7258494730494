import {
  Box,
  Stack,
  Table as MuiTable,
  TableContainer,
  TextField
} from '@mui/material';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TableHeaderData, TableRowData, ThemableBoxType } from 'src/types';
import { TableType } from '../Table';
import { TableBody, TableHeader } from '../components';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

export type Header = {
  id: string;
  label: TableHeaderData;
  filterEnabled?: boolean;
};

export type TableStaticProps = {
  type: TableType.STATIC;
  headers: Header[];
  rows: TableRowData[];
  filtersEnabled?: boolean;
  rowClass?: (tableRowData: TableRowData) => string;
  onRowClicked?: (row: TableRowData) => void;
};

export const TableStaticContainer = ({
  headers,
  rows,
  filtersEnabled,
  rowClass,
  onRowClicked
}: TableStaticProps) => {
  const [filters, setFilters] = useState(new Map<string, string>());

  const headersWithFilters = useMemo<Header[]>(() => {
    return headers.map((header) => {
      if (filtersEnabled && header.filterEnabled) {
        return {
          ...header,
          label: (
            <Stack direction="column">
              <span>{header.label}</span>
              <ThemableBox type={ThemableBoxType.FILTER_FIELD} >
                <TextField
                  size="small"
                  placeholder="Search"
                  variant="outlined"
                  onChange={(
                    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
                  ) => {
                    setFilters((existingFilters) => {
                      const newFilterValue = event.target.value;
                      const newFilters = new Map(existingFilters);
                      newFilters.set(header.id, newFilterValue);
                      return newFilters;
                    });
                  }}
                  fullWidth
                />
              </ThemableBox>
            </Stack>
          )
        };
      } else {
        return header;
      }
    });
  }, [headers, filtersEnabled]);

  const rowsFiltered = useMemo(
    () =>
      rows.filter((row) =>
        Object.keys(row).every((rowId) => {
          const rowValue = row[`${rowId}`];
          const rowFilter = filters.get(rowId);
          return (
            !rowFilter ||
            rowValue.toString().toLowerCase().includes(rowFilter.toLowerCase())
          );
        })
      ),
    [rows, filters]
  );

  useEffect(() => {
    // when user toggles the filters enabled button, clear existing filters
    if (!filtersEnabled && filters.size > 0) {
      filters.clear();
    }
  }, [filters, filtersEnabled]);

  return (
    <Box mt={2}>
      <TableContainer>
        <MuiTable>
          <TableHeader headers={headersWithFilters} disableSort={true} />
          <TableBody
            rows={rowsFiltered}
            headers={headers}
            rowClass={rowClass}
            onRowClicked={onRowClicked}
          />
        </MuiTable>
      </TableContainer>
    </Box>
  );
};
