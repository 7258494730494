import { SortDirection } from '@mui/material';
import {
  useQueryParams,
  withDefault,
  StringParam,
  NumberParam
} from 'use-query-params';
import { ObjectParam } from 'serialize-query-params/lib/params';
import { isStringTheNumberZero } from 'src/lib';

const OrderParam = {
  encode: (order: SortDirection) => order as string,
  decode: (
    order: string | null | undefined | (string | null)[]
  ): SortDirection =>
    typeof order === 'string' ? (order as SortDirection) : 'asc'
};

interface DefaultQueryParams {
  defaultPageSize: number;
  disableSort?: boolean;
  defaultFilters?: Record<string, string>;
}

export const useTableQueryParams = ({
  defaultPageSize,
  disableSort = false,
  defaultFilters
}: DefaultQueryParams) => {
  const [query, setQuery] = useQueryParams({
    order: OrderParam,
    orderBy: withDefault(StringParam, ''),
    page: withDefault(NumberParam, 0),
    pageSize: withDefault(NumberParam, defaultPageSize),
    search: StringParam,
    filters: withDefault(ObjectParam, defaultFilters ?? {})
  });

  const {
    order,
    orderBy,
    page,
    pageSize,
    search: searchQueryPram,
    filters
  } = query;

  const setOrder = (order: SortDirection) => {
    if (!disableSort) {
      setQuery({ order }, 'replaceIn');
    }
  };

  const setOrderBy = (orderBy: string) => {
    if (!disableSort) {
      setQuery({ orderBy }, 'replaceIn');
    }
  };

  const setPage = (page: number) => setQuery({ page }, 'replaceIn');
  const setPageSize = (pageSize: number) => setQuery({ pageSize }, 'replaceIn');

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setPage(0);
  };

  const search = searchQueryPram ? searchQueryPram : undefined;

  const setSearch = (search: string) => {
    const searchTransformed = search ? search : undefined;
    setQuery({ search: searchTransformed }, 'replaceIn');
  };

  const setFilter = (filterName: string, filterValue?: string) => {
    const newFiltersParamValue = {
      ...filters
    };
    if (filterValue || isStringTheNumberZero(filterValue)) {
      newFiltersParamValue[String(filterName)] = filterValue;
    } else {
      delete newFiltersParamValue[String(filterName)];
    }
    setQuery({ filters: newFiltersParamValue }, 'replaceIn');
  };

  const setFilters = (newfilters: Record<string, string | undefined>) => {
    const newFiltersParamValue = {
      ...filters
    };
    for (const filterName in newfilters) {
      if (newfilters[String(filterName)]) {
        newFiltersParamValue[String(filterName)] =
          newfilters[String(filterName)];
      } else {
        delete newFiltersParamValue[String(filterName)];
      }
    }
    setQuery({ filters: newFiltersParamValue }, 'replaceIn');
  };

  return {
    order,
    setOrder,
    orderBy,
    setOrderBy,
    page,
    handlePageChange,
    pageSize,
    handlePageSizeChange,
    search,
    setSearch,
    filters,
    setFilter,
    setFilters
  };
};
