import { ReactFragment } from 'react';
import { Typography } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from '../themable-box/ThemableBox';

export type ModalProps = {
  children: ReactFragment;
  title?: string;
};

export const Modal = ({ children, title }: ModalProps) => {
  return (
    <ThemableBox type={ThemableBoxType.MODAL}>
      {title && <Typography variant="h2">{title}</Typography>}

      {children}
    </ThemableBox>
  );
};
