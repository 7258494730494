export enum EntityType {
  PROGRAMME,
  MODULE,
  TAG,
  AMENITY
}

/**
 * Gets the plural form of the name of an entity type (as listed in the EntityType enums).
 *
 * @param entityType - An EntityType enum that corresponds to the entity the user has
 * limited permissions to view.
 * @returns The plural form of the name of an entity type.
 */
export function getEntityTypePlural(entityType: EntityType): string {
  switch (entityType) {
    case EntityType.AMENITY:
      return 'Amenities';
    case EntityType.MODULE:
      return 'Modules';
    case EntityType.PROGRAMME:
      return 'Programmes';
    case EntityType.TAG:
      return 'Tags';
    default:
      return 'These types of entities';
  }
}

/**
 * Gets the singular form of the name of an entity type (as listed in the EntityType enums).
 *
 * @param entityType - An EntityType enum that corresponds to the entity the user has
 * limited permissions to view.
 * @returns The singular form of the name of an entity type.
 */
export function getEntityTypeSingular(entityType: EntityType): string {
  switch (entityType) {
    case EntityType.AMENITY:
    case EntityType.MODULE:
    case EntityType.PROGRAMME:
    case EntityType.TAG:
      return EntityType[entityType]
        .charAt(0)
        .toUpperCase()
        .concat(EntityType[entityType].slice(1).toLowerCase());
    default:
      return 'This type of entity';
  }
}

/**
 * Gets a display string to be used as a warning for users with limited view permissions
 *
 * @param entityNamePlural - An EntityType enum that corresponds the user has
 * limited permissions to view (e.g. "Modules", "Tags", etc.)
 * @returns A display string that reads "You do not have permission to view <entityNamePlural>".
 */
export function getWarningTextForNoViewPermissions(
  entityType: EntityType
): string {
  return `You do not have permission to view ${getEntityTypePlural(
    entityType
  ).toLowerCase()}`;
}

/**
 * Gets a display string to be used as a warning for users with limited view permissions
 *
 * @param entityNameSingular - The string name of the entity (singular) that the user has
 * limited permissions to view (e.g. "Module", "Programme", etc.)
 * @returns A display string that reads "You do not have permission to view <entityNamePlural>".
 */
export function getWarningTextForLimitedOptions(
  entityType: EntityType
): string {
  return `You may be shown limited ${getEntityTypeSingular(
    entityType
  ).toLowerCase()} options due to your permission settings`;
}
