import { useCallback } from 'react';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  Student,
  StudentDTO,
  StudentGroup
} from 'src/types';
import { useAxiosInstance } from 'src/lib';
import { getStudentFromDto } from './dto-mappers';

export interface IClassListsService {
  getClassListStudents: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<Student[]>;
  getStudents: GetAPI<Student>;
  removeStudent: (
    moduleScheduleId: number,
    groupId: number,
    studentId: number,
    abortController?: AbortController
  ) => Promise<void>;

  removeAllStudents: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<void>;

  copyStudentsFromProgramme: (
    moduleScheduleId: number,
    programmeId: number,
    year: number,
    academicYear: number,
    abortController?: AbortController
  ) => Promise<void>;

  copyStudentsFromModule: (
    sourceModuleScheduleId: number,
    destinationModuleScheduleId: number,
    importGroups: boolean
  ) => Promise<void>;

  addStudent: (
    moduleScheduleId: number,
    studentNumber: string
  ) => Promise<void>;

  getAttentionStudents: GetAPI<Student>;

  updateStudentsInClassList: (
    year?: number,
    moduleScheduleIds?: number[],
    abortController?: AbortController
  ) => Promise<void>;

  getGroupsByModuleScheduleId: GetAPI<StudentGroup>;

  findAllStudentsInAClassListAndGroup: (
    moduleScheduleId: number,
    groupId: number,
    abortController?: AbortController
  ) => Promise<Student[]>;
}

export const useClassListsService = (): IClassListsService => {
  const classListsApi = useAxiosInstance('/v2/classLists');

  const getClassListStudents = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<Student[]> => {
      return classListsApi
        .get(
          `students?moduleScheduleId=${moduleScheduleId}&pageSize=9999&sort=-surname&pageNumber=0`,
          {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          }
        )
        .then((response) => {
          const students = response.data.content as StudentDTO[];
          return students.map<Student>(getStudentFromDto);
        });
    },
    [classListsApi]
  );

  const findAllStudentsInAClassListAndGroup = useCallback(
    (
      moduleScheduleId: number,
      groupId: number,
      abortController?: AbortController
    ): Promise<Student[]> => {
      return classListsApi
        .get(
          `allStudents?moduleScheduleId=${moduleScheduleId}&groupId=${groupId}`,
          {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          }
        )
        .then((response) => {
          const students = response.data as StudentDTO[];
          return students.map((student) => getStudentFromDto(student));
        });
    },
    [classListsApi]
  );

  const getStudents = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Student>> => {
      return classListsApi
        .get('students', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<StudentDTO>;

          return {
            items: content.map<Student>(getStudentFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Student>;
        });
    },
    [classListsApi]
  );

  const removeStudent = useCallback(
    (
      moduleScheduleId: number,
      groupId: number,
      studentId: number,
      abortController?: AbortController
    ): Promise<void> => {
      return classListsApi.post('removeStudent', undefined, {
        params: {
          moduleScheduleId,
          groupId,
          studentId
        },
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [classListsApi]
  );

  const removeAllStudents = useCallback(
    (
      moduleScheduleId: number,
      abortController?: AbortController
    ): Promise<void> => {
      return classListsApi.post('removeAllStudents', undefined, {
        params: {
          moduleScheduleId
        },
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [classListsApi]
  );

  const copyStudentsFromProgramme = useCallback(
    (
      moduleScheduleId: number,
      programmeId: number,
      year: number,
      academicYear: number,
      abortController?: AbortController
    ): Promise<void> => {
      return classListsApi.post(
        'copyStudentsFromProgramme',
        undefined,

        {
          params: {
            moduleScheduleId,
            programmeId,
            year,
            academicYear
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [classListsApi]
  );

  const copyStudentsFromModule = useCallback(
    (
      sourceModuleScheduleId: number,
      destinationModuleScheduleId: number,
      importGroups: boolean,
      abortController?: AbortController
    ): Promise<void> => {
      return classListsApi.post(
        'copyStudentsFromModule',
        undefined,

        {
          params: {
            sourceModuleScheduleId,
            destinationModuleScheduleId,
            importGroups
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [classListsApi]
  );

  const addStudent = useCallback(
    (
      moduleScheduleId: number,
      studentNumber: string,
      abortController?: AbortController
    ): Promise<void> => {
      return classListsApi.post(
        'addStudent',
        undefined,

        {
          params: {
            moduleScheduleId,
            studentNumber
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [classListsApi]
  );

  const getAttentionStudents = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Student>> => {
      return classListsApi
        .get('attentionStudents', {
          params: request,
          withCredentials: true,
          signal: request?.abortController?.signal
        })
        .then((response) => {
          return {
            items: response.data as Student[],
            hasNextPage: false,
            hasPrevPage: false,
            pageNumber: 1,
            totalCount: response.data.length,
            totalPages: 1
          } as PaginatedResponse<Student>;
        });
    },
    [classListsApi]
  );

  const updateStudentsInClassList = useCallback(
    (
      year?: number,
      moduleScheduleIds?: number[],
      abortController?: AbortController
    ): Promise<void> => {
      return classListsApi.post('updateStudentsInClassList', undefined, {
        params: {
          year,
          moduleScheduleIds
        },
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [classListsApi]
  );

  const getGroupsByModuleScheduleId = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<StudentGroup>> => {
      return classListsApi
        .get('groups', {
          params: request,
          withCredentials: true,
          signal: request?.abortController?.signal
        })
        .then((response) => {
          return {
            items: response.data as StudentGroup[],
            hasNextPage: false,
            hasPrevPage: false,
            pageNumber: 1,
            totalCount: response.data.length,
            totalPages: 1
          } as PaginatedResponse<StudentGroup>;
        });
    },
    [classListsApi]
  );

  return {
    getClassListStudents,
    removeStudent,
    removeAllStudents,
    copyStudentsFromProgramme,
    copyStudentsFromModule,
    addStudent,
    getAttentionStudents,
    updateStudentsInClassList,
    getStudents,
    getGroupsByModuleScheduleId,
    findAllStudentsInAClassListAndGroup
  };
};
