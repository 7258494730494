import { PropsWithChildren } from 'react';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from '../themable-box/ThemableBox';

export type BorderBoxProps = {
  variant: 'success' | 'failure' | 'generic';
};

export const BorderBox = ({
  variant,
  children
}: PropsWithChildren<BorderBoxProps>) => {
  return (
    <ThemableBox
      key={variant}
      className={variant}
      type={ThemableBoxType.BORDER_BOX}
    >
      {children}
    </ThemableBox>
  );
};
