export const isEmailFormatValid = (email: string): boolean =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

export const isPhoneNumberValid = (phoneNumber: string): boolean => {
  // eslint-disable-next-line security/detect-unsafe-regex
  return /^[0-9]*(\/[0-9]*)*$/i.test(phoneNumber);
};

export const isLongitudeValid = (longitude: string): boolean => {
  // eslint-disable-next-line security/detect-unsafe-regex
  return /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/i.test(
    longitude
  );
};

export const isLatitudeValid = (longitude: string): boolean => {
  // eslint-disable-next-line security/detect-unsafe-regex
  return /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/i.test(
    longitude
  );
};

export const isTextValidPositiveInteger = (text: string): boolean => {
  return text !== '' && /^[0-9\b]+$/.test(text);
};

export const isTextContainSearch = (text: string, search: string): boolean => {
  return (
    search.trim() === '' || text.toLowerCase().includes(search.toLowerCase())
  );
};

export function isValidateIntegerAllowZero(number: number) {
  return number || number === 0;
}

export function convertHoursToMilliSeconds(hours: number) {
  return hours * 3600000;
}

export function convertMinutesToMilliSeconds(minutes: number) {
  return minutes * 60000;
}

export const INLINE_ERRORS = {
  REQUIRED: 'Required'
};

export function isTextAlphaNumeric(text: string): boolean {
  return text !== '' && /^[a-zA-Z0-9]+$/.test(text);
}

export function isNotEmptyString(text: string): boolean {
  return text.trim().length !== 0;
}

export function isEndDateAfterOrOnStartDate(startDate: Date, endDate: Date): boolean {
  return endDate >= startDate;
}