import { DropEvent, FileRejection, useDropzone } from 'react-dropzone';
import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';

export type FileInputProps = {
  onFileUpload: (fileData: Blob, fileName: string) => Promise<void>;
};

export const FileInput = ({ onFileUpload }: FileInputProps) => {
  const onDrop: (
    acceptedFiles: File[],
    fileRejections: FileRejection[],
    event: DropEvent
  ) => Promise<void> = useCallback(
    acceptedFiles => {
      const promises = acceptedFiles.map(file =>
        onFileUpload(file as Blob, file.name)
      );

      return Promise.all(promises).then(() => Promise.resolve());
    },
    [onFileUpload]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const dropAreaStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 300,
    width: '100%',
    bgcolor: 'secondary.light',
    cursor: 'pointer'
  };

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <Box sx={dropAreaStyle}>
        {isDragActive ? (
          <Typography>Drop the files here ...</Typography>
        ) : (
          <Typography>
            Drop some files to upload, or click to select one.
          </Typography>
        )}
      </Box>
    </div>
  );
};
