import { useCallback } from 'react';
import { TAG_CATEGORIES } from 'src/lib/constants';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  Tag,
  TagCategory,
  TagDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface ITagsService {
  getTags: GetAPI<Tag>;
  getTag: (tagId: number, abortController?: AbortController) => Promise<Tag>;
  deleteTag: (
    tagId: number,
    abortController?: AbortController
  ) => Promise<void>;
  updateTag: (tag: Tag, abortController?: AbortController) => Promise<void>;
  createTag: (tag: Tag, abortController?: AbortController) => Promise<void>;
  getTagCategories: () => Promise<TagCategory[]>;
}
export const useTagsService = (): ITagsService => {
  const tagsApi = useAxiosInstance('/v2/tags');

  const getTags = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Tag>> => {
      return tagsApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<TagDTO>;

          return {
            items: content.map((tagDto) => getTagFromDto(tagDto)),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Tag>;
        });
    },
    [tagsApi]
  );

  const getTag = useCallback(
    (tagId: number, abortController?: AbortController) => {
      return tagsApi
        .get(`${tagId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const tagDto = response.data as TagDTO;
          return getTagFromDto(tagDto);
        });
    },
    [tagsApi]
  );

  const deleteTag = useCallback(
    (tagId: number, abortController?: AbortController): Promise<void> =>
      tagsApi.delete(`${tagId}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [tagsApi]
  );

  const updateTag = (
    tag: Tag,
    abortController?: AbortController
  ): Promise<void> =>
    tagsApi.put(`${tag.id}`, getTagDto(tag), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const createTag = (
    tag: Tag,
    abortController?: AbortController
  ): Promise<void> =>
    tagsApi.post('', getTagDto(tag), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const getTagCategories = useCallback((): Promise<TagCategory[]> => {
    // TODO: move to backend
    return Promise.resolve(TAG_CATEGORIES);
  }, []);

  return { getTags, getTagCategories, getTag, updateTag, createTag, deleteTag };
};

function getTagCategory(tagCategoryCode: string): TagCategory {
  // TODO: move to backend (add display name to tags returned from get Tags endpoint)
  const tagCategory = TAG_CATEGORIES.find(
    (tagCategory) => tagCategory.code === tagCategoryCode
  );

  if (!tagCategory) {
    throw new Error('tag category not found');
  }

  return tagCategory;
}

function getTagFromDto(tagDto: TagDTO): Tag {
  return {
    ...tagDto,
    categoryDisplayName: getTagCategory(tagDto.category).displayName
  };
}

function getTagDto(tag: Tag): TagDTO {
  const { categoryDisplayName, ...rest } = tag;

  return {
    ...rest
  };
}
