import React, { useState } from 'react';
import { TextFieldV2 } from 'src/common/text-field-v2/TextFieldV2';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import { isTextValidPositiveInteger } from 'src/lib';
import { ThemableBoxType } from 'src/types';

interface TableCountProps {
  value: string;
  onChange: (onChangeValue: number) => void;
  disabled: boolean;
}

export const TableCount = ({ value, disabled, onChange }: TableCountProps) => {
  const [count, setCount] = useState<string>(value);

  return (
    <ThemableBox type={ThemableBoxType.TABLE_COUNT}>
      <TextFieldV2
        value={count}
        variant="outlined"
        InputProps={{
          type: 'number'
        }}
        disabled={disabled}
        onClick={(event) => event.stopPropagation()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          if (!newValue || isTextValidPositiveInteger(newValue)) {
            onChange(parseInt(event.target.value));
            setCount(event.target.value);
          }
        }}
      />
    </ThemableBox>
  );
};
