import React, { useCallback, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from 'src/common';
import { NotificationState } from 'src/types';
import { useNotificationsService } from 'src/services';
import {
  NotificationsListActionType,
  NotificationsListState,
  getReducer
} from './get-reducer';
import { NotificationsConfig } from '../types';
import { NotificationConfigState } from '../enums';
import { NOTIFICATIONS_FETCH_PAGE_SIZE, NOTIFICATIONS_MIN } from '../lib';

export interface IComponentState {
  state: NotificationsListState;
  fetchNotifications: (abortController?: AbortController) => void;
  handleListExpandButtonClicked: (
    mouseEvent: React.MouseEvent<HTMLElement>
  ) => void;

  handleNotificationClicked: (notificationConfig: NotificationsConfig) => void;
  handleNotificationButtonClicked: (mouseEvent: NotificationsConfig) => void;

  handleListClosed: () => void;
  isListExpanded: boolean;

  handleListHeaderMenuOpenButtonClick: (
    mouseEvent: React.MouseEvent<HTMLElement>
  ) => void;
  handleListHeaderMenuClosed: () => void;
  isListHeaderMenuOpen: boolean;
  handleMarkAllAsReadMenuClicked: () => void;
  handleMarkAllAsCompletedMenuClicked: () => void;

  anchorListElement: HTMLElement | null;
  anchorListHeaderMenuElement: HTMLElement | null;
}

export type UseComponentStateProps = {
  anchorListElement: HTMLElement | null;
  anchorListHeaderMenuElement: HTMLElement | null;
  setAnchorListElement: (element: HTMLElement | null) => void;
  setAnchorListHeaderMenuElement: (element: HTMLElement | null) => void;
};

export function useComponentState({
  anchorListElement,
  anchorListHeaderMenuElement,
  setAnchorListElement,
  setAnchorListHeaderMenuElement
}: UseComponentStateProps): IComponentState {
  const { handleError } = useAppContext();
  const navigate = useNavigate();
  const [isListHeaderMenuOpen, setIsListHeaderMenuOpen] = useState(false);
  const isListExpanded = Boolean(anchorListElement);

  const handleListHeaderMenuOpenButtonClick = (
    mouseEvent: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorListHeaderMenuElement(mouseEvent.currentTarget);
    setIsListHeaderMenuOpen(true);
  };

  const handleListHeaderMenuClosed = useCallback(() => {
    setAnchorListHeaderMenuElement(null);
    setIsListHeaderMenuOpen(false);
  }, [setAnchorListHeaderMenuElement]);

  const handleListExpandButtonClicked = (
    mouseEvent: React.MouseEvent<HTMLElement>
  ) => {
    setAnchorListElement(mouseEvent.currentTarget);
    handleListHeaderMenuClosed();
  };

  const handleListClosed = () => {
    setAnchorListElement(null);
  };

  const {
    getNotifications,
    markNotificationAsRead,
    markNotificationAsComplete,
    markAllNotificationsAsRead,
    markAllNotificationsAsComplete
  } = useNotificationsService();

  const [state, dispatch] = useReducer(getReducer(), {
    notifications: undefined
  });

  const fetchNotifications = useCallback(
    (abortController?: AbortController) => {
      const getNotificationsCountsPromise = getNotifications({
        pageNumber: 1,
        pageSize: 1,
        order: 'desc',
        orderBy: 'createdAt',
        filters: {
          completed: 'false',
          viewed: 'false'
        },
        abortController: abortController
      }).then((result) => {
        dispatch({
          type: NotificationsListActionType.SET_COUNT_UNREAD,
          payload: {
            count: result.totalCount
          }
        });
      });

      const getNotificationsPromise = getNotifications({
        pageNumber: 1,
        pageSize: NOTIFICATIONS_FETCH_PAGE_SIZE,
        order: 'desc',
        orderBy: 'createdAt',
        filters: {
          completed: 'false'
        },
        abortController: abortController
      }).then((result) => {
        const notificationConfigs = result.items.map<NotificationsConfig>(
          (notification) => {
            function getNotificationConfigState(
              notificationState: NotificationState
            ) {
              switch (notificationState) {
                case NotificationState.UNREAD:
                  return NotificationConfigState.UNREAD;
                case NotificationState.READ:
                  return NotificationConfigState.READ;
                case NotificationState.COMPLETED:
                  return NotificationConfigState.COMPLETED;
              }
            }

            return {
              notificationId: notification.id,
              heading: notification.title,
              detail: notification.message,
              state: getNotificationConfigState(notification.state),
              isUpdating: false,
              link: notification.resourceLink
            };
          }
        );

        dispatch({
          type: NotificationsListActionType.MULTIPLE_ACTIONS,
          payload: [
            {
              type: NotificationsListActionType.SET_COUNT_ALL,
              payload: {
                count: result.totalCount
              }
            },
            {
              type: NotificationsListActionType.INITIALISE_NOTIFICATIONS,
              payload: { notifications: notificationConfigs }
            },
            {
              type: NotificationsListActionType.SET_IS_FETCHING_NOTIFICATIONS,
              payload: { isFetching: false }
            }
          ]
        });
      });

      dispatch({
        type: NotificationsListActionType.SET_IS_FETCHING_NOTIFICATIONS,
        payload: { isFetching: true }
      });

      return Promise.any([
        getNotificationsCountsPromise,
        getNotificationsPromise
      ]).catch(handleError);
    },
    [getNotifications, handleError]
  );

  const handleNotificationClicked = useCallback(
    (notificationConfig: NotificationsConfig) => {
      if (
        notificationConfig.state === NotificationConfigState.UNREAD &&
        !notificationConfig.isUpdating
      ) {
        dispatch({
          type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING,
          payload: {
            notificationId: notificationConfig.notificationId,
            isUpdating: true
          }
        });

        markNotificationAsRead(notificationConfig.notificationId)
          .then(() => {
            dispatch({
              type: NotificationsListActionType.MULTIPLE_ACTIONS,
              payload: [
                {
                  type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING,
                  payload: {
                    notificationId: notificationConfig.notificationId,
                    isUpdating: false
                  }
                },
                {
                  type: NotificationsListActionType.MARK_NOTIFICATION_AS_READ,
                  payload: {
                    notificationId: notificationConfig.notificationId
                  }
                }
              ]
            });
          })
          .catch(handleError);
      }

      if (notificationConfig.link) {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        navigate(notificationConfig.link);
      }
    },
    [handleError, markNotificationAsRead, navigate]
  );

  const handleNotificationButtonClicked = useCallback(
    (notificationConfig: NotificationsConfig) => {
      if (notificationConfig.isUpdating) {
        return;
      }

      switch (notificationConfig.state) {
        case NotificationConfigState.UNREAD:
          dispatch({
            type: NotificationsListActionType.MULTIPLE_ACTIONS,
            payload: [
              {
                type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING,
                payload: {
                  notificationId: notificationConfig.notificationId,
                  isUpdating: true
                }
              }
            ]
          });

          markNotificationAsRead(notificationConfig.notificationId)
            .then(() => {
              dispatch({
                type: NotificationsListActionType.MULTIPLE_ACTIONS,
                payload: [
                  {
                    type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING,
                    payload: {
                      notificationId: notificationConfig.notificationId,
                      isUpdating: false
                    }
                  },
                  {
                    type: NotificationsListActionType.MARK_NOTIFICATION_AS_READ,
                    payload: {
                      notificationId: notificationConfig.notificationId
                    }
                  }
                ]
              });
            })
            .catch(handleError);
          break;

        case NotificationConfigState.READ: {
          dispatch({
            type: NotificationsListActionType.MULTIPLE_ACTIONS,
            payload: [
              {
                type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING,
                payload: {
                  notificationId: notificationConfig.notificationId,
                  isUpdating: true
                }
              }
            ]
          });

          const isShouldFetchMoreNotifications =
            (state.notifications || []).filter(
              (notificationConfig) =>
                notificationConfig.state !== NotificationConfigState.COMPLETED
            ).length < NOTIFICATIONS_MIN;

          markNotificationAsComplete(notificationConfig.notificationId)
            .then(() => {
              if (isShouldFetchMoreNotifications) {
                return fetchNotifications();
              }

              dispatch({
                type: NotificationsListActionType.MULTIPLE_ACTIONS,
                payload: [
                  {
                    type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING,
                    payload: {
                      notificationId: notificationConfig.notificationId,
                      isUpdating: false
                    }
                  },
                  {
                    type: NotificationsListActionType.MARK_NOTIFICATION_AS_COMPLETED,
                    payload: {
                      notificationId: notificationConfig.notificationId
                    }
                  }
                ]
              });
            })
            .catch(handleError);
          break;
        }
      }
    },
    [
      handleError,
      markNotificationAsComplete,
      markNotificationAsRead,
      state.notifications,
      fetchNotifications
    ]
  );

  const handleMarkAllAsReadMenuClicked = useCallback(() => {
    if (!state.notifications) {
      throw new Error('no notifications to mark as read');
    }

    const notificationIds = state.notifications.map(
      (notificationConfig) => notificationConfig.notificationId
    );

    dispatch({
      type: NotificationsListActionType.MULTIPLE_ACTIONS,
      payload: [
        {
          type: NotificationsListActionType.SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING,
          payload: {
            notificationIds: notificationIds,
            isUpdating: true
          }
        }
      ]
    });

    markAllNotificationsAsRead(notificationIds)
      .then(() => {
        dispatch({
          type: NotificationsListActionType.MULTIPLE_ACTIONS,
          payload: [
            {
              type: NotificationsListActionType.SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING,
              payload: {
                notificationIds: notificationIds,
                isUpdating: false
              }
            },
            {
              type: NotificationsListActionType.MARK_ALL_NOTIFICATIONS_AS_READ
            }
          ]
        });
      })
      .catch(handleError);

    handleListHeaderMenuClosed();
  }, [
    handleError,
    markAllNotificationsAsRead,
    state.notifications,
    handleListHeaderMenuClosed
  ]);

  const handleMarkAllAsCompletedMenuClicked = useCallback(() => {
    if (!state.notifications) {
      throw new Error('no notifications to mark as completed');
    }

    const notificationIds = state.notifications.map(
      (notificationConfig) => notificationConfig.notificationId
    );

    dispatch({
      type: NotificationsListActionType.MULTIPLE_ACTIONS,
      payload: [
        {
          type: NotificationsListActionType.SET_IS_FETCHING_NOTIFICATIONS,
          payload: { isFetching: true }
        },
        {
          type: NotificationsListActionType.SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING,
          payload: {
            notificationIds: notificationIds,
            isUpdating: true
          }
        }
      ]
    });

    markAllNotificationsAsComplete(notificationIds)
      .then(() => {
        dispatch({
          type: NotificationsListActionType.MULTIPLE_ACTIONS,
          payload: [
            {
              type: NotificationsListActionType.SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING,
              payload: {
                notificationIds: notificationIds,
                isUpdating: false
              }
            },
            {
              type: NotificationsListActionType.MARK_ALL_NOTIFICATIONS_AS_COMPLETED
            }
          ]
        });
      })
      .then(() => {
        fetchNotifications().catch(handleError);
      })
      .catch(handleError);

    handleListHeaderMenuClosed();
  }, [
    handleError,
    markAllNotificationsAsComplete,
    state.notifications,
    fetchNotifications,
    handleListHeaderMenuClosed
  ]);

  return {
    state,
    fetchNotifications: fetchNotifications,
    handleListExpandButtonClicked,
    isListExpanded,
    anchorListElement,
    anchorListHeaderMenuElement,
    handleListClosed,
    handleListHeaderMenuOpenButtonClick,
    handleNotificationClicked: handleNotificationClicked,
    handleNotificationButtonClicked: handleNotificationButtonClicked,
    isListHeaderMenuOpen,
    handleListHeaderMenuClosed,
    handleMarkAllAsReadMenuClicked,
    handleMarkAllAsCompletedMenuClicked
  };
}
