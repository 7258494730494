import { getProgrammeScheduleFromDto } from 'src/services/dto-mappers';
import {
  GetAPI,
  getPaginatedResponse,
  PaginatedRequest,
  PaginatedResponse
} from '../types';
import { Programme } from 'src/types';
import { useCallback } from 'react';
import { ProgrammeDTO } from 'src/types/dto';
import { useAxiosInstance } from 'src/lib';

export interface IProgrammesService {
  getProgrammes: GetAPI<Programme>;
  getAllProgrammes: GetAPI<Programme>;
  getUnscheduledProgrammes: GetAPI<Programme>;
  getProgramme: (
    programeeId: number,
    abortController?: AbortController
  ) => Promise<Programme>;
  deleteProgramme: (
    programeeId: number,
    abortController?: AbortController
  ) => Promise<void>;
  updateProgramme: (
    programmeId: number,
    programme: Programme,
    abortController?: AbortController
  ) => Promise<void>;
  verifyProgrammes: (
    year: number,
    ids?: number[],
    abortController?: AbortController
  ) => Promise<void>;
  createProgramme: (
    programme: Programme,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useProgrammesService = (): IProgrammesService => {
  const programmesApi = useAxiosInstance('/v2/programmes');

  const getProgrammes = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Programme>> => {
      const controller = request.abortController || new AbortController();

      return programmesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: controller.signal
        })
        .then((response) => {
          const { page, _embedded } = response.data;

          const itemsDto = (_embedded?.simpleProgrammeDToes ||
            []) as ProgrammeDTO[];

          return {
            items: itemsDto.map(getProgrammeFromDto),
            hasNextPage: page.number < page.totalElements,
            hasPrevPage: page.number > 0,
            pageNumber: page.number,
            totalCount: page.totalElements,
            totalPages: page.totalPages
          } as PaginatedResponse<Programme>;
        });
    },
    [programmesApi]
  );

  const getAllProgrammes = (
    request: PaginatedRequest
  ): Promise<PaginatedResponse<Programme>> =>
    programmesApi
      .get('all', {
        params: request,
        withCredentials: true,
        signal: request?.abortController?.signal
      })
      .then((response) =>
        getPaginatedResponse<ProgrammeDTO, Programme>(
          response,
          getProgrammeFromDto
        )
      );

  const getProgramme = useCallback(
    (
      programmeId: number,
      abortController?: AbortController
    ): Promise<Programme> => {
      return programmesApi
        .get(`/${programmeId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => response.data as Programme);
    },
    [programmesApi]
  );

  const deleteProgramme = useCallback(
    (programmeId: number, abortController?: AbortController): Promise<void> => {
      return programmesApi.delete(`${programmeId}`, {
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined
      });
    },
    [programmesApi]
  );

  const updateProgramme = useCallback(
    (
      programmeId: number,
      programme: Programme,
      abortController?: AbortController
    ): Promise<void> => {
      return programmesApi.put(
        `${programmeId}`,
        getDTOFromProgramme(programme),
        {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        }
      );
    },
    [programmesApi]
  );

  const verifyProgrammes = (
    year: number,
    ids?: number[],
    abortController?: AbortController
  ): Promise<void> =>
    programmesApi.post(
      `verify?programmeId=${ids?.toString() as string}&year=${year}`,
      {},
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );

  const getUnscheduledProgrammes = (
    request: PaginatedRequest
  ): Promise<PaginatedResponse<Programme>> =>
    programmesApi
      .get('unscheduled', {
        params: request,
        withCredentials: true,
        signal: request?.abortController?.signal
      })
      .then((response) =>
        getPaginatedResponse<ProgrammeDTO, Programme>(
          response,
          getProgrammeFromDto
        )
      );

  const createProgramme = (
    programme: Programme,
    abortController?: AbortController
  ): Promise<void> =>
    programmesApi.post('', getDTOFromProgramme(programme), {
      withCredentials: true,
      signal: abortController?.signal
    });

  return {
    getProgrammes,
    getAllProgrammes,
    getProgramme,
    deleteProgramme,
    updateProgramme,
    verifyProgrammes,
    createProgramme,
    getUnscheduledProgrammes
  };
};

function getProgrammeFromDto(dto: ProgrammeDTO): Programme {
  return {
    ...dto,
    programmeSchedules: dto.programmeSchedules?.map(getProgrammeScheduleFromDto)
  } as Programme;
}

function getDTOFromProgramme(programme: Programme): ProgrammeDTO {
  return {
    id: programme.id,
    isActive: programme.isActive,
    dateVerified: programme.dateVerified,
    displayName: programme.displayName,
    tagIds: programme?.tags?.map<number>((tag) => tag.id),
    nameEnglish: programme.nameEnglish,
    programmeCode: programme.programmeCode,
    programmeIdentifier: programme.programmeIdentifier,
    programmePackageId: programme.programmePackageId
  } as ProgrammeDTO;
}
