import { Staff } from '../models';

export type PlacementScheduleDTO = {
  id: number;
  trainingSiteIds?: number[];
  trainingSiteId?: number;
  trainingSiteName: string;
  trainingSiteBookable: boolean;
  facilityId: number;
  facilityName: string;
  facilityLongitude: string;
  facilityLatitude: string;
  themeScheduleId: number;
  themeName: string;
  supervisorIds?: number[];
  supervisorNames?: string[];
  studentIds?: number[];
  studentNames?: string[];
  studentGroupIds?: number[];
  studentGroupId?: number;
  studentGroupName?: string;
  moduleScheduleId: number;
  moduleScheduleName: string;
  moduleName: string;
  moduleYear: number;
  moduleCode: number;
  notes?: string;
  activityTypeId?: number;
  activityType: string;
  activityId: number;
  activityDesc: string;
  capacity: number;
  overCapacity: number;
  roomBookingId?: number;
  academicBookingStatus?: string;
  startDateTime: string;
  endDateTime: string;
  /*  createdByStaff and lastUpdatedByStaff marked as nullable to cater
   *  for legacy PlacementSchedule entries that have NULL value for
   *  CREATED_BY_USER_ID and LAST_UPDATED_BY_USER_ID
   */
  createdByStaff?: Staff;
  lastUpdatedByStaff?: Staff;
  approvedByStaff?: Staff;
  createdByUserId: number;
  lastUpdatedByUserId: number;
  createdDateTime: string;
  lastUpdatedDateTime: string;
  recurrence: RecurrenceDTO;
  isCancelled: boolean;
  cancellationReason?: string;
  transportStudentsIds: number[];
  accommodationStudentsIds: number[];
  bookableStudentsIds: number[];
  excludedGroupStudentsIds: number[];
  conflictOverriddenStudentsIds: number[];
  isDeleted: boolean;
  deletionReason?: string;
  facilityAndTrainingSiteNames: string;
  equipmentIds: number[];
};

export type RecurrenceDTO = {
  id: number;
  frequencyType: string;
  activeDate: string;
  expiryDate: string;
  arrivalDate?: string;
  departureDate?: string;
  enabled: boolean;
  weekdaysBitField: number;
  future: boolean;
  series: boolean;
  interval: number;
  maxOccurrences: number;
};

export const AcademicBookingDTO = {
  APPROVED: 'Approved',
  AWAITING_APPROVAL: 'Awaiting Approval',
  REJECTED: 'Rejected',
  CANCELLED: 'Cancelled'
};

export type PlacementScheduleCreateResponseDTO = {
  id: number;
};
