import { useMemo } from 'react';
import { Stack } from '@mui/material';
import { Pill, PillStyle, Table, TableType } from 'src/common';
import { DATE_TIME_FORMAT, getDisplayDateStringFromDate } from 'src/lib';
import { TableRowData, RoomBooking } from 'src/types';
import {
  getConflictsPillLabel,
  getStatusColumnText,
  getPillStyleFromBookingStatus
} from '../lib';

export type BookingsApproverTableProps = {
  bookings: RoomBooking[];
  onViewBooking: (roomBookingId: number) => void;
  onViewBookingConflicts: (roomBooking: RoomBooking) => void;
};

const headers = [
  { id: 'status', label: 'Status' },
  { id: 'title', label: 'Title' },
  { id: 'capacity', label: 'Capacity' },
  { id: 'facility', label: 'Facility' },
  { id: 'trainingSite', label: 'Training Site' },
  { id: 'startDateTime', label: 'Start Date and Time' },
  { id: 'endDateTime', label: 'End Date and Time' },
  { id: 'lastUpdated', label: 'Last Updated' },
  { id: 'bookedBy', label: 'Booked by' },
  { id: 'controls', label: '' }
];

export function BookingsApproverTable({
  bookings,
  onViewBooking,
  onViewBookingConflicts
}: BookingsApproverTableProps) {
  const tableRows = useMemo<TableRowData[]>(() => {
    return bookings.map<TableRowData>((booking) => {
      const conflictsCount = booking.conflictsCount || 0;
      return {
        title: booking.title ?? '',
        capacity: booking.capacity,
        facility: booking.facilityName,
        trainingSite: booking.trainingSiteName,
        startDateTime: getDisplayDateStringFromDate(
          booking.startDateTime,
          DATE_TIME_FORMAT.FORMAL_DATE_TIME_FORMAT
        ),
        endDateTime: getDisplayDateStringFromDate(
          booking.endDateTime,
          DATE_TIME_FORMAT.FORMAL_DATE_TIME_FORMAT
        ),
        lastUpdated: booking.lastUpdatedUser?.displayName ?? '',
        status: (
          <Pill
            label={getStatusColumnText(booking.status)}
            pillStyle={getPillStyleFromBookingStatus(booking.status)}
          />
        ),
        bookedBy: booking.bookedByUser?.displayName ?? '',
        controls: (
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            {conflictsCount > 0 && (
              <Pill
                label={getConflictsPillLabel(conflictsCount)}
                onClick={() => onViewBookingConflicts(booking)}
                pillStyle={PillStyle.RED}
              />
            )}
          </Stack>
        ),
        bookingId: booking.id
      };
    });
  }, [bookings, onViewBookingConflicts]);

  return (
    <Table
      type={TableType.STATIC}
      headers={headers}
      rows={tableRows}
      onRowClicked={(row) => {
        const { bookingId } = row;

        if (typeof bookingId !== 'number') {
          throw new Error('booking id expected to have number type');
        }

        onViewBooking(bookingId);
      }}
    />
  );
}
