import { ErrorInfo, useCallback } from 'react';
import axios from 'axios';

export interface IErrorLoggingService {
  logError: (error: Error, errorInfo?: ErrorInfo) => void;
}

export const useErrorLoggingService = (): IErrorLoggingService => {
  const logError = useCallback((error: Error, errorInfo?: ErrorInfo): void => { /* eslint-disable-next-line no-console */
    console.error(
      axios.isAxiosError(error)
        ? error?.response?.data.message || error.message
        : error.message,
      errorInfo ?? ''
    );
  }, []);

  return {
    logError
  };
};
