import { SetStateAction } from 'react';

export type IButtonDebounce = {
  buttonDisabledDebounce: (
    setDisabled: React.Dispatch<SetStateAction<boolean>>,
    delay?: number
  ) => void;
};
export const useButtonDisabledDebounce = (): IButtonDebounce => {
  /**
   * Sets the boolean to true, then sets a Timeout callback that, after a delay, return a true value
   *
   * @param setDisabled The SetStateAction to set a boolean state that represents the disabled state of an element
   * @param delay The time delay before the callback with true is returned. Default is 2000ms
   */
  const buttonDisabledDebounce = (
    setDisabled: React.Dispatch<SetStateAction<boolean>>,
    delay = 2000
  ) => {
    setDisabled(true);
    setTimeout(() => setDisabled(false), delay);
  };
  return {
    buttonDisabledDebounce
  };
};
