import * as React from 'react';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { useFormikContext } from 'formik';
import { debounce } from '@mui/material/utils';
import { Autocomplete, Box, CircularProgress } from '@mui/material';
import {
  getAllFromPaginatedApiV2,
  getPaginatedApiFromGetAll,
  isRecordsEqual
} from 'src/lib';
import { TextFieldV2 } from 'src/common';
import { GetAPI, PaginatedRequest, PaginatedResponse } from 'src/types';
import {
  AutoCompleteActionType,
  getAutocompleteReducer
} from './autocomplete-reducer';
import {
  AutocompleteChangeDetails,
  AutocompleteChangeReason
} from '@mui/material/Autocomplete';
import { useAppContext } from '../app-context-provider/AppContext';

export type AsyncAutocompletePropsV2<T> = {
  label: string;
  placeholder?: string;
  getOptions: GetAPI<T>;
  getOptionsFilters?: Record<string, string>;
  getOptionLabel: (value: T) => string;
  isOptionEqualToValue: (option: T, value: T) => boolean;
  isOptionHighlighted?: (option: T) => boolean;
  error?: boolean;
  required?: boolean;
  pageSize?: number;
  size?: 'small' | 'medium';
  userInputDebounceDelayMilliseconds?: number;
  helperText?: React.ReactNode;
  multiple?: boolean;
  minSearchTextLength?: number;
  onSelectedOptionChanged?: (
    value: T | T[] | null,
    reason?: AutocompleteChangeReason,
    detail?: AutocompleteChangeDetails<T> | undefined
  ) => void; // eslint-disable-next-line
  [x: string]: any;
};

const DEFAULT_PAGE_SIZE = 15;
const DEFAULT_USER_INPUT_DEBOUNCE_DELAY = 1000;
const DEFAULT_MIN_SEARCH_TEXT_LENGTH = 3;

function isSearchTextRequiredLength(
  searchText: string,
  minLength = DEFAULT_MIN_SEARCH_TEXT_LENGTH
) {
  return searchText.length === 0 || searchText.length >= minLength;
}

export function AsyncAutocompleteV2<T>(
  props: AsyncAutocompletePropsV2<T>
): JSX.Element {
  const { handleError } = useAppContext();
  const observer = useRef<IntersectionObserver | null>(null);
  const formikContext = useFormikContext();

  const {
    isOptionHighlighted,
    label,
    getOptions,
    getOptionsFilters,
    isOptionEqualToValue,
    getOptionLabel,
    multiple,
    name,
    field,
    error,
    helperText,
    required,
    onSelectedOptionChanged,
    value,
    pageSize,
    userInputDebounceDelayMilliseconds,
    minSearchTextLength,
    ...rest
  } = props;

  const minSearchTextLengthMemo = useMemo(
    () => minSearchTextLength,
    [minSearchTextLength]
  );

  const reducer = useMemo(() => getAutocompleteReducer<T>(), []);

  const [
    {
      options,
      filters,
      isFetchingOptions,
      isListOpen,
      searchText,
      lastOptionPageFetched,
      nextOptionPageCanFetch
    },
    dispatch
  ] = useReducer(reducer, {
    isListOpen: false,
    isMultiSelect: multiple || false,
    isFetchingOptions: false,
    isNoMatchingOptions: false,
    searchText: '',
    options: []
  });

  const optionSetRefForIntersectionObserver = useCallback(
    (node: HTMLLIElement | null) => {
      if (observer.current) {
        observer.current.disconnect();
      }
      observer.current = new IntersectionObserver((entries) => {
        /* when the list of options is expanded and the user has scrolled down
          past the half-way point of the current page */
        if (entries[0].isIntersecting) {
          // request the next page from the backend
          dispatch({ type: AutoCompleteActionType.ON_MORE_PAGES_NEEDED });
        }
      });
      if (node) {
        observer.current.observe(node);
      }
    },
    []
  );

  const getOptionsFiltered = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<T>> => {
      return getOptions({
        ...request,
        filters: !filters ? request.filters : { ...request.filters, ...filters }
      });
    },
    [getOptions, filters]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchApiData = useCallback(
    debounce(
      (
        searchText: string,
        pageNumber: number,
        abortController: AbortController
      ): Promise<void> => {
        const getOptions = searchText
          ? getPaginatedApiFromGetAll(() =>
              getAllFromPaginatedApiV2({
                getApi: getOptionsFiltered,
                search: searchText || undefined,
                abortController: abortController
              })
            )
          : getOptionsFiltered;

        return getOptions({
          search: searchText || undefined,
          pageNumber: pageNumber,
          pageSize: pageSize || DEFAULT_PAGE_SIZE,
          abortController: abortController
        })
          .then((results) => {
            dispatch({
              type: AutoCompleteActionType.MULTIPLE_ACTIONS,
              payload: [
                {
                  type: AutoCompleteActionType.SET_OPTIONS_FROM_SERVICE_RESPONSE,
                  payload: {
                    options: results.items,
                    totalPages: results.totalPages,
                    pageNumber: results.pageNumber
                  }
                },
                {
                  type: AutoCompleteActionType.SET_IS_NO_MATCHING_OPTIONS,
                  payload: {
                    isNoMatchingOptions: results.items.length === 0
                  }
                }
              ]
            });
          })
          .catch(handleError);
      },
      userInputDebounceDelayMilliseconds || DEFAULT_USER_INPUT_DEBOUNCE_DELAY
    ),
    [getOptionsFiltered, handleError]
  );

  useEffect(() => {
    if (!isRecordsEqual(filters, getOptionsFilters)) {
      dispatch({
        type: AutoCompleteActionType.ON_FILTERS_CHANGED,
        payload: {
          filters: getOptionsFilters
        }
      });
    }
  }, [getOptionsFilters, filters]);

  useEffect(() => {
    if (
      isFetchingOptions &&
      isSearchTextRequiredLength(searchText, minSearchTextLengthMemo)
    ) {
      const abortController = new AbortController();
      fetchApiData(
        searchText,
        nextOptionPageCanFetch || 0,
        abortController
      )?.catch(handleError);
      return () => {
        abortController.abort();
      };
    }
  }, [
    isFetchingOptions,
    fetchApiData,
    searchText,
    nextOptionPageCanFetch,
    lastOptionPageFetched,
    minSearchTextLengthMemo,
    isListOpen,
    handleError
  ]);

  return (
    <Autocomplete
      {...rest}
      value={value ?? (multiple ? [] : null)}
      openOnFocus={true}
      clearOnBlur={true}
      selectOnFocus={!props.readOnly}
      onChange={(_, value, reason, detail) => {
        onSelectedOptionChanged &&
          onSelectedOptionChanged(value, reason, detail);

        if (formikContext) {
          formikContext.setFieldValue(
            field.name as string,
            value === null ? undefined : value
          );
        }

        const isOptionsCleared =
          multiple && Array.isArray(value) ? value.length === 0 : !value;

        dispatch({
          type: isOptionsCleared
            ? AutoCompleteActionType.ON_OPTION_CLEARED
            : AutoCompleteActionType.ON_OPTION_SELECTION_CHANGED
        });
      }}
      ListboxProps={{
        role: 'list-box' // work around for this bug: https://github.com/mui/material-ui/issues/30249
      }}
      multiple={multiple || false}
      open={isListOpen}
      onOpen={() => {
        if (!props.readOnly) {
          dispatch({
            type: AutoCompleteActionType.SET_IS_LIST_OPEN,
            payload: { isListOpen: true }
          });
        }
      }}
      onClose={() => {
        dispatch({
          type: AutoCompleteActionType.SET_IS_LIST_OPEN,
          payload: { isListOpen: false }
        });
      }}
      onInputChange={(event, newInputValue, reason) => {
        if (event) {
          dispatch({
            type: AutoCompleteActionType.SET_SEARCH_TEXT,
            payload: {
              inputValue: newInputValue,
              isInputFromUser: reason === 'input'
            }
          });
        }
      }}
      options={options || []}
      renderOption={(props, option: T) => {
        const optionIndex = options.indexOf(option);
        const isOptionThatTriggersNextPageRequest =
          optionIndex === // i.e. option in the middle of the current page
          options.length - 1 - Math.ceil((pageSize || DEFAULT_PAGE_SIZE) / 2);
        const listItemProps: React.DetailedHTMLProps<
          React.LiHTMLAttributes<HTMLLIElement>,
          HTMLLIElement
        > = {
          ...props
        };

        if (isOptionThatTriggersNextPageRequest) {
          listItemProps.ref = optionSetRefForIntersectionObserver;
        }

        const optionLabel = getOptionLabel(option);
        return (
          <Box
            className={
              isOptionHighlighted && isOptionHighlighted(option)
                ? 'highlighted-option'
                : ''
            }
          >
            <li {...listItemProps} key={`${optionIndex} - ${optionLabel}`}>
              {optionLabel}
            </li>
          </Box>
        );
      }}
      loading={isFetchingOptions}
      loadingText={
        isSearchTextRequiredLength(searchText, minSearchTextLength)
          ? searchText
            ? 'Searching...'
            : 'Loading...'
          : `Please enter ${
              minSearchTextLength || DEFAULT_MIN_SEARCH_TEXT_LENGTH
            } or more characters`
      }
      isOptionEqualToValue={isOptionEqualToValue}
      getOptionLabel={getOptionLabel}
      renderInput={(params) => (
        <TextFieldV2
          {...params}
          value={value}
          InputLabelProps={{
            ...params.InputLabelProps,
            required: required && (!multiple || props.value?.length === 0)
          }}
          error={error}
          helperText={helperText}
          label={label}
          placeholder={props.placeholder}
          InputProps={{
            ...params.InputProps,
            size: props.size ?? 'medium',
            readOnly: props.readOnly,
            endAdornment: (
              <>
                {isFetchingOptions &&
                !props.readOnly &&
                isSearchTextRequiredLength(searchText, minSearchTextLength) ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  );
}
