import { RoomBookingType } from 'src/types';

export function getCacheStoreRootKey(
  roomBookingType: Exclude<RoomBookingType, RoomBookingType.ACADEMIC>
) {
  switch (roomBookingType) {
    case RoomBookingType.TRANSPORT:
      return 'transport-bookings';

    case RoomBookingType.ACCOMMODATION:
      return 'accommodation-bookings';

    case RoomBookingType.ADHOC:
      return 'adhoc-bookings';

    default:
      throw new Error('booking type is not supported');
  }
}

export function getCacheStoreFiltersKey(
  roomBookingType: Exclude<RoomBookingType, RoomBookingType.ACADEMIC>,
  isApprover: boolean
) {
  const keySuffix: string = isApprover ? '-approvers' : '';

  switch (roomBookingType) {
    case RoomBookingType.TRANSPORT:
      return `filters-transport-bookings${keySuffix}`;

    case RoomBookingType.ACCOMMODATION:
      return `filters-accommodation-bookings${keySuffix}`;

    case RoomBookingType.ADHOC:
      return `filters-adhoc-bookings${keySuffix}`;

    default:
      throw new Error('booking type is not supported');
  }
}
