import { StudentGroupDTO } from './StudentGroupDTO.type';
import { ThemeScheduleDTO } from './ThemeScheduleDTO.type';

export enum ValidationTypeDTO {
  STUDENT_GROUP_VALIDATION = 'STUDENT_GROUP_VALIDATION',
  CLINICAL_THEME_SCHEDULES_VALIDATION = 'CLINICAL_THEME_SCHEDULES_VALIDATION',
  THEME_SCHEDULES_MATCH_VALIDATION = 'THEME_SCHEDULES_MATCH_VALIDATION',
  GROUP_NAMES_MATCH_VALIDATION = 'GROUP_NAMES_MATCH_VALIDATION',
  MIN_REQUIRED_WEEKS_VALIDATION = 'MIN_REQUIRED_WEEKS_VALIDATION',
  MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION = 'MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION',
  AREA_CAPACITIES_VALIDATION = 'AREA_CAPACITIES_VALIDATION'
}

type BaseValidationDTO = {
  valid: boolean;
  validationMessage: string;
};

export type StudentGroupValidationDTO = BaseValidationDTO & {
  type: ValidationTypeDTO.STUDENT_GROUP_VALIDATION;
  visualizeValidation: StudentGroupDTO[];
};

export type ClinicalThemeSchedulesValidationDTO = BaseValidationDTO & {
  type: ValidationTypeDTO.CLINICAL_THEME_SCHEDULES_VALIDATION;
  visualizeValidation: ThemeScheduleDTO[];
};

export type ThemeSchedulesNamesMatchValidationDTO = BaseValidationDTO & {
  type: ValidationTypeDTO.THEME_SCHEDULES_MATCH_VALIDATION;
};

export type GroupNamesMatchValidationDTO = BaseValidationDTO & {
  type: ValidationTypeDTO.GROUP_NAMES_MATCH_VALIDATION;
};

export type MinRequiredWeeksAgainstAvailableValidationDTO =
  BaseValidationDTO & {
    type: ValidationTypeDTO.MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION;
    visualizeValidation: ThemeScheduleDTO[];
  };

export type AreaCapacitiesValidationDTO = BaseValidationDTO & {
  type: ValidationTypeDTO.AREA_CAPACITIES_VALIDATION;
  visualizeValidation: ThemeScheduleDTO[];
};

export type ThemeAutomationValidationResultDTO =
  | StudentGroupValidationDTO
  | ClinicalThemeSchedulesValidationDTO
  | ThemeSchedulesNamesMatchValidationDTO
  | GroupNamesMatchValidationDTO
  | MinRequiredWeeksAgainstAvailableValidationDTO
  | AreaCapacitiesValidationDTO;
