export type ThemeAutomationRequestDTO = {
  id: number;
  requestedRunSeconds: number;
  progress: string;
  createdAt: string;
  completedAt?: string;
  scoreHard: number;
  scoreSoft: number;
  startDate: string;
  endDate: string;
  scoreOvercapacity: number;
  numOfWeeks: number;
  moduleScheduleId: number;
  moduleScheduleIds: number[];
  fixedHolidays?: ThemeAutomationFixedHolidayDTO[];
  moduleScheduleDisplayName: string;
  moduleScheduleDisplayNames: string[];
};

export const ThemeAutomationProgressDTO = {
  AWAITING_START: 'AWAITING_START',
  IN_PROGRESS: 'IN_PROGRESS',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  IMPORTED: 'IMPORTED'
};

export type ThemeAutomationFixedHolidayDTO = {
  startDate: string;
  endDate: string;
};
