import { useCallback } from 'react';
import {
  GetAPI,
  getPaginatedResponse,
  Module,
  ModuleDTO,
  PaginatedRequest,
  PaginatedResponse
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IModulesService {
  getModules: GetAPI<Module>;
  createModule: (
    module: Module,
    abortController?: AbortController
  ) => Promise<void>;
  updateModule: (
    module: Module,
    abortController?: AbortController
  ) => Promise<void>;
  getModule: (id: number, abortController?: AbortController) => Promise<Module>;
  deleteModule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  verifyModules: (
    year: number,
    ids?: number[],
    abortController?: AbortController
  ) => Promise<void>;
  getAllModules: GetAPI<Module>;
  getAllModulesList: (
    params: ModuleParams,
    abortController?: AbortController
  ) => Promise<Module[]>;
  getUnscheduledModules: (
    request: PaginatedRequest
  ) => Promise<PaginatedResponse<Module>>;
}

export const useModulesService = (): IModulesService => {
  const modulesApi = useAxiosInstance('/v2/modules');

  const getModules = useCallback(
    async (request: PaginatedRequest): Promise<PaginatedResponse<Module>> => {
      const controller = request.abortController || new AbortController();

      return modulesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: controller.signal
        })
        .then((response) => {
          const { page, _embedded } = response.data;

          return {
            items: _embedded?.simpleModuleDToes || [],
            hasNextPage: page.number < page.totalElements,
            hasPrevPage: page.number > 0,
            pageNumber: page.number,
            totalCount: page.totalElements,
            totalPages: page.totalPages
          } as PaginatedResponse<Module>;
        });
    },
    [modulesApi]
  );

  const getModule = useCallback(
    (id: number, abortController?: AbortController): Promise<Module> =>
      modulesApi
        .get(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => getModuleFromDto(response.data as ModuleDTO)),
    [modulesApi]
  );

  const deleteModule = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      modulesApi.delete(`/${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [modulesApi]
  );

  const createModule = (
    module: Module,
    abortController?: AbortController
  ): Promise<void> =>
    modulesApi.post('', getDTOFromModule(module), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const updateModule = (
    module: Module,
    abortController?: AbortController
  ): Promise<void> =>
    modulesApi.put(`/${module.id}`, getDTOFromModule(module), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const verifyModules = (
    year: number,
    ids?: number[],
    abortController?: AbortController
  ): Promise<void> =>
    modulesApi.post(
      `verify?moduleId=${ids?.toString() as string}&year=${year}`,
      {},
      {
        withCredentials: true,
        signal: abortController?.signal
      }
    );

  const getAllModulesList = useCallback(
    (
      params: ModuleParams,
      abortController?: AbortController
    ): Promise<Module[]> => {
      return modulesApi
        .post('all', getModuleBodyDto(params), {
          params: getModuleParamsDto(params),
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => {
          const moduleDtos = response.data as ModuleDTO[];
          return moduleDtos.map(getModuleFromDto);
        });
    },
    [modulesApi]
  );

  const getAllModules = (
    request: PaginatedRequest
  ): Promise<PaginatedResponse<Module>> =>
    modulesApi
      .get('all', {
        params: request,
        withCredentials: true,
        signal: request?.abortController?.signal
      })
      .then((response) =>
        getPaginatedResponse<ModuleDTO, Module>(response, getModuleFromDto)
      );

  const getUnscheduledModules = (
    request: PaginatedRequest
  ): Promise<PaginatedResponse<Module>> =>
    modulesApi
      .get('', {
        params: request,
        withCredentials: true,
        signal: request?.abortController?.signal
      })
      .then((response) =>
        getPaginatedResponse<ModuleDTO, Module>(response, getModuleFromDto)
      );
  return {
    getModules,
    createModule,
    updateModule,
    getModule,
    deleteModule,
    verifyModules,
    getAllModules,
    getAllModulesList,
    getUnscheduledModules
  };
};

export type ModuleParams = {
  search?: string;
  programmeId?: number;
  programmeIds?: string;
  calendarYear?: number;
  academicYear?: number;
  ids?: number[];
  verified?: boolean;
  supervisorId?: number;
  userId?: number;
  studentId?: number;
  tagIds?: string;
  activeStatus?: boolean;
};

type ModulesParamsDto = {
  search?: string;
  programmeId?: number;
  programmeIds?: string;
  calendarYear?: number;
  academicYear?: number;
  ids?: string[];
  verified?: boolean;
  supervisorId?: number;
  studentId?: number;
  userId?: number;
  tagIds?: string;
  activeStatus?: boolean;
};

function getModuleBodyDto(moduleParams: ModuleParams): number[] | undefined {
  const { ids } = moduleParams;
  if (ids && ids.length > 0) {
    return ids;
  }
}

function getModuleParamsDto(moduleParams: ModuleParams): ModulesParamsDto {
  const modulesDto: ModulesParamsDto = {};
  const {
    programmeId,
    programmeIds,
    calendarYear,
    supervisorId,
    studentId,
    userId,
    tagIds,
    activeStatus
  } = moduleParams;

  if (programmeId) {
    modulesDto.programmeId = programmeId;
  }

  if (programmeIds !== undefined) {
    modulesDto.programmeIds = programmeIds;
  }

  if (calendarYear) {
    modulesDto.calendarYear = calendarYear;
  }

  if (supervisorId !== undefined) {
    modulesDto.supervisorId = supervisorId;
  }

  if (studentId !== undefined) {
    modulesDto.studentId = studentId;
  }

  if (userId !== undefined) {
    modulesDto.userId = userId;
  }

  if (activeStatus !== undefined) {
    modulesDto.activeStatus = activeStatus;
  }

  if (tagIds !== undefined) {
    modulesDto.tagIds = tagIds;
  }

  return modulesDto;
}

function getDTOFromModule(module: Module): ModuleDTO {
  return {
    ...module,
    tagIds: module.tags?.map((tag) => tag.id),
    tags: undefined,
    defaultProgrammeIds: module.defaultProgrammes?.map(
      (programme) => programme.id
    ),
    defaultProgrammes: undefined
  } as unknown as ModuleDTO;
}

function getModuleFromDto(dto: ModuleDTO): Module {
  return {
    ...dto
  } as Module;
}
