import { FormikErrors } from 'formik';
import { FormProps, FormValues, MyModulesTabId } from './form-types';

export const getInitialValues = (formProps: FormProps): FormValues => {
  return {
    moduleSchedule: formProps.moduleSchedule,
    moduleScheduleLastSelected: formProps.moduleScheduleLastSelected,
    isNavigationButtonsVisible: formProps.isNavigationButtonsVisible,
    selectedTab: formProps.myModulesTabId ?? MyModulesTabId.PLACEMENT_SCHEDULES
  };
};

export const getOnSubmit = ({
  onSubmit
}: FormProps): ((values: FormValues) => void) => {
  return (values: FormValues) => {
    onSubmit(values.selectedTab, values.moduleSchedule);
  };
};

export const getValidate =
  (_: FormProps) =>
  (_: FormValues): FormikErrors<FormValues> => {
    const errors: FormikErrors<FormValues> = {};

    return errors;
  };

export const getMyModulesTabIdFromString = (
  myModulesTabIdString: string
): MyModulesTabId => {
  switch (myModulesTabIdString) {
    case MyModulesTabId.PLACEMENT_SCHEDULES:
      return MyModulesTabId.PLACEMENT_SCHEDULES;
    case MyModulesTabId.CLASS_LIST:
      return MyModulesTabId.CLASS_LIST;
    case MyModulesTabId.SCHEDULE_THEME:
      return MyModulesTabId.SCHEDULE_THEME;
    case MyModulesTabId.SUPERVISORS:
      return MyModulesTabId.SUPERVISORS;
    case MyModulesTabId.ACTIVITIES:
      return MyModulesTabId.ACTIVITIES;
    default:
      throw new Error('unknown my modules tab id');
  }
};
