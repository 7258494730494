import { Stack, Typography } from '@mui/material';
import { ReactFragment } from 'react';
import { ThemableBox } from '../themable-box/ThemableBox';
import { ThemableBoxType } from 'src/types';

export type EmbeddedScreenProps = {
  children: ReactFragment;
  title?: string;
};

export const EmbeddedScreen = ({ children, title }: EmbeddedScreenProps) => {
  return (
    <ThemableBox type={ThemableBoxType.EMBEDDED_CONTAINER}>
      <Stack spacing={2} direction="column">
        <>{title && <Typography variant="h2">{title}</Typography>}</>
        <>{children}</>
      </Stack>
    </ThemableBox>
  );
};
