import { Backdrop } from '@mui/material';
import { HelixLoadingIndicator } from '../loading-indicator/HelixLoadingIndicator';

export type BusyBackdropProps = {
  show: boolean;
};

export const BusyBackdrop = ({ show }: BusyBackdropProps) => {
  return (
    <Backdrop sx={{ zIndex: theme => theme.zIndex.drawer + 1 }} open={show}>
      <HelixLoadingIndicator />
    </Backdrop>
  );
};
