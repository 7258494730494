import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { cloneDeep } from 'lodash';
import { LoadingIndicator } from 'src/common/loading-indicator/LoadingIndicator';
import { parse, stringify } from 'src/lib';

export interface ICacheStore {
  set: <T>(key: string, value: T) => void;

  get<T>(key: string): T | undefined;

  clear: (key: string) => void;

  clearAll: () => void;
}

export type CachedItem = {
  itemJson: string;
  timeToLiveMilliseconds?: number;
};

export type SessionStorageCacheStoreContextProviderProps = {
  children?: ReactNode;
  storageKey?: string;
};

export const CacheStoreContext = createContext<ICacheStore | undefined>(
  undefined
);

type CacheMap = Map<string, CachedItem | undefined>;

const CACHE_STORE_SESSION_STORAGE_KEY = 'cache';

export const SessionStorageCacheStoreContextProvider = ({
  children,
  storageKey = CACHE_STORE_SESSION_STORAGE_KEY
}: SessionStorageCacheStoreContextProviderProps) => {
  const cache = useRef<CacheMap>(new Map());

  const [isCacheLoaded, setIsCacheLoaded] = useState(false);

  useEffect(() => {
    if (!isCacheLoaded) {
      const sessionStorageJson = sessionStorage.getItem(storageKey);

      if (sessionStorageJson) {
        cache.current = parse(sessionStorageJson);
      }

      setIsCacheLoaded(true);
    }
  }, [storageKey, isCacheLoaded]);

  const get = useCallback(<T,>(key: string) => {
    const cachedItem = cache.current.get(key);
    const cachedItemCopy = cloneDeep(cachedItem);
    return cachedItemCopy ? parse<T>(cachedItemCopy.itemJson) : undefined;
  }, []);

  function set<T>(key: string, value: T) {
    const valueCopy = cloneDeep(value);
    const cachedItem: CachedItem = {
      itemJson: stringify(valueCopy)
    };
    cache.current.set(key, cachedItem);
    const newValue = stringify(cache.current);
    sessionStorage.setItem(CACHE_STORE_SESSION_STORAGE_KEY, newValue);
  }

  const clear = (key: string) => {
    cache.current.delete(key);
  };

  const clearAll = () => {
    cache.current.clear();
    const newValue = stringify(cache.current);
    sessionStorage.setItem(CACHE_STORE_SESSION_STORAGE_KEY, newValue);
  };

  return isCacheLoaded ? (
    <CacheStoreContext.Provider value={{ get, set, clear, clearAll }}>
      {children}
    </CacheStoreContext.Provider>
  ) : (
    <LoadingIndicator text="Loading cache" />
  );
};

/**
 @param keyPrefix - added to the beginning of key names passed into get and set functions of ICacheStore
 */
export const useCacheStore = (keyPrefix: string): ICacheStore => {
  const context = useContext(CacheStoreContext);

  if (!context) {
    throw new Error(
      'Cache Store Context cannot be used outside of Cache Store Context Provider'
    );
  }

  const get = <T,>(key: string) => {
    return context.get<T>(`${keyPrefix}.${key}`);
  };

  const set = <T,>(key: string, value: T) => {
    context.set<T>(`${keyPrefix}.${key}`, value);
  };

  return {
    ...context,
    set,
    get
  };
};
