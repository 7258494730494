import { forwardRef, useMemo } from 'react';
import { TableComponents, TableVirtuoso } from 'react-virtuoso';
import { get } from 'lodash';
import {
  Box,
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { TableHeaderConfig, TableRowData } from 'src/types';
import { TableType } from '../Table';

const DEFAULT_HEIGHT_PIXELS = 400;

export type TableStaticVirtualisedProps = {
  type: TableType.STATIC_VIRTUALISED;
  headers: TableHeaderConfig[];
  rows: TableRowData[];
  height?: number;
  getRowClass?: (tableRow: TableRowData) => string;
};

const getPlaceholderRowComponent = (headers: TableHeaderConfig[]) => () => {
  return (
    <MuiTableBody>
      <TableRow key={`row-${1}-no-data-to-display`} data-testid={`row-${1}`}>
        <TableCell colSpan={headers.length} align="center">
          No data to display
        </TableCell>
      </TableRow>
    </MuiTableBody>
  );
};

export const TableStaticVirtualisedContainer = ({
  headers,
  rows,
  height,
  getRowClass
}: TableStaticVirtualisedProps) => {
  const tableComponents: TableComponents<TableRowData> = useMemo(
    () => ({
      Table: (props) => (
        <MuiTable {...props} style={{ borderCollapse: 'separate' }} />
      ),
      TableHead: TableHead,
      TableRow: TableRow,
      TableBody: forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
      EmptyPlaceholder: getPlaceholderRowComponent(headers)
    }),
    [headers]
  );

  return (
    <Box mt={2}>
      <TableVirtuoso
        style={{ height: height ?? DEFAULT_HEIGHT_PIXELS }}
        data={rows}
        components={tableComponents}
        fixedHeaderContent={() => (
          <TableRow>
            {headers.map((header) => (
              <TableCell key={header.id} align="left">
                <Typography variant="body2">{header.label}</Typography>
              </TableCell>
            ))}
          </TableRow>
        )}
        itemContent={(index, row) => (
          <>
            {headers.map((header, cellIndex) => {
              const cellValue = get(row, header.id, '');

              return (
                <TableCell
                  key={`${index}-${cellIndex}-${
                    cellValue ? cellValue.toString() : 'undefined'
                  }`}
                  className={getRowClass ? getRowClass(row) : ''}
                  id={`row-${index}-cell-${header.id}`}
                >
                  {cellValue}
                </TableCell>
              );
            })}
          </>
        )}
      />
    </Box>
  );
};
