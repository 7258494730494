import { useCallback } from 'react';

import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,

  useAxiosInstance
} from 'src/lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  StudentPlacementSchedule,
  StudentPlacementScheduleDTO
} from 'src/types';

export interface IStudentPlacementScheduleService {
  getStudentPlacementSchedules: GetAPI<StudentPlacementSchedule>;
  getStudentPlacementSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<StudentPlacementSchedule>;
  createStudentPlacementSchedule: (
    studentPlacementSchedule: StudentPlacementSchedule,
    abortController?: AbortController
  ) => Promise<void>;
  updateStudentPlacementSchedule: (
    studentPlacementSchedule: StudentPlacementSchedule,
    abortController?: AbortController
  ) => Promise<void>;
  deleteStudentPlacementSchedule: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  downloadStudentPlacementSchedule: (
    filters?: Record<string, string>,
    search?: string,
    abortController?: AbortController
  ) => Promise<Blob>;
  downloadStudentPrivateVehicleUsage: (
    facilityId?: number,
    abortController?: AbortController
  ) => Promise<Blob>;
}

export const useStudentPlacementScheduleService =
  (): IStudentPlacementScheduleService => {

    const studentplacementscheduleApi = useAxiosInstance(
      '/v2/studentPlacementSchedules'
    );

    const getStudentPlacementSchedules = useCallback(
      (
        request: PaginatedRequest
      ): Promise<PaginatedResponse<StudentPlacementSchedule>> => {
        return studentplacementscheduleApi
          .get('', {
            params: request,
            withCredentials: true,
            signal: request.abortController
              ? request.abortController.signal
              : undefined
          })
          .then((response) => {
            const { content, first, last, number, totalElements, totalPages } =
              response.data as PaginatedResponseDTO<StudentPlacementScheduleDTO>;
            return {
              items: content.map(getStudentPlacementScheduleFromDTO),
              hasNextPage: !last,
              hasPrevPage: !first,
              pageNumber: number,
              totalCount: totalElements,
              totalPages: totalPages
            } as PaginatedResponse<StudentPlacementSchedule>;
          });
      },
      [studentplacementscheduleApi]
    );

    const getStudentPlacementSchedule = useCallback(
      (
        id: number,
        abortController?: AbortController
      ): Promise<StudentPlacementSchedule> => {
        return studentplacementscheduleApi
          .get(`/${id}`, {
            withCredentials: true,
            signal: abortController?.signal
          })
          .then((response) => {
            const studentPlacementScheduleDTO =
              response.data as StudentPlacementScheduleDTO;
            return getStudentPlacementScheduleFromDTO(
              studentPlacementScheduleDTO
            );
          });
      },
      [studentplacementscheduleApi]
    );

    const deleteStudentPlacementSchedule = useCallback(
      (id: number, abortController?: AbortController): Promise<void> =>
        studentplacementscheduleApi.delete(`/${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        }),
      [studentplacementscheduleApi]
    );

    const createStudentPlacementSchedule = (
      studentPlacementSchedule: StudentPlacementSchedule,
      abortController?: AbortController
    ): Promise<void> =>
      studentplacementscheduleApi.post(
        '',
        getStudentPlacementScheduleDto(studentPlacementSchedule),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      );

    const updateStudentPlacementSchedule = (
      studentPlacementSchedule: StudentPlacementSchedule,
      abortController?: AbortController
    ): Promise<void> => {
      return studentplacementscheduleApi.put(
        `/${studentPlacementSchedule.id}`,
        abortController
      );
    };

    const downloadStudentPlacementSchedule = (
      filters?: Record<string, string>,
      search?: string,
      abortController?: AbortController
    ): Promise<Blob> => {
      return studentplacementscheduleApi
        .get('download', {
          params: {
            filters,
            search: search
          },
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined,
          responseType: 'blob'
        })
        .then((response) => {
          const type = response.headers['content-type'];
          return new Blob([response.data] as BlobPart[], { type });
        });
    };

    const downloadStudentPrivateVehicleUsage = (
      facilityId?: number,
      abortController?: AbortController
    ): Promise<Blob> => {
      let params = undefined;
      if (facilityId !== undefined) {
        params = {
          facilityId: facilityId
        };
      }
      return studentplacementscheduleApi
        .get('studentPrivateVehicleUsage/download', {
          params: params,
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined,
          responseType: 'blob'
        })
        .then((response) => {
          const type = response.headers['content-type'];
          return new Blob([response.data] as BlobPart[], { type });
        });
    };

    return {
      getStudentPlacementSchedules,
      getStudentPlacementSchedule,
      createStudentPlacementSchedule,
      updateStudentPlacementSchedule,
      deleteStudentPlacementSchedule,
      downloadStudentPlacementSchedule,
      downloadStudentPrivateVehicleUsage
    };
  };

function getStudentPlacementScheduleDto(
  studentPlacementSchedule: StudentPlacementSchedule
): StudentPlacementScheduleDTO {
  return {
    ...studentPlacementSchedule,
    startDateTime: getDtoDateStringFromDate(
      studentPlacementSchedule.startDateTime
    ),
    endDateTime: getDtoDateStringFromDate(
      studentPlacementSchedule.startDateTime
    )
  };
}

function getStudentPlacementScheduleFromDTO(
  studentPlacementScheduleDTO: StudentPlacementScheduleDTO
): StudentPlacementSchedule {
  return {
    ...studentPlacementScheduleDTO,
    startDateTime: getDateFromDtoDateString(
      studentPlacementScheduleDTO.startDateTime
    ),
    endDateTime: getDateFromDtoDateString(
      studentPlacementScheduleDTO.endDateTime
    )
  };
}
