import { useCallback } from 'react';
import {
  getDtoFromWeekdays,
  getWeekdaysFromDto
} from 'src/services/dto-mappers';
import {
  Activity,
  ActivityDTO,
  BulkDelete,
  BulkDeleteDTO,
  GetAPI,
  ModuleSchedule,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  Tag
} from 'src/types';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';

export interface IActivitiesService {
  getActivities: GetAPI<Activity>;
  getActivity: (
    id: number,
    abortController?: AbortController
  ) => Promise<Activity>;
  createActivity: (
    activity: Activity,
    abortController?: AbortController
  ) => Promise<void>;
  updateActivity: (
    id: number,
    activity: Activity,
    abortController?: AbortController
  ) => Promise<void>;
  deleteActivity: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
  deleteBulkActivities: (
    activityIds?: number[],
    themeScheduleId?: number,
    moduleScheduleId?: number,
    abortController?: AbortController
  ) => Promise<BulkDelete>;
  copyActivitiesFromModule: (
    destinationModuleScheduleId: number,
    sourceModuleSchedule?: ModuleSchedule,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useActivitiesService = (): IActivitiesService => {
  const activitiesApi = useAxiosInstance('/v2/activities');

  const getActivities = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Activity>> => {
      return activitiesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<Activity>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Activity>;
        });
    },
    [activitiesApi]
  );

  const getActivity = useCallback(
    (id: number, abortController?: AbortController): Promise<Activity> =>
      activitiesApi
        .get(`${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => getActivityFromDTO(response.data as ActivityDTO)),
    [activitiesApi]
  );

  const deleteActivity = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      activitiesApi.delete(`${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [activitiesApi]
  );

  const deleteBulkActivities = useCallback(
    (
      activityIds?: number[],
      themeScheduleId?: number,
      moduleScheduleId?: number,
      abortController?: AbortController
    ): Promise<BulkDelete> => {
      return activitiesApi
        .delete('', {
          data: {
            ids: activityIds,
            themeScheduleId: themeScheduleId,
            moduleScheduleId: moduleScheduleId
          },
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => {
          return getBulkDeleteFromDTO(response.data as BulkDeleteDTO);
        });
    },
    [activitiesApi]
  );

  const createActivity = (
    activity: Activity,
    abortController?: AbortController
  ): Promise<void> =>
    activitiesApi.post('', getDTOFromActivity(activity), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const updateActivity = (
    id: number,
    activity: Activity,
    abortController?: AbortController
  ): Promise<void> =>
    activitiesApi.put(`${id}`, getDTOFromActivity(activity), {
      withCredentials: true,
      signal: abortController?.signal
    });

  const copyActivitiesFromModule = useCallback(
    (
      destinationModuleScheduleId: number,
      sourceModuleSchedule?: ModuleSchedule,
      abortController?: AbortController
    ): Promise<void> =>
      activitiesApi.post('copyActivitiesFromModule', undefined, {
        params: {
          sourceModuleScheduleId: sourceModuleSchedule
            ? sourceModuleSchedule.id.toString()
            : '',
          destinationModuleScheduleId: destinationModuleScheduleId
        },
        signal: abortController?.signal,
        withCredentials: true
      }),
    [activitiesApi]
  );

  return {
    getActivities,
    getActivity,
    deleteActivity,
    createActivity,
    updateActivity,
    copyActivitiesFromModule,
    deleteBulkActivities
  };
};

function getDTOFromActivity(activity: Activity): ActivityDTO {
  return {
    ...activity,
    signOffSupervisorId: activity.formSignOffSupervisor?.id,
    dayOfWeekBitField: getDtoFromWeekdays(activity.dayOfWeekBitField),
    tagIds: activity.tags?.map((tag: Tag) => tag.id),
    startTime: getDtoDateStringFromDate(activity.startTime),
    endTime: getDtoDateStringFromDate(activity.endTime)
  };
}

function getActivityFromDTO(activity: ActivityDTO): Activity {
  return {
    ...activity,
    dayOfWeekBitField: getWeekdaysFromDto(activity.dayOfWeekBitField),
    startTime: getDateFromDtoDateString(activity.startTime),
    endTime: getDateFromDtoDateString(activity.endTime)
  };
}

function getBulkDeleteFromDTO(deleteBulkDTO: BulkDeleteDTO): BulkDelete {
  return {
    ...deleteBulkDTO
  };
}
