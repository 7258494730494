import { useCallback } from 'react';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  Role
} from 'src/types';
import { useAxiosInstance } from 'src/lib';
import { UserRole } from 'src/types/models/UserRole';

export interface IRolesService {
  getRoles: GetAPI<Role>;
  getCurrentUserRoles: (abortController?: AbortController) => Promise<UserRole>;
  getRole: (id: number, abortController?: AbortController) => Promise<Role>;
  createRole: (role: Role, abortController?: AbortController) => Promise<void>;
  updateRole: (
    id: number,
    role: Role,
    abortController?: AbortController
  ) => Promise<void>;
  deleteRole: (id: number, abortController?: AbortController) => Promise<void>;
}

export const useRolesService = (): IRolesService => {
  const rolesApi = useAxiosInstance('/v2/roles');

  const getRoles = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Role>> => {
      return rolesApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<Role>;

          return {
            items: content,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Role>;
        });
    },
    [rolesApi]
  );

  const getCurrentUserRoles = useCallback(
    (abortController?: AbortController): Promise<UserRole> => {
      return rolesApi
        .get('current-user', {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) => response.data as UserRole);
    },
    [rolesApi]
  );

  const getRole = useCallback(
    (id: number, abortController?: AbortController): Promise<Role> =>
      rolesApi
        .get(`${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => response.data as Role),
    [rolesApi]
  );

  const createRole = useCallback(
    (role: Role, abortController?: AbortController): Promise<void> =>
      rolesApi.post('', role, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [rolesApi]
  );

  const updateRole = useCallback(
    (
      id: number,
      role: Role,
      abortController?: AbortController
    ): Promise<void> =>
      rolesApi.put(`${id}`, role, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [rolesApi]
  );

  const deleteRole = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      rolesApi.delete(`${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [rolesApi]
  );

  return {
    getRoles,
    getCurrentUserRoles,
    getRole,
    createRole,
    updateRole,
    deleteRole
  };
};
