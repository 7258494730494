import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';
import { debounce } from 'lodash';

export type FormValuesChangeListenerProps<T> = {
  children: (newValues: T, oldValues?: T) => void;
  handlerFieldDelays?: Map<keyof T, number>;
};

/**
 * Component offers callback function as children that is called
 * when the values in the Formik Form, in which the component is
 * nested within, change.
 *
 * @param handleOnValuesChange Callback function called when
 * form values change. Function is passed both the existing
 * and new values for the form.
 *
 * @param handlerFieldDelays Optional. A {@link Map} used to specify, for one or
 * more fields, the time in milliseconds delay between the last change
 * to a field, and the call to {@link handleOnValuesChange}. If the parameter
 * is not specified, or if there is no key specified for the field that
 * changed, the {@link handleOnValuesChange} function will be called instantly.
 */
export function FormikValuesChangeListener<T>({
  children: handleOnValuesChange,
  handlerFieldDelays
}: FormValuesChangeListenerProps<T>) {
  const { values } = useFormikContext<T>();

  const oldValues = useRef<T | undefined>();

  useEffect(() => {
    const changedFieldName = getFirstChangedFieldName(
      values,
      oldValues.current
    );

    const responseDelayForField =
      changedFieldName &&
      handlerFieldDelays &&
      handlerFieldDelays.get(changedFieldName);

    if (responseDelayForField === undefined) {
      return handleOnValuesChange(values, oldValues.current);
    }

    const handleOnValuesChangeDebounced = debounce(
      handleOnValuesChange,
      responseDelayForField
    );
    handleOnValuesChangeDebounced(values, oldValues.current);

    oldValues.current = values;

    return () => {
      handleOnValuesChangeDebounced.cancel();
    };
  }, [handleOnValuesChange, values, handlerFieldDelays]);

  return null;
}

/**
 * Compares the old and new form values, and returns the field name
 * of the first field that changed, or undefined if no fields changed.
 */
function getFirstChangedFieldName<T>(
  newValues: T,
  oldValues?: T
): keyof T | undefined {
  let key: keyof T;

  for (key in newValues) {
    const currentValue = newValues[key];
    const oldValue = oldValues ? oldValues[key] : undefined;

    if (currentValue !== oldValue) {
      return key;
    }
  }
}
