import React, { FC } from 'react';
import { List, ListItem, Skeleton } from '@mui/material';
import { ThemableBox } from 'src/common';
import { ThemableBoxType } from 'src/types';

export type ListBodySkeletonProps = {
  numberOfNotifications: number;
};

export const ListBodySkeleton: FC<ListBodySkeletonProps> = ({
  numberOfNotifications
}) => {
  return (
    <ThemableBox type={ThemableBoxType.NOTIFICATIONS_LIST}>
      <List>
        {Array(numberOfNotifications)
          .fill(0)
          .map((_, index) => (
            <ListItem key={index}>
              <Skeleton variant="rounded" animation="wave" height={60} />
            </ListItem>
          ))}
      </List>
    </ThemableBox>
  );
};
