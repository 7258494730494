import * as React from 'react';
import { useMemo } from 'react';
import { Moment } from 'moment/moment';
import moment from 'moment';
import { Box } from '@mui/material';
import ReactCalendarTimeline from 'react-calendar-timeline';
import Timeline, {
  ReactCalendarTimelineProps,
  TimelineGroupBase,
  TimelineItemBase
} from 'react-calendar-timeline';
import { isValidDate } from 'src/lib';
import { TableEventGroup, TableEventItem, ThemableBoxType } from 'src/types';
import { ThemableBox } from '../themable-box/ThemableBox';

export type CalendarTimelineProps = {
  events: TableEventGroup[];
  children?: React.ReactNode;
  onSetRef?: (
    ref: ReactCalendarTimeline<TimelineItemBase<Moment>, TimelineGroupBase>
  ) => void;
} & Omit<
  ReactCalendarTimelineProps<TimelineItemBase<Moment>>,
  'groups' | 'items'
>;

export const CalendarTimeline = ({
  events,
  defaultTimeStart,
  defaultTimeEnd,
  children,
  canMove,
  canResize,
  onSetRef,
  ...rest
}: CalendarTimelineProps) => {
  const eventLookup = useMemo<Map<number, TableEventItem>>(() => {
    const result = new Map<number, TableEventItem>();

    if (events) {
      events
        .flatMap((eventGroup) => eventGroup.eventItems)
        .forEach((value) => {
          if (value) {
            result.set(value.id, value);
          }
        });
    }

    return result;
  }, [events]);

  const [timeStart, timeEnd] = useMemo<[Date, Date]>(() => {
    const initialDate = new Date(NaN);
    return events
      .flatMap((eventGroup) => eventGroup.eventItems)
      .reduce<[Date, Date]>(
        ([smallestCurrent, largestCurrent], eventItem) => {
          const largest =
            !isValidDate(largestCurrent) || eventItem.endTime > largestCurrent
              ? eventItem.endTime
              : largestCurrent;
          const smallest =
            !isValidDate(largestCurrent) ||
            eventItem.startTime < smallestCurrent
              ? eventItem.startTime
              : smallestCurrent;
          return [smallest, largest];
        },
        [initialDate, initialDate]
      );
  }, [events]);

  const groups = useMemo<TimelineGroupBase[]>(
    () =>
      events.map<TimelineGroupBase>((group, index) => {
        return {
          id: index,
          title: group.isPublicHolidays ? (
            <Box className="calendarPublicHolidays">{group.groupTitle}</Box>
          ) : (
            group.groupTitle
          )
        };
      }),
    [events]
  );

  const items = useMemo<TimelineItemBase<Moment>[]>(
    () =>
      events.flatMap((group, index) => {
        return group.eventItems.map<TimelineItemBase<Moment>>((groupItem) => {
          return {
            id: groupItem.id,
            group: index,
            title: groupItem.title,
            start_time: moment(groupItem.startTime),
            end_time: moment(groupItem.endTime),
            onDoubleClick: groupItem.onDoubleClick,
            className: groupItem.className,
            canMove: group.isPublicHolidays ? false : canMove,
            canResize: group.isPublicHolidays ? false : canResize
          };
        });
      }),
    [events, canMove, canResize]
  );

  const handleItemDoubleClick = (itemId: number) => {
    const event = eventLookup.get(itemId);
    event?.onDoubleClick && event.onDoubleClick();
  };

  const timelineProps: ReactCalendarTimelineProps<TimelineItemBase<Moment>> = {
    groups: groups,
    items: items,
    lineHeight: 42,
    stackItems: true,
    itemHeightRatio: 0.75,
    sidebarWidth: 150,
    onItemDoubleClick: handleItemDoubleClick,
    buffer: 1,
    defaultTimeStart: defaultTimeStart || moment(timeStart),
    defaultTimeEnd: defaultTimeEnd || moment(timeEnd),
    ...rest
  };

  return groups.length > 0 ? (
    <ThemableBox type={ThemableBoxType.CALENDAR_TIMELINE}>
      <Timeline
        {...timelineProps}
        ref={function (input) {
          if (input && onSetRef) {
            onSetRef(input);
          }
        }}
      >
        {children && children}
      </Timeline>
    </ThemableBox>
  ) : (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '20px',
        paddingBottom: '20px'
      }}
    >
      No data to display
    </Box>
  );
};
