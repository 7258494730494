import { BookedBy, Filters } from '../components';

export const QueryParams = {
  IS_APPROVER_VIEW: 'isApproverView'
};

export const REQUEST_A_TRAINING_AREA_PLACEHOLDER_NAME =
  '<request a venue training area>';

export const DEFAULT_FILTERS: Filters = {
  bookedBy: BookedBy.ME
};

export const REVIEW_DISABLED_TOOLTIP =
  "You don't have the required permissions to review this booking";
