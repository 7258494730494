import { useCallback } from 'react';
import { useEnv } from 'src/common';

export interface IAuthService {
  logout: () => void;
}

export const useAuthService = (): IAuthService => {
  const { apiUrl } = useEnv();

  const logout = useCallback((): void => {
    window.location.href = `${apiUrl}/logout/cas`;
  }, [apiUrl]);

  return { logout };
};
