import React, { FC, useMemo, useState } from 'react';
import { Box, Link, Stack } from '@mui/material';

export type ShowMoreLessProps = {
  stringsToDisplay: string[];
  maxLines?: number;
};

/**
 * Returns a JSX element that can expand and collapse to show
 * or hide large amounts of text
 *
 * @param stringsToDisplay - An array of strings to display in the element
 * @param maxLines - The maximum number of lines of text to show before truncating all other text. Default is 5
 * @returns An element that can be expanded and collapses to view or hide text
 */

export const ShowMoreLess: FC<ShowMoreLessProps> = ({
  stringsToDisplay,
  maxLines = 5
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleOnClick = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
  };

  function getLabel(isOpen: boolean, numberOfItems: number, maxRows: number) {
    if (isOpen) {
      return 'Less';
    }
    return `+ ${numberOfItems - maxRows} more...`;
  }

  const itemMore = useMemo(
    () => stringsToDisplay.map((item, index) => <Box key={index}>{item}</Box>),
    [stringsToDisplay]
  );

  const itemLess = useMemo(
    () => itemMore.slice(0, maxLines),
    [itemMore, maxLines]
  );

  if (maxLines <= 1) {
    maxLines = 1;
  }
  if (stringsToDisplay.length <= maxLines) {
    return <Box>{stringsToDisplay.join('\n')}</Box>;
  }

  return (
    <Box>
      <Stack direction="column">{isOpen ? itemMore : itemLess}</Stack>
      <Link underline="hover" onClick={handleOnClick}>
        {getLabel(isOpen, itemMore.length, maxLines)}
      </Link>
    </Box>
  );
};
