import { FileDetails, Tag } from 'src/types';
import { getDtoDateStringFromDate } from 'src/lib';
import { FileTagsEditModal } from '.';
import React from 'react';
import { useFilesService } from 'src/services';

export type TagFile = {
  index: number;
  fileDetails: FileDetails;
};

type Props<T> = {
  file: FileDetails;
  fileIndex: number;
  files: FileDetails[];
  formValues: T;
  tagOptions: Tag[];
  setFileTags: (tagFile?: TagFile) => void;
  setValues: (values: T) => void;
};

export function FileTagsCreateEdit<T>({
  file,
  files,
  tagOptions,
  fileIndex,
  formValues: values,
  setFileTags,
  setValues
}: Props<T>) {
  const { updateFile } = useFilesService();

  return (
    <FileTagsEditModal
      file={file}
      options={tagOptions}
      onSave={selectedTags => {
        const fileToEdit: FileDetails = {
          ...file,
          tags: selectedTags,
          tagIds: selectedTags.map(selectedTag => selectedTag.id)
        };
        const filesNew: FileDetails[] = [...(files || [])].map((file, index) =>
          index === fileIndex ? fileToEdit : file
        );

        setValues({
          ...values,
          files: filesNew
        });

        setFileTags(undefined);

        return updateFile({
          id: fileToEdit.id,
          dateCreated: getDtoDateStringFromDate(fileToEdit.dateCreated),
          mimeType: fileToEdit.mimeType,
          name: fileToEdit.name,
          size: fileToEdit.size,
          tagIds: fileToEdit.tagIds,
          userId: fileToEdit.createdByUserId
        });
      }}
      onClose={() => setFileTags(undefined)}
    />
  );
}
