import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppContext } from 'src/common';

export function useQuery<X, Y, Z>(
  params: X | undefined,
  getQueryResults: (params: Y) => Promise<Z>,
  getQueryParams: (functionParams: X) => Y | undefined
): [Z | undefined, () => void, boolean] {
  const { handleError } = useAppContext();
  const [result, setResult] = useState<Z | undefined>();
  const [isQueryComplete, setIsQueryComplete] = useState(false);
  const isGettingResult = useRef<boolean>(false);

  const refreshResult = useCallback(() => {
    setResult(undefined);
  }, []);

  useEffect(() => {
    if (params && !result && !isGettingResult.current) {
      isGettingResult.current = true;
      setIsQueryComplete(false);
      const transformedParams = getQueryParams(params);

      if (transformedParams) {
        getQueryResults(transformedParams)
          .then(functionResult => {
            setResult(functionResult);
            setIsQueryComplete(true);
            isGettingResult.current = false;
          })
          .catch(handleError);
      }
    }
  }, [result, params, getQueryResults, getQueryParams, handleError]);

  return [result, refreshResult, isQueryComplete];
}
