import { FC } from 'react';
import { Grid } from '@mui/material';
import { BorderBox } from 'src/common';

export const ListViewContentPanel: FC = ({ children }) => {
  return (
    <Grid item xs>
      <BorderBox variant="generic">{children}</BorderBox>
    </Grid>
  );
};
