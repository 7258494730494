import { FormSubmissionDTO } from 'src/types/dto';
import { Form, FormComment } from '.';

export type FormSubmission = Omit<
  FormSubmissionDTO,
  'formComments' | 'state'
> & {
  form: Form;
  formComments: FormComment[];
  state: FormSubmissionStateEnum;
};

export type FormSubmissionState = {
  id: number;
  value: string;
  label: string;
};

export enum FormSubmissionStateEnum {
  IN_PROGRESS,
  AWAITING_APPROVAL,
  APPROVED,
  REJECTED
}
