import { useMemo, useState } from 'react';
import moment from 'moment/moment';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';
import {
  DateRange,
  DateRangeType,
  ListViewType,
  ThemableBoxType
} from 'src/types';
import { DatePickerModal } from './DatePickerModal';
import { DatePicker } from '@mui/x-date-pickers';

export type DateRangeControlProps = {
  dateRange: DateRange;
  dateRangeType: DateRangeType;
  listViewType: ListViewType;
  onNextDateRange: () => void;
  onPreviousDateRange: () => void;
  onSetDateRange?: (dateInRange: Date) => void;
  setDateRange?: (dateRange: DateRange) => void;
};

export const DateRangeControl = ({
  dateRange,
  dateRangeType,
  listViewType,
  onNextDateRange,
  onPreviousDateRange,
  onSetDateRange,
  setDateRange
}: DateRangeControlProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const MAX_YEARS = 20;
  const MIN_DATE = moment().subtract(MAX_YEARS, 'year').toDate();
  const MAX_DATE = moment().add(MAX_YEARS, 'year').toDate();
  const dateText = useMemo(() => {
    const fromDateMoment = moment(dateRange.fromDate);

    switch (dateRangeType) {
      case DateRangeType.YEAR: {
        return fromDateMoment.format('YYYY');
      }

      case DateRangeType.MONTH: {
        return fromDateMoment.format('MMMM YYYY');
      }

      case DateRangeType.WEEK: {
        const toDateMoment = moment(dateRange.toDate);
        const isSameMonth =
          fromDateMoment.get('month') === toDateMoment.get('month');
        const isSameYear =
          fromDateMoment.get('year') === toDateMoment.get('year');
        const fromDateFormat = `DD${isSameMonth ? '' : ' MMM'}${
          isSameYear ? '' : ' YYYY'
        }`;
        const toDateFormat = 'DD MMM YYYY';

        return `${fromDateMoment.format(
          fromDateFormat
        )} - ${toDateMoment.format(toDateFormat)}`;
      }
      case DateRangeType.DAY:
        return fromDateMoment.format('DD MMM YYYY');
      default:
        throw new Error('unknown date range type');
    }
  }, [dateRange, dateRangeType]);

  return (
    <ThemableBox type={ThemableBoxType.DATE_RANGE_CONTROL}>
      {listViewType === ListViewType.CALENDAR && (
        <>
          {onSetDateRange && (
            <DatePickerModal
              show={showModal}
              dateRange={dateRange}
              onClose={() => setShowModal(false)}
              dateRangeType={dateRangeType}
              onSetDateRange={onSetDateRange}
            />
          )}
          <Stack direction="row">
            <Box>
              <Typography noWrap onClick={() => setShowModal(true)}>
                {dateText}
              </Typography>
            </Box>

            <IconButton size="small" onClick={onPreviousDateRange}>
              <KeyboardArrowLeftIcon fontSize="inherit" />
            </IconButton>

            <IconButton size="small" onClick={onNextDateRange}>
              <KeyboardArrowRightIcon fontSize="inherit" />
            </IconButton>
          </Stack>
        </>
      )}
      {listViewType === ListViewType.TABLE && (
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Start Date"
            onChange={function (
              value: Date | null,
            ): void {
              setDateRange && setDateRange({
                fromDate: value ? value: new Date(),
                toDate: dateRange.toDate
              });
            }}
            minDate={MIN_DATE}
            maxDate={dateRange.toDate}
            value={dateRange.fromDate}
            renderInput={(params) => <TextField {...params} />}
          />
          <DatePicker
            label="End Date"
            onChange={function (
              value: Date | null
            ): void {
              setDateRange && setDateRange({
                fromDate: dateRange.fromDate,
                toDate: value ? value: new Date()
              });
            }}
            minDate={dateRange.fromDate}
            maxDate={MAX_DATE}
            value={dateRange.toDate}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
      )}
    </ThemableBox>
  );
};
