import { useCallback } from 'react';
import {
  getDateFromDtoDateString,
  getDtoDateStringFromDate,
  useAxiosInstance
} from 'src/lib';
import {
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO,
  ThemePlacement,
  ThemePlacementDTO,
} from 'src/types';

export type DownloadThemePlacementsParams = {
  abortController: AbortController,
  moduleScheduleId: number, 
  themeSchedules: number[], 
  startDate: Date, 
  endDate: Date
}
export interface IThemePlacementsService {
  getThemePlacements: GetAPI<ThemePlacement>;

  getThemePlacement: (
    themePlacementId: number,
    abortController?: AbortController
  ) => Promise<ThemePlacement>;

  getIsEvents: (
    startDate: Date,
    endDate: Date
  ) => Promise<boolean>;

  createThemePlacement: (
    themePlacement: ThemePlacement,
    abortController?: AbortController
  ) => Promise<void>;

  
  downloadThemePlacements: (params: DownloadThemePlacementsParams) => Promise<Blob> ;

  /**
   * This service function returns a {@link ThemePlacement} as the backend
   * may assign a new value to the {@link ThemePlacement.id} field.
   * */
  updateThemePlacement: (
    themePlacement: ThemePlacement,
    abortController?: AbortController
  ) => Promise<ThemePlacement>;

  deleteThemePlacement: (
    themePlacementId: number,
    abortController?: AbortController
  ) => Promise<void>;
}

export const useThemePlacementsService = (): IThemePlacementsService => {
  const themePlacementsApi = useAxiosInstance('/v2/themePlacements');

  const getThemePlacements = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<ThemePlacement>> => {
      return themePlacementsApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController
            ? request.abortController.signal
            : undefined
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<ThemePlacementDTO>;

          return {
            items: content.map(getThemePlacementFromDto),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<ThemePlacement>;
        });
    },
    [themePlacementsApi]
  );
  const getThemePlacement = useCallback(
    (
      themePlacementId: number,
      abortController?: AbortController
    ): Promise<ThemePlacement> => {
      return themePlacementsApi
        .get(`${themePlacementId}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then((response) =>
          getThemePlacementFromDto(response.data as ThemePlacementDTO)
        );
    },
    [themePlacementsApi]
  );

  const getIsEvents = useCallback(
    (
      startDate: Date,
      endDate: Date
    ): Promise<boolean> => {
      return themePlacementsApi
        .get('/checkEventConflict', {
          withCredentials: true,
          params: {
            startDateTime: getDtoDateStringFromDate(startDate),
            endDateTime: getDtoDateStringFromDate(endDate)
          }
        }).then((result)=> JSON.parse(String(result.request.response)) as boolean);
    },
    [themePlacementsApi]
  );

  const createThemePlacement = useCallback(
    (
      themePlacement: ThemePlacement,
      abortController?: AbortController
    ): Promise<void> => {
      return themePlacementsApi.post(
        '',
        getDtoFromThemePlacement(themePlacement),
        {
          withCredentials: true,
          signal: abortController?.signal
        }
      );
    },
    [themePlacementsApi]
  );

  const updateThemePlacement = useCallback(
    (
      themePlacement: ThemePlacement,
      abortController?: AbortController
    ): Promise<ThemePlacement> => {
      return themePlacementsApi
        .put(
          themePlacement.id.toString(),
          getDtoFromThemePlacement(themePlacement),
          {
            withCredentials: true,
            signal: abortController?.signal
          }
        )
        .then((response) =>
          getThemePlacementFromDto(response.data as ThemePlacementDTO)
        );
    },
    [themePlacementsApi]
  );

  const deleteThemePlacement = useCallback(
    (
      themePlacementId: number,
      abortController?: AbortController
    ): Promise<void> => {
      return themePlacementsApi.delete(themePlacementId.toString(), {
        withCredentials: true,
        signal: abortController?.signal
      });
    },
    [themePlacementsApi]
  );

  const downloadThemePlacements = useCallback(
    (
      downloadParams: DownloadThemePlacementsParams
    ): Promise<Blob> => {
    const {
      abortController,
      startDate,
      endDate,
      moduleScheduleId,
      themeSchedules
    } = downloadParams;
    return themePlacementsApi
      .get('download', {
        withCredentials: true,
        signal: abortController ? abortController.signal : undefined,
        params: {
          startDate: getDtoDateStringFromDate(startDate),
          endDate: getDtoDateStringFromDate(endDate),
          moduleScheduleId : moduleScheduleId,
          themeScheduleIds : themeSchedules
        },
        responseType: 'blob'
      })
      .then((response) => {
        const type = response.headers['content-type'];
        return new Blob([response.data] as BlobPart[], { type });
      });
  },[themePlacementsApi]);

  return {
    downloadThemePlacements,
    getThemePlacements,
    getThemePlacement,
    createThemePlacement,
    updateThemePlacement,
    deleteThemePlacement,
    getIsEvents
  };

  function getThemePlacementFromDto(
    themePlacementDto: ThemePlacementDTO
  ): ThemePlacement {
    return {
      ...themePlacementDto,
      startDateTime: getDateFromDtoDateString(themePlacementDto.startDateTime),
      endDateTime: getDateFromDtoDateString(themePlacementDto.endDateTime)
    };
  }

  function getDtoFromThemePlacement(
    themePlacement: ThemePlacement
  ): ThemePlacementDTO {
    return {
      ...themePlacement,
      startDateTime: getDtoDateStringFromDate(themePlacement.startDateTime),
      endDateTime: getDtoDateStringFromDate(themePlacement.endDateTime)
    };
  }
};
