import React, { FC, ReactNode } from 'react';
import { Box } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common/themable-box/ThemableBox';

export type FormOuterLayoutProps = {
  formElements: ReactNode;
  formFooter: ReactNode;
};

const CLASS_NAMES = {
  CONTENT: 'content',
  BELOW_CONTENT_AREA: 'below-content-area',
  FOOTER: 'footer'
};

export const FormOuterLayout: FC<FormOuterLayoutProps> = ({
  formElements,
  formFooter
}) => {
  return (
    <ThemableBox type={ThemableBoxType.FORM_LAYOUT}>
      <Box className={CLASS_NAMES.CONTENT}>{formElements}</Box>
      <Box className={CLASS_NAMES.BELOW_CONTENT_AREA} />
      <Box className={CLASS_NAMES.FOOTER}>{formFooter}</Box>
    </ThemableBox>
  );
};
