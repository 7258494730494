import { useMemo } from 'react';
import { DateRange, GetAPI, RoomBooking, RoomBookingType } from 'src/types';
import { RoomBookingParams } from 'src/services';
import { Filters } from '../../common/components';
import { useBookings, UseBookingsProps } from '../../common/hooks';

export type BookingsLoaderProps = {
  filters: Filters;
  dateRange: DateRange;
  getRoomBookings: GetAPI<RoomBooking, RoomBookingParams>;
  children: (
    isBookingsLoading: boolean,
    bookings?: RoomBooking[]
  ) => JSX.Element;
  bookingType: RoomBookingType;
  facilityId?: number;
  date?: Date;
};

export function BookingLoader(props: BookingsLoaderProps) {
  const useBookingsProps = useMemo(() => {
    const bookingsProps: UseBookingsProps = {
      filters: props.filters,
      dateRange: props.dateRange,
      getRoomBookings: props.getRoomBookings,
      isApproverViewEnabled: true,
      facilityId: props.facilityId,
      date: props.date,
      bookingType: props.bookingType
    };
    return bookingsProps;
  }, [props]);

  const { bookings, isBookingsLoading } = useBookings(useBookingsProps);

  return props.children(isBookingsLoading, bookings);
}
