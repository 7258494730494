import { Grid } from '@mui/material';
import { ReactElement } from 'react';

export type ListViewActionsLayoutProps = {
  primaryActions?: ReactElement[];
  secondaryActions?: ReactElement[];
  search?: ReactElement;
};

export const ListViewActionsLayout = ({
  primaryActions,
  secondaryActions,
  search
}: ListViewActionsLayoutProps) => {
  return (
    <Grid container spacing={1} justifyContent="space-between">
      {
        <Grid container item spacing={1} xs>
          {primaryActions?.map((primaryAction) => {
            return (
              <Grid item xs="auto">
                {primaryAction}
              </Grid>
            );
          })}
          {secondaryActions?.map((secondaryAction) => {
            return (
              <Grid item xs="auto">
                {secondaryAction}
              </Grid>
            );
          })}
        </Grid>
      }
      {search && <Grid item>{search}</Grid>}
    </Grid>
  );
};
