import { DaysOfWeekChoices } from 'src/lib';
import { Weekday, WeekdayDTO } from 'src/types';

export function getWeekdaysFromDto(weekdayDto: WeekdayDTO): Weekday[] {
  return DaysOfWeekChoices.slice()
    .reverse()
    .reduce((daysArrAcc, day) => {
      if (weekdayDto / day.id >= 1) {
        weekdayDto = weekdayDto % day.id;
        return daysArrAcc.concat([day.name]);
      } else {
        return daysArrAcc;
      }
    }, [] as Weekday[]);
}
