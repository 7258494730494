import { useCallback } from 'react';
import { useAxiosInstance } from 'src/lib';
import { AccreditationDTO, AccreditationStatusDTO } from 'src/types/dto';
import {
  Accreditation,
  AccreditationStatus,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';
import moment from 'moment/moment';
import { getAccreditationFromDTO } from 'src/services/dto-mappers';

export type AccreditationCreateOrUpdatePayload = Omit<
  Accreditation,
  'id' | 'createdByUser'
>;

export type AccreditationCreatePayloadDTO = Omit<
  AccreditationDTO,
  'id' | 'createdUserId'
>;

export interface IAccreditationService {
  getAccreditations: GetAPI<Accreditation>;
  getAccreditation: (id: number) => Promise<Accreditation>;
  createAccreditation: (
    accreditation: AccreditationCreateOrUpdatePayload
  ) => Promise<void>;
  updateAccreditation: (
    id: number,
    accreditation: Accreditation
  ) => Promise<void>;
  deleteAccreditation: (
    id: number,
    abortController?: AbortController
  ) => Promise<void>;
}
function getDtoFromAccreditation(
  accred: AccreditationCreateOrUpdatePayload
): AccreditationCreatePayloadDTO {
  return {
    ...accred,
    facilityType: accred.facilityType,
    facilityId: accred.facilityId,
    validTo: moment(accred.validTo).toISOString(),
    validFrom: moment(accred.validFrom).toISOString(),
    dateCreated: moment(new Date()).toISOString(),
    status: getDtoFromAccreditationStatus(accred.status)
  };
}

export const useAccreditationService = (): IAccreditationService => {
  const accreditationsApi = useAxiosInstance('/v2/accreditations');

  const getAccreditations = useCallback(
    (
      request: PaginatedRequest,
      abortController?: AbortController
    ): Promise<PaginatedResponse<Accreditation>> => {
      return accreditationsApi
        .get('', {
          params: request,
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) => {
          const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<AccreditationDTO>;

          return {
            items: content.map<Accreditation>((accredDTO) =>
              getAccreditationFromDTO(accredDTO)
            ),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Accreditation>;
        })
        .catch((error) => {
          throw error;
        });
    },
    [accreditationsApi]
  );

  const createAccreditation = useCallback(
    (
      accreditation: AccreditationCreateOrUpdatePayload,
      abortController?: AbortController
    ): Promise<void> =>
      accreditationsApi.post('', getDtoFromAccreditation(accreditation), {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [accreditationsApi]
  );

  const updateAccreditation = useCallback(
    (
      id: number,
      accreditation: Accreditation,
      abortController?: AbortController
    ): Promise<void> =>
      accreditationsApi.put(`${id}`, getDtoFromAccreditation(accreditation), {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [accreditationsApi]
  );

  const getAccreditation = useCallback(
    (id: number, abortController?: AbortController): Promise<Accreditation> =>
      accreditationsApi
        .get(`${id}`, {
          withCredentials: true,
          signal: abortController?.signal
        })
        .then((response) =>
          getAccreditationFromDTO(response.data as AccreditationDTO)
        )
        .catch((error) => {
          throw error;
        }),
    [accreditationsApi]
  );

  const deleteAccreditation = useCallback(
    (id: number, abortController?: AbortController): Promise<void> =>
      accreditationsApi.delete(`${id}`, {
        withCredentials: true,
        signal: abortController?.signal
      }),
    [accreditationsApi]
  );

  return {
    getAccreditation,
    getAccreditations,
    createAccreditation,
    updateAccreditation,
    deleteAccreditation
  };
};

export function getDtoFromAccreditationStatus(
  accreditationStatus: AccreditationStatus
): string {
  switch (accreditationStatus) {
    case AccreditationStatus.NONE:
      return AccreditationStatusDTO.NONE;
    case AccreditationStatus.ACCREDITED_VALID:
      return AccreditationStatusDTO.ACCREDITED_VALID;
    case AccreditationStatus.PENDING_APPROVAL:
      return AccreditationStatusDTO.PENDING_APPROVAL;
    case AccreditationStatus.ACCREDITED_EXPIRED:
      return AccreditationStatusDTO.ACCREDITED_EXPIRED;
    case AccreditationStatus.ACCREDITED_REVOKED:
      return AccreditationStatusDTO.ACCREDITED_REVOKED;
    default:
      throw new Error('unknown accreditation status');
  }
}
