import * as React from 'react';
import Button from '@mui/material/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { FC } from 'react';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { ThemableBoxType } from 'src/types';
import { BorderBox, ThemableBox, useTableContext } from 'src/common';
import { useClearFilterParams } from 'src/common-v2';

export type ListViewFilterProps = {
  title: string;
  filterCount: number;
  clearAllFilters?: () => void;
};

export const ListViewFilter: FC<ListViewFilterProps> = ({
  children,
  title,
  filterCount,
  clearAllFilters
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { clearFilterParameters } = useClearFilterParams();
  const { filters } = useTableContext();

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const handleClearFilters = () => {
    if (clearAllFilters) {
      clearAllFilters();
    } else {
      clearFilterParameters();
    }
  };

  return (
    <>
      {!isOpen && (
        <Button
          variant="contained"
          disableElevation
          onClick={toggleDrawer}
          endIcon={<KeyboardArrowRightIcon />}
        >
          {`Filters: ${
            Object.values(filters).filter((value) => value !== undefined)
              .length > 0
              ? Object.values(filters).filter((value) => value !== undefined)
                  .length.toString() + ' /'
              : ''
          } ${Object.keys(filters).length > 12 ? '12+' : filterCount}`}
        </Button>
      )}
      {isOpen && (
        <BorderBox variant="generic">
          <ThemableBox type={ThemableBoxType.OPENED_LIST_VIEW_FILTER_CONTAINER}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="h5">Filters</Typography>
              </Grid>
              <Grid item xs="auto">
                <IconButton onClick={toggleDrawer}>
                  <KeyboardArrowDownIcon />
                </IconButton>
              </Grid>
            </Grid>
            <Stack spacing={2}>
              <Typography>{title}</Typography>
              <Stack spacing={1}>{children}</Stack>
              <Button
                variant="delete"
                onClick={() => {
                  handleClearFilters();
                  setIsOpen(false);
                }}
                fullWidth
              >
                Clear all Filters
              </Button>
            </Stack>
          </ThemableBox>
        </BorderBox>
      )}
    </>
  );
};
