import { PropsWithChildren } from 'react';
import { ThemableBoxType } from 'src/types';
import { ThemableBox } from 'src/common';

export type MinMaxWidthLayoutProps = {
  minWidth?: MinWidthConstant;
  maxWidth?: MaxWidthConstant;
};

export enum MaxWidthConstant {
  MAX_WIDTH_160PX = 1,
  MAX_WIDTH_120PX
}

export enum MinWidthConstant {
  MIN_WIDTH_200PX = 1,
  MIN_WIDTH_300PX,
  MIN_WIDTH_400PX
}

export const MinMaxWidthLayout = ({
  minWidth,
  maxWidth,
  children
}: PropsWithChildren<MinMaxWidthLayoutProps>) => {
  return (
    <ThemableBox
      type={ThemableBoxType.MIN_MAX_WIDTH_LAYOUT}
      className={getClassNameString(minWidth, maxWidth)}
    >
      {children}
    </ThemableBox>
  );
};

function getClassNameString(
  minWidth: MinWidthConstant | undefined,
  maxWidth: MaxWidthConstant | undefined
): string {
  return `${minWidth ? MinWidthConstant[minWidth].toString() : ''} ${
    maxWidth ? MaxWidthConstant[maxWidth].toString() : ''
  }`.trim();
}
