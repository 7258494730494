import React from 'react';
import { Divider, Stack } from '@mui/material';
import { SkeletonField } from './index';

export function FiltersSkeleton() {
  return (
    <Stack spacing={2}>
      <SkeletonField />
      <SkeletonField />
      <SkeletonField />
      <SkeletonField />
      <SkeletonField />

      <Divider />

      <SkeletonField />
      <SkeletonField />

      <Divider />

      <SkeletonField />
      <SkeletonField />
      <SkeletonField />
      <SkeletonField />
      <SkeletonField />
    </Stack>
  );
}
