import { Reducer } from 'react';
import { NotificationsConfig } from '../types';
import { NotificationConfigState } from '../enums';

export enum NotificationsListActionType {
  INITIALISE_NOTIFICATIONS,
  SET_COUNT_ALL,
  SET_COUNT_UNREAD,
  MARK_NOTIFICATION_AS_READ,
  MARK_NOTIFICATION_AS_COMPLETED,
  MARK_ALL_NOTIFICATIONS_AS_READ,
  MARK_ALL_NOTIFICATIONS_AS_COMPLETED,
  SET_IS_NOTIFICATION_UPDATING,
  SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING,
  SET_IS_FETCHING_NOTIFICATIONS,
  MULTIPLE_ACTIONS
}

export type InitialiseNotifications = {
  type: NotificationsListActionType.INITIALISE_NOTIFICATIONS;
  payload: {
    notifications?: NotificationsConfig[];
  };
};

export type SetCountAll = {
  type: NotificationsListActionType.SET_COUNT_ALL;
  payload: {
    count: number;
  };
};

export type SetCountUnread = {
  type: NotificationsListActionType.SET_COUNT_UNREAD;
  payload: {
    count: number;
  };
};

export type MarkNotificationAsRead = {
  type: NotificationsListActionType.MARK_NOTIFICATION_AS_READ;
  payload: {
    notificationId: number;
  };
};

export type MarkNotificationAsComplete = {
  type: NotificationsListActionType.MARK_NOTIFICATION_AS_COMPLETED;
  payload: {
    notificationId: number;
  };
};

export type MarkAllNotificationsAsRead = {
  type: NotificationsListActionType.MARK_ALL_NOTIFICATIONS_AS_READ;
};

export type MarkAllNotificationAsCompleted = {
  type: NotificationsListActionType.MARK_ALL_NOTIFICATIONS_AS_COMPLETED;
};

export type MultipleActions = {
  type: NotificationsListActionType.MULTIPLE_ACTIONS;
  payload: NotificationsListAction[];
};

export type SetIsNotificationUpdating = {
  type: NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING;
  payload: {
    notificationId: number;
    isUpdating: boolean;
  };
};

export type SetIsMultipleNotificationsUpdating = {
  type: NotificationsListActionType.SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING;
  payload: {
    notificationIds: number[];
    isUpdating: boolean;
  };
};

export type SetIsFetchingNotifications = {
  type: NotificationsListActionType.SET_IS_FETCHING_NOTIFICATIONS;
  payload: {
    isFetching: boolean;
  };
};

export type NotificationsListAction =
  | InitialiseNotifications
  | SetCountAll
  | SetCountUnread
  | MarkNotificationAsRead
  | MarkNotificationAsComplete
  | MultipleActions
  | SetIsNotificationUpdating
  | MarkAllNotificationsAsRead
  | MarkAllNotificationAsCompleted
  | SetIsMultipleNotificationsUpdating
  | SetIsFetchingNotifications;

export type NotificationsListState = {
  notifications?: NotificationsConfig[];
  isFetchingNotifications?: boolean;
  totalAllNotifications?: number;
  totalUnreadNotifications?: number;
};

export const getReducer: () => Reducer<
  NotificationsListState,
  NotificationsListAction
> = () => (state: NotificationsListState, action: NotificationsListAction) => {
  function getNextState(
    state: NotificationsListState,
    action: NotificationsListAction
  ): NotificationsListState {
    switch (action.type) {
      case NotificationsListActionType.INITIALISE_NOTIFICATIONS: {
        const { notifications } = action.payload;
        return {
          ...state,
          notifications: notifications
        };
      }

      case NotificationsListActionType.SET_COUNT_ALL: {
        const { count } = action.payload;
        return {
          ...state,
          totalAllNotifications: count
        };
      }

      case NotificationsListActionType.SET_COUNT_UNREAD: {
        const { count } = action.payload;
        return {
          ...state,
          totalUnreadNotifications: count
        };
      }

      case NotificationsListActionType.MARK_NOTIFICATION_AS_READ: {
        const { notificationId } = action.payload;

        if (!state.notifications) {
          throw new Error('notifications not populated');
        }

        const notificationToUpdateIndex = state.notifications.findIndex(
          (notificationConfig) =>
            notificationConfig.notificationId === notificationId
        );
        const notificationToUpdate = state.notifications.at(
          notificationToUpdateIndex
        );

        if (notificationToUpdateIndex === -1 || !notificationToUpdate) {
          throw new Error('notification not found');
        }

        if (
          notificationToUpdate.isUpdating ||
          notificationToUpdate.state !== NotificationConfigState.UNREAD
        ) {
          return state;
        }

        if (!state.totalUnreadNotifications) {
          throw new Error(
            'total number of notifications expected to be non-zero'
          );
        }

        return {
          ...state,
          notifications: Object.assign([...state.notifications], {
            [notificationToUpdateIndex]: {
              ...notificationToUpdate,
              state: NotificationConfigState.READ
            }
          }),
          totalUnreadNotifications: state.totalUnreadNotifications - 1
        };
      }

      case NotificationsListActionType.MARK_NOTIFICATION_AS_COMPLETED: {
        const { notificationId } = action.payload;

        if (!state.notifications) {
          throw new Error('notifications not populated');
        }

        const notificationToUpdateIndex = state.notifications.findIndex(
          (notificationConfig) =>
            notificationConfig.notificationId === notificationId
        );
        const notificationToUpdate = state.notifications.at(
          notificationToUpdateIndex
        );

        if (notificationToUpdateIndex === -1 || !notificationToUpdate) {
          throw new Error('notification not found');
        }

        if (
          notificationToUpdate.isUpdating ||
          notificationToUpdate.state !== NotificationConfigState.READ
        ) {
          return state;
        }

        if (!state.totalAllNotifications) {
          throw new Error(
            'total number of notifications expected to be non-zero'
          );
        }

        return {
          ...state,
          notifications: Object.assign([...state.notifications], {
            [notificationToUpdateIndex]: {
              ...notificationToUpdate,
              state: NotificationConfigState.COMPLETED
            }
          }),
          totalAllNotifications: state.totalAllNotifications - 1
        };
      }

      case NotificationsListActionType.SET_IS_NOTIFICATION_UPDATING: {
        const { notificationId, isUpdating } = action.payload;

        if (!state.notifications) {
          throw new Error('notifications not populated');
        }

        const notificationToUpdateIndex = state.notifications.findIndex(
          (notificationConfig) =>
            notificationConfig.notificationId === notificationId
        );
        const notificationToUpdate = state.notifications.at(
          notificationToUpdateIndex
        );

        if (notificationToUpdateIndex === -1 || !notificationToUpdate) {
          throw new Error('notification not found');
        }

        return {
          ...state,
          notifications: Object.assign([...state.notifications], {
            [notificationToUpdateIndex]: {
              ...notificationToUpdate,
              isUpdating: isUpdating
            }
          })
        };
      }

      case NotificationsListActionType.SET_IS_MULTIPLE_NOTIFICATIONS_UPDATING: {
        const { notificationIds, isUpdating } = action.payload;
        return {
          ...state,
          notifications: state.notifications
            ? state.notifications.map((notificationConfig) => {
                return {
                  ...notificationConfig,
                  isUpdating: notificationIds.includes(
                    notificationConfig.notificationId
                  )
                    ? isUpdating
                    : notificationConfig.isUpdating
                };
              })
            : state.notifications
        };
      }

      case NotificationsListActionType.MARK_ALL_NOTIFICATIONS_AS_READ: {
        return {
          ...state,
          notifications: state.notifications
            ? state.notifications.map((notificationConfig) => {
                return {
                  ...notificationConfig,
                  state:
                    notificationConfig.state === NotificationConfigState.UNREAD
                      ? NotificationConfigState.READ
                      : notificationConfig.state
                };
              })
            : state.notifications
        };
      }

      case NotificationsListActionType.MARK_ALL_NOTIFICATIONS_AS_COMPLETED: {
        return {
          ...state,
          notifications: state.notifications
            ? state.notifications.map((notificationConfig) => {
                return {
                  ...notificationConfig,
                  state: NotificationConfigState.COMPLETED
                };
              })
            : state.notifications
        };
      }

      case NotificationsListActionType.SET_IS_FETCHING_NOTIFICATIONS: {
        const { isFetching } = action.payload;
        return {
          ...state,
          isFetchingNotifications: isFetching
        };
      }

      default:
        return state;
    }
  }

  switch (action.type) {
    case NotificationsListActionType.MULTIPLE_ACTIONS:
      return action.payload.reduce((finalResult, action) => {
        return getNextState(finalResult, action);
      }, state);

    default:
      return getNextState(state, action);
  }
};
