import { ToggleButton, ToggleButtonGroup } from '@mui/material';

export type TogglePickerProps = {
  leftLabel: string;
  rightLabel: string;
  selectedToggle: 'right' | 'left';
  onToggleChange: () => void;
};

/**
 * TogglePicker is a 2-variable toggle button, styled after the ToggleButtonGroup
 * element. The selected toggle is set to either 'left' or 'right'.
 */
export const TogglePicker = ({
  leftLabel,
  rightLabel,
  onToggleChange,
  selectedToggle = 'left'
}: TogglePickerProps) => {
  return (
    <ToggleButtonGroup
      exclusive
      value={selectedToggle}
      onChange={(event) => {
        onToggleChange();
      }}
    >
      <ToggleButton value="left" color="primary" size="small">{leftLabel}</ToggleButton>
      <ToggleButton value="right" color="primary" size="small">{rightLabel}</ToggleButton>
    </ToggleButtonGroup>
  );
};
