import {
    GetAPI,
    PaginatedRequest,
    PaginatedResponse,
    PaginatedResponseDTO,
    allStudentAttentionsType,
    StudentAccommodation,
  } from '../types';
  import { useCallback } from 'react';
  import { getDateFromDtoDateString, useAxiosInstance } from 'src/lib';
import { allStudentAttentionsTypeDTO, StudentAccommodationDTO } from 'src/types/dto';
import { getRoomBookingTypeFromString } from 'src/modules/bookings/bookings/common/lib';
  
  export interface IStudentsEndService {
    getStudentAttention: GetAPI<allStudentAttentionsType>;
    getStudentAccommodation: GetAPI<StudentAccommodation>;
  }
  
  export const useStudentEndService = (): IStudentsEndService => {

    const studentsApi = useAxiosInstance('/v2');
  
    const getStudentAttention = useCallback(
      (request: PaginatedRequest): Promise<PaginatedResponse<allStudentAttentionsType>> => {
        return studentsApi
          .get('studentAttendance', {
            params: request,
            withCredentials: true,
            signal: request?.abortController?.signal
          })
          .then(response => {
            const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<allStudentAttentionsTypeDTO>;
            return {
              items: content.map((allStudentAttentionsTypeDTO) => getStudentAttentionFromDTO(allStudentAttentionsTypeDTO)),
              hasNextPage: !last,
              hasPrevPage: !first,
              pageNumber: number,
              totalCount: totalElements,
              totalPages: totalPages
            } as PaginatedResponse<allStudentAttentionsType>;

          });
      },
      [studentsApi]
    );
  
    const getStudentAccommodation = useCallback(
        (request: PaginatedRequest): Promise<PaginatedResponse<StudentAccommodation>> => {
        return studentsApi
        .get('studentAccommodation', {
            params: request,
            withCredentials: true,
            signal: request?.abortController?.signal
          })
          .then(response => {
            const { content, first, last, number, totalElements, totalPages } =
            response.data as PaginatedResponseDTO<StudentAccommodationDTO>;

          return {
            items: content.map((studentAccommodationDTO) => getstudentAcommodationFromDTO(studentAccommodationDTO)),
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<StudentAccommodation>;
        });
      },
      [studentsApi]
    );
  
    return { getStudentAttention, getStudentAccommodation };
  };

  function getStudentAttentionFromDTO(allStudentAttentionsTypeDTO: allStudentAttentionsTypeDTO): allStudentAttentionsType {
    const { startDate, endDate, ...rest } = allStudentAttentionsTypeDTO;

    return {
      ...rest,
      startDate: getDateFromDtoDateString(startDate),
      endDate: getDateFromDtoDateString(endDate)
    };
  };

  function getstudentAcommodationFromDTO(studentAccommodationDTO: StudentAccommodationDTO): StudentAccommodation {
    const { startDateTime, endDateTime, roomBookingType, ...rest } = studentAccommodationDTO;
    
    return {
      ...rest,
      startDateTime: startDateTime ? getDateFromDtoDateString(startDateTime) : undefined,
      endDateTime: endDateTime ? getDateFromDtoDateString(endDateTime) : undefined,
      roomBookingType: getRoomBookingTypeFromString(roomBookingType)
    };
  };

