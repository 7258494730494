import { useCallback } from 'react';
import { useAxiosInstance } from 'src/lib';
import {
  Department,
  DepartmentDTO,
  GetAPI,
  PaginatedRequest,
  PaginatedResponse,
  PaginatedResponseDTO
} from 'src/types';

export interface IDepartmentsService {
  getDepartments: GetAPI<Department>;
  getDepartment: (
    id: number,
    abortController?: AbortController
  ) => Promise<Department>;
}

export const useDepartmentService = (): IDepartmentsService => {

  const departmentAPI = useAxiosInstance('/api/v2/departments');

  const getDepartments = useCallback(
    (request: PaginatedRequest): Promise<PaginatedResponse<Department>> => {
      return departmentAPI
        .get('', {
          params: request,
          withCredentials: true,
          signal: request.abortController?.signal
        })
        .then(response => {
          const {
            first,
            last,
            number,
            totalElements,
            totalPages
          } = response.data as PaginatedResponseDTO<DepartmentDTO>;

          const departments = response.data._embedded
            .departmentDToes.map((dto: DepartmentDTO) => {
              return getDepartmentFromDto(dto);
            }) as Department[];

          return {
            items: departments,
            hasNextPage: !last,
            hasPrevPage: !first,
            pageNumber: number,
            totalCount: totalElements,
            totalPages: totalPages
          } as PaginatedResponse<Department>;
        });
    },
    [departmentAPI]
  );

  const getDepartment = useCallback(
    (id: number, abortController?: AbortController): Promise<Department> => {
      return departmentAPI
        .get(`${id}`, {
          withCredentials: true,
          signal: abortController ? abortController.signal : undefined
        })
        .then(response => getDepartmentFromDto(response.data as DepartmentDTO));
    },
    [departmentAPI]
  );

  return { getDepartments, getDepartment };
};

function getDepartmentFromDto(
  departmentDto: DepartmentDTO
): Department {

  return {
    ...departmentDto
  };
}
