import React, { useState } from 'react';
import { Button } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import XLSX from 'sheetjs-style';
import { saveAs } from 'file-saver';
import { useButtonDisabledDebounce } from 'src/common-v2';
import { useAppContext, useResponseHandler } from 'src/common';

type Props<T> = {
  fileName: string;
  data: T[];
};

function exportDataToExcel<T>(fileName: string, data: T[]) {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const ws = XLSX.utils.json_to_sheet(data);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const blob = new Blob([excelBuffer], { type: fileType });
  saveAs(blob, fileName + fileExtension);
}

export function ExcelExport<T>({ fileName, data }: Props<T>) {
  const { handleServiceCall } = useResponseHandler();
  const { buttonDisabledDebounce } = useButtonDisabledDebounce();
  const { handleError } = useAppContext();
  const [isDownloadButtonDebouncing, setIsDownloadButtonDebouncing] =
    useState(false);

  const handleExportClick = () => {
    buttonDisabledDebounce(setIsDownloadButtonDebouncing);
    handleServiceCall({
      serviceCall: () => {
        return Promise.resolve(exportDataToExcel(fileName, data)).catch(
          handleError
        );
      },
      successProps: {
        title: 'Starting download...',
        description: 'Download starting...'
      },
      failureProps: {
        title: 'File download failed'
      }
    });
  };

  return (
    <Button
      onClick={() => handleExportClick()}
      startIcon={<DownloadIcon />}
      disabled={isDownloadButtonDebouncing}
      variant="outlined"
    >
      Download
    </Button>
  );
}
