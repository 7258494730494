import { FormAnswerDTO, FormCommentDTO } from '.';

export type FormSubmissionDTO = {
  id: number;
  placementScheduleId: number;
  title: string;
  description: string;
  formId: number;
  userDisplayName: string;
  formAnswers: FormAnswerDTO[];
  formComments: FormCommentDTO[];
  state: string;
};

export const FormSubmissionStateDTO = {
  AWAITING_APPROVAL: 'AWAITING_APPROVAL',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  IN_PROGRESS: 'IN_PROGRESS',
};
