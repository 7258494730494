import { downloadFile } from 'src/lib';
import { useResponseHandler } from 'src/common';
import { useFilesService } from 'src/services';
import { useState } from 'react';

interface IUseFileView {
  filePreviewImageSource?: string;
  setFilePreviewImageSource: (source?: string) => void;
  handleFileDownload: (fileName: string, fileId: number) => void;
  handleFilePreview: (fileId: number) => void;
}

export const useFileView = (): IUseFileView => {
  const { handleServiceCall } = useResponseHandler();
  const { downloadFileBlob, getFilePreviewImageSource } = useFilesService();
  const [filePreviewImageSource, setFilePreviewImageSource] = useState<
    string | undefined
  >();

  const handleFileDownload = (fileName: string, fileId: number): void => {
    handleServiceCall({
      failureProps: { title: 'Failed to download the file' },
      successProps: { description: '', title: 'The file has been downloaded' },
      serviceCall: () =>
        downloadFileBlob(fileId).then(fileBlob => {
          fileBlob && downloadFile(fileBlob, fileName);
        })
    });
  };

  const handleFilePreview = (fileId: number) => {
    setFilePreviewImageSource(getFilePreviewImageSource(fileId));
  };

  return {
    filePreviewImageSource,
    setFilePreviewImageSource,
    handleFilePreview,
    handleFileDownload
  };
};
