import { useCallback, useMemo } from 'react';
import {
  StudentGroup,
  StudentGroupDTO,
  StudentGroupValidationDTO,
  ThemeAutomationValidationResult,
  ThemeAutomationValidationResultDTO,
  ThemeSchedule,
  ThemeScheduleDTO,
  ValidationType,
  ValidationTypeDTO
} from 'src/types';
import { useAxiosInstance } from 'src/lib';

export interface IThemeAutomationValidationService {
  getStudentGroupsValidation: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationValidationResult>;

  getClinicalThemeSchedulesValidation: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationValidationResult>;

  getThemeSchedulesMatchValidation: (
    moduleScheduleId: number,
    connectedModuleScheduleId?: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationValidationResult>;

  getGroupNamesMatchValidation: (
    moduleScheduleId: number,
    connectedModuleScheduleId?: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationValidationResult>;

  getMinRequiredWeeksAgainstAvailableValidation: (
    moduleScheduleId?: number,
    connectedModuleScheduleId?: number,
    numAvailableWeeks?: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationValidationResult>;

  getAreaCapacitiesValidation: (
    moduleScheduleId: number,
    abortController?: AbortController
  ) => Promise<ThemeAutomationValidationResult>;
}

export const useThemeAutomationValidationService =
  (): IThemeAutomationValidationService => {
    const themeAutomationValidationApi = useAxiosInstance(
      '/v2/themesAutomationValidation'
    );

    const getStudentGroupsValidation = useCallback(
      (
        moduleScheduleId: number,
        abortController?: AbortController
      ): Promise<ThemeAutomationValidationResult> => {
        return themeAutomationValidationApi
          .get(`studentGroups/${moduleScheduleId.toString()}`, {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) =>
            getThemeAutomationValidationFromDto(
              response.data as StudentGroupValidationDTO
            )
          );
      },
      [themeAutomationValidationApi]
    );

    const getClinicalThemeSchedulesValidation = useCallback(
      (
        moduleScheduleId: number,
        abortController?: AbortController
      ): Promise<ThemeAutomationValidationResult> => {
        return themeAutomationValidationApi
          .get(`clinicalThemeSchedules/${moduleScheduleId.toString()}`, {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) =>
            getThemeAutomationValidationFromDto(
              response.data as StudentGroupValidationDTO
            )
          );
      },
      [themeAutomationValidationApi]
    );

    const getThemeSchedulesMatchValidation = useCallback(
      (
        moduleScheduleId: number,
        connectedModuleScheduleId?: number,
        abortController?: AbortController
      ): Promise<ThemeAutomationValidationResult> => {
        return themeAutomationValidationApi
          .get('themeSchedulesMatch', {
            params: getValidationRequestDto({
              moduleScheduleId,
              connectedModuleScheduleId
            }),
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) =>
            getThemeAutomationValidationFromDto(
              response.data as StudentGroupValidationDTO
            )
          );
      },
      [themeAutomationValidationApi]
    );

    const getGroupNamesMatchValidation = useCallback(
      (
        moduleScheduleId: number,
        connectedModuleScheduleId?: number,
        abortController?: AbortController
      ): Promise<ThemeAutomationValidationResult> => {
        return themeAutomationValidationApi
          .get('groupNamesMatch', {
            params: getValidationRequestDto({
              moduleScheduleId,
              connectedModuleScheduleId
            }),
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) =>
            getThemeAutomationValidationFromDto(
              response.data as StudentGroupValidationDTO
            )
          );
      },
      [themeAutomationValidationApi]
    );

    const getMinRequiredWeeksAgainstAvailableValidation = useCallback(
      (
        moduleScheduleId?: number,
        connectedModuleScheduleId?: number,
        numAvailableWeeks?: number,
        abortController?: AbortController
      ): Promise<ThemeAutomationValidationResult> => {
        return themeAutomationValidationApi
          .get('minRequiredWeeksAgainstAvailable', {
            params: getValidationRequestDto({
              moduleScheduleId,
              connectedModuleScheduleId,
              numAvailableWeeks
            }),
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) =>
            getThemeAutomationValidationFromDto(
              response.data as StudentGroupValidationDTO
            )
          );
      },
      [themeAutomationValidationApi]
    );

    const getAreaCapacitiesValidation = useCallback(
      (
        moduleScheduleId: number,
        abortController?: AbortController
      ): Promise<ThemeAutomationValidationResult> => {
        return themeAutomationValidationApi
          .get(`areaCapacities/${moduleScheduleId.toString()}`, {
            withCredentials: true,
            signal: abortController ? abortController.signal : undefined
          })
          .then((response) =>
            getThemeAutomationValidationFromDto(
              response.data as StudentGroupValidationDTO
            )
          );
      },
      [themeAutomationValidationApi]
    );

    const service = useMemo(() => {
      return {
        getStudentGroupsValidation,
        getClinicalThemeSchedulesValidation,
        getThemeSchedulesMatchValidation,
        getGroupNamesMatchValidation,
        getMinRequiredWeeksAgainstAvailableValidation,
        getAreaCapacitiesValidation
      };
    }, [
      getStudentGroupsValidation,
      getClinicalThemeSchedulesValidation,
      getThemeSchedulesMatchValidation,
      getGroupNamesMatchValidation,
      getMinRequiredWeeksAgainstAvailableValidation,
      getAreaCapacitiesValidation
    ]);

    return service;
  };

function getThemeAutomationValidationFromDto(
  themeAutomationValidationDto: ThemeAutomationValidationResultDTO
): ThemeAutomationValidationResult {
  switch (themeAutomationValidationDto.type) {
    case ValidationTypeDTO.STUDENT_GROUP_VALIDATION:
      return {
        ...themeAutomationValidationDto,
        isValid: themeAutomationValidationDto.valid,
        validationType: ValidationType.STUDENT_GROUP_VALIDATION,
        visualizeValidation:
          themeAutomationValidationDto.visualizeValidation.map(
            getStudentGroupFromDto
          )
      };

    case ValidationTypeDTO.CLINICAL_THEME_SCHEDULES_VALIDATION:
      return {
        ...themeAutomationValidationDto,
        isValid: themeAutomationValidationDto.valid,
        validationType: ValidationType.CLINICAL_THEME_SCHEDULES_VALIDATION,
        visualizeValidation:
          themeAutomationValidationDto.visualizeValidation.map(
            getThemeScheduleFromDto
          )
      };

    case ValidationTypeDTO.THEME_SCHEDULES_MATCH_VALIDATION:
      return {
        ...themeAutomationValidationDto,
        isValid: themeAutomationValidationDto.valid,
        validationType: ValidationType.THEME_SCHEDULES_MATCH_VALIDATION
      };

    case ValidationTypeDTO.GROUP_NAMES_MATCH_VALIDATION:
      return {
        ...themeAutomationValidationDto,
        isValid: themeAutomationValidationDto.valid,
        validationType: ValidationType.GROUP_NAMES_MATCH_VALIDATION
      };

    case ValidationTypeDTO.MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION:
      return {
        ...themeAutomationValidationDto,
        isValid: themeAutomationValidationDto.valid,
        validationType:
          ValidationType.MIN_REQUIRED_WEEKS_AGAINST_AVAILABLE_WEEKS_VALIDATION
      };

    case ValidationTypeDTO.AREA_CAPACITIES_VALIDATION:
      return {
        ...themeAutomationValidationDto,
        isValid: themeAutomationValidationDto.valid,
        validationType: ValidationType.AREA_CAPACITIES_VALIDATION,
        visualizeValidation:
          themeAutomationValidationDto.visualizeValidation.map(
            getThemeScheduleFromDto
          )
      };
  }
}

function getStudentGroupFromDto(
  studentGroupDto: StudentGroupDTO
): StudentGroup {
  return {
    ...studentGroupDto
  };
}

function getThemeScheduleFromDto(
  themeScheduleDto: ThemeScheduleDTO
): ThemeSchedule {
  return {
    ...themeScheduleDto
  };
}

type ValidationRequest = {
  moduleScheduleId?: number;
  connectedModuleScheduleId?: number;
  numAvailableWeeks?: number;
};

type ValidationRequestDTO = {
  moduleScheduleId?: string;
  connectedModuleScheduleId?: string;
  numAvailableWeeks?: string;
};

function getValidationRequestDto({
  moduleScheduleId,
  connectedModuleScheduleId,
  numAvailableWeeks
}: ValidationRequest): ValidationRequestDTO {
  const result: ValidationRequestDTO = {};

  if (moduleScheduleId !== undefined) {
    result.moduleScheduleId = moduleScheduleId.toString();
  }

  if (connectedModuleScheduleId !== undefined) {
    result.connectedModuleScheduleId = connectedModuleScheduleId.toString();
  }

  if (numAvailableWeeks !== undefined) {
    result.numAvailableWeeks = numAvailableWeeks.toString();
  }

  return result;
}
