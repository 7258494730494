import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Field } from 'formik';
import {
  AppIcon,
  AsyncAutocompleteV2,
  IconType,
  ShowIf,
  StaticAutocomplete,
  Table,
  TableType
} from 'src/common';
import {
  FormType,
  PlacementScheduleStudentDetails,
  TableRowData
} from 'src/types';
import { PlacementPlusVersion, StudentFormProps } from './form-types';
import { SkeletonField } from 'src/modules/bookings/bookings/common/components/SkeletonField';
import { DATE_TIME_FORMAT, getDisplayDateStringFromDate } from 'src/lib';
import { getDisplayStringForGender } from 'src/lib/formatting-utils';

const headers = [
  { id: 'student', label: 'Student' },
  { id: 'gender', label: 'Gender' },
  { id: 'hasTransport', label: 'Has Transport' },
  { id: 'vehicleRegistraition', label: 'Vehicle License' },
  { id: 'licenseExpiry', label: 'License Expiry' },
  { id: 'needsTransport', label: 'Needs Trans?' },
  { id: 'needsAccom', label: 'Needs Accom?' },
  { id: 'bookable', label: 'Bookable?' }
];

export function StudentsForm(studentsProps: StudentFormProps) {
  const { values } = studentsProps.formikHelpers;

  function fetchIndividualRows(
    students: PlacementScheduleStudentDetails[]
  ): Array<TableRowData> {
    if (students) {
      const rows: Array<TableRowData> = students.map(
        (student: PlacementScheduleStudentDetails, index) => {
          return {
            student: student.displayName,
            gender: getDisplayStringForGender(student.genderV2),
            hasTransport: <label>{student.hasOwnTransport.toString()}</label>,
            vehicleRegistraition: (
              <label>
                {student.carRegistration
                  ? student.carRegistration.toString()
                  : 'No Registration'}
              </label>
            ),
            licenseExpiry: (
              <label>
                {student.licenceExpiry
                  ? getDisplayDateStringFromDate(
                      student.licenceExpiry,
                      DATE_TIME_FORMAT.EXCEL_DATE_FORMAT
                    )
                  : 'No License'}
              </label>
            ),
            needsTransport: (
              <Field
                name={`individualStudents[${index}].needTransport`}
                disabled={true}
                type={
                  student.needTransport
                    ? IconType.CHECKBOX_CHECKED
                    : IconType.CHECKBOX_UNCHECKED
                }
                style="silver"
                as={AppIcon}
              />
            ),
            needsAccom: (
              <Field
                name={`individualStudents[${index}].needAccommodation`}
                type={
                  student.needAccommodation
                    ? IconType.CHECKBOX_CHECKED
                    : IconType.CHECKBOX_UNCHECKED
                }
                style={'silver'}
                disabled={true}
                as={AppIcon}
              />
            ),
            bookable: (
              <Field
                name={`individualStudents[${index}].bookable`}
                type={
                  student.bookable
                    ? IconType.CHECKBOX_CHECKED
                    : IconType.CHECKBOX_UNCHECKED
                }
                style={'silver'}
                disabled={true}
                as={AppIcon}
              />
            )
          } as TableRowData;
        }
      );
      return rows;
    } else {
      return new Array<TableRowData>();
    }
  }

  return (
    <Stack spacing={2}>
      <Stack sx={{ ml: 2, mt: 2 }}>
        <Typography variant="headingFourForm">Students</Typography>
      </Stack>
      <ShowIf condition={!studentsProps.isModuleStudentsLoading}>
        <Grid container spacing={2}>
          <Grid item xs={5}>
            {studentsProps.placementPlusVersion ===
              PlacementPlusVersion.REBUILD && (
              <Field
                name="individualStudents"
                label="Students"
                value={values.individualStudents}
                multiple
                options={studentsProps.moduleScheduleStudents}
                getOptionLabel={(option: PlacementScheduleStudentDetails) =>
                  option.displayName
                }
                isOptionEqualToValue={(
                  option: PlacementScheduleStudentDetails,
                  value: PlacementScheduleStudentDetails
                ) => option.id === value.id}
                readOnly={
                  studentsProps.type === FormType.VIEW ||
                  studentsProps.isApprover === true
                }
                limitTags={2}
                component={StaticAutocomplete}
              />
            )}

            {studentsProps.placementPlusVersion ===
              PlacementPlusVersion.LEGACY && (
              <Field
                name="individualStudents"
                label="Students"
                value={values.individualStudents}
                multiple
                getOptions={studentsProps.getModuleScheduleStudents}
                getOptionLabel={(option: PlacementScheduleStudentDetails) =>
                  option.displayName
                }
                isOptionEqualToValue={(
                  option: PlacementScheduleStudentDetails,
                  value: PlacementScheduleStudentDetails
                ) => option.id === value.id}
                readOnly={
                  studentsProps.type === FormType.VIEW ||
                  studentsProps.isApprover === true
                }
                limitTags={2}
                component={AsyncAutocompleteV2}
              />
            )}
          </Grid>

          <Grid item xs={2} display="flex">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Typography variant="headingSixForm">
                Student Count:
                {` ${values.individualStudents.length.toString()}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Stack sx={{ pl: 2 }}>
          {values.individualStudents.length > 0 && (
            <Accordion defaultExpanded={true}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Students</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table
                  type={TableType.STATIC}
                  headers={headers}
                  rows={fetchIndividualRows(values.individualStudents)}
                />
              </AccordionDetails>
            </Accordion>
          )}
          {values.individualStudents.length < 1 && (
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Students</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Table
                  type={TableType.STATIC}
                  headers={headers}
                  rows={fetchIndividualRows(values.individualStudents)}
                />
              </AccordionDetails>
            </Accordion>
          )}
        </Stack>
      </ShowIf>

      <ShowIf condition={studentsProps.isModuleStudentsLoading}>
        <SkeletonField />
        <Divider />
        <SkeletonField />
      </ShowIf>
    </Stack>
  );
}
