import React, { FC, useEffect } from 'react';
import { Box, Popover, Stack } from '@mui/material';
import {
  ListBody,
  ListHeader,
  ListHeaderNoNotifications,
  ListBodySkeleton,
  ButtonExpandList,
  ListHeaderSkeleton,
  useComponentState,
  ButtonExpandListSkeleton,
  ListMenu
} from '../components';
import {
  NOTIFICATIONS_FETCH_PAGE_SIZE,
  NOTIFICATIONS_RE_FETCH_INTERVAL_MILLISECONDS,
  MAX_NOTIFICATIONS_BADGE_TOTAL
} from '../lib';

export const NotificationsContainer: FC = () => {
  const [anchorListElement, setAnchorListElement] =
    React.useState<null | HTMLElement>(null);
  const [anchorListHeaderMenuElement, setAnchorListHeaderMenuElement] =
    React.useState<null | HTMLElement>(null);

  const {
    state,
    fetchNotifications,
    handleListExpandButtonClicked,
    handleListClosed,
    handleNotificationClicked,
    handleListHeaderMenuClosed,
    handleListHeaderMenuOpenButtonClick,
    handleNotificationButtonClicked,
    handleMarkAllAsReadMenuClicked,
    handleMarkAllAsCompletedMenuClicked,
    isListExpanded,
    isListHeaderMenuOpen
  } = useComponentState({
    anchorListElement,
    setAnchorListElement,
    anchorListHeaderMenuElement,
    setAnchorListHeaderMenuElement
  });

  useEffect(() => {
    const abortController = new AbortController();
    fetchNotifications(abortController);
    return () => {
      abortController.abort();
    };
  }, [fetchNotifications]);

  useEffect(() => {
    const abortController = new AbortController();
    const interval = setInterval(() => {
      fetchNotifications(abortController);
    }, NOTIFICATIONS_RE_FETCH_INTERVAL_MILLISECONDS);

    return () => {
      abortController.abort();
      clearInterval(interval);
    };
  }, [fetchNotifications]);

  if (!state.notifications) {
    return <ButtonExpandListSkeleton />;
  }

  return (
    <Box>
      <ButtonExpandList
        onClick={handleListExpandButtonClicked}
        totalNotifications={state.totalAllNotifications}
        maxNotifications={MAX_NOTIFICATIONS_BADGE_TOTAL}
      />

      <Popover
        open={isListExpanded}
        anchorEl={anchorListElement}
        onClose={handleListClosed}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Stack>
          {state.isFetchingNotifications ? (
            <ListHeaderSkeleton />
          ) : (state.totalAllNotifications ?? 0) > 0 ? (
            <ListHeader
              totalAllNotifications={state.totalAllNotifications ?? 0}
              totalUnreadNotifications={state.totalUnreadNotifications ?? 0}
              onMenuOpenButtonOnClick={handleListHeaderMenuOpenButtonClick}
            />
          ) : (
            <ListHeaderNoNotifications />
          )}

          {(state.totalAllNotifications ?? 0) > 0 &&
            (state.isFetchingNotifications ? (
              <ListBodySkeleton
                numberOfNotifications={NOTIFICATIONS_FETCH_PAGE_SIZE}
              />
            ) : (
              <ListBody
                notificationConfigs={state.notifications ?? []}
                onNotificationClick={handleNotificationClicked}
                onNotificationButtonClick={handleNotificationButtonClicked}
              />
            ))}

          <ListMenu
            isListMenuOpen={isListHeaderMenuOpen}
            onAllMarkedAsRead={handleMarkAllAsReadMenuClicked}
            onAllMarkedAsCompleted={handleMarkAllAsCompletedMenuClicked}
            onListMenuClosed={handleListHeaderMenuClosed}
            anchorElement={anchorListHeaderMenuElement}
          />
        </Stack>
      </Popover>
    </Box>
  );
};
