import { TableBody as MuiTableBody, TableCell, TableRow } from '@mui/material';
import { get } from 'lodash';
import { TableHeaderConfig, TableRowData } from 'src/types';

interface TableBodyProps {
  headers: TableHeaderConfig[];
  rows?: TableRowData[];
  onRowClicked?: (row: TableRowData) => void;
  checkIsRowSelected?: (row: TableRowData) => boolean;
  rowClass?: (tableRow: TableRowData) => string;
}

export const TableBody = ({
  rows,
  headers,
  onRowClicked,
  checkIsRowSelected,
  rowClass
}: TableBodyProps) => {
  if (rows === undefined) {
    return (
      <MuiTableBody>
        <TableRow key={1} data-testid={`row-${1}`}>
          <TableCell colSpan={headers.length} align="center">
            <></>
          </TableCell>
        </TableRow>
      </MuiTableBody>
    );
  }
  if (rows.length <= 0) {
    return (
      <MuiTableBody>
        <TableRow key={`row-${1}-no-data-to-display`} data-testid={`row-${1}`}>
          <TableCell colSpan={headers.length} align="center">
            No data to display
          </TableCell>
        </TableRow>
      </MuiTableBody>
    );
  } else {
    return (
      <MuiTableBody>
        {rows.map((row, rowIndex) => {
          const isSelected = checkIsRowSelected && checkIsRowSelected(row);

          return (
            <TableRow
              selected={isSelected}
              hover={!!onRowClicked}
              onClick={onRowClicked ? () => onRowClicked(row) : undefined}
              tabIndex={-1}
              key={`row-${rowIndex}-${isSelected ? 'selected' : 'unselected'}`}
              data-testid={`row-${rowIndex}`}
              className={rowClass ? rowClass(row) : ''}
            >
              {headers.map((header, cellIndex) => {
                const cellValue = get(row, header.id, '');

                return (
                  <TableCell
                    key={`${rowIndex}-${cellIndex}-${
                      cellValue ? cellValue.toString() : 'undefined'
                    }`}
                    component="th"
                    id={`row-${rowIndex}-cell-${header.id}`}
                    data-testid={`row-${rowIndex}-cell-${header.id}`}
                    scope="row"
                    padding="normal"
                  >
                    {cellValue}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </MuiTableBody>
    );
  }
};
