import { GetAPI, ModuleSchedule } from 'src/types';

export type FormProps = {
  moduleSchedule?: ModuleSchedule;
  moduleScheduleLastSelected?: ModuleSchedule;
  myModulesTabId?: MyModulesTabId;
  getMyModuleSchedules: GetAPI<ModuleSchedule>;
  onSubmit: (
    myModulesTabId?: MyModulesTabId,
    moduleScheduleId?: ModuleSchedule
  ) => void;
  isNavigationButtonsVisible: boolean;
};

export enum MyModulesTabId {
  PLACEMENT_SCHEDULES = 'placement schedules',
  SCHEDULE_THEME = 'schedule theme',
  CLASS_LIST = 'class list',
  ACTIVITIES = 'activities',
  SUPERVISORS = 'supervisors'
}

export type FormValues = {
  isNavigationButtonsVisible: boolean;
  selectedTab?: MyModulesTabId;
  moduleSchedule?: ModuleSchedule;
  moduleScheduleLastSelected?: ModuleSchedule;
};