import { AccreditationDTO, AccreditationStatusDTO } from 'src/types/dto';
import { Accreditation, AccreditationStatus } from 'src/types';
import { getDateFromDtoDateString } from 'src/lib';

export function getAccreditationStatusFromDTO(
  accreditationStatusDto: string
): AccreditationStatus {
  switch (accreditationStatusDto) {
    case AccreditationStatusDTO.NONE:
      return AccreditationStatus.NONE;
    case AccreditationStatusDTO.ACCREDITED_VALID:
      return AccreditationStatus.ACCREDITED_VALID;
    case AccreditationStatusDTO.PENDING_APPROVAL:
      return AccreditationStatus.PENDING_APPROVAL;
    case AccreditationStatusDTO.ACCREDITED_EXPIRED:
      return AccreditationStatus.ACCREDITED_EXPIRED;
    case AccreditationStatusDTO.ACCREDITED_REVOKED:
      return AccreditationStatus.ACCREDITED_REVOKED;
    default:
      throw new Error('unknown accreditation status');
  }
}

export function getAccreditationFromDTO(
  accredDTO: AccreditationDTO
): Accreditation {
  return {
    ...accredDTO,
    validFrom: getDateFromDtoDateString(accredDTO.validFrom),
    validTo: getDateFromDtoDateString(accredDTO.validTo),
    dateCreated: getDateFromDtoDateString(accredDTO.dateCreated),
    status: getAccreditationStatusFromDTO(accredDTO.status)
  } ;
}
